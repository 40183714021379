import React, { useEffect, useState,useContext } from 'react';
import { DownOutlined, SmileOutlined } from '@ant-design/icons';
import type { MenuProps } from 'antd';
import { Dropdown, Space, message } from 'antd';
import { Link } from 'react-router-dom'
import bell from '@/images/navigation/bell.png'
import triangle from '@/images/navigation/triangle.png'
import Avatar from './Avatar';
import { useLocation } from 'react-router-dom';
import { MyContext } from '@/context/MyContext';
import { GetUserDetail } from '@/services/login';
const Header: React.FC = () => {
    const {items:item,title,setItems} = useContext(MyContext)
    const [email,setEmail] = useState("")
    const { pathname } = useLocation()
    const items: MenuProps['items'] = [
        {
            key: '1',
            label: (
                <Link to="/login" onClick={() => {
                    localStorage.removeItem("Authorization")
                    message.success('Log out successfully.')
                }}>Logout</Link>
            ),
        }];
        const getUserData = async()=>{
            const res:any = await GetUserDetail()
            if(res.isSuccess){
                setEmail(res.data.email)
            }
        }
        useEffect(()=>{
            if(document.body.clientWidth > 776){
               +(localStorage.getItem('role') || 0) === 0 && getUserData()
            }
        },[])
    return (
        <header className=" flex w-full bg-[rgb(249,249,251)] py-[25px] justify-between items-center">
            <div className='flex items-center'>
                <h3 className="text-[20px] font-extrabold text-[#4D5E80] mr-[22px] m-0">{title}</h3>
                <span className='text-[#7D8FB3] text-[16px]'>{item}</span>
            </div>
            <div className='flex'>
                {/* <Avatar children={<img src={bell} />} dot={true} /> */}
                <Dropdown menu={{ items }} className='cursor-pointer ml-[19px]'>
                    <a onClick={(e) => e.preventDefault()}>
                        <Space>
                            {/* <Avatar children="WD" /> */}
                            <span>{email}</span>
                            <img src={triangle} />
                        </Space>
                    </a>
                </Dropdown>
            </div>
        </header>
    )
}
export default Header;