import React, { useEffect, useState } from 'react'
import close from '@/images/navigation/close_white.png'
import { Link, useNavigate } from 'react-router-dom'
import type { MenuProps } from 'antd';
import { Dropdown, message } from 'antd'
export default function InspectionProgess({ setOpen, uploadStatus = false, loading = false, issueData, currentStep, completeArr = [], reportStatus,progressInfo ,imageStep}: {
    loading?: boolean
    uploadStatus?: boolean
    setOpen: Function
    currentStep?: string | number
    reportStatus: ReportStatusType
    completeArr?: CompleteArrType
    issueData: IssueDataType
    progressInfo:inspectionReportAPI.GetInspectionDetailPerResponse 
    imageStep:boolean
}) {
    const navigate = useNavigate()
    let [completeNum, setCompleteNum] = useState(0)
    const [title, setTitle] = useState("")
    const [pageIdx, setPageIdx] = useState(0)
    const [firstTitle, setFirstTitle] = useState("")
    // const steps = JSON.parse(sessionStorage.getItem('steps') || "")
    const steps = JSON.parse(localStorage.getItem('steps') || "[]")
    const jumpPage = (index: number) => {
        setOpen(false)
        navigate(`/reportPhone?id=${index}`)
    }
    const items: MenuProps['items'] = [
        {
            label: (
                <div onClick={() => jumpPage(pageIdx)}>
                    <p style={{ fontWeight: 700 }}>{firstTitle}</p>
                    {title}
                </div>
            ),
            key: '0',
        },
    ]
    function checkObjectValues(obj: any) {
        return Object.values(obj).reduce((acc: any, val) => acc + val, 0);
    }
    useEffect(() => {
        // completeArr?.forEach((item, index) => {
        //     console.log(item.quality,item.argumentId);
        //     progressInfo?.info?.forEach(item2=>{
        //         if(item.argumentId === item2.argumentID){
        //             item.quality = item2.quality === 0 ? "NA" :
        //             item2.quality === 1 ? "AC" :
        //                 item2.quality === 2 ? "MI" :
        //                     item2.quality === 3 ? "MA" :
        //                         item2.quality === 4 ? "PD" : ""
        //         }
        //     })
        // })
    }, [])
    return (
        <div className='inspectionProgress'>
            <div className="mask"></div>
            <div className="triangle"></div>
            <div className='inspectionProgress_content'>
                <h3>inspection progress:<span>{completeNum + ((reportStatus.result && reportStatus.result !== "Pending")
                    // && checkObjectValues(issueData)=== Number(sessionStorage.getItem("inspectedQuantity") || 0) 
                    ? 1 : 0
                )} / {steps?.length + 2}</span></h3>

                <ul className='procedure'>
                    {completeArr?.map((item, index: number) => (
                        <Dropdown destroyPopupOnHide={true} key={index} menu={{ items }}>
                            <li className={(item.quality === "NA") ? "NA" :
                                    (item.quality === "AC") ? "AC" :
                                        (item.quality === "MA") ? "MA" :
                                            (item.quality === "MI") ? "MI" : 
                                                 (item.quality === "PD") ? 'PD':""}
                                onClick={(e) => {
                                    e.preventDefault()
                                    setPageIdx(index)
                                    setTitle(steps[index].title)
                                    setFirstTitle(steps[index].parentArgumentTitle || "")
                                }}>{index + 1}</li>
                        </Dropdown>
                    ))}
                    <li className={imageStep?'AC':""} onClick={() => {
                            if (uploadStatus || loading) {
                                message.error("Please wait until the image is uploaded.")
                                return
                            }
                            navigate('/uploadImg')
                        }}>
                        {completeArr.length + 1}</li>
                    <li className={reportStatus.result &&
                        // checkObjectValues(issueData) === Number(sessionStorage.getItem("inspectedQuantity") || 0) &&  
                        (reportStatus.result === "Fail" ? Object.values(reportStatus.failureCode.text).some(value => value !== null && value !== undefined && value !== "") : true) ? (
                        (reportStatus.result === "Fail") ? "MA" :
                            (reportStatus.result === "Pending") ? "PD" : "AC"
                    ) : ""}
                        onClick={() => {
                            if (uploadStatus || loading) {
                                message.error("Please wait until the image is uploaded.")
                                return
                            }
                            navigate('/submitReport')
                        }}>
                        {completeArr.length + 2}</li>
                </ul>
                <div className='description'>
                    {/* <div className='complete'>
                        <span>Complete</span>
                        <div></div>
                    </div> */}
                    <div className='incomplete'>
                        <span>Incomplete</span>
                        <div></div>
                    </div>
                    <div className='NA complete'>
                        <span>NA</span>
                        <div></div>
                    </div>
                    <div className='AC complete'>
                        <span>AC</span>
                        <div></div>
                    </div>
                    <div className='MI complete'>
                        <span>MI</span>
                        <div></div>
                    </div>
                    <div className='MA complete'>
                        <span>MA</span>
                        <div></div>
                    </div>
                    <div className='PD complete'>
                        <span>PD</span>
                        <div></div>
                    </div>
                </div>
                <img className='close' onClick={() => setOpen(false)} src={close} alt="" />
            </div>
        </div>
    )
} 