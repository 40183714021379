import React, { useEffect, useState, useRef } from 'react';
import { Button, Modal, message } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { useNavigate } from 'react-router-dom';
import { VerifyLastConditionalPassComment } from '@/services/inspectionReport';
const ConfirmationPopup: React.FC<{
    confirmOpen: boolean
    setConfirmOpen: React.Dispatch<React.SetStateAction<boolean>>
    conditionalPassComment: string
    id: number
}> = ({ confirmOpen, setConfirmOpen, conditionalPassComment, id }) => {
    const navigate = useNavigate()
    const [num, setNum] = useState(6)
    const [loading, setLoading] = useState(false)
    const [bottom, setButton] = useState(false)
    const [isScroll, setIsScroll] = useState(false)
    const agreementRef = useRef(null);

    const handleScroll = () => {
        const agreementDiv:any = agreementRef.current;
        if (agreementDiv) {
            const scrollHeight = agreementDiv?.scrollHeight;
            const scrollTop = agreementDiv?.scrollTop;
            const clientHeight = agreementDiv?.clientHeight;
            if (scrollTop + clientHeight >= scrollHeight) {
                setButton(true);
            } else {

            }
        }
    };
    let timer: any = null
    const confirmFn = async () => {
        setLoading(true)
        try {
            const res = await VerifyLastConditionalPassComment({ params: { id } })
            if (res?.isSuccess) {
                setConfirmOpen(false)
                clearInterval(timer)
                setConfirmOpen(false)
            } else {
                message.error(res?.message)
            }
            setLoading(false)
        } catch (error) {
            setLoading(false)
        }
    }
    function hasVerticalScrollbar(el: HTMLDivElement) {
        return el?.scrollHeight > el?.clientHeight;
    }
    useEffect(() => {
        if (confirmOpen) {
            setNum(6)
            setIsScroll(hasVerticalScrollbar(agreementRef.current!));
            timer = setInterval(() => {
                setNum((prevNum) => prevNum - 1);
            }, 1000);
        }
        return () => clearInterval(timer);
    }, [confirmOpen])
    return (
        <>
            <Modal
                title={<div><ExclamationCircleOutlined style={{ color: "#faad14" }} /> <span>Please review the CONDITION of CONDITIONAL PASS and ensure factory has properly addressed the issues.</span></div>}
                className='ConfirmationPassPopup'
                open={confirmOpen}
                onOk={() => confirmFn()}
                onCancel={() => navigate(-1)}
                okText={<>
                    OK {num > 0 && `(${num})`}
                </>}
                okButtonProps={{ disabled: isScroll ? !(bottom && num <= 0) : num > 0 ,loading}}
                closable={false}
                maskClosable={false}
            >
                <div className='modal_content' onScroll={handleScroll} ref={agreementRef}>
                    {conditionalPassComment}
                </div>

            </Modal>
        </>
    )
}
export default ConfirmationPopup