import React from 'react';
import { Button, Result } from 'antd';
import { Link } from 'react-router-dom';
const Page403:React.FC = () => {
    return (
        <Result
            status="403"
            title="403"
            subTitle="Sorry, you are not authorized to access this page."
            extra={<Link to="/login"><Button type="primary" >Back To Login</Button></Link>}
        />
    )
}
export default Page403;