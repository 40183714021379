import { useEffect, useState, useRef } from "react"
import "@/styles/addPoToInspection.less"
import { Button, message, Spin, AutoComplete } from "antd"
import type { ColumnsType } from 'antd/es/table';
import close from '@/images/navigation/big_close.svg'
import { Input, Select, Form, DatePicker, Table } from 'antd';
import { GetPODetail, AddPOToInspection } from "@/services/inspection";
import { GetInspectionRate } from "@/services/inspectionRate";
import { GetPurchaseOrder, GetPoList } from "@/services/purchaseOrder"
import { GetInspectorList } from "@/services/inspection";
import report from '@/images/navigation/report.png'
import { GetCheckList, GetActiveChecklistList } from '@/services/checkList';
import { ExportOutlined } from '@ant-design/icons'
import { Export } from "@/services/inspectionReport";
import { useNavigate, useSearchParams, Link } from "react-router-dom";
import moment from "moment";
import { debounce } from 'lodash';
const { Option } = Select;
interface ItemDataType {
    itemNo: string,
    description: string,
    quantity: string,
    comment: string,
    usedInProject: boolean
    canCreateInspection:boolean
}
interface ReportDataType {
    reportId: string
    itemNo: string,
    inspectionDate: string,
    inspectedQty: string,
    passQty: string,
    rejectQty: string,
    inspectionResult: string,
    inspectResult: string
    comment: string,
    failureCode: string,
    ViewReport: string,
    isFinal: boolean
}
const AddPo: React.FC = () => {
    const [form] = Form.useForm()
    const [poNumArr, setPoNumArr] = useState<{ value: string; label: string }[]>([])
    const [checkList, setCheckList] = useState<{ id: number; value: string }[]>([])
    const [itemData, setItemData] = useState<ItemDataType[]>([])
    const [inspectionRateList, setInspectionRateList] = useState<any>([])
    const [submitLoading, setSubmitLoading] = useState(false)
    const [reportOpen, setReportOpen] = useState(false)
    const [selectLoading, setSelectLoading] = useState(false)
    const [selectStatus, setSelectStatus] = useState(false)
    const [checkListSelectStatus, setCheckListSelectStatus] = useState(false)
    const [assigntoInspectorOptions, setAssigntoInspectorOptions] = useState([])
    const navigate = useNavigate()
    const [search] = useSearchParams()
    let inspectionId = search.get("inspectionId") || 0
    const poNumberRef = useRef<any>(null)
    const [reportParams, setReportParams] = useState({
        inspectionProjectId: +inspectionId,
        ItemNo: "",
        PONumber: ""
    })
    const [reportsData, setReportsData] = useState<ReportDataType[]>([
        // {
        //     no: "1",
        //     itemNo: "37591-MNDC",
        //     InsectionDate: "16” SWANK FLUSH-MOUNT CEILING FAN",
        //     InspectedQTY: "400",
        //     PassQTY: "80",
        //     RejectQTY: "56",
        //     InspectionResult: "Pass",
        //     FailureCode: "Labeling",
        //     Comment: "1.Wrong label. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur",
        //     ViewReport: "",
        // },
        // {
        //     no: "3",
        //     itemNo: "37591-MNDC",
        //     InsectionDate: "16” SWANK FLUSH-MOUNT CEILING FAN",
        //     InspectedQTY: "400",
        //     PassQTY: "",
        //     RejectQTY: "",
        //     InspectionResult: "Fail",
        //     FailureCode: "Functionality, Appearance ",
        //     Comment: `
        // 1.Semper a augue suscipit, luctus neque purus ipsum neque dolor primis libero tempus velna culpa expedita.
        // 2.Quisque dui at erat auctor pulvinar nisl felis, gravida et aliquam vitae, aliquet quis nibh.
        // `,
        //     ViewReport: "",
        // },
        // {
        //     no: "2",
        //     itemNo: "37591-MNDC",
        //     InsectionDate: "16” SWANK FLUSH-MOUNT CEILING FAN",
        //     InspectedQTY: "400",
        //     PassQTY: "",
        //     RejectQTY: "",
        //     InspectionResult: "Fail",
        //     FailureCode: "Functionality, Packaging",
        //     Comment: "1.Cum sociis natoque penatibus et magnis dis parturient montes, nascetur",
        //     ViewReport: "",
        // },
    ])
    const [loading, setLoading] = useState(false)
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const onFinish = async (values: any) => {
        console.log(values);
        const { poNumber, inspectionRateId, checklistId, status, assignToInspector } = values
        let id = 0
        checkList.forEach((item) => {
            if (checklistId === item.value) {
                id = +item.id
            }
        })
        console.log(id);

        console.log({
            data: {
                inspectionProjectId: +inspectionId,
                poNumber,
                inspectionRateId,
                status: +status,
                checklistId: +id,
                inspectionId: +assignToInspector,
                items: selectedRowKeys
            }
        });

        // return
        setSubmitLoading(true)
        if (selectedRowKeys.length === 0) {
            message.error('Please select at least one item')
            setSubmitLoading(false)
            return
        }
        try {
            const res: any = await AddPOToInspection({
                data: {
                    inspectionProjectId: +inspectionId,
                    poNumber,
                    inspectionRateId,
                    status: +status,
                    checklistId: +id,
                    inspectionId: +assignToInspector,
                    items: selectedRowKeys
                }
            })
            if (res.isSuccess) {
                setSubmitLoading(false)
                message.success("Successfully.")
                // getData()
                // closePage()
                // navigate('/inspections')
                navigate(-1)
            } else {
                message.error(res.message)
                setSubmitLoading(false)

            }
        } catch (error) {
            console.log(error);

        }
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };
    const itemColumns: ColumnsType<ItemDataType> = [
        {
            title: 'Item no.',
            dataIndex: 'itemNo',
            key: 'itemNo',
            width: 200,
            sorter: (a, b) => +a.itemNo.replace('-', '') - +b.itemNo.replace('-', ''),

        },
        {
            title: 'Desc.',
            dataIndex: 'description',
            key: 'description',
            sorter: (a, b) => {
                const aFirstLetter = a.description[0].toLowerCase();
                const bFirstLetter = b.description[0].toLowerCase();
                if (aFirstLetter < bFirstLetter) {
                    return -1;
                }
                if (aFirstLetter > bFirstLetter) {
                    return 1;
                }
                return 0;
            },
        },
        {
            title: 'QTY',
            dataIndex: "quantity",
            key: "quantity",
            align: "right",
            width: 100,
            sorter: (a, b) => +a.quantity - +b.quantity,
            render: (text) => (
                <span>{(text).toLocaleString()}</span>
            )
        },
        {
            title: 'Comment',
            dataIndex: 'comment',
            key: 'comment',
            sorter: (a, b) => {
                const aFirstLetter = a.comment[0]?.toLowerCase();
                const bFirstLetter = b.comment[0]?.toLowerCase();
                if (aFirstLetter < bFirstLetter) {
                    return -1;
                }
                if (aFirstLetter > bFirstLetter) {
                    return 1;
                }
                return 0;
            },
        },
    ]
    const reportsColumns: ColumnsType<ReportDataType> = [
        {
            title: 'No.',
            dataIndex: 'no',
            key: 'no',
            width: 100,
            sorter: (a, b) => +a.reportId - +b.reportId,
        },
        {
            title: 'Item no.',
            dataIndex: 'itemNo',
            key: 'itemNo',
            sorter: (a, b) => +a.itemNo.replace('-', '') - +b.itemNo.replace('-', ''),
        },
        {
            title: 'Insection Date.',
            dataIndex: 'inspectionDate',
            key: 'inspectionDate',
            width: 130,
            sorter: (a, b) => +new Date(a.inspectionDate) - +new Date(b.inspectionDate)
        },
        {
            title: 'Inspected QTY',
            dataIndex: 'inspectedQty',
            key: 'inspectedQty',
            width: 130,
            align: "right",
            sorter: (a, b) => +a.inspectedQty - +b.inspectedQty,
        },
        {
            title: 'Pass QTY',
            dataIndex: 'passQty',
            key: 'passQty',
            width: 130,
            align: "right",
            sorter: (a, b) => +a.passQty - +b.passQty,
        },
        {
            title: 'Reject QTY',
            dataIndex: 'rejectQty',
            key: 'rejectQty',
            width: 130,
            align: "right",
            sorter: (a, b) => +a.rejectQty - +b.rejectQty,
        },
        {
            title: 'Inspection Result',
            dataIndex: 'inspectResult',
            key: 'inspectResult',
            sorter: (a, b) => +a.inspectResult - +b.inspectResult,
            render: (text) => (
                <div className="flex" style={{ display: text === null ? "none" : "" }}>
                    <div className={text === 2 ? "qcStatus_pass" : text === 0 ? "qcStatus_fail" : "qcStatus_default"}>
                        <div className="round"></div>
                        <span>{text === 2 ? "Pass" : text === 0 ? "Fail" : text === 1 ? "Pending" : ""}</span>
                    </div>
                </div>
            )
        },
        {
            title: 'Failure Code',
            dataIndex: 'failureCode',
            key: 'failureCode',
            sorter: (a, b) => {
                const aFirstLetter = a.failureCode[0]?.toLowerCase();
                const bFirstLetter = b.failureCode[0]?.toLowerCase();
                if (aFirstLetter < bFirstLetter) {
                    return -1;
                }
                if (aFirstLetter > bFirstLetter) {
                    return 1;
                }
                return 0;
            },
        },
        {
            title: 'Comment',
            dataIndex: 'comment',
            key: 'comment',
            width: 365,
            sorter: (a, b) => {
                const aFirstLetter = a.comment[0]?.toLowerCase();
                const bFirstLetter = b.comment[0]?.toLowerCase();
                if (aFirstLetter < bFirstLetter) {
                    return -1;
                }
                if (aFirstLetter > bFirstLetter) {
                    return 1;
                }
                return 0;
            },
        },
        {
            title: 'View Report',
            dataIndex: 'ViewReport',
            key: 'ViewReport',
            render: (_, record) => (
                <div
                    className="flex"
                    onClick={() => {
                        setReportParams({
                            inspectionProjectId: Number(search.get("inspectionId") || 0),
                            ItemNo: record.itemNo,
                            PONumber: form.getFieldValue("poNumber")
                        })
                        setReportOpen(true)
                        // navigate('/')
                    }}>
                    <Link to={`/inspectionReport?inspectionId=${Number(search.get("inspectionId") || 0)}&itemNo=${record.itemNo}&poNumber=${form.getFieldValue("poNumber")}`}><img style={{ marginRight: "4px" }} className="mr-[4px]" src={report} />
                        <a>Report</a></Link>
                    {record?.isFinal && <div className="flex" onClick={async () => {
                        setLoading(true)
                        const res: any = await Export({ params: { PONumber: form.getFieldValue("poNumber"), ItemNo: record.itemNo } })
                        console.log(res);
                        if (res) {
                            const link = document.createElement('a');
                            link.href = URL.createObjectURL(res);
                            let currentDate = new Date();
                            let year = currentDate.getFullYear();
                            let month = currentDate.getMonth() + 1;
                            let day = currentDate.getDate();
                            let hour = currentDate.getHours();
                            let minute = currentDate.getMinutes();
                            let second = currentDate.getSeconds();
                            let nowDate = `${year}-${month}-${day}-${hour}-${minute}-${second}`
                            let inspectorName = ""
                            assigntoInspectorOptions.forEach((item: any) => {
                                if (item.value === form.getFieldValue("assignToInspector")) {
                                    inspectorName = item.label
                                }
                            })
                            link.download = `${form.getFieldValue("poNumber")}_${inspectorName}_${record.itemNo}_${nowDate}`
                            console.log(`${form.getFieldValue("poNumber")}_${inspectorName}_${record.itemNo}_${nowDate}`);

                            link.click();
                        } else {
                            message.error('Export failure.')
                        }

                        setLoading(false)
                    }} style={{ marginLeft: "20px" }}>
                        <ExportOutlined width="20px" height="20px" style={{ width: "20px", height: "20px", color: "#3B82F6" }} />
                        <a>Export</a>
                    </div>}

                </div>
            )
        },
    ]

    const changePoNum = async (PONumber: string) => {
        setLoading(true)
        setSelectedRowKeys([])
        const res: any = await GetPODetail({ params: { PONumber } })
        if (res.isSuccess) {
            setLoading(false)
            const { createdDate, vendor, assignToInspector, area, items, inspectionReports } = res.data
            const dataString = moment(createdDate, 'M/D/YYYY h:mm A')
            form.setFieldsValue({
                createdDate: dataString,
                vendor, area
            })
            setItemData(items?.map((item: any) => ({
                key: item.itemNo,
                no: "",
                ...item
            })))
            setReportsData(inspectionReports?.map((item: any) => ({ key: item.reportId, ...item })))
        }
    }
    const getPoNumber = async (Keyword?: string) => {
        setSelectLoading(true)
        const res: any = await GetPoList({ params: { Keyword } })
        if (res.isSuccess) {
            setSelectLoading(false)
            setPoNumArr(res?.data?.map((item: any) => {
                return {
                    value: item.poNumber,
                    label: item.poNumber,
                    style: { background: item.inUsed ? 'yellow' : "" }
                }
            }))
        } else {
            setPoNumArr([])
            setSelectLoading(true)
        }
    }
    const getCheckList = async () => {
        const res: any = await GetCheckList({ params: { status: "active" } })
        if (res.isSuccess) {
            setCheckList(res?.data?.data?.map((item: any) => {
                return {
                    value: item.id,
                    label: item.name
                }
            }))
        }
    }
    const getCheckListAutoComplate = async (Keyword?: string) => {
        const res: any = await GetActiveChecklistList({ params: { Keyword } })
        if (res.isSuccess) {
            setCheckList(res?.data?.map((item: { id: number, name: string }) => ({
                id: item.id,
                value: item.name,
            })))
        }
    }
    const getRateList = async () => {
        const res: any = await GetInspectionRate()
        if (res.isSuccess) {
            setInspectionRateList(res?.data?.data?.map((item: any) => ({
                value: item.id,
                label: item.optionValue
            })))
            console.log(search);
        }
    }
    const getAssigntoInspector = async () => {
        const res: any = await GetInspectorList({ params: { InspectionProjectId: Number(search.get("inspectionId") || 0) } })
        if (res.isSuccess) {
            setAssigntoInspectorOptions(res.data.map((item: any) => ({ label: item.userName, value: item.id })))
            form.setFieldValue("assignToInspector", res?.data[0]?.id)
        }
    }
    const debouncedSearch = debounce((value) => {
        getPoNumber(value);
    }, 500);
    const debouncedCheckList = debounce((value) => {
        getCheckListAutoComplate(value)
    }, 500);
    useEffect(() => {

        console.log(search);
        // form.setFieldValue("assignToInspector","email")
        getPoNumber()
        // getCheckList()
        getCheckListAutoComplate()
        getRateList()
        getAssigntoInspector()
    }, [])
    const onSelectChange = (newSelectedRowKeys: React.Key[], selectedRows: any) => {
        console.log('selectedRowKeys changed: ', selectedRows?.map((item: any) => ({
            itemNo: item.itemNo,
            quantity: item.quantity
        })));

        setSelectedRowKeys(selectedRows?.map((item: ItemDataType) => ({
            itemNo: item.itemNo,
            quantity: item.quantity
        })));
    };

    const rowSelection = {
        selectedRowKeys: selectedRowKeys.map((item: any) => item.itemNo),
        onChange: onSelectChange,
        getCheckboxProps:(record:ItemDataType)=>({
            disabled:!record.canCreateInspection,
            name:record.itemNo
        })
    };
    return (
        <>
            <section className="AddPo">
                <div className="head">
                    <h2>Add PO to Inspection</h2>
                    <img style={{ cursor: "pointer" }} onClick={() => navigate(-1)} src={close} alt="" />
                </div>
                <Spin spinning={loading} >
                    <Form
                        initialValues={{
                            po: "All",
                            status: "0"
                        }}
                        form={form}
                        name="basic"
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        layout='vertical'
                    >
                        <ul className="generalInfo">
                            <li>
                                <div className="active">General Info</div>
                                <div style={{ visibility: "hidden" }}>History</div>
                            </li>
                            <li>
                                {/* <Button style={{ background: "none" }} onClick={() => form.resetFields()}>Cancel</Button> */}
                                {/* <Button style={{ margin: "0 8px" }}>Send Email</Button> */}
                                <Button type="primary" loading={submitLoading} htmlType="submit">Save</Button>
                            </li>
                        </ul>
                        <div className="data_entry">
                            <div>
                                <Form.Item
                                    label="PO #"
                                    name="poNumber"
                                    style={{ width: "32%" }}
                                    rules={[{ required: true }]}
                                >
                                    {/* <Select
                                        className=""
                                        showSearch
                                        loading={selectLoading}
                                        onChange={changePoNum}
                                        optionFilterProp="children"
                                        filterOption={(input, option) => {
                                            return (option?.label ?? '')?.toLowerCase().includes(input.toLowerCase())
                                        }
                                        }
                                        options={poNumArr}
                                    /> */}
                                    <AutoComplete
                                        options={poNumArr}
                                        ref={poNumberRef}
                                        onChange={(value) => {
                                            // getPoNumber(value)
                                            debouncedSearch(value)
                                        }}

                                        onBlur={(e: any) => {
                                            if (!selectStatus) {
                                                form.setFieldValue("poNumber", "")
                                            }

                                        }}
                                        onSelect={(value) => {
                                            setSelectStatus(true)
                                            changePoNum(value)
                                        }}
                                        defaultActiveFirstOption
                                        filterOption={(inputValue, option) =>
                                            option!.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                        }
                                    />
                                </Form.Item>
                                <Form.Item
                                    label="Created Date"
                                    name="createdDate"
                                    style={{ width: "32%" }}
                                >
                                    <DatePicker
                                        format="YYYY-MM-DD"
                                        disabled
                                        style={{ width: "100%" }} />
                                </Form.Item>
                                <Form.Item
                                    label="Inspection Rate %"
                                    name="inspectionRateId"
                                    style={{ width: "32%" }}
                                    rules={[{ required: true }]}
                                >
                                    <Select
                                        className=""
                                        options={inspectionRateList}
                                    />
                                </Form.Item>
                                <Form.Item
                                    label="Inspection Checklist Form"
                                    name="checklistId"
                                    style={{ width: "100%" }}
                                    rules={[{ required: true }]}
                                >
                                    {/* <Select
                                        className=""
                                        options={checkList}
                                        showSearch
                                        filterOption={(input, option) => {
                                            return (option?.label ?? '')?.toLowerCase().includes(input.toLowerCase())
                                        }
                                        }
                                    /> */}
                                    <AutoComplete
                                        options={checkList}
                                        onBlur={() => {
                                            if (!checkListSelectStatus) {
                                                form.setFieldValue("checklistId", "")
                                            }
                                        }}
                                        onSelect={(value, option) => {
                                            getCheckListAutoComplate(value)
                                            setCheckListSelectStatus(true)
                                        }}
                                        onChange={(value) => {
                                            setCheckListSelectStatus(false)
                                            // getCheckListAutoComplate(value)
                                            debouncedCheckList(value)
                                        }}
                                        defaultActiveFirstOption
                                        filterOption={(inputValue, option) => {
                                            // console.log(inputValue,option);

                                            return option!.value?.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                        }

                                        }
                                    />
                                </Form.Item>
                            </div>
                            <div>
                                <Form.Item
                                    label="Vendor"
                                    name="vendor"
                                    style={{ width: "80%" }}
                                >
                                    <Input disabled />
                                </Form.Item>
                                <Form.Item
                                    label="Status"
                                    name="status"
                                    style={{ width: "18%" }}
                                // rules={[{ required: true }]}
                                >
                                    <Select
                                        disabled
                                        className=""
                                        options={[
                                            { label: "To Do", value: "0" },
                                            { label: "Active", value: "1" },
                                            { label: "Fail", value: "2" },
                                            { label: "Completed ", value: "3" },
                                        ]}
                                    />
                                </Form.Item>
                                <Form.Item
                                    label="Assign to Inspector"
                                    name="assignToInspector"
                                    style={{ width: "100%" }}
                                    rules={[{ required: true }]}
                                >
                                    <Select
                                        className=""
                                        options={assigntoInspectorOptions}
                                    />
                                    {/* <Input disabled /> */}
                                </Form.Item>
                                {/* <Form.Item
                                    label="Area"
                                    name="area"
                                    style={{ width: "48%" }}
                                >
                                    <Input disabled />
                                </Form.Item> */}
                            </div>
                        </div>
                        <div className="tableContent">
                            <h3><span style={{ color: "red" }}>*</span> Items {itemData.length > 0 && selectedRowKeys.length === 0 && <span style={{ color: "red" }}>Please select at least one item</span>}</h3>
                            <Table
                                className="poTable"
                                columns={itemColumns}
                                dataSource={itemData}
                                scroll={{ y: 200 }}
                                pagination={false}
                                rowSelection={rowSelection}
                                rowClassName={(record, index) => {
                                    if (record.usedInProject) {
                                        return 'selected';
                                    }
                                    return '';
                                }}
                            />
                            <h3 style={{ marginTop: "25px" }}>Inspection Reports</h3>
                            <Table
                                className="poTable"
                                columns={reportsColumns}
                                dataSource={reportsData}
                                scroll={{ y: 200 }}
                                pagination={false}
                            />
                        </div>
                    </Form>
                </Spin>
            </section>

        </>
    )
}
export default AddPo