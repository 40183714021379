import { useEffect, useState, useRef } from "react"
import "@/styles/inspectionReport.less"
import type { ColumnsType } from 'antd/es/table';
import close from '@/images/navigation/big_close.svg'
import { Input, Checkbox, Form, DatePicker, Table, Spin, message, Image, Button, Upload } from 'antd';
import report from '@/images/navigation/report.png'
import pass from '@/images/navigation/pass.svg'
import pending from '@/images/navigation/pending.png'
import fail from '@/images/navigation/fail.png'
import email from '@/images/navigation/email.png'
import print from '@/images/navigation/print.png'
import ReactToPrint from 'react-to-print';
import type { UploadProps, UploadFile } from 'antd';
import { ExclamationCircleFilled, InboxOutlined, DeleteTwoTone } from '@ant-design/icons';
import { useSearchParams, useNavigate } from "react-router-dom";
import { GetReportDetail } from "@/services/purchaseOrder";
import { VerifyReport, GetFile } from "@/services/inspectionReport";
import img from '@/images/navigation/img.png'
import moment from "moment";
import ViewImg from "@/page/inspections/comonents/ViewImg";
const { Dragger } = Upload;
const InspectionReport: React.FC = () => {
    const { TextArea } = Input
    const [form] = Form.useForm()
    const [result, setResult] = useState<any>("")
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [fileIdArr, setFileIdArr] = useState([])
    const [loading, setLoading] = useState(false)
    const [isFinal, setIsFinal] = useState(false)
    const navigate = useNavigate()
    const [issueData, setIssueData] = useState<IssueDataType>({
        Critical: 0,
        CriticalAccept: 0,
        MajorAccept: 0,
        MajorReject: 0,
        MinorAccept: 0,
        MinorReject: 0
    })
    const [search] = useSearchParams()
    const [verified, setVerified] = useState({
        verifiedBy: "",
        verifiedDate: ""
    })
    const [failureCode, setFailureCode] = useState({
        Functionality: false,
        Structure: false,
        Appearance: false,
        Certification: false,
        Packaging: false,
        Labeling: false,
    })
    const reportParams = {
        // inspectionProjectId: search.get("inspectionId"),
        PurchaseOrder_InspectionProjectId: search.get("itemId"),
        ItemNo: search.get("itemNo"),
        PONumber: search.get("poNumber")
    }
    const onFinish = (values: any) => {
        console.log('Success:', values);
    };
    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };
    interface DataType {
        key: React.Key;
        details: string,
        value: string,
        NA: boolean | "",
        AC: boolean | "",
        MI: boolean | "",
        MA: boolean | "",
        PD: boolean | "",
        images: string,
        comment: string,
        list: number,
        category: string
        categoryLength: number
        children?: DataType[]
    }
    const inspectionReportRef = useRef(null)
    const [ConditionalFileList, setConditionalFileList] = useState<UploadFile[]>([])
    const [fileLoading, setFileLoading] = useState(false)
    const [data, setData] = useState<DataType[]>([
        // {
        //     key: 1,
        //     details: `Self-inspection report`,
        //     value: "773546326643",
        //     NA: false,
        //     AC: false,
        //     MI: false,
        //     MA: false,
        //     images: ``,
        //     comment: `Cum sociis natoque penatibus et magnis dis parturient montes`,
        //     list: 1
        // },
        // {
        //     key: 2,
        //     details: `Production finish over 80%`,
        //     value: "773546326643",
        //     NA: false,
        //     AC: false,
        //     MI: false,
        //     MA: false,
        //     images: ``,
        //     comment: `Cum sociis natoque penatibus et magnis dis parturient montes`,
        //     list: 1
        // },
        // {
        //     key: 3,
        //     details: "Package check",
        //     value: "",
        //     NA: "",
        //     AC: "",
        //     MI: "",
        //     MA: "",
        //     images: ``,
        //     comment: ``,
        //     list: 1,
        //     children: [
        //         {
        //             key: 13,
        //             details: `Shipping Marks`,
        //             value: "773546326643",
        //             NA: false,
        //             AC: false,
        //             MI: false,
        //             MA: false,
        //             images: ``,
        //             comment: `Cum sociis natoque penatibus et magnis dis parturient montes`,
        //             list: 2,
        //         },
        //         {
        //             key: 14,
        //             details: `Shipping Marks`,
        //             value: "773546326643",
        //             NA: false,
        //             AC: false,
        //             MI: false,
        //             MA: false,
        //             images: ``,
        //             comment: `Cum sociis natoque penatibus et magnis dis parturient montes`,
        //             list: 2,
        //         },
        //         {
        //             key: 15,
        //             details: `Shipping Marks`,
        //             value: "773546326643",
        //             NA: false,
        //             AC: false,
        //             MI: false,
        //             MA: false,
        //             images: ``,
        //             comment: `Cum sociis natoque penatibus et magnis dis parturient montes`,
        //             list: 2,
        //         },
        //         {
        //             key: 16,
        //             details: `Shipping Marks`,
        //             value: "773546326643",
        //             NA: false,
        //             AC: false,
        //             MI: false,
        //             MA: false,
        //             images: ``,
        //             comment: `Cum sociis natoque penatibus et magnis dis parturient montes`,
        //             list: 2,
        //         },
        //     ]
        // },

    ])
    const columns: ColumnsType<DataType> = [
        {
            title: 'Details',
            dataIndex: 'details',
            // width: 150,
            render: (text, record) => (
                <div className={record?.list === 1 ? "detail1" : "detail2"}>{text}</div>
            )

        },
        {
            title: 'Value',
            dataIndex: 'standard',
            // width: 150,
            className: "standard",
            render: (text, record) => (
                <div className="comment">
                    {text?.indexOf("http") !== -1 ? <Image width={50} src={text} /> : <>{text}</>}
                </div>
            )
        },
        {
            title: 'NA',
            dataIndex: 'NA',
            align: "center",
            width: 100,
            render: (_, record, index) => (
                record.NA !== "" && <div className="reportTd"
                >
                    <div className="round" style={{ background: record.NA ? "#7D8FB3" : "" }}>
                        {record.NA && <svg width="100%" height="100%" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="12.749" cy="12" r="7.5" fill="white" stroke="#7D8FB3" strokeWidth="9" />
                        </svg>}
                    </div>
                </div>
            )
        },
        {
            title: 'AC',
            dataIndex: 'AC',
            align: "center",
            className: "ac",
            width: 100,
            render: (_, record, index) => (
                record.AC !== "" && <div className="reportTd"
                >
                    <div className="round" style={{ background: record.AC ? "#98C9A6" : "" }}>
                        {record.AC && <svg width="100%" height="100%" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="12.749" cy="12" r="7.5" fill="white" stroke="#98C9A6" strokeWidth="9" />
                        </svg>}
                    </div>
                </div>
            )
        },
        {
            title: 'MI',
            dataIndex: 'MI',
            align: "center",
            className: "mi",
            width: 100,
            render: (_, record, index) => (
                record.MI !== "" && <div className="reportTd"
                >
                    <div className="round" style={{ background: record.MI ? "#E3BA7B" : "" }}>
                        {record.MI && <svg width="100%" height="100%" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="12.749" cy="12" r="7.5" fill="white" stroke="#E3BA7B" strokeWidth="9" />
                        </svg>}
                    </div>
                </div>
            ),
        },
        {
            title: 'MA',
            dataIndex: 'MA',
            align: "center",
            className: "ma",
            width: 100,
            render: (_, record, index) => {
                return (
                    record.MA !== "" && <div className="reportTd"
                    >
                        <div className="round" style={{ background: record.MA ? "#E37B7B" : "" }}>
                            {record.MA && <svg width="100%" height="100%" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="12.749" cy="12" r="7.5" fill="white" stroke="#E37B7B" strokeWidth="9" />
                            </svg>}
                        </div>
                    </div>
                )
            }
        },
        {
            title: 'PD',
            dataIndex: 'PD',
            align: "center",
            className: "pd",
            width: 100,
            render: (_, record, index) => {
                return (
                    record.PD !== "" && <div className="reportTd">
                        <div className="round" style={{ background: record.PD ? "#3B82F6" : "" }}>
                            {record.PD && <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 24 24" fill="none">
                                <circle cx="12" cy="12" r="7.5" fill="white" stroke="#3B82F6" stroke-width="9" />
                            </svg>}
                        </div>
                    </div>
                )
            }
        },
        {
            title: 'Images',
            dataIndex: 'images',
            align: "center",
            width: 100,
            onCell: (record, index) => {
                if (record?.category) {
                    if (data[index! - 1]?.category === record?.category) {
                        return { rowSpan: 0 }
                    } else {
                        return { rowSpan: record.categoryLength }
                    }
                } else {
                    return { rowSpan: 1 }
                }
            },
            render: (text) => (
                text?.length > 0 && (<div style={{ cursor: "pointer" }} onClick={() => {
                    setIsModalOpen(true);
                    setFileIdArr(text)
                }}><img src={img} alt="" /> {text.length}</div>)
            )
        },
        {
            title: 'Comment',
            dataIndex: 'comment',
            render: (text) => (
                <div className="comment">{text}</div>
            )
        },
    ];
    const getFileList = async (fileIdArr: number[]) => {
        setFileLoading(true)
        const arr: any = fileIdArr.map(async (fileId: number) => {
            // fileList: [1,2,3].map(async (fileId: number) => {
            const res: any = await GetFile({ params: { fileId } })
            const contentDisposition = res.headers['content-disposition']
            const filenameMatches = /filename=([^;]+)/g.exec(contentDisposition);
            let name = filenameMatches ? filenameMatches[1] : "Unknown"
            delete res.headers
            return {
                uid: fileId,
                name,
                url: URL.createObjectURL(res),
                status: 'done',
                response: { data: [fileId] }
            }
        })
        Promise.all(arr).then(res => {
            // setGetFileStatus(true)
            setConditionalFileList([...res])
            setFileLoading(false)


        })
    }
    const GetReportData = async () => {
        setLoading(true)
        try {
            const res: any = await GetReportDetail({ params: reportParams })
            if (res.isSuccess) {
                const { steps, failureCodes,conditionalPassFiles, critical, criticalAccept, majorAccept, majorReject, minorAccept, minorReject, isFinal } = res.data
                setIsFinal(isFinal)
                form.setFieldsValue({
                    Description: res.data.description,
                    Vendor: res.data.description,
                    InspectionDate: moment(res.data.createdDate, 'M/D/YYYY h:mm A'),
                    pono: res.data.poNumber,
                    poqty: res.data.poQuantity,
                    InspectedQty: res.data.inspectedQuantity,
                    ModelNumber: res.data.itemNo,
                    Inspectionby: res.data.inspectionBy,
                    Comment: res.data.comment,
                    finalCommentsRef: res.data.finalComments,
                    producedQuantity: res.data.producedQuantity,
                    ConditionaComment: res.data.conditionalPassComment,
                    aqlQty:res.data.aqlQty,
                    insType:res.data.insType

                })
                setVerified({
                    verifiedBy: res.data.verifiedBy,
                    verifiedDate: res.data.verifiedDate
                })
                setResult(res.data.result)
                failureCodes?.forEach((item: any) => {
                    if (item.failureCode === 0) {
                        failureCode["Functionality"] = true
                        form.setFieldValue("Functionality", item.comment)
                    } else if (item.failureCode === 1) {
                        failureCode["Structure"] = true
                        form.setFieldValue("Structure", item.comment)
                    } else if (item.failureCode === 2) {
                        failureCode["Appearance"] = true
                        form.setFieldValue("Appearance", item.comment)
                    } else if (item.failureCode === 3) {
                        failureCode["Certification"] = true
                        form.setFieldValue("Certification", item.comment)
                    } else if (item.failureCode === 4) {
                        failureCode["Packaging"] = true
                        form.setFieldValue("Packaging", item.comment)
                    } else if (item.failureCode === 5) {
                        failureCode["Labeling"] = true
                        form.setFieldValue("Labeling", item.comment)
                    }
                })
                conditionalPassFiles && getFileList(conditionalPassFiles.map((x:any)=>x.fileID))
                setFailureCode(JSON.parse(JSON.stringify(failureCode)))
                let newData = steps.map((item: any, index: number) => {
                    return {
                        key: index,
                        list: 1,
                        argumentId: item.argumentId,
                        details: item.title,
                        comment: item.comment,
                        standard: item.standard,
                        images: item.imageIds,
                        category: item.parentArgumentTitle,
                        NA: item.quality === 0,
                        AC: item.quality === 1,
                        MI: item.quality === 2,
                        MA: item.quality === 3,
                        PD: item.quality === 4,
                    }
                })
                let arr = newData.map((item: any) => {
                    if (item.category) {
                        return item.category
                    } else {
                        return item.details
                    }
                })
                const result = arr.reduce((acc: string[][], item: any) => {
                    const lastGroup = acc[acc.length - 1];
                    if (lastGroup && lastGroup[0] === item) {
                        lastGroup.push(item);
                    } else {
                        acc.push([item]);
                    }
                    return acc;
                }, []);
                newData.forEach((item: any) => {
                    result.forEach((item2: string[]) => {
                        if (item.category === item2[0]) {
                            item.categoryLength = item2.length
                        }
                    })
                })
                setData(newData)
                setIssueData({
                    Critical: critical,
                    CriticalAccept: criticalAccept,
                    MajorAccept: majorAccept,
                    MajorReject: majorReject,
                    MinorAccept: minorAccept,
                    MinorReject: minorReject
                })
                setLoading(false)
            } else {
                message.error(res.message)
            }
        } catch (error: any) {
            console.log(error);
            message.error(error.message)
        }
    }
    useEffect(() => {
        GetReportData();
    }, [])

    return (
        <section className="inspectionReport" >
            <div className="head">
                <h2>Inspection Report</h2>
                <img style={{ cursor: "pointer" }} onClick={() => navigate(-1)} src={close} alt="" />
            </div>
            <Spin spinning={loading}>
                <Form
                    form={form}
                    name="basic"
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    layout='vertical'
                >
                    <ul className="generalInfo">
                        <li>
                            <div className="active">General Info</div>
                            <div style={{ visibility: "hidden" }}>History</div>
                        </li>
                        <li>

                        </li>
                    </ul>

                    <div ref={inspectionReportRef}>
                        <div className="data_entry">
                                <Form.Item
                                    label="Model Number"
                                    name="ModelNumber"
                                // rules={[{ required: true, message: 'Please input your username!' }]}
                                >
                                    <Input disabled />
                                </Form.Item>
                                <Form.Item
                                    label="Vendor"
                                    name="Vendor"
                                    style={{gridColumn:"span 2"}}
                                >
                                    <Input disabled />
                                </Form.Item>
                                <Form.Item
                                    label="P. O. No."
                                    name="pono"
                                >
                                    <Input disabled />
                                </Form.Item>
                                <Form.Item
                                    label="INS TYPE"
                                    name="insType"
                                >
                                    <Input disabled />
                                </Form.Item>
                                <Form.Item
                                    label="P.O. Q’ty"
                                    name="poqty"
                                >
                                    <Input disabled />
                                </Form.Item>
                                <Form.Item
                                    label="Description"
                                    name="Description"
                                    style={{gridColumn:"span 3"}}
                                >
                                    <Input disabled />
                                </Form.Item>
                                <Form.Item
                                    label="Produced Q’ty"
                                    name="producedQuantity"
                                >
                                    <Input disabled />
                                </Form.Item>
                                <Form.Item
                                    label="Inspected Q’ty"
                                    name="InspectedQty"
                                >
                                    <Input disabled />
                                </Form.Item>
                                <Form.Item
                                    label="AQL Q’ty"
                                    name="aqlQty"
                                >
                                    <Input disabled />
                                </Form.Item>
                                <Form.Item
                                    label="Inspection Date"
                                    name="InspectionDate"
                                    style={{gridColumn:"span 3"}}
                                >
                                    <DatePicker disabled style={{ width: "100%" }} />
                                </Form.Item>
                                <Form.Item
                                    label="Inspection by"
                                    name="Inspectionby"
                                    style={{gridColumn:"span 3"}}
                                >
                                    <Input disabled />
                                </Form.Item>
                        </div>
                        <div className="pieviewBox_" style={{ padding: "0 20px" }}>
                            <table className="table2" style={{ width: "100%" }}>
                                <tbody>
                                    <tr>
                                        <td style={{ background: "#7D8FB3", color: "white", fontSize: "14px", border: "1px solid #7D8FB3" }} colSpan={4}>Critical Issue</td>
                                    </tr>
                                    <tr>
                                        <td>Critical</td>
                                        <td>{issueData?.Critical || 0}</td>
                                        <td>Accept</td>
                                        <td>{issueData?.CriticalAccept || 0}</td>
                                    </tr>
                                    <tr>
                                        <td style={{ background: "#7D8FB3", color: "white", fontSize: "14px", border: "1px solid #7D8FB3" }} colSpan={4}>Major Issue</td>
                                    </tr>
                                    <tr>
                                        <td>Accept</td>
                                        <td>{issueData?.MajorAccept || 0}</td>
                                        <td>Reject</td>
                                        <td>{issueData?.MajorReject || 0}</td>
                                    </tr>
                                    <tr>
                                        <td style={{ background: "#7D8FB3", color: "white", fontSize: "14px", border: "1px solid #7D8FB3" }} colSpan={4}>Minor Issue</td>
                                    </tr>
                                    <tr>
                                        <td>Accept</td>
                                        <td>{issueData?.MinorAccept || 0}</td>
                                        <td>Reject</td>
                                        <td>{issueData?.MinorReject || 0}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div className="report">
                            <Table className="reportTable" bordered={false} columns={columns} dataSource={data} pagination={false} scroll={{ y: 400 }} />
                        </div>
                        <section className="inspection_result">
                            <div className="result">
                                <h3>Inspection Result</h3>
                                <ul className="">
                                    <li className="Pass" style={{ opacity: result == 2 ? "" : "0.25", marginRight: "8px" }}>
                                        <img src={pass} alt="" />
                                        pass
                                    </li>
                                    <li className="Pending" style={{ opacity: result == 1 ? "" : "0.25" }}>
                                        <img src={pending} alt="" />
                                        Pending
                                    </li>
                                    <li className="Fail" style={{ opacity: result == 0 ? "" : "0.25" }}>
                                        <img src={fail} alt="" />
                                        Fail
                                    </li>
                                    {result === 3 && <li className="Pass" onClick={() => setResult(3)} style={{ opacity: result == 3 ? "" : "0.25", marginLeft: "8px" }}>
                                        <img src={pass} alt="" />
                                        Conditional Pass
                                    </li>}
                                </ul>
                            </div>
                            <h5>Failure Code</h5>
                            <ul className="failure_code">
                                <li>
                                    <Checkbox disabled checked={failureCode.Functionality} onChange={(e) => setFailureCode({ ...failureCode, Functionality: e.target.checked })} >Functionality</Checkbox>
                                    <Form.Item name="Functionality"><Input readOnly disabled autoFocus /></Form.Item>
                                </li>
                                <li>
                                    <Checkbox disabled checked={failureCode.Structure} onChange={(e) => setFailureCode({ ...failureCode, Structure: e.target.checked })}  >Structure</Checkbox>
                                    <Form.Item name="Structure"><Input readOnly disabled autoFocus={failureCode.Structure} /></Form.Item>
                                </li>
                                <li>
                                    <Checkbox disabled checked={failureCode.Appearance} onChange={(e) => setFailureCode({ ...failureCode, Appearance: e.target.checked })}  >Appearance</Checkbox>
                                    <Form.Item name="Appearance"><Input readOnly disabled autoFocus={failureCode.Appearance} /></Form.Item>

                                </li>
                                <li>
                                    <Checkbox disabled checked={failureCode.Certification} onChange={(e) => setFailureCode({ ...failureCode, Certification: e.target.checked })}  >Certification</Checkbox>
                                    <Form.Item name="Certification"><Input readOnly disabled autoFocus={failureCode.Certification} /></Form.Item>
                                </li>
                                <li>
                                    <Checkbox disabled checked={failureCode.Packaging} onChange={(e) => setFailureCode({ ...failureCode, Packaging: e.target.checked })}  >Packaging</Checkbox>
                                    <Form.Item name="Packaging"><Input readOnly disabled autoFocus={failureCode.Packaging} /></Form.Item>

                                </li>
                                <li>
                                    <Checkbox disabled checked={failureCode.Labeling} onChange={(e) => setFailureCode({ ...failureCode, Labeling: e.target.checked })}  >Labeling</Checkbox>
                                    <Form.Item name="Labeling"><Input readOnly disabled autoFocus={failureCode.Labeling} /></Form.Item>

                                </li>
                            </ul>
                            <h5>Comment </h5>
                            <Form.Item name="Comment">
                                <TextArea disabled style={{ height: 130 }} />
                            </Form.Item>
                            {result === 3 && <div style={{ marginBottom: "15px" }}>
                                <h5>Conditional Comment</h5>
                                <Form.Item name="ConditionaComment">
                                    <TextArea disabled style={{ height: 130 }} />
                                </Form.Item>
                                <h5>Upload Conditional File</h5>
                                <Spin
                                    spinning={fileLoading}>
                                    <Dragger
                                        disabled
                                        name='formFiles'
                                        action="api/Common/UploadFile"
                                        headers={{
                                            Authorization: localStorage.getItem('Authorization') || '',
                                        }}
                                        fileList={ConditionalFileList}
                                        multiple
                                        onChange={(info) => {
                                            const { status } = info.file;
                                            if (status !== 'uploading') {
                                                console.log(info.file, info.fileList);
                                            }
                                            if (status === 'done') {
                                                message.success(`${info.file.name} file uploaded successfully.`);
                                                setConditionalFileList([...info.fileList])
                                            } else if (status === 'error') {
                                                message.error(`${info.file.name} file upload failed.`);
                                            }
                                            console.log(status);
                                            setConditionalFileList([...info.fileList])

                                        }}
                                        onDrop={(e) => {
                                            console.log('Dropped files', e.dataTransfer.files);
                                        }}
                                        itemRender={(originNode, file: any) => {
                                            return (
                                                <>
                                                    <div className="uploadBox_">
                                                        <a
                                                            onClick={(e) => e.stopPropagation()}
                                                            href={file.url || URL.createObjectURL(file.originFileObj)}
                                                            download={file.name}
                                                            className="download-link"
                                                            style={{ width: '50%' }}
                                                        >
                                                            {file.name}
                                                        </a>
                                                        {/* <span style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                            <DeleteTwoTone onClick={async () => {
                                                                const newFileList = [...ConditionalFileList];
                                                                newFileList.splice(newFileList.findIndex(item => item.uid === file.uid), 1);
                                                                setConditionalFileList([...newFileList])
                                                            }
                                                            } />
                                                        </span> */}
                                                    </div>
                                                </>
                                            )
                                        }}
                                    >
                                        <p className="ant-upload-drag-icon">
                                            <InboxOutlined />
                                        </p>
                                        <p className="ant-upload-text">Click or drag file to this area to upload</p>
                                        <p className="ant-upload-hint">
                                            Support for a single or bulk upload.
                                        </p>
                                    </Dragger>
                                </Spin>

                            </div>}
                            {isFinal && (
                                <>
                                    <h5>Final Comments *</h5>
                                    <Form.Item name="finalCommentsRef">
                                        <TextArea style={{ height: 130 }} />
                                    </Form.Item>
                                    <div className="verified">
                                        <div><h5>Verified By:</h5> <span>{verified.verifiedBy}</span></div>
                                        <div><h5>Verified Date:</h5> <span>{verified.verifiedDate}</span></div>
                                    </div>
                                </>
                            )}

                        </section>
                    </div>

                    <ul className="submitData">
                        <li>
                            {/* <Button><img src={email} alt="" /> Send Email</Button>

                        <ReactToPrint
                            trigger={() => <Button><img src={print} alt="" /> Print Report</Button>}
                            content={() => inspectionReportRef.current}
                        /> */}
                        </li>
                        <li>
                            {/* <Button type="primary" disabled htmlType="submit">Summit Report</Button> */}
                            {isFinal && <Button type="primary" onClick={async () => {
                                if (!form.getFieldValue('finalCommentsRef')) {
                                    message.error('Final Comments are required.')
                                    return
                                }
                                try {
                                    setLoading(true)
                                    const res: any = await VerifyReport({
                                        data: {
                                            purchaseOrder_InspectionProjectId: +(search.get("itemId") || 0),
                                            itemNo: search.get("itemNo"),
                                            poNumber: search.get("poNumber"),
                                            finalComments: form.getFieldValue('finalCommentsRef')
                                        }
                                    })
                                    if (res.isSuccess) {
                                        message.success('Successfully.')
                                        // setLoading(false)
                                        GetReportData()
                                    } else {
                                        setLoading(false)
                                    }
                                } catch (error) {
                                    console.log(error);
                                    setLoading(false)
                                }
                            }} >Save</Button>}
                        </li>
                    </ul>
                </Form>
            </Spin>

            <ViewImg fileIdArr={fileIdArr} isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />
        </section>

    )
}
export default InspectionReport