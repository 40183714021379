import React from 'react'
import Login from '../page/login'
import Home from '../page'
import Inspections from '@/page/inspections'
import InspectionPhone from '@/page/inspectionPhone/inspectionPhone'
import InspectionList from '@/page/inspectionPhone/inspectionList'
import InspectionDetail from '@/page/inspectionPhone/inspectionDetail'
import Report from '@/page/inspectionPhone/report'
import Report01 from '@/page/inspectionPhone/reportPage/report01'
import Report03 from '@/page/inspectionPhone/reportPage/report02'
import SubmitReport from '@/page/inspectionPhone/reportPage/submitReport'
import CheckList from '@/page/checkList'
import EditCheckList from '@/page/editCheckList'
import EditCheckList2 from '@/page/editCheckList2'
import PurchaseOrders from '@/page/purchaseOrder'
import NewCheckList from '@/page/newCheckList'
import ReportPhone from '@/page/inspectionPhone/inspectionReportPhone'
import User from '@/page/user'
import AddPOToInspection  from '@/page/addPoToInspection'
import EditPoToInspection from '@/page/editPoToInspection'
import InspectionReport from '@/page/inspectionReport'
import ViewCheckList from '@/page/viewCheckList'
import InspectionPCReport from '@/page/inspectionPhone/inspectionPCrReport'
import NewCheckList2 from '@/page/newCheckList2'
import Attributes from '@/page/attributes'
import Page403 from '@/page/403'
import UploadImg from '@/page/inspectionPhone/reportPage/uploadImg'
import ConditionalPassReport from '@/page/conditionalPassReport'
import ConditionalPassReportPublic from '@/page/conditionalPassReportPublic'
import { HashRouter as Router, Routes, Route,Navigate } from 'react-router-dom'
const BaseRouter = () => {
    return (
        <Router>
            <Routes>
                <Route path="/"  element={<Home />} >
                    <Route path="/inspections" element={<Inspections />} />
                    <Route path="/checkList"  element={<CheckList />} ></Route>
                    <Route path="/attributes"  element={<Attributes />} ></Route>
                    <Route path="/users"  element={<User />} ></Route>
                    <Route path="/purchaseOrders"  element={<PurchaseOrders />} ></Route>
                    <Route path="/newCkeckList"  element={<NewCheckList />} ></Route>
                    <Route path="/newCkeckList2"  element={<NewCheckList2 />} ></Route>
                    <Route path="/editCheckList"  element={<EditCheckList />} ></Route>
                    <Route path="/editCheckList2"  element={<EditCheckList2 />} ></Route>
                    <Route path="/viewCheckList"  element={<ViewCheckList />} ></Route>
                    <Route path="/inspectionReport"  element={<InspectionReport />} ></Route>
                    <Route path="/conditionalPassReport"  element={<ConditionalPassReport />} ></Route>
                </Route>
                <Route path="/conditionalPassReportPublic"  element={<ConditionalPassReportPublic />} ></Route>
                <Route path="/login"  element={<Login />} ></Route>
                <Route path="/addPOToInspection"  element={<AddPOToInspection />} ></Route>
                <Route path="/editPoToInspection"  element={<EditPoToInspection />} ></Route>
                <Route path="/403" element={<Page403 />}></Route>
                <Route path="/inspectionPCReport" element={<InspectionPCReport />}></Route>
                {/* {document.body.clientWidth <= 766 ? ( */}
                    <>
                        <Route  path="/inspectionPhone" element={<InspectionPhone />} />
                        <Route path="/inspectionList" element={<InspectionList />} />
                        <Route path="/inspectionDetail" element={<InspectionDetail />} />
                        <Route path="/reportPhone" element={<ReportPhone />} />
                        <Route path="/uploadImg" element={<UploadImg />} />
                        <Route path="/submitReport" element={<SubmitReport />} />
                        <Route path="/report" element={<Report />} >
                            <Route path="/report/report01" element={<Report01 />} />
                            <Route path="/report/report03" element={<Report03 />} />
                        </Route>
                    </>
                {/* ) : (<></>)} */}
            </Routes>
        </Router>
    )
}

export default BaseRouter;