import React, { useState } from 'react'
import '@/styles/inspectionPhone/inspectionReportPhone.less'
import type { RcFile, UploadFile, UploadProps } from 'antd/es/upload/interface';
import { Button, Input, Upload } from 'antd'
import { DownOutlined } from '@ant-design/icons';
import type { MenuProps } from 'antd';
import { Dropdown, Space } from 'antd';
import more from '@/images/navigation/more.png'
import save from '@/images/navigation/save.png'
import exit from '@/images/navigation/exit.png'
import progress from '@/images/navigation/progress.png'
import submit from '@/images/navigation/submit.png'
import close from '@/images/navigation/close_white.png'
import InspectionProgess from '@/components/InspectionProgess';
import { Outlet } from 'react-router-dom'
import warn from '@/images/navigation/warn.png'
export default function Report() {
    const [open, setOpen] = useState(false)

    const items: MenuProps['items'] = [
        {
            label: (
                <div className='dropdown_' >
                    <img src={save} alt="" />
                    Save Report
                </div>
            ),
            key: '0',
        },
        {
            label: (
                <div className='dropdown_'>
                    <img src={exit} alt="" />
                    Save & Exit
                </div>
            ),
            key: '1',
        },
        {
            label: (
                <div className='dropdown_' onClick={() => setOpen(true)}>
                    <img src={progress} alt="" />
                    Inspection Progress
                </div>
            ),
            key: "2"
        },
        {
            label: (
                <div className='dropdown_'>
                    <img src={submit} alt="" />
                    Submit Report
                </div>
            ),
            key: "3"
        }
    ];
    return (
        <>
            <section className='inspectionReportPhone'>
                <div className='report_hearder'>
                    <div className='phone_header'>
                        <span></span>
                        <span>Inspection Report</span>
                        <Dropdown menu={{ items }}>
                            <a onClick={(e) => e.preventDefault()}>
                                <Space>
                                    <img style={{ width: "5vw" }} src={more} alt="" />
                                </Space>
                            </a>
                        </Dropdown>
                    </div>
                    <ul className="progress">
                        <li>
                            <span>Current</span>
                            <p>1/50</p>
                        </li>
                        <li>
                            <span></span>
                            <div className='progress_bar'>
                                <div></div>
                            </div>
                        </li>
                        <li>
                            <span>Completed</span>
                            <p>0%</p>
                        </li>
                    </ul>
                </div>
                <div className='content_'>
                    <Outlet />
                </div>
            </section>
            {/* {open ? <InspectionProgess setOpen={setOpen} /> : <></>} */}

        </>
    )
}