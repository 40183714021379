import request, { RequestOptionsType } from '../request'
export async function GetTemplateArgumentLi(options?: any) {
    return request('api/Checklist/TemplateArgumentList', {
        method: "GET",
        ...(options || {})
    })
}
export async function AddCheckList(options: any) {
    return request('/api/Checklist/Add', {
        method: "POST",
        ...(options || {})
    })
}
export async function GetCheckList(options?: any) {
    return request('api/Checklist/Get', {
        method: "GET",
        ...(options || {})
    })
}
export async function EditCheckListApi(options: any) {
    return request('api/Checklist/Edit', {
        method: "POST",
        ...(options || {})
    })
}
export async function GetEditCheckList(options: any) {
    return request('api/Checklist/GetDetail', {
        method: "GET",
        ...(options || {})
    })
}
export async function GetActiveChecklistList(options?: any) {
    return request('api/Checklist/GetActiveChecklistList', {
        method: "GET",
        ...(options || {})
    })
}
export async function ExportCheckList(options: any) {
    return request('api/Checklist/Export', {
        method: "GET",
        ...(options || {}),
        responseType: "blob",
    })
}
export async function AddAttribute<
    T extends CheckListAPI.AddCheckListRequest,
    R extends CheckListAPI.AddCheckListResponse
>(options: RequestOptionsType<T>) {
    return request<R>('api/Checklist/AddAttribute', {
        method: "POST",
        ...(options || {}),
    })
}
export async function DeleteAttribute(options: any) {
    return request('api/Checklist/DeleteAttribute', {
        method: "POST",
        ...(options || {}),
    })
}
export async function CopyCheckList(options: RequestOptionsType<{ id: number }>) {
    return request('api/Checklist/CopyCheckList', {
        method: "GET",
        ...(options || {}),
    })
}
export async function SendReportEmail(options: RequestOptionsType<{
    reportID: number,
    toEmail: string,
    subject?: string,
    comment?: string,
    cc?: string,
    bcc?: string
}>) {
    return request('api/InspectionReport/SendReportEmail', {
        method: "POST",
        ...(options || {}),
    })
}
export async function Delete(options: RequestOptionsType<{id:number}>) {
    return request('api/Checklist/Delete', {
        method: "GET",
        ...(options || {}),
    })
}