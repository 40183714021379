import { useState, useEffect, useContext } from 'react'
import { Button } from "antd";
import { Input, Space, Select, Table, Modal } from 'antd';
import file from '@/images/navigation/file.png'
import '@/styles/inspection.less'
import type { ColumnsType } from 'antd/es/table';
import report from '@/images/navigation/report.png'
import add from '@/images/navigation/add.png'
import "@/styles/addPoToInspection.less"
import { SendOutlined, CloudUploadOutlined, ExportOutlined } from '@ant-design/icons';
import { GetInspectionList } from '@/services/inspection';
import { GetCheckList } from '@/services/checkList';
import { GetUserList } from '@/services/login';
import edit from '@/images/navigation/edit.png'
import { MyContext } from '@/context/MyContext';
import { Link, useNavigate } from 'react-router-dom';
import { Export, GetFile, GetReportList } from "@/services/inspectionReport";
import UploadReportFile from '@/page/conditionalPassReport/components/UploadReportFile';
import closeImg from '@/images/navigation/big_close.svg'
import SendEmail from '../conditionalPassReport/components/SendEmail';
interface ReportDataType {
    key: string | number
    id: number
    purchaseOrder_InspectionProjectsID: number
    itemNo: string,
    vendor: string
    poNumber: string
    producedQuantity: number
    inspectionDate: string,
    inspectedQuantity: number,
    passQuantity: number,
    rejectQuantity: number,
    inspector: string
    comment: string,
    isFinal: boolean
    resultStr: string
    failureCodes: {
        failureCode: number,
        comment: string
    }[],
    uploadFileIds: number[]
    getFileStatus: boolean
    fileList: any
    poQuantity: number
    verified: boolean
    conditionalPassComment: string
    insType: number
}
type ParamsType = {
    Status: string
    Keyword: string
}
const ConditionalPassReportPublic: React.FC = () => {
    const { Search } = Input
    const navigate = useNavigate()
    // const { setItems, setTitle } = useContext(MyContext)
    const [items, setItems] = useState<string | number>(0)
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [reportID,setReportId] = useState(0)
    const [sendEmailStatus,setSendEmailStatus] = useState(false)
    const [editOpen, setEditOpen] = useState(false);
    const [editId, setEditId] = useState(0)
    const [fileIndex, setFileIndex] = useState(0)
    const [addToPoOpen, setAddToPoOpen] = useState(false)
    const [total, setTotal] = useState(0)
    const [page, setPage] = useState({ PageIndex: 0, PageSize: 10 })
    const [loading, setLoading] = useState(false)
    const [params, setParams] = useState<any>({})
    const [updateList, setUpdateList] = useState(false)
    const [inspectionId, setInspectionId] = useState("")
    const [fileLoading, setFileLoading] = useState(false)
    const [itemNo, setItemNo] = useState('')
    const [purchaseOrderInspectionProjectsID, setPurchaseOrderInspectionProjectsID] = useState(0)
    const handleChange = (result: string) => {
        params.result = result
        setParams(params)
        getInspectionTableData({
            ...params,
            PageIndex: 0,
            PageSize: 10
        })
        setPage({ PageIndex: 0, PageSize: 10 })
    };
    interface DataType {
        key: string
        userName: string
        id: number
        email: string,
        userRole: string,
        dateCreated: string,
        userStatus: string
    }
    function getFileNameFromResponse(response: any) {
        // 从 Content-Disposition 响应头获取文件名
        let fileName = ''
        const contentDispositionHeader = response.headers.get('Content-Disposition');
        if (contentDispositionHeader) {
            const matches = contentDispositionHeader.match(/filename=([^;]+)/);
            if (matches && matches.length > 1) {
                fileName = matches[1].trim();
            }
        }

        if (fileName) {
            return fileName.replace(/"/g, '')
        } else {
            return 'downloaded.file';
        }

    }
    const reportsColumns: ColumnsType<ReportDataType> = [
        {
            title: 'Item no.',
            dataIndex: 'itemNo',
            key: 'itemNo',
            // width:150,
            sorter: (a, b) => {
                const aFirstLetter = a.itemNo[0]?.toLowerCase();
                const bFirstLetter = b.itemNo[0]?.toLowerCase();
                if (aFirstLetter < bFirstLetter) {
                    return -1;
                }
                if (aFirstLetter > bFirstLetter) {
                    return 1;
                }
                return 0;
            },
            // render(text, record) {
            //     return <Link to={`/inspectionReport?itemId=${record.purchaseOrder_InspectionProjectsID}&itemNo=${record.itemNo}&poNumber=${record.poNumber}`}>
            //         {text}
            //     </Link>
            // }
        },
        {
            title: 'PO #',
            dataIndex: 'poNumber',
            key: 'poNumber',
            width: 100,
            sorter: (a, b) => +a.poNumber.replace('-', '') - +b.poNumber.replace('-', ''),
        },
        {
            title: 'INS Type',
            dataIndex: 'insType',
            key: 'insType',
            // width: 100,
            sorter: (a, b) => +a.insType - +b.insType,
        },
        {
            title: 'Vendor',
            dataIndex: 'vendor',
            key: 'vendor',
            // width:100,
            sorter: (a, b) => {
                const aFirstLetter = a.vendor[0]?.toLowerCase();
                const bFirstLetter = b.vendor[0]?.toLowerCase();
                if (aFirstLetter < bFirstLetter) {
                    return -1;
                }
                if (aFirstLetter > bFirstLetter) {
                    return 1;
                }
                return 0;
            },
        },
        {
            title: 'Inspector',
            dataIndex: 'inspector',
            key: 'inspector',
            // width:100,
            sorter: (a, b) => {
                const aFirstLetter = a.inspector[0]?.toLowerCase();
                const bFirstLetter = b.inspector[0]?.toLowerCase();
                if (aFirstLetter < bFirstLetter) {
                    return -1;
                }
                if (aFirstLetter > bFirstLetter) {
                    return 1;
                }
                return 0;
            }
        },
        // {
        //     title: 'Insection Date.',
        //     dataIndex: 'inspectionDate',
        //     key: 'inspectionDate',
        //     // width: 150,
        //     sorter: (a, b) => +new Date(a.inspectionDate) - +new Date(b.inspectionDate)
        // },
        {
            title: 'Produced QTY',
            dataIndex: 'producedQuantity',
            key: 'producedQuantity',
            align: "right",
            width: 160,
            sorter: (a, b) => +a.producedQuantity - +b.producedQuantity,
            render: (text) => <>{text?.toLocaleString()}</>
        },
        {
            title: 'PO QTY',
            dataIndex: 'poQuantity',
            key: 'poQuantity',
            align: "right",
            width: 120,
            sorter: (a, b) => +a.poQuantity - +b.poQuantity,
            render: (text) => <>{text.toLocaleString()}</>
        },
        // {
        //     title: 'Pass QTY',
        //     dataIndex: 'passQuantity',
        //     key: 'passQuantity',
        //     width: 110,
        //     align: "right",
        //     sorter: (a, b) => +a.passQuantity - +b.passQuantity,
        // },
        // {
        //     title: 'Reject QTY',
        //     dataIndex: 'rejectQuantity',
        //     key: 'rejectQuantity',
        //     width: 120,
        //     align: "right",
        //     sorter: (a, b) => +a.rejectQuantity - +b.rejectQuantity,
        // },
        {
            title: 'Inspection Result',
            dataIndex: 'resultStr',
            key: 'resultStr',
            width: 160,
            sorter: (a, b) => {
                const aFirstLetter = a.resultStr[0]?.toLowerCase();
                const bFirstLetter = b.resultStr[0]?.toLowerCase();
                if (aFirstLetter < bFirstLetter) {
                    return -1;
                }
                if (aFirstLetter > bFirstLetter) {
                    return 1;
                }
                return 0;
            },
            render: (text) => (
                <div className="flex" style={{ display: text === null ? "none" : "" }}>
                    {/* <div className={text === 2 ? "qcStatus_pass" : text === 0 ? "qcStatus_fail" : "qcStatus_default"}>
                        <div className="round"></div>
                        <span>{text === 2 ? "Pass" : text === 0 ? "Fail" : text === 1 ? "Pending" : ""}</span>
                    </div> */}
                    <div className={text === 'Pass' || text === 'ConditionalPass' ? "qcStatus_pass" : text === 'Fail' ? "qcStatus_fail" : "qcStatus_default"}>
                        <div className="round"></div>
                        <span>{text}</span>
                    </div>
                </div>
            )
        },
        {
            title: 'Failure Code',
            dataIndex: 'failureCodes',
            key: 'failureCodes',
            // sorter: (a, b) => {
            //     let obj: { [key: React.Key]: string } = { 0: 'Functionality', 1: "Structure", 2: "Appearance", 3: "Certification", 4: "Packaging", 5: "Labeling " }
            //     const aFirstLetter = a.failureCodes.map(item => obj[item.failureCode]).join(',')[0]?.toLowerCase();
            //     const bFirstLetter = b.failureCodes.map(item => obj[item.failureCode]).join(',')[0]?.toLowerCase();
            //     if (aFirstLetter < bFirstLetter) {
            //         return -1;
            //     }
            //     if (aFirstLetter > bFirstLetter) {
            //         return 1;
            //     }
            //     return 0;
            // },
            render(text, record) {
                let obj: { [key: React.Key]: string } = { 0: 'Functionality', 1: "Structure", 2: "Appearance", 3: "Certification", 4: "Packaging", 5: "Labeling " }


                return <>{record.failureCodes.map(item => obj[item.failureCode]).join(',')}</>
            }
        },
        {
            title: 'Comment',
            dataIndex: 'comment',
            key: 'comment',
            // width: 365,
            sorter: (a, b) => {
                if (!a.comment) {
                    return 0
                }
                const aFirstLetter = a?.comment[0]?.toLowerCase();
                const bFirstLetter = b?.comment[0]?.toLowerCase();
                if (aFirstLetter < bFirstLetter) {
                    return -1;
                }
                if (aFirstLetter > bFirstLetter) {
                    return 1;
                }
                return 0;
            },
        },
        {
            title: 'Verified',
            dataIndex: 'verified',
            key: 'verified',
            width: 100,
            render: (verified) => (
                verified ?
                    <svg className="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2318" width="24" height="24"><path d="M511.999994 0C229.205543 0 0.020822 229.226376 0.020822 512.020827c0 282.752797 229.184721 511.979173 511.979173 511.979173s511.979173-229.226376 511.979173-511.979173C1023.979167 229.226376 794.794446 0 511.999994 0zM815.371918 318.95082l-346.651263 461.201969c-10.830249 14.370907-27.32555 23.409999-45.27877 24.742952-1.582882 0.124964-3.12411 0.166619-4.665338 0.166619-16.328682 0-32.074198-6.373185-43.779197-17.911565l-192.903389-189.44604c-24.617988-24.20144-24.992881-63.731847-0.791441-88.349835 24.20144-24.659643 63.731847-24.951226 88.349835-0.833096l142.042875 139.501932 303.788472-404.2182c20.744091-27.575479 59.899605-33.115568 87.516739-12.413131C830.534266 252.219827 836.116009 291.375341 815.371918 318.95082z" fill="#9EDD8F" p-id="2319"></path></svg> :
                    <svg className="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3345" width="24" height="24"><path d="M512 0a512 512 0 0 0-512 512 512 512 0 0 0 512 512 512 512 0 0 0 512-512 512 512 0 0 0-512-512z" fill="#FD6B6D" p-id="3346"></path><path d="M513.755429 565.540571L359.277714 720.018286a39.058286 39.058286 0 0 1-55.296-0.073143 39.277714 39.277714 0 0 1 0.073143-55.442286l154.331429-154.331428-155.062857-155.136a36.571429 36.571429 0 0 1 51.712-51.785143l365.714285 365.714285a36.571429 36.571429 0 1 1-51.785143 51.785143L513.755429 565.540571z m157.549714-262.582857a35.254857 35.254857 0 1 1 49.737143 49.737143l-106.057143 108.982857a35.254857 35.254857 0 1 1-49.883429-49.810285l106.203429-108.982858z" fill="#FFFFFF" p-id="3347"></path></svg>
            )
        },
        {
            title: 'Action',
            dataIndex: 'Action',
            key: 'Action',
            width: 200,
            render: (_, record, index) => (
                <div className="flex items-center">
                    <a className="flex items-center" title="Export" onClick={async () => {
                        setLoading(true)
                        const res: any = await Export({ params: { PONumber: record.poNumber, ItemNo: record.itemNo, PurchaseOrder_InspectionProjectId: record.purchaseOrder_InspectionProjectsID } })
                        const link = document.createElement('a');
                        link.href = URL.createObjectURL(res);
                        // link.download = `${record.poNumber}_${record.inspector}_${record.itemNo}_${version}`
                        link.download = getFileNameFromResponse(res)
                        link.click();
                        setLoading(false)
                    }} style={{ marginLeft: "20px" }}>
                        <ExportOutlined width="20px" height="20px" style={{ fontSize: "30px", width: "20px", height: "20px", color: "#3B82F6" }} />
                        {/* <a>Export</a> */}
                    </a>
                    <CloudUploadOutlined onClick={() => {
                        setFileIndex(index)
                        setIsModalOpen(true)
                        setItemNo(record.itemNo)
                        setPurchaseOrderInspectionProjectsID(record.purchaseOrder_InspectionProjectsID)
                        !record.getFileStatus && getFileList(record.uploadFileIds, index)
                    }} title="Upload File" style={{ fontSize: "23px", cursor: "pointer", marginLeft: "20px", color: "#3B82F6" }} />
                    <SendOutlined onClick={()=>{
                        setSendEmailStatus(true)
                        setReportId(record.id)
                        }} title='Send Email' style={{fontSize:22,marginLeft:"20px",color: "#3B82F6",cursor:"pointer"}} />
                </div>
            )
        },
    ]
    const [reportsData, setReportsData] = useState<ReportDataType[]>([])
    const getFileList = async (fileIdArr: number[], index: number) => {
        setFileLoading(true)
        const arr: any = fileIdArr.map(async (fileId: number) => {
            // fileList: [1,2,3].map(async (fileId: number) => {
            const res: any = await GetFile({ params: { fileId } })
            const contentDisposition = res.headers['content-disposition']
            const filenameMatches = /filename=([^;]+)/g.exec(contentDisposition);
            let name = filenameMatches ? filenameMatches[1] : "Unknown"
            delete res.headers
            return {
                uid: fileId,
                name,
                url: URL.createObjectURL(res),
                status: 'done',
                response: { data: [fileId] }
            }
        })
        Promise.all(arr).then(res => {
            // setFileList(res)
            // itemData[index].fileList = res
            // itemData[index].getFileStatus = true
            reportsData[index].fileList = res
            reportsData[index].getFileStatus = true
            // setItemData(deepCloneArray(itemData))
            setReportsData(deepCloneArray(reportsData))
            setFileLoading(false)


        })
    }
    const deepCloneArray: any = (arr: any) => {
        const clonedArray = [];
        for (let i = 0; i < arr.length; i++) {
            if (Array.isArray(arr[i])) {
                clonedArray[i] = deepCloneArray(arr[i]);
            } else {
                clonedArray[i] = arr[i];
            }
        }
        return clonedArray;
    }
    useEffect(() => {
        getInspectionTableData({ ...page })
        setItems('(3)')
    }, [updateList])
    const onSearch = (Keyword: string) => {
        getInspectionTableData({
            ...params,
            Keyword,
            PageIndex: 0,
            PageSize: 10
        })
        setPage({ PageIndex: 0, PageSize: 10 })
        setParams({
            ...params,
            Keyword
        })
    };
    const getInspectionTableData = async (params: {
        PageSize: number
        PageIndex: number
    }) => {
        setLoading(true)
        try {
            const res = await GetReportList({
                params: {
                    result: '["Pass","Fail","Pending","ConditionalPass"]',
                    ...params
                }
            })
            if (res?.isSuccess) {
                setLoading(false)
                setItems(res?.totalCount?.toLocaleString()!)
                setTotal(res?.totalCount!)
                setReportsData(res?.data.map((item, index: number) => ({
                    key: index,
                    isFinal: false,
                    inspectionDate: "",
                    fileList: [],
                    getFileStatus: false,
                    ...item
                })))
            } else {
                setLoading(false)
                setItems(0)
                setReportsData([])
                setTotal(0)
            }
        } catch (error) {
            console.log(error);
            setLoading(false)
            setItems(0)
            setReportsData([])
            setTotal(0)
        }
    }
    return (
        <section className='flex' >
            <div style={{ width: "100%", height: "100vh", padding: "0 23px 15px 15px" }} className={` bg-[rgb(249,249,251)]`}>
                <header className=" flex w-full bg-[rgb(249,249,251)] py-[25px] justify-between items-center">
                    <div className='flex items-center'>
                        <h3 className="text-[20px] font-extrabold text-[#4D5E80] mr-[22px] m-0">Reports</h3>
                        <span className='text-[#7D8FB3] text-[16px]'>{`(${items})`}</span>
                    </div>
                    <div className='flex'>
                        <img style={{ cursor: "pointer" }} onClick={() => navigate("/inspectionPhone")} src={closeImg} alt="" />
                    </div>
                </header>
                <>
                    <section>
                        <div className="flex justify-between flex-wrap reports-div">
                            <div>

                                <Search
                                    placeholder="Search"
                                    style={{ width: 400,maxWidth:'100%' }}
                                    className="search"
                                    onSearch={onSearch}
                                    onChange={(e) => setParams({
                                        ...params,
                                        Keyword: e.target.value
                                    })}
                                />
                            </div>
                            <div>
                                <span className="vendor">Inspection Result</span>
                                <Select
                                    style={{ width: 150 }}
                                    onChange={handleChange}
                                    className="select"
                                    defaultValue="All"
                                    options={[
                                        { value: '["Pass","Fail","Pending","ConditionalPass"]', label: 'All' },
                                        { value: '["ConditionalPass"]', label: 'Conditional Pass' },
                                        { value: '["Pass"]', label: 'Pass' },
                                        { value: '["Fail"]', label: 'Fail' },
                                        { value: '["Pending"]', label: 'Pending' },
                                    ]}
                                />
                                {/* <span className="status">User Role</span>
                    <Select
                        style={{ width: 150 }}
                        onChange={handleStatus}
                        className="select"
                        defaultValue="All"
                        options={[
                            { value: '', label: 'All' },
                            { value: 'Super Admin', label: 'Super Admin' },
                            { value: 'Inspector', label: 'Inspector' },
                        ]}
                    /> */}
                            </div>
                        </div>
                        <div className="page-content bg-white" style={{ height: 200 }}>
                            <Table
                                className="qc-table"
                                columns={reportsColumns}
                                dataSource={reportsData}
                                loading={loading}
                                scroll={{ x: 2000, y: 500 }}
                                pagination={{
                                    current: page.PageIndex + 1,
                                    pageSize: page.PageSize,
                                    total,
                                    onChange: (page, pageSize) => {
                                        setPage({ PageIndex: page - 1, PageSize: pageSize })
                                        getInspectionTableData({
                                            ...params, PageIndex: page - 1, PageSize: pageSize
                                        })
                                    }
                                }}
                            // expandIcon={(e)=>{
                            //     return <>e</>
                            // }}
                            />
                        </div>

                    </section>
                    <SendEmail reportID={reportID} sendEmailStatus={sendEmailStatus} setSendEmailStatus={setSendEmailStatus}/>
                    <UploadReportFile
                        isModalOpen={isModalOpen}
                        setIsModalOpen={setIsModalOpen}
                        reportsData={reportsData}
                        setReportsData={setReportsData}
                        fileIndex={fileIndex}
                        fileLoading={fileLoading}
                        setFileLoading={setFileLoading}
                        itemNo={itemNo}
                        purchaseOrder_InspectionProjectsID={purchaseOrderInspectionProjectsID}
                    />
                </>
            </div>
        </section>

    )
}
export default ConditionalPassReportPublic;