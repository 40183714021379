import { useState, useEffect, useContext } from 'react'
import { Button, message } from "antd";
import { Input, Space, Select, Table, Modal,Popconfirm } from 'antd';
import file from '@/images/navigation/file.png'
import '@/styles/inspection.less'
import type { ColumnsType } from 'antd/es/table';
import report from '@/images/navigation/report.png'
import add from '@/images/navigation/add.png'
import { CaretDownOutlined, ExportOutlined, CopyOutlined, DeleteOutlined } from '@ant-design/icons';
import { GetInspectionList } from '@/services/inspection';
import { GetCheckList, ExportCheckList, CopyCheckList,Delete } from '@/services/checkList';
import edit from '@/images/navigation/edit.png'
import { MyContext } from '@/context/MyContext';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
const CheckList: React.FC = () => {
    const { Search } = Input
    const Navigate = useNavigate()
    const { setItems, setTitle } = useContext(MyContext)
    const [total, setTotal] = useState(0)
    const [search] = useSearchParams()
    const [page, setPage] = useState({
        PageIndex: Number(search.get("PageIndex") || 0),
        pageSize: Number(search.get("PageSize") || 10)
    })
    const [loading, setLoading] = useState(false)
    const [params, setParams] = useState({})
    const [updateList, setUpdateList] = useState(false)
    const handleStatus = (Status: string) => {
        setParams({
            ...params,
            Status
        })
        getInspectionTableData({
            ...params,
            Status,
            PageIndex: 0,
            PageSize: 10
        })
        setPage({ PageIndex: 0, pageSize: 10 })
    }
    interface DataType {
        key: string
        id: number
        name: string,
        description: string,
        author: string,
        status: string,
        createdDate: string,
        isUsed: boolean
    }
    const checkListCopy = async (id: number) => {
        const res: any = await CopyCheckList({ params: { id } })
        setLoading(true)
        if (res.isSuccess) {
            message.success('Successfully')
            getInspectionTableData({
                ...params,
                PageIndex: 0,
                PageSize: 10
            })
            setPage({PageIndex:0,pageSize:10})
        }

    }
    const deleteCheckList = async (id:number)=>{
        setLoading(true)
        const res:any = await Delete({params:{id}})
        if(res.isSuccess){
            message.success('Successfully')
            getInspectionTableData({ ...params,...page })
        }else{
            message.error(res.message)
        }
        setLoading(false)
    }
    const columns: ColumnsType<DataType> = [
        {
            title: 'Check List Name',
            dataIndex: 'name',
            key: 'name',
            sorter: (a, b) => {
                const aFirstLetter = a.name[0].toLowerCase();
                const bFirstLetter = b.name[0].toLowerCase();
                if (aFirstLetter < bFirstLetter) {
                    return -1;
                }
                if (aFirstLetter > bFirstLetter) {
                    return 1;
                }
                return 0;
            },
        },
        {
            title: 'Desc.',
            dataIndex: 'description',
            key: 'description',
            sorter: (a, b) => {
                const aFirstLetter = a.description[0].toLowerCase();
                const bFirstLetter = b.description[0].toLowerCase();
                if (aFirstLetter < bFirstLetter) {
                    return -1;
                }
                if (aFirstLetter > bFirstLetter) {
                    return 1;
                }
                return 0;
            },
        },
        {
            title: 'Author',
            dataIndex: 'author',
            key: 'author',
            sorter: (a, b) => {
                const aFirstLetter = a.author[0].toLowerCase();
                const bFirstLetter = b.author[0].toLowerCase();
                if (aFirstLetter < bFirstLetter) {
                    return -1;
                }
                if (aFirstLetter > bFirstLetter) {
                    return 1;
                }
                return 0;
            },
        },
        {
            title: 'Status',
            key: 'status',
            dataIndex: 'status',
            width: 100,
            sorter: (a, b) => {
                const aFirstLetter = a.status[0].toLowerCase();
                const bFirstLetter = b.status[0].toLowerCase();
                if (aFirstLetter < bFirstLetter) {
                    return -1;
                }
                if (aFirstLetter > bFirstLetter) {
                    return 1;
                }
                return 0;
            },
            render: (text, record) => (
                <div className="flex">
                    <div className={
                        // text === "ToDo" || text === "Completed"  ? "qcStatus_default" :
                        text == "Active" ? "qcStatus_pass" : "qcStatus_default"
                    }>
                        <div className="round"></div>
                        <span>{text}</span>
                    </div>
                </div>
            ),
        },
        {
            title: 'Created Date',
            key: 'createdDate',
            dataIndex: 'createdDate',
            sorter: (a, b) => +new Date(a.createdDate) - +new Date(b.createdDate),
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
                <div className='action'>
                    <div style={{height:"32px"}} title="Preview" onClick={() => Navigate('/viewCheckList?id=' + record.id + "&name=" + record.name)}>
                    <svg  style={{ marginRight: "10px", cursor: "pointer" }} className="icon" viewBox="0 0 1434 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3658" width="32" height="32"><path d="M716.762463 0C204.762463 0 0 512 0 512s204.762463 512 716.762463 512c512 0 716.762463-512 716.762463-512S1228.57478 0 716.762463 0L716.762463 0zM716.762463 819.049853c-169.665689 0-307.237537-137.571848-307.237537-307.237537 0-169.665689 137.571848-307.237537 307.237537-307.237537 169.665689 0 307.237537 137.571848 307.237537 307.237537C1023.812317 681.665689 886.240469 819.049853 716.762463 819.049853L716.762463 819.049853zM716.762463 819.049853M870.28739 512c0 84.832845-68.879765 153.524927-153.524927 153.524927S563.049853 596.645161 563.049853 512c0-84.832845 68.879765-153.524927 153.524927-153.524927C801.595308 358.28739 870.28739 427.167155 870.28739 512L870.28739 512zM870.28739 512" fill="#bfbfbf" p-id="3659"></path></svg>
                    </div>
                    
                    <ExportOutlined title='Export' style={{ fontSize: "20px", marginRight: "10px" }} onClick={async () => {
                        try {
                            setLoading(true)
                            const res: any = await ExportCheckList({ params: { ID: record.id } })
                            if (res) {
                                console.log(res);
                                const link = document.createElement('a');
                                link.href = URL.createObjectURL(res);
                                const contentDisposition = res.headers['content-disposition']
                                const filenameMatches = /filename=([^;]+)/g.exec(contentDisposition);
                                let name = "Checklist_" + record.name
                                link.download = name
                                link.click();
                                setLoading(false)
                            }
                        } catch (error) {
                            console.log(error);
                            setLoading(false)
                        }
                    }} />
                    <CopyOutlined onClick={() => checkListCopy(record.id)} title='Copy' style={{ marginRight: "10px", cursor: "pointer", fontSize: 20 }} />
                    {!record.isUsed &&
                        <Popconfirm
                            title="Delete the check list"
                            description="Are you sure to delete this check list?"
                            onConfirm={(e)=>{
                                deleteCheckList(record.id)
                            }}
                            okText="Yes"
                            cancelText="No"
                        >
                            <DeleteOutlined title='Delete' style={{ fontSize: "20px", marginRight: "10px", cursor: "pointer" }} />
                        </Popconfirm>
                    }
                    {!record.isUsed && <Link to={`/editCheckList2?id=${record.id}`}><img title='Edit' src={edit} alt="" /></Link>}

                </div>
            ),
        },
    ];
    const [data, setData] = useState<DataType[]>([])
    useEffect(() => {
        getInspectionTableData({ ...page })
        setTitle("Check List")
    }, [updateList])
    const onSearch = (Keyword: string) => {
        getInspectionTableData({
            ...params,
            Keyword,
            PageIndex: 0,
            PageSize: 10
        })
        setPage({ PageIndex: 0, pageSize: 10 })
        setParams({
            ...params,
            Keyword
        })
    };
    const getInspectionTableData = async (params?: InspectionAPI.InspectionListRequest) => {
        !loading && setLoading(true)
        GetCheckList({ params }).then((res: any) => {
            if (res.isSuccess) {
                setLoading(false)
                setItems(`(${res?.totalCount.toLocaleString()})`)
                setData(res?.data?.data?.map((item: any, index: number) => ({ key: index, ...item })))
                setTotal(res.totalCount)
            } else {
                setLoading(false)
                setItems(0)
                setData([])
                setTotal(0)
            }
        }).catch(error => {
            console.log(error);
            setLoading(false)
            setItems(0)
            setData([])
            setTotal(0)
        })

    }
    return (
        <section>
            <div className="flex justify-between">
                <div>
                    <Button type="primary" onClick={() => Navigate('/newCkeckList2')} className="mr-[5px] new" style={{ borderRadius: "4px" }}>+ New</Button>
                    <Search
                        placeholder="Search by Check List Name, Desc"
                        style={{ width: 400 }}
                        className="search"
                        onSearch={onSearch}
                        onChange={(e) => setParams({
                            ...params,
                            Keyword: e.target.value
                        })}
                    />
                </div>
                <div>
                    {/* <span className="vendor">Vendor</span>
                    <Select
                        style={{ width: 120 }}
                        onChange={handleChange}
                        className="select"
                        defaultValue="all"
                        options={[
                            { value: 'all', label: 'All' },
                            { value: 'lucy', label: 'Lucy' },
                            { value: 'Yiminghe', label: 'yiminghe' },
                        ]}
                    /> */}
                    <span className="status">Status</span>
                    <Select
                        style={{ width: 120 }}
                        onChange={handleStatus}
                        className="select"
                        defaultValue="all"
                        options={[
                            { value: 'all', label: 'All' },
                            { value: 'Active', label: 'Active' },
                            { value: 'InActive', label: 'Inactive' },
                        ]}
                    />
                </div>
            </div>
            <div className="page-content bg-white" style={{ height: 200 }}>
                <Table
                    className="qc-table"
                    columns={columns}
                    dataSource={data}
                    loading={loading}
                    scroll={{ y: 500 }}
                    pagination={{
                        current: page.PageIndex + 1,
                        pageSize: page.pageSize,
                        total,
                        onChange: (page, pageSize) => {
                            setPage({ PageIndex: page - 1, pageSize: pageSize })
                            Navigate(`/checkList?PageIndex=${page - 1}&PageSize=${pageSize}`)
                            getInspectionTableData({
                                ...params, PageIndex: page - 1, PageSize: pageSize
                            })
                        }
                    }}
                // expandIcon={(e)=>{
                //     return <>e</>
                // }}
                />
            </div>
        </section>
    )
}
export default CheckList;