import React, { useEffect, useRef, useState } from 'react'
import { CaretLeftOutlined } from '@ant-design/icons'
import { Button, message, Skeleton } from 'antd'
import '@/styles/inspectionPhone/inspectionDetail.less'
import { Link, useParams, useNavigate, useSearchParams } from 'react-router-dom'
import { GetInspectionDetailByInspector } from '@/services/inspectionReport'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import ConfirmationPopup from './inspectionPCrReport/components/ConfirmationPopup'
const InspectionDetail: React.FC<{
    clearData: any
}> = ({ clearData }) => {
    // const { itemNo } = useParams()
    const [search] = useSearchParams()
    const navigate = useNavigate()
    const [inspectedQTY, setInspectedQTY] = useState("")
    const [producedQTY, setProducedQTY] = useState("")
    const [aqlQty, setAqlQty] = useState("")
    const qtyRef = useRef<any>(null)
    const producedQytRef = useRef<any>(null)
    const aqlQtyQtyRef = useRef<HTMLInputElement>(null)
    const [loading, setLoading] = useState(false)
    const [detailData, setDetailData] = useState<{
        insType?:number,
        aqlQty?:number,
        reportId?: number
        projectId?: number
        poNumber?: string
        result?: number
        createdDate?: string
        inspectionRate?: string
        inspectionReport?: string
        vendor?: string
        itemNo?: string
        description?: string
        poQuantity?: number
        inspectedQuantity?: 0
        comment?: string
        status?: string
        steps?: string[]
        failureCodes?: {
            failureCode: number
            comment: string
        }[]
        critical?: number,
        criticalAccept?: number
        majorAccept?: number
        majorReject?: number
        minorAccept?: number
        minorReject?: number
        purchaseOrder_InspectionProjectId?: number | string
    }>({})
    const [confirmOpen, setConfirmOpen] = useState(false)
    const [conditionalPassComment, setConditionalPassComment] = useState("")
    const [conditionalPassId, setConditionalPassId] = useState(0)
    const inspectionProjectId = search.get("id") || ""
    const ItemNo = search.get("itemNo") || ""
    const PONumber = search.get("PONumber") || ""
    const PurchaseOrder_InspectionProjectId = Number(search.get("itemId") || 0)
    const getInspectionDetail = async (params: {
        PurchaseOrder_InspectionProjectId: number
        ItemNo: string
        PONumber: string
    }) => {
        setLoading(true)
        const res: any = await GetInspectionDetailByInspector({ params })
        if (res.isSuccess) {
            setLoading(false)
            setDetailData(res.data)
            // producedQytRef.current.value = res.data.poQuantity
            setInspectedQTY(res.data.inspectedQuantity)
            setAqlQty(res.data.aqlQty)
            setProducedQTY(res.data.producedQuantity || res.data.inspectedQuantity)
            setConditionalPassComment(res.data.lastConditionalPassComment)
            setConditionalPassId(res.data.reportId)
            res.data.lastConditionalPassComment && setConfirmOpen(true)
        } else {
            setLoading(false)
            message.error(res.message)
        }
    }
    useEffect(() => {
        clearData()
        getInspectionDetail({ ItemNo, PONumber, PurchaseOrder_InspectionProjectId })
        // let inspectedQuantity = sessionStorage.getItem('inspectedQuantity')
        let inspectedQuantity = localStorage.getItem('inspectedQuantity')
        if (inspectedQuantity) {
            setInspectedQTY(inspectedQuantity)
        }
    }, [])
    return (
        <section className='inspectionDetail'>
            <ConfirmationPopup
                confirmOpen={confirmOpen}
                setConfirmOpen={setConfirmOpen}
                conditionalPassComment={conditionalPassComment}
                id={conditionalPassId}
            />
            <div className='phone_header'>
                <CaretLeftOutlined onClick={() => {
                    navigate("/inspectionList")
                    clearData()
                }} />
                <span>{ItemNo}</span>
                <span></span>
            </div>
            <Skeleton paragraph={{ rows: 15 }} style={{ marginTop: "5.833vw" }} loading={loading} active >
                <div className='inspectionDetail_content'>
                    <ul>
                        <li>
                            <div className="label">pO #</div>
                            <p>{detailData?.poNumber}</p>
                        </li>
                        <li>
                            <div className="label">Created Date</div>
                            <p>{detailData?.createdDate}</p>
                        </li>
                        <li>
                            <div className="label">Inspection rate %</div>
                            <p>{detailData?.inspectionRate}</p>
                        </li>
                        {
                            detailData?.inspectionReport && <li>
                                <div className="label">Insepection report</div>
                                <p>{detailData?.inspectionReport}</p>
                            </li>
                        }

                        <li style={{ width: "100%" }}>
                            <div className="label">Vendor</div>
                            <p>{detailData?.vendor}</p>
                        </li>
                        <li style={{ width: "100%" }}>
                            <div className="label">Item no.</div>
                            <p>{detailData?.itemNo}</p>
                        </li>
                        <li style={{ width: "100%" }}>
                            <div className="label">Desc.</div>
                            <p>{detailData?.description}</p>
                        </li>
                        <li>
                            <div className="label">PO QTY</div>
                            <p>{detailData?.poQuantity}</p>
                        </li>
                        <li>
                            <div className="label">Inspected QTY <span style={{ color: "red" }}>*</span></div>
                            <input
                                ref={qtyRef}
                                placeholder={`1 ~ ${detailData?.poQuantity}`}
                                onFocus={() => qtyRef.current.select()}
                                onBlur={() => sessionStorage.removeItem("inspectedQuantity")}
                                value={inspectedQTY}
                                onChange={(e) => setInspectedQTY(e.target.value)}
                                type="number" />
                        </li>
                        {detailData?.comment ? <li style={{ width: "50%" }}>
                            <div className="label">comment</div>
                            <p>{detailData?.comment}</p>
                        </li> : (
                            <li style={{ width: "50%" }}>
                                <div className="label">Status</div>
                                <p className={
                                    `onStatus ${detailData?.status?.indexOf("Pending") !== -1 ? "pending" :
                                        detailData?.status?.indexOf("Progress") !== -1 ? "InProgress" :
                                            detailData?.status?.indexOf("Submitted") !== -1 ? "submitted" : ""}`
                                }>{detailData?.status}</p>
                            </li>
                        )}
                        <li>
                            <div className="label">Produced QTY <span style={{ color: "red" }}>*</span></div>
                            <input
                                ref={producedQytRef}
                                placeholder={`1 ~ ${detailData?.poQuantity}`}
                                onFocus={() => producedQytRef.current.select()}
                                onBlur={() => sessionStorage.removeItem("producedQuantity")}
                                value={producedQTY}
                                onChange={(e) => setProducedQTY(e.target.value)}
                                type="number" />
                        </li>
                        {detailData?.comment && <li style={{ width: "100%" }}>
                            <div className="label">Status</div>
                            <p className={
                                `onStatus ${detailData?.status?.indexOf("Pending") !== -1 ? "pending" :
                                    detailData?.status?.indexOf("Progress") !== -1 ? "InProgress" :
                                        detailData?.status?.indexOf("Submitted") !== -1 ? "submitted" : ""}`
                            }>{detailData?.status}</p>
                        </li>}
                        <li>
                            <div className="label">INS TYPE</div>
                            <p>{detailData.insType}</p>
                        </li>
                        <li>
                            <div className="label">AQL QTY</div>
                            <input
                                ref={aqlQtyQtyRef}
                                placeholder={`1 ~ ${detailData?.poQuantity}`}
                                onFocus={() => aqlQtyQtyRef.current!.select()}
                                onBlur={() => sessionStorage.removeItem("aqlQty")}
                                value={aqlQty}
                                onChange={(e) => setAqlQty(e.target.value)}
                                type="number" />
                        </li>
                    </ul>
                </div>
            </Skeleton>


            <Button onClick={() => {
                if (!inspectedQTY) {
                    message.error("Inspected QTY cannot be empty.")
                    return;
                }
                if (+inspectedQTY < 1 || +inspectedQTY > (detailData?.poQuantity || 0)) {
                    message.error("The Inspected QTY cannot be greater than the PO QTY.")
                    return;
                }
                if (+aqlQty < 0 || +aqlQty > (detailData?.poQuantity || 0)) {
                    message.error("The AQL QTY cannot be greater than the PO QTY.")
                    return;
                }
                if (+producedQTY <= 0) {
                    message.error("Produced QTY cannot be less than or equal to zero.")
                    return
                }
                clearData()
                sessionStorage.setItem("steps", JSON.stringify(detailData?.steps))
                localStorage.setItem("steps", JSON.stringify(detailData?.steps))
                sessionStorage.setItem("failureCodes", JSON.stringify(detailData?.failureCodes || []))
                localStorage.setItem("failureCodes", JSON.stringify(detailData?.failureCodes || []))
                sessionStorage.setItem("itemNo", search.get("itemNo") || "")
                localStorage.setItem("itemNo", search.get("itemNo") || "")
                sessionStorage.setItem("PONumber", search.get("PONumber") || "")
                localStorage.setItem("PONumber", search.get("PONumber") || "")
                sessionStorage.setItem("inspectedQuantity", inspectedQTY)
                localStorage.setItem("inspectedQuantity", inspectedQTY)
                localStorage.setItem("producedQuantity", producedQTY)
                localStorage.setItem("aqlQty", aqlQty)
                sessionStorage.setItem("vendor", detailData.vendor || "")
                localStorage.setItem("vendor", detailData.vendor || "")
                sessionStorage.setItem("result", detailData?.result?.toString() || "")
                localStorage.setItem("result", detailData?.result?.toString() || "")
                sessionStorage.setItem("comment", detailData?.comment || "")
                localStorage.setItem("comment", detailData?.comment || "")
                sessionStorage.setItem("status", detailData?.status || "")
                localStorage.setItem("status", detailData?.status || "")
                sessionStorage.setItem("poQuantity", detailData?.poQuantity?.toString() || "")
                localStorage.setItem("poQuantity", detailData?.poQuantity?.toString() || "")
                sessionStorage.setItem("purchaseOrder_InspectionProjectId", detailData?.purchaseOrder_InspectionProjectId?.toString() || "")
                localStorage.setItem("purchaseOrder_InspectionProjectId", detailData?.purchaseOrder_InspectionProjectId?.toString() || "")
                sessionStorage.setItem("issueObj", JSON.stringify({
                    critical: detailData?.critical,
                    criticalAccept: detailData?.criticalAccept,
                    majorAccept: detailData?.majorAccept,
                    majorReject: detailData?.majorReject,
                    minorAccept: detailData?.minorAccept,
                    minorReject: detailData?.minorReject,
                }))
                localStorage.setItem("issueObj", JSON.stringify({
                    critical: detailData?.critical,
                    criticalAccept: detailData?.criticalAccept,
                    majorAccept: detailData?.majorAccept,
                    majorReject: detailData?.majorReject,
                    minorAccept: detailData?.minorAccept,
                    minorReject: detailData?.minorReject,
                }))
                detailData.reportId && sessionStorage.setItem("reportId", detailData.reportId.toString())
                detailData.reportId && localStorage.setItem("reportId", detailData.reportId.toString())
                if (window.matchMedia('(max-width: 768px)').matches) {
                    navigate('/reportPhone?id=0')
                } else {
                    navigate(`/inspectionPCReport?itemNo=${search.get("itemNo")}&poNumber=${search.get("PONumber")}&itemId=${detailData?.purchaseOrder_InspectionProjectId}`)
                }
            }} type='primary'>{detailData?.status === "Pending" ? "Start" : "Continue"}</Button>
        </section>
    )
}
const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        clearData() {
            let action = { type: "clear" }
            dispatch(action)
        }
    }
}
export default connect(null, mapDispatchToProps)(InspectionDetail)