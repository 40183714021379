
import { message } from 'antd'
import axios, {
  AxiosInstance,
  AxiosInterceptorManager,
  AxiosPromise,
  AxiosRequestConfig,
  AxiosResponse,
  // InternalAxiosRequestConfig
} from 'axios'
export type RequestOptionsType<T> = Omit<AxiosRequestConfig<T>, 'params'> & {
  params?: T
}

export interface ResponseDataType<T extends any = any> {
  headers: any
  code: number
  message: string
  data: T
  isSuccess: boolean
  totalCount?: number
}
interface InternalAxiosRequestConfig<T = any> extends AxiosRequestConfig {
  headers: {
    Accept: 'application/json, text/plain, */*';
    'Content-Type': 'application/x-www-form-urlencoded';
    Authorization: string;
  } & T;
}

interface NewAxiosInstanceType extends AxiosInstance {
  <T extends any>(url: string, config?: AxiosRequestConfig): AxiosPromise<
    ResponseDataType<T>['data'] | undefined
  >
  interceptors: {
    request: AxiosInterceptorManager<InternalAxiosRequestConfig>
    // response: AxiosInterceptorManager<AxiosResponse<ResponseDataType>>
    response: AxiosInterceptorManager<AxiosResponse>
  }
}
// const baseURL = "https://qc.eurofase.com"
// const baseURL = "http://qc.tqstech.cn:10008"
// const baseURL = process.env.REACT_APP_API_URL
const baseURL = ""
const axiosOption = {
  baseURL,
  // timeout: 10000
}
const showMessage = (content: string) => {
    message.open({
      key: "updatable",
      type: 'error',
      content,
      duration: 3
    })
}
const instance:NewAxiosInstanceType = axios.create(axiosOption)
instance.interceptors.request.use(function (config) {
  let token = localStorage.getItem("Authorization")
  if (token) {
    config.headers = {
      ...config.headers,
      'Authorization': token
    }

  }
  return config
}, function (error) {
  if (error?.response?.status === 401) {
    console.log(error);
    showMessage('No login or login expired please log in again.')
    window.location.hash = '#/login'
  }else if(error?.response?.status === 403){
    window.location.hash = '#/login'
  }
  return Promise.reject(error)
})
instance.interceptors.response.use(function (response) {
  response.data.headers = response.headers
  return response;
}, function (error) {
  if (error?.response?.status === 401) {
    const token = localStorage.getItem("Authorization")
    if(token){
      showMessage('No login or login expired please log in again.')
      window.location.hash = '#/login?type=expired'
    }else{
      window.location.hash = '#/login'
    }
    
  }else if(error?.response?.status === 403){
    window.location.hash = '#/login'
  }
  return Promise.reject(error);
});
// type ValueOf<T> = T[keyof T]


 const request = <T extends any>(
  url: string,
  config: AxiosRequestConfig,
): Promise<T | undefined> => {
  return new Promise((resolve, reject) => {
    instance<T>(url, config)
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        console.log(error);
        
        reject(error)
      })
  })
}
export default request