import React, { useEffect, useState } from 'react';
import { Upload, Modal, Image, Spin } from 'antd';
import type { RcFile, UploadProps } from 'antd/es/upload';
import type { UploadFile } from 'antd/es/upload/interface';
import { PlusOutlined } from '@ant-design/icons';
import { GetFile } from '@/services/inspectionReport';
const getBase64 = (file: RcFile): Promise<string> =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result as string);
        reader.onerror = (error) => reject(error);
    });
const deepCloneArray: any = (arr: any) => {
    const clonedArray = [];
    for (let i = 0; i < arr.length; i++) {
        if (Array.isArray(arr[i])) {
            clonedArray[i] = deepCloneArray(arr[i]);
        } else {
            clonedArray[i] = arr[i];
        }
    }
    return clonedArray;
}
const ViewImg: React.FC<{
    isModalOpen: boolean
    setIsModalOpen: Function
    fileIdArr: number[]
}> = ({ isModalOpen, setIsModalOpen, fileIdArr }) => {
    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [previewTitle, setPreviewTitle] = useState('');
    const [loading, setLoading] = useState(false)
    let [fileList, setFileList] = useState<UploadFile[]>([

    ]);
    const handleCancel = () => setPreviewOpen(false);

    const handlePreview = async (file: UploadFile) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj as RcFile);
        }

        setPreviewImage(file.url || (file.preview as string));
        setPreviewOpen(true);
        setPreviewTitle(file.name || file.url!.substring(file.url!.lastIndexOf('/') + 1));
    };

    const handleChange: UploadProps['onChange'] = ({ fileList: newFileList }) =>
        setFileList(newFileList);

    useEffect(() => {
        if (isModalOpen) {
            setFileList([])
            setLoading(true)
            const arr: any = fileIdArr.map(async (fileId: number) => {
                // fileList: [1,2,3].map(async (fileId: number) => {
                const res: any = await GetFile({ params: { fileId } })
                const contentDisposition = res.headers['content-disposition']
                const filenameMatches = /filename=([^;]+)/g.exec(contentDisposition);
                let name = filenameMatches ? filenameMatches[1] : "Unknown"
                delete res.headers
                return {
                    name,
                    url: URL.createObjectURL(res)
                }
            })
            Promise.all(arr).then(res => {
                setLoading(false)
                setFileList(res)
                console.log(res);

            })


        }

    }, [isModalOpen])
    return (
        <Modal
            okButtonProps={{ style: { display: 'none' } }}
            cancelButtonProps={{ style: { display: 'none' } }}
            open={isModalOpen} onOk={() => setIsModalOpen(false)} onCancel={() => setIsModalOpen(false)}>

            <div style={{ overflow: "auto" }}>
                <Spin spinning={loading}>
                    <div style={{ padding: "0 10px", display: "flex", maxHeight: "50vh", minHeight: "20vh", flexWrap: "wrap" }}>

                        {/* <Upload
                action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                listType="picture-card"
                fileList={fileList}
                onPreview={handlePreview}
                onChange={handleChange}
            >
            </Upload> */}
                        {
                            fileList?.map((item) => <Image style={{ marginBottom: "10px" }} src={item.url} />)
                        }

                    </div>
                </Spin>
            </div>

            <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
                <img alt="example" style={{ width: '100%' }} src={previewImage} />
            </Modal>
        </Modal>
    );
};

export default ViewImg;