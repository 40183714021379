import { useState, useEffect, useContext } from 'react'
import { Button, message } from "antd";
import { Input, Space, Select, Table, Modal } from 'antd';
import file from '@/images/navigation/file.png'
import '@/styles/inspection.less'
import type { ColumnsType } from 'antd/es/table';
import report from '@/images/navigation/report.png'
import add from '@/images/navigation/add.png'
import { CaretDownOutlined, CaretRightOutlined } from '@ant-design/icons';
import NewInspection from './comonents/NewInspection';
import EditInspection from './comonents/EditInspection'
import AddPo from './comonents/AddPo';
import InspectionReport from './comonents/InspectionReport';
import { GetInspectionList } from '@/services/inspection';
import edit from '@/images/navigation/edit.png'
import EditPo from './comonents/EditPo';
import { Link, useNavigate ,useSearchParams} from 'react-router-dom';
import { MyContext } from '@/context/MyContext';
const Inspections: React.FC = () => {
    const { Search } = Input;
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [editIsModalOpen, setEditIsModalOpen] = useState(false)
    const [addToPoOpen, setAddToPoOpen] = useState(false)
    const [editToPoOpen, setEditToPoOpen] = useState(false)
    const [reportOpen, setReportOpen] = useState(false)
    const [total, setTotal] = useState(0)
    const [search] = useSearchParams()
    const [page, setPage] = useState({ 
        PageIndex: Number(search.get("PageIndex") || 0), 
        pageSize: Number(search.get("PageSize") || 10)
    })
    const [email, setEmail] = useState("")
    const [addPoStatus, setAddPoStatus] = useState(false)
    const [poNumber, setPoNumber] = useState<string | undefined>("")
    const [loading, setLoading] = useState(false)
    const [params, setParams] = useState({})
    const [projectId, setProjectId] = useState(0)
    const [updateList, setUpdateList] = useState(false)
    const [inspectionId, setInspectionId] = useState("")
    const { setItems, setTitle } = useContext(MyContext)
    const navigate = useNavigate()
    const handleChange = (Vendor: string) => {
        setParams({
            ...params,
            Vendor
        })
        getInspectionTableData({
            ...params,
            Vendor
        })
    };
    const handleStatus = (Status: string) => {
        setParams({
            ...params,
            Status
        })
        getInspectionTableData({
            ...params,
            Status,
            PageIndex: 0,
            PageSize: 10
        })
        setPage({ PageIndex: 0, pageSize: 10 })
    }
    const openPoModal = (num: any) => {
        setAddToPoOpen(true)
        // setPONumber(num)
        console.log(num);
        setInspectionId(num.InspectionID)
        setEmail(num.assignedTo)

    }
    interface DataType {
        key: string
        InspectionID: string,
        po: string,
        Vendor?: string,
        Items: string,
        rate: string,
        assignedTo: string,
        qcStatus: string,
        Lastupdated: string,
        list: number
        expand: string,
        version: string
        purchaseOrder_InspectionProjectId: number
        children?: any,
    }
    const columns: ColumnsType<DataType> = [
        // {
        //     title: 'Inspection ID',
        //     dataIndex: 'InspectionID',
        //     key: 'InspectionID',
        //     width: 140,
        //     sorter: (a, b) => +a.InspectionID - +b.InspectionID,
        //     render: (text, record) => (
        //         record.list === 1 && <>{text}</>
        //     )
        // },
        {
            dataIndex: "add",
            key: "add",
            width: 50,
            render: (_, record) => (
                // record.list === 1 && <img className="cursor-pointer" onClick={() =>openPoModal(record) } src={add} alt="" />
                record.list === 1 && <Link to={`/addPoToInspection?inspectionId=${record.InspectionID}`}><img className="cursor-pointer" src={add} alt="" /></Link>
            ),
        },
        {
            title: 'Inspection Project title / PO#',
            dataIndex: 'po',
            key: 'po',
            width: "25%",
            sorter: (a, b) => {
                const aFirstLetter = a.po[0].toLowerCase();
                const bFirstLetter = b.po[0].toLowerCase();
                if (aFirstLetter < bFirstLetter) {
                    return -1;
                }
                if (aFirstLetter > bFirstLetter) {
                    return 1;
                }
                return 0;
            },
            render: (_, record) => (
                <Space size="middle">
                    {record.list === 1 && <img src={file} alt="" />}
                    <span style={{ color: record.list === 1 ? "" : "#5C7CEA", cursor: record.list === 1 ? "" : "pointer" }} onClick={() => {
                        if (record.list === 2) {
                            navigate(`/editPoToInspection?inspectionId=${record.InspectionID}&poNumber=${record.po}&itemId=${record.purchaseOrder_InspectionProjectId}`)
                            setInspectionId(record.InspectionID)
                            setEditToPoOpen(true)
                            setPoNumber(record.po)
                        }
                    }}>{record.po}</span>

                </Space>
            ),
        },
        {
            title: 'Vendor',
            dataIndex: 'Vendor',
            key: 'Vendor',
        },
        {
            title: 'Items',
            key: 'Items',
            dataIndex: 'Items',
            align: "right",
            width: 100,
            sorter: (a, b) => +a.Items - +b.Items,
            render: (text) => (
                <span>{text.toLocaleString()}</span>
            )
        },
        {
            title: 'Inspection Rate %',
            key: 'rate',
            dataIndex: 'rate',
        },
        {
            title: 'Assigned To',
            key: 'assignedTo',
            dataIndex: 'assignedTo',
        },
        {
            title: 'Status',
            key: 'qcStatus',
            dataIndex: 'qcStatus',
            width: 150,
            sorter: (a, b) => {
                const aFirstLetter = a.qcStatus[0]?.toLowerCase();
                const bFirstLetter = b.qcStatus[0]?.toLowerCase();
                if (aFirstLetter < bFirstLetter) {
                    return -1;
                }
                if (aFirstLetter > bFirstLetter) {
                    return 1;
                }
                return 0;
            },
            render: (text, record) => (
                <div className="flex">
                    <div className={
                        // text === "ToDo" || text === "Completed"  ? "qcStatus_default" :
                        text === "Active" ? "qcStatus_pass" :
                            text === "Fail" ? "qcStatus_fail" : "qcStatus_default"
                    }>
                        <div className="round"></div>
                        <span>{text}</span>
                    </div>
                </div>
            ),
        },
        // {
        //     title: 'Version',
        //     key: 'version',
        //     dataIndex: 'version',
        //     width: 100,
        // },
        {
            title: 'Last Updated',
            key: 'Lastupdated',
            dataIndex: 'Lastupdated',
            width: 180,
            sorter: (a, b) => +new Date(a.Lastupdated) - +new Date(b.Lastupdated),
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
                <div className='action'>
                    {record.list === 1 && <img title='Edit' onClick={() => {
                        setProjectId(+record.InspectionID)
                        setEditIsModalOpen(true)
                    }} src={edit} alt="" />}
                    {/* {record.list === 2 && <div onClick={() => {
                    setInspectionId(record.InspectionID)
                    setEditToPoOpen(true)
                    setPoNumber(record.po)
                   }}>
                    <img src={edit} alt='' />
                    </div> }  */}
                    {record.list === 2 && <Link to={`/editPoToInspection?inspectionId=${record.InspectionID}&poNumber=${record.po}&itemId=${record.purchaseOrder_InspectionProjectId}`}><img src={edit} alt='' /></Link>}

                </div>
            ),
        },
    ];
    const [data, setData] = useState<DataType[]>([
        // {
        //     key: '1',
        //     InspectionID: 'QC10345678',
        //     po: "Avoids a pain that produces no resultant",
        //     Vendor: "",
        //     Items: "500",
        //     rate: "Full inpenction",
        //     assignedTo: "Sibbet Smith",
        //     qcStatus: "",
        //     Lastupdated: "Sibbet Smith",
        //     list: 1,
        //     expand: "",
        //     children: [
        //         {
        //             key: '11',
        //             po: "QC10345678",
        //             Vendor: "asdasdasdsad",
        //             Items: "500",
        //             rate: "Full inpenction",
        //             assignedTo: "Sibbet Smith",
        //             qcStatus: "",
        //             Lastupdated: "Sibbet Smith",
        //             list: 2,
        //             expand: ""
        //         }
        //     ]
        // },
    ])
    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
        setEditIsModalOpen(false)
    };

    const handleCancel = () => {
        setIsModalOpen(false);
        setEditIsModalOpen(false)
    };
    useEffect(() => {
        setItems("")
        getInspectionTableData(page)
    }, [updateList])
    useEffect(() => {
        setTitle("Inspection Projects")
    }, [])
    const onSearch = (Keyword: string) => {
        getInspectionTableData({
            ...params,
            Keyword,
            PageIndex: 0,
            PageSize: 10
        })
        setPage({ PageIndex: 0, pageSize: 10 })
        setParams({
            ...params,
            Keyword
        })
    };
    const getInspectionTableData = async (params?: InspectionAPI.InspectionListRequest) => {
        setLoading(true)
        return new Promise((resolve, reject) => {
            GetInspectionList({ params }).then((res: any) => {
                if (res.isSuccess) {
                    setData(res.data?.data?.map((item: any) => {
                        return (
                            {
                                key: item.id,
                                InspectionID: item.id,
                                po: item.title,
                                Vendor: "",
                                Items: item.items,
                                assignedTo: item.assignedTo,
                                Lastupdated: item.lastUpdated,
                                list: 1,
                                qcStatus: item.status,
                                children: item.purchaseOrders?.map((item2: any) => ({
                                    InspectionID: item.id,
                                    key: item2.purchaseOrder_InspectionProjectId,
                                    list: 2,
                                    po: item2.poNumber,
                                    assignedTo: item2.assignedTo,
                                    Vendor: item2.vendor,
                                    Items: item2.items,
                                    rate: item2.inspectionRate,
                                    qcStatus: item2.status,
                                    version: item2.version,
                                    Lastupdated: item2.lastUpdated,
                                    purchaseOrder_InspectionProjectId: item2.purchaseOrder_InspectionProjectId
                                })),
                            }
                        )
                    }))
                    setTotal(res.totalCount)
                    setItems(`(${res.totalCount.toLocaleString()})`)
                    setLoading(false)
                    resolve(true)
                } else {
                    setLoading(false)
                    setItems(0)
                    setData([])
                    setTotal(0)
                    reject()
                }
            }).catch(error => {
                console.log(error);
                // message.error(error.message || "Failed.")
                setLoading(false)
                setItems(0)
                setData([])
                setTotal(0)
                reject()
            })
        })


    }
    return (
        <section>
            <div className="flex justify-between">
                <div>
                    <Button type="primary" onClick={showModal} className="mr-[5px] new" style={{ borderRadius: "4px" }}>+ New</Button>
                    <Search
                        placeholder="Search by Inspection Project Title"
                        style={{ width: 400 }}
                        className="search"
                        onSearch={onSearch}
                        onChange={(e) => setParams({
                            ...params,
                            Keyword: e.target.value
                        })}
                    />
                </div>
                <div>
                    {/* <span className="vendor">Vendor</span>
                    <Select
                        style={{ width: 120 }}
                        onChange={handleChange}
                        className="select"
                        defaultValue="all"
                        options={[
                            { value: 'all', label: 'All' },
                            { value: 'lucy', label: 'Lucy' },
                            { value: 'Yiminghe', label: 'yiminghe' },
                        ]}
                    /> */}
                    <span className="status">Status</span>
                    <Select
                        style={{ width: 120 }}
                        onChange={handleStatus}
                        className="select"
                        defaultValue="all"
                        options={[
                            { value: 'all', label: 'All' },
                            { value: 'To Do', label: 'To Do' },
                            { value: 'Active', label: 'Active' },
                            // { value: 'Fail', label: 'Fail' },
                            { value: 'Completed', label: 'Completed' },
                        ]}
                    />
                </div>
            </div>
            <div className="page-content bg-white" style={{ height: 200 }}>
                <Table
                    className="qc-table"
                    columns={columns}
                    dataSource={data}
                    loading={loading}
                    scroll={{ y: 550, x: document.body.clientWidth < 1750 ? 1700 : undefined }}
                    expandable={{
                        expandIcon: ({ expanded, onExpand, record }) =>{
                           return expanded ? (
                                <CaretDownOutlined style={{ marginRight: "17px", visibility: record.children?.length > 0 ? "visible" : "hidden" }} onClick={e => onExpand(record, e)} />
                            ) : (
                                <CaretRightOutlined style={{ marginRight: "17px", visibility: record.children?.length > 0 ? "visible" : "hidden" }} onClick={e => onExpand(record, e)} />
                            )
                        }
                    }}
                    expandIconColumnIndex={1}
                    pagination={{
                        current: page.PageIndex + 1,
                        pageSize: page.pageSize,
                        total,
                        onChange: (page, pageSize) => {
                            setPage({ PageIndex: page - 1, pageSize: pageSize })
                            navigate(`/inspections?PageIndex=${page - 1}&PageSize=${pageSize}`)
                            getInspectionTableData({
                                ...params, PageIndex: page - 1, PageSize: pageSize
                            })
                        }
                    }}
                // expandIcon={(e)=>{
                //     return <>e</>
                // }}
                />
            </div>
            {isModalOpen ? <NewInspection
                setAddPoStatus={setAddPoStatus}
                isModalOpen={isModalOpen}
                handleOk={handleOk}
                handleCancel={handleCancel}
                updateList={updateList}
                setUpdateList={setUpdateList}
                getData={getInspectionTableData}
                setPage={setPage}
            /> : <></>}
            {editIsModalOpen ? <EditInspection
                isModalOpen={editIsModalOpen}
                handleOk={handleOk}
                handleCancel={handleCancel}
                id={projectId}
                updateList={updateList}
                setUpdateList={setUpdateList}
            /> : <></>}
            {addToPoOpen ? <AddPo inspectionId={inspectionId} email={email} reportOpen={setReportOpen} closePage={setAddToPoOpen} getData={getInspectionTableData} /> : <></>}
            {editToPoOpen ? <EditPo poNumber={poNumber} inspectionId={inspectionId} reportOpen={setReportOpen} closePage={setEditToPoOpen} getData={getInspectionTableData} /> : <></>}

            <Modal
                title="Do you want to Add PO to Inspection now?"
                cancelText="No"
                okText="Yes"
                open={addPoStatus}
                onOk={() => {
                    setAddPoStatus(false)
                    navigate(`/addPoToInspection?inspectionId=${data[0]?.InspectionID}`)
                }}
                onCancel={() => setAddPoStatus(false)}>
            </Modal>
            {/* {reportOpen ? <InspectionReport closePage={setReportOpen} /> : <></>} */}
        </section>
    )
}
export default Inspections;