import request, { RequestOptionsType } from '../request'

export async function CreateInspectionProject(options:any){
    return request('api/InspectionProject/Create',{
        method:"POST",
        ...(options || {})
    })
}
export async function EditInspectionProject(options:any){
    return request('api/InspectionProject/Edit',{
        method:"POST",
        ...(options || {})
    })
}
export async function GetEditDetail(options:any){
    return request('api/InspectionProject/GetDetail',{
        method:"GET",
        ...(options || {})
    })
}
export async function GetInspectorList(options?:any){
    return request('api/InspectionProject/InspectorList',{
        method:"GET",
        ...(options || {})
    })
}
export async function GetInspectionList<
    T extends InspectionAPI.InspectionListRequest,
    R extends InspectionAPI.InspectionListResponse
>(options?:RequestOptionsType<T>){
    return request<R>('api/InspectionProject/Get',{
        method:"GET",
        ...(options || {})
    })
}
export async function GetPODetail(options:any){
    return request('api/PurchaseOrder/GetDetail',{
        method:"GET",
        ...(options || {})
    })
}
export async function AddPOToInspection<
    T extends InspectionAPI.InspectionAddPoRequest,
    R extends InspectionAPI.PostResponse
>(options:T){
    return request<R>('api/InspectionProject/AddPOToInspection',{
        method:"POST",
        ...(options || {})
    })
}