import { useEffect } from 'react';
import type { MenuProps } from 'antd';
import { Button, Menu } from 'antd';
import { useState } from 'react'
import { Link, useLocation,useNavigate } from 'react-router-dom'
import icon1 from '@/images/navigation/icon1.png'
import icon2 from '@/images/navigation/icon2.png'
import icon3 from '@/images/navigation/icon3.png'
import icon4 from '@/images/navigation/icon4.png'
import icon5 from '@/images/navigation/icon5.png'
import icon6 from '@/images/navigation/icon6.png'
import icon7 from '@/images/navigation/icon7.png'
import icon8 from '@/images/navigation/icon8.png'
import iconActive1 from '@/images/navigation/icon-active1.png'
import iconActive2 from '@/images/navigation/icon-active2.png'
import iconActive3 from '@/images/navigation/icon-active3.png'
import iconActive4 from '@/images/navigation/icon-active4.png'
import iconActive5 from '@/images/navigation/icon-active5.png'
import iconActive6 from '@/images/navigation/icon-active6.svg'
import iconActive7 from '@/images/navigation/icon-active7.png'
import iconActive8 from '@/images/navigation/icon-active8.png'
import logo from '@/images/login/logo.png'
import * as R from 'ramda'
type MenuItem = Required<MenuProps>['items'][number];
function getItem(
    label: React.ReactNode,
    key: React.Key,
    icon?: React.ReactNode,
    children?: MenuItem[],
    type?: 'group',
): MenuItem {
    return {
        key,
        icon,
        children,
        label,
        type,
    } as MenuItem;
}

export default function Navigation() {
    const { pathname } = useLocation()
    const [active, setActive] = useState<any>(4)
    const [selectKey, setSewlectKey] = useState<any>()
    const navigate = useNavigate()
    const items: MenuItem[] = [
        // getItem(<Link to="">Dashboard</Link>, '1', <img src={active === "1" ? iconActive1 : icon1} />,),
        // getItem(<Link to="">Projects</Link>, '2', <img src={active === "2" ? iconActive2 : icon2} />),
        
        getItem(<Link to="/inspections?PageIndex=0&PageSize=10">Inspections</Link>, '4', <img src={active === "4" ? iconActive4 : icon4} />),
        // getItem(<Link to="">CAPAs</Link>, '5', <img src={active === "5" ? iconActive5 : icon5} />),
        getItem('Management', 'sub1', <img src={active==="8" || active ==="9" ? iconActive6 : icon6} />, [
            // getItem(<Link to="">Products</Link>, '6'),
            // getItem(<Link to="">Vendors</Link>, '7'),
            // getItem(<Link to="">Inspectors</Link>, '8'),
            getItem(<Link to="/users">Users</Link>, '9'),
        ]),
        getItem('Check Lists', 'checklist1', <img src={active === "10" || active ==="11" ? iconActive7 : icon7} />,[
            getItem(<Link to="/checkList">Check Lists</Link>, '10'),
            getItem(<Link to="/attributes">Attributes</Link>, '11'),
        ]),
        getItem(<Link to="/purchaseOrders">Purchase Order</Link>, '3', <img src={active === "3" ? iconActive3 : icon3} />),
        getItem(<Link to="/conditionalPassReport">Reports</Link>, '12', <img src={active === "12" ? iconActive1 : icon1} />),
        // getItem(<Link to="">System Settings</Link>, '11', <img src={active === "11" ? iconActive8 : icon8} />),


    ];
    useEffect(() => {
        switch (pathname) {
            case "/checkList":
                setActive("10");
                setSewlectKey(["10"]);
                break;
            case "/inspections":
                setActive("4")
                setSewlectKey(["4"])
                break;
            case "/users":
                setActive("9")
                setSewlectKey(["9"])
                break;
            case "/purchaseOrders":
                setActive("3")
                setSewlectKey(["3"])
                break;
            case "/attributes":
                setActive("11")
                setSewlectKey(["11"])
                break;
            case "/conditionalPassReport":
                setActive("12")
                setSewlectKey(["12"])
                break;
        }
    }, [pathname])
    return (
        <div style={{ borderRight: "1px solid #E7E7E7" }} className='h-[100vh] w-[200px] border-r-[1px] border-r-[#E7E7E7] bg-[#F7F8FA]'>
            <div style={{ borderBottom: "1px solid #E7E7E7" }} className='border-b-[1px] border-r-[1px] border-[red] pt-[25px] pb-[17px] pl-[20px]'>
                <img src={logo} className='w-[80%]' alt="" />
            </div>
            <Menu
                onClick={(e) => {
                    if (e.keyPath.length > 1) {
                        setActive(e.keyPath)
                    } else {
                        setActive(e.key)
                        setSewlectKey([e.key])
                    }
                }}
                style={{ border: "none" }}
                className='bg-[#F7F8FA]'
                // style={{ height: "100%" }}
                selectedKeys={selectKey}
                // defaultOpenKeys={['sub1']}
                mode="inline"
                // theme="dark"
                items={items}
            />
        </div>
    )
}