import react,{ useState, useEffect, useContext } from 'react'
import { Input, Space, Modal, Table, Button, message } from 'antd';
import edit from '@/images/navigation/edit.png'
import { MyContext } from '@/context/MyContext';
import type { ColumnsType } from 'antd/es/table';
import { CaretDownOutlined, CaretRightOutlined,ExclamationCircleFilled  } from '@ant-design/icons';
import { Link, useNavigate,useSearchParams } from 'react-router-dom';
import { AddAttribute,GetTemplateArgumentLi,DeleteAttribute } from '@/services/checkList';
import add from '@/images/navigation/add.png'
import EditImg from '@/images/navigation/edit.png'
import DeleteImg from '@/images/navigation/delete.png'
import NewAttribute from './components/NewAttribute';
const { confirm } = Modal;

export default function Attributes() {
    const { Search } = Input
    const Navigate = useNavigate()
    const { setItems, setTitle } = useContext(MyContext)
    const [total, setTotal] = useState(0)
    const [search] = useSearchParams()
    const [page, setPage] = useState({ 
        PageIndex: Number(search.get("PageIndex") || 0), 
        pageSize: Number(search.get("PageSize") || 10) 
    })
    const [loading, setLoading] = useState(false)
    const [params, setParams] = useState({})
    const [updateList, setUpdateList] = useState(false)
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isParentId,setIsParentId] = useState(0)
    const showDeleteConfirm = (id:number) => {
        confirm({
          className:"ConfirmDelete",
          title: 'Are you sure delete this attribute?',
          icon: <ExclamationCircleFilled />,
          okText: 'Yes',
        //   okType: 'danger',
          cancelText: 'No',
          onOk:async ()=>{
            const res:any = await DeleteAttribute({params:{id}})
            if(res.isSuccess){
                message.success('Successfully.')
                setUpdateList(!updateList)
            }else{
                message.error(res.message)
            }
          },
          onCancel() {
            console.log('Cancel');
          },
        });
      };
    const handleStatus = (Status: string) => {
        setParams({
            ...params,
            Status
        })
        getInspectionTableData({
            ...params,
            Status,
            PageIndex: 0,
            PageSize: 10
        })
        setPage({ PageIndex: 0, pageSize: 10 })
    }
    interface DataType {
        key: string
        id: number
        attribut: string,
        type: string,
        isUsed: boolean,
        list:number
        children:any
        inUsed:boolean
    }
    const columns: ColumnsType<DataType> = [
        {
            dataIndex: "add",
            key: "add",
            width: 50,
            render: (_, record) => (
                // record.list === 1 && <img className="cursor-pointer" onClick={() =>openPoModal(record) } src={add} alt="" />
                record.list === 1 && !record.inUsed &&  <Link to=""><img className="cursor-pointer" onClick={()=>{
                    setIsParentId(record.id)
                    setIsModalOpen(true)
                }} src={add} alt="" /></Link>
            ),
        },
        {
            title: 'Attribute / Group Name',
            dataIndex: 'attribut',
            key: 'attribut',
            sorter: (a, b) => {
                const aFirstLetter = a.attribut[0].toLowerCase();
                const bFirstLetter = b.attribut[0].toLowerCase();
                if (aFirstLetter < bFirstLetter) {
                    return -1;
                }
                if (aFirstLetter > bFirstLetter) {
                    return 1;
                }
                return 0;
            },
            render:(text,record)=>{
                return <span style={{color:record.list===1?"#0A1629":""}}>{text}</span>
            }
        },
        // {
        //     title: 'Attribut Type.',
        //     dataIndex: 'type',
        //     key: 'type',
        //     sorter: (a, b) => {
        //         const aFirstLetter = a.type[0].toLowerCase();
        //         const bFirstLetter = b.type[0].toLowerCase();
        //         if (aFirstLetter < bFirstLetter) {
        //             return -1;
        //         }
        //         if (aFirstLetter > bFirstLetter) {
        //             return 1;
        //         }
        //         return 0;
        //     },
        // },
        {
            title: 'Action',
            key: 'action',
            width:150,
            render: (_, record) => (
                <div className='action'>
                    {!record.inUsed && <img src={DeleteImg} onClick={()=>showDeleteConfirm(record.id)} alt="" />}
                </div>
            ),
        },
    ];
    const [data, setData] = useState<DataType[]>([])
    useEffect(() => {
        getInspectionTableData({ ...page })
        setTitle("Check List Attributes")
    }, [updateList])
    const onSearch = (Keyword: string) => {
        getInspectionTableData({
            ...params,
            Keyword,
            PageIndex: 0,
            PageSize: 10
        })
        setPage({ PageIndex: 0, pageSize: 10 })
        setParams({
            ...params,
            Keyword
        })
    };
    const getInspectionTableData = async (params?: InspectionAPI.InspectionListRequest) => {
        setLoading(true)
        GetTemplateArgumentLi({ params }).then((res: any) => {
            if (res.isSuccess) {
                setLoading(false)
                setItems(`(${res?.totalCount.toLocaleString()})`)
                setData(res?.data?.map((item: any, index: number) => ({ 
                    key: item.id, 
                    attribut:item.argument,
                    id:item.id,
                    list:1,
                    inUsed:item.inUsed,
                    children:item.subarguments.map((x2:any)=>({
                        key:x2.id,
                        attribut:x2.subargument,
                        id:x2.id,
                        inUsed:x2.inUsed
                    }))
                })))
                setTotal(res.totalCount)
            } else {
                setLoading(false)
                setItems(0)
                setData([])
                setTotal(0)
            }
        })

    }
    return (
        <section>
            <div className="flex justify-between">
                <div>
                    <Button type="primary" onClick={() => {
                        setIsModalOpen(true)
                        setIsParentId(0)
                    }} className="mr-[5px] new" style={{ borderRadius: "4px" }}>+ New</Button>
                    <Search
                        placeholder="Search by Name"
                        style={{ width: 400 }}
                        className="search"
                        onSearch={onSearch}
                        onChange={(e) => setParams({
                            ...params,
                            Keyword: e.target.value
                        })}
                    />
                </div>
            </div>
            <div className="page-content bg-white" style={{ height: 200 }}>
                <Table
                    className="qc-table"
                    columns={columns}
                    dataSource={data}
                    loading={loading}
                    scroll={{ y: 500 }}
                    expandable={{
                        expandIcon: ({ expanded, onExpand, record }) =>{
                           return expanded ? (
                                <CaretDownOutlined style={{ marginRight: "17px",visibility: record.children?.length > 0 ? "visible" : "hidden" }} onClick={e => onExpand(record, e)} />
                            ) : (
                                <CaretRightOutlined style={{ marginRight: "17px",visibility: record.children?.length > 0 ? "visible" : "hidden" }} onClick={e => onExpand(record, e)} />
                            )
                        }
                    }}
                    expandIconColumnIndex={1}
                    pagination={{
                        current: page.PageIndex + 1,
                        pageSize: page.pageSize,
                        total,
                        onChange: (page, pageSize) => {
                            setPage({ PageIndex: page - 1, pageSize: pageSize })
                            Navigate(`/attributes?PageIndex=${page - 1}&PageSize=${pageSize}`)
                            getInspectionTableData({
                                ...params, PageIndex: page - 1, PageSize: pageSize
                            })
                        }
                    }}
                // expandIcon={(e)=>{
                //     return <>e</>
                // }}
                />
            </div>
            <NewAttribute 
                isModalOpen={isModalOpen} 
                setIsModalOpen={setIsModalOpen} 
                updateList={updateList} 
                parentId={isParentId}
                setUpdateList={setUpdateList} />
        </section>
    )
}