import { useEffect, useState, useRef } from "react"
import "@/styles/inspectionReport.less"
import type { ColumnsType } from 'antd/es/table';
import close from '@/images/navigation/big_close.svg'
import { Input, Checkbox, Form, DatePicker, Table, Button, message,Image } from 'antd';
import report from '@/images/navigation/report.png'
import pass from '@/images/navigation/pass.svg'
import pending from '@/images/navigation/pending.png'
import fail from '@/images/navigation/fail.png'
import email from '@/images/navigation/email.png'
import print from '@/images/navigation/print.png'
import ReactToPrint from 'react-to-print';
import { GetReportDetail } from "@/services/purchaseOrder";
import img from '@/images/navigation/img.png'
import moment from "moment";
import ViewImg from "./ViewImg";
const InspectionReport: React.FC<{
    closePage: Function
    reportParams: ReportParamsTyple
}> = ({ closePage, reportParams }) => {
    const { TextArea } = Input
    const [form] = Form.useForm()
    const [result,setResult] = useState<any>("")
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [fileIdArr,setFileIdArr] = useState([])
    const [loading,setLoading] = useState(false)
    const [failureCode, setFailureCode] = useState({
        Functionality: false,
        Structure: false,
        Appearance: false,
        Certification: false,
        Packaging: false,
        Labeling: false,
    })
    const onFinish = (values: any) => {
        console.log('Success:', values);
    };
    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };
    interface DataType {
        key: React.Key;
        details: string,
        value: string,
        NA: boolean | "",
        AC: boolean | "",
        MI: boolean | "",
        MA: boolean | "",
        images: string,
        comment: string,
        list: number,
        children?: DataType[]
    }
    const inspectionReportRef = useRef(null)
    const [data, setData] = useState<DataType[]>([
        // {
        //     key: 1,
        //     details: `Self-inspection report`,
        //     value: "773546326643",
        //     NA: false,
        //     AC: false,
        //     MI: false,
        //     MA: false,
        //     images: ``,
        //     comment: `Cum sociis natoque penatibus et magnis dis parturient montes`,
        //     list: 1
        // },
        // {
        //     key: 2,
        //     details: `Production finish over 80%`,
        //     value: "773546326643",
        //     NA: false,
        //     AC: false,
        //     MI: false,
        //     MA: false,
        //     images: ``,
        //     comment: `Cum sociis natoque penatibus et magnis dis parturient montes`,
        //     list: 1
        // },
        // {
        //     key: 3,
        //     details: "Package check",
        //     value: "",
        //     NA: "",
        //     AC: "",
        //     MI: "",
        //     MA: "",
        //     images: ``,
        //     comment: ``,
        //     list: 1,
        //     children: [
        //         {
        //             key: 13,
        //             details: `Shipping Marks`,
        //             value: "773546326643",
        //             NA: false,
        //             AC: false,
        //             MI: false,
        //             MA: false,
        //             images: ``,
        //             comment: `Cum sociis natoque penatibus et magnis dis parturient montes`,
        //             list: 2,
        //         },
        //         {
        //             key: 14,
        //             details: `Shipping Marks`,
        //             value: "773546326643",
        //             NA: false,
        //             AC: false,
        //             MI: false,
        //             MA: false,
        //             images: ``,
        //             comment: `Cum sociis natoque penatibus et magnis dis parturient montes`,
        //             list: 2,
        //         },
        //         {
        //             key: 15,
        //             details: `Shipping Marks`,
        //             value: "773546326643",
        //             NA: false,
        //             AC: false,
        //             MI: false,
        //             MA: false,
        //             images: ``,
        //             comment: `Cum sociis natoque penatibus et magnis dis parturient montes`,
        //             list: 2,
        //         },
        //         {
        //             key: 16,
        //             details: `Shipping Marks`,
        //             value: "773546326643",
        //             NA: false,
        //             AC: false,
        //             MI: false,
        //             MA: false,
        //             images: ``,
        //             comment: `Cum sociis natoque penatibus et magnis dis parturient montes`,
        //             list: 2,
        //         },
        //     ]
        // },

    ])
    const columns: ColumnsType<DataType> = [
        {
            title: 'Details',
            dataIndex: 'details',
            // width: 150,
            render: (text, record) => (
                <div className={record?.list === 1 ? "detail1" : "detail2"}>{text}</div>
            )

        },
        {
            title: 'Value',
            dataIndex: 'value',
            // width: 150,
            className: "value",
            render: (text, record) => (
                <div className="comment">
                    {text?.indexOf("http")!==-1?<Image width={50}  src={text} />:<>{text}</>}
                </div>
            )

        },
        {
            title: 'NA',
            dataIndex: 'NA',
            align: "center",
            width: 100,
            render: (_, record, index) => (
                record.NA !== "" && <div className="reportTd"
                // onClick={() => {
                //     if (record.list === 1) {
                //         data.forEach((x, i) => {
                //             if (index === i) {
                //                 if (record.NA) {
                //                     x.NA = false
                //                 } else {
                //                     x.NA = true
                //                     x.AC = false
                //                     x.MA = false
                //                     x.MI = false
                //                 }
                //             }
                //         })
                //     } else if (record.list === 2) {
                //         data.forEach((x, i) => {
                //             x?.children?.forEach((y, idx2) => {
                //                 if (record.key === y.key) {
                //                     if (record.NA) {
                //                         y.NA = false
                //                     } else {
                //                         y.NA = true
                //                         y.MI = false
                //                         y.MA = false
                //                         y.AC = false
                //                     }
                //                 }
                //             })

                //         })
                //     }
                //     setData(JSON.parse(JSON.stringify(data)))
                // }}
                >
                    <div className="round" style={{background:record.NA?"#7D8FB3":""}}>
                        {record.NA && <svg width="100%" height="100%" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="12.749" cy="12" r="7.5" fill="white" stroke="#7D8FB3" strokeWidth="9" />
                        </svg>}
                    </div>
                </div>
            )
        },
        {
            title: 'AC',
            dataIndex: 'AC',
            align: "center",
            className: "ac",
            width: 100,
            render: (_, record, index) => (
                record.AC !== "" && <div className="reportTd"
                // onClick={() => {
                //     if (record.list === 1) {
                //         data.forEach((x, i) => {
                //             if (index === i) {
                //                 if (record.AC) {
                //                     x.AC = false
                //                 } else {
                //                     x.AC = true
                //                     x.MI = false
                //                     x.MA = false
                //                     x.NA = false
                //                 }
                //             }
                //         })
                //     } else if (record.list === 2) {
                //         data.forEach((x, i) => {
                //             x?.children?.forEach((y, idx2) => {
                //                 if (record.key === y.key) {
                //                     if (record.AC) {
                //                         y.AC = false
                //                     } else {
                //                         y.AC = true
                //                         y.MI = false
                //                         y.MA = false
                //                         y.NA = false
                //                     }
                //                 }
                //             })

                //         })
                //     }
                //     setData(JSON.parse(JSON.stringify(data)))
                // }}
                >
                    <div className="round" style={{background:record.AC?"#98C9A6":""}}>
                        {record.AC && <svg width="100%" height="100%" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="12.749" cy="12" r="7.5" fill="white" stroke="#98C9A6" strokeWidth="9" />
                        </svg>}
                    </div>
                </div>
            )
        },
        {
            title: 'MI',
            dataIndex: 'MI',
            align: "center",
            className: "mi",
            width: 100,
            render: (_, record, index) => (
                record.MI !== "" && <div className="reportTd"
                // onClick={() => {
                //     if (record.list === 1) {
                //         data.forEach((x, i) => {
                //             if (index === i) {
                //                 if (record.MI) {
                //                     x.MI = false
                //                 } else {
                //                     x.MI = true
                //                     x.AC = false
                //                     x.MA = false
                //                     x.NA = false
                //                 }
                //             }
                //         })
                //     } else if (record.list === 2) {
                //         data.forEach((x, i) => {
                //             x?.children?.forEach((y, idx2) => {
                //                 if (record.key === y.key) {
                //                     if (record.MI) {
                //                         y.MI = false
                //                     } else {
                //                         y.MI = true
                //                         y.AC = false
                //                         y.MA = false
                //                         y.NA = false
                //                     }
                //                 }
                //             })

                //         })
                //     }
                //     setData(JSON.parse(JSON.stringify(data)))
                // }}
                >
                    <div className="round" style={{background:record.MI?"#E3BA7B":""}}>
                        {record.MI && <svg width="100%" height="100%" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="12.749" cy="12" r="7.5" fill="white" stroke="#E3BA7B" strokeWidth="9" />
                        </svg>}
                    </div>
                </div>
            ),
        },
        {
            title: 'MA',
            dataIndex: 'MA',
            align: "center",
            className: "ma",
            width: 100,
            render: (_, record, index) => {
                return (
                    record.MA !== "" && <div className="reportTd"
                    // onClick={() => {
                    //     if (record.list === 1) {
                    //         data.forEach((x, i) => {
                    //             if (index === i) {
                    //                 if (record.MA) {
                    //                     x.MA = false
                    //                 } else {
                    //                     x.MA = true
                    //                     x.AC = false
                    //                     x.MI = false
                    //                     x.NA = false
                    //                 }
                    //             }
                    //         })
                    //         setData(JSON.parse(JSON.stringify(data)))
                    //     } else if (record.list === 2) {
                    //         data.forEach((x, i) => {
                    //             x?.children?.forEach((y, idx2) => {
                    //                 if (record.key === y.key) {
                    //                     if (record.MA) {
                    //                         y.MA = false
                    //                     } else {
                    //                         y.MA = true
                    //                         y.AC = false
                    //                         y.MI = false
                    //                         y.NA = false
                    //                     }
                    //                 }
                    //             })

                    //         })
                    //         setData(JSON.parse(JSON.stringify(data)))
                    //     }
                    // }}
                    >
                        <div className="round"  style={{background:record.MA?"#E37B7B":""}}>
                            {record.MA && <svg width="100%" height="100%" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="12.749" cy="12" r="7.5" fill="white" stroke="#E37B7B" strokeWidth="9" />
                            </svg>}
                        </div>
                    </div>
                )
            }
        },
        {
            title: 'Images',
            dataIndex: 'images',
            align: "center",
            width: 100,
            render: (text) => (
                text?.length > 0 && (<div style={{cursor:"pointer"}} onClick={()=>{
                    setIsModalOpen(true);
                    setFileIdArr(text)
                }}><img src={img} alt="" /> {text.length}</div>)
            )
        },
        {
            title: 'Comment',
            dataIndex: 'comment',
            render: (text) => (
                <div className="comment">{text}</div>
            )
        },
    ];
    const GetReportData = async () => {
        setLoading(true)
        const res: any = await GetReportDetail({ params: reportParams })
        if (res.isSuccess) {
            const { steps, failureCodes } = res.data
            form.setFieldsValue({
                Description: res.data.description,
                Vendor: res.data.description,
                InspectionDate: moment(res.data.createdDate, 'M/D/YYYY h:mm A'),
                pono: res.data.poNumber,
                poqty: res.data.poQuantity,
                InspectedQty: res.data.inspectedQuantity,
                ModelNumber: res.data.itemNo,
                Inspectionby: res.data.inspectionBy,
                Comment: res.data.comment,
            })
            setResult(res.data.result)
            failureCodes?.forEach((item: any) => {
                if (item.failureCode === 0) {
                    failureCode["Functionality"] = true
                    form.setFieldValue("Functionality", item.comment)
                } else if (item.failureCode === 1) {
                    failureCode["Structure"] = true
                    form.setFieldValue("Structure", item.comment)
                } else if (item.failureCode === 2) {
                    failureCode["Appearance"] = true
                    form.setFieldValue("Appearance", item.comment)
                } else if (item.failureCode === 3) {
                    failureCode["Certification"] = true
                    form.setFieldValue("Certification", item.comment)
                } else if (item.failureCode === 4) {
                    failureCode["Packaging"] = true
                    form.setFieldValue("Packaging", item.comment)
                } else if (item.failureCode === 5) {
                    failureCode["Labeling"] = true
                    form.setFieldValue("Labeling", item.comment)
                }
            })
            setFailureCode(JSON.parse(JSON.stringify(failureCode)))
            setData(steps.map((item: any, index: number) => {
                return {
                    key: index,
                    list: 1,
                    details: item.title,
                    comment: item.comment,
                    value:item.value,
                    images: item.imageIds,
                    NA: item.quality === 0,
                    AC: item.quality === 1,
                    MI: item.quality === 2,
                    MA: item.quality === 3,
                }
            }))
            setLoading(false)
        } else {
            message.error(res.message)
        }
    }
    useEffect(() => {
        GetReportData();
    }, [])

    return (
        <section className="inspectionReport" >
            <div className="head">
                <h2>Inspection Report</h2>
                <img style={{ cursor: "pointer" }} onClick={() => closePage(false)} src={close} alt="" />
            </div>
            <Form
                form={form}
                name="basic"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                layout='vertical'
            >
                <ul className="generalInfo">
                    <li>
                        <div className="active">General Info</div>
                        <div style={{ visibility: "hidden" }}>History</div>
                    </li>
                    <li>

                    </li>
                </ul>

                <div ref={inspectionReportRef}>
                    <div className="data_entry">
                        <div style={{ width: "60%" }}>
                            <Form.Item
                                label="Model Number"
                                name="ModelNumber"
                                style={{ width: "38%" }}
                            // rules={[{ required: true, message: 'Please input your username!' }]}
                            >
                                <Input disabled />
                            </Form.Item>
                            <Form.Item
                                label="Vendor"
                                name="Vendor"
                                style={{ width: "60%" }}
                            >
                                <Input disabled />
                            </Form.Item>
                            <Form.Item
                                label="Description"
                                name="Description"
                                style={{ width: "100%" }}
                            >
                                <Input disabled />
                            </Form.Item>
                        </div>
                        <div style={{ width: "38%" }}>
                            <Form.Item
                                label="P. O. No."
                                name="pono"
                                style={{ width: "48%" }}
                            >
                                <Input disabled />
                            </Form.Item>
                            <Form.Item
                                label="P.O. Q’ty"
                                name="poqty"
                                style={{ width: "24%" }}
                            >
                                <Input disabled />
                            </Form.Item>
                            <Form.Item
                                label="Inspected Q’ty"
                                name="InspectedQty"
                                style={{ width: "24%" }}
                            >
                                <Input disabled />
                            </Form.Item>
                            <Form.Item
                                label="Inspection Date"
                                name="InspectionDate"
                                style={{ width: "48%" }}
                            >
                                <DatePicker disabled style={{ width: "100%" }} />
                            </Form.Item>
                            <Form.Item
                                label="Inspection by"
                                name="Inspectionby"
                                style={{ width: "48%" }}
                            >
                                <Input disabled />
                            </Form.Item>
                        </div>
                    </div>
                    <div className="report">
                        <Table loading={loading} className="reportTable" bordered={false} columns={columns} dataSource={data} pagination={false} scroll={{ y: 400 }} />
                    </div>
                    <section className="inspection_result">
                        <div className="result">
                            <h3>Inspection Result</h3>
                            <ul className="">
                                <li className="Pass" style={{opacity:result==2?"":"0.25"}}>
                                    <img src={pass} alt="" />
                                    pass
                                </li>
                                <li className="Pending" style={{opacity:result==1?"":"0.25"}}>
                                    <img src={pending} alt="" />
                                    Pending
                                </li>
                                <li className="Fail" style={{opacity:result==0?"":"0.25"}}>
                                    <img src={fail} alt="" />
                                    Fail
                                </li>
                            </ul>
                        </div>
                        <h5>*Failure Code</h5>
                        <ul className="failure_code">
                            <li>
                                <Checkbox checked={failureCode.Functionality} onChange={(e) => setFailureCode({ ...failureCode, Functionality: e.target.checked })} >Functionality</Checkbox>
                                <Form.Item name="Functionality"><Input readOnly disabled={!failureCode.Functionality} autoFocus /></Form.Item>
                            </li>
                            <li>
                                <Checkbox checked={failureCode.Structure} onChange={(e) => setFailureCode({ ...failureCode, Structure: e.target.checked })}  >Structure</Checkbox>
                                <Form.Item name="Structure"><Input readOnly disabled={!failureCode.Structure} autoFocus={failureCode.Structure} /></Form.Item>
                            </li>
                            <li>
                                <Checkbox checked={failureCode.Appearance} onChange={(e) => setFailureCode({ ...failureCode, Appearance: e.target.checked })}  >Appearance</Checkbox>
                                <Form.Item name="Appearance"><Input readOnly disabled={!failureCode.Appearance} autoFocus={failureCode.Appearance} /></Form.Item>

                            </li>
                            <li>
                                <Checkbox checked={failureCode.Certification} onChange={(e) => setFailureCode({ ...failureCode, Certification: e.target.checked })}  >Certification</Checkbox>
                                <Form.Item name="Certification"><Input readOnly disabled={!failureCode.Certification} autoFocus={failureCode.Certification} /></Form.Item>
                            </li>
                            <li>
                                <Checkbox checked={failureCode.Packaging} onChange={(e) => setFailureCode({ ...failureCode, Packaging: e.target.checked })}  >Packaging</Checkbox>
                                <Form.Item name="Packaging"><Input readOnly disabled={!failureCode.Packaging} autoFocus={failureCode.Packaging} /></Form.Item>

                            </li>
                            <li>
                                <Checkbox checked={failureCode.Labeling} onChange={(e) => setFailureCode({ ...failureCode, Labeling: e.target.checked })}  >Labeling</Checkbox>
                                <Form.Item name="Labeling"><Input readOnly disabled={!failureCode.Labeling} autoFocus={failureCode.Labeling} /></Form.Item>

                            </li>
                        </ul>
                        <h5>Comment </h5>
                        <Form.Item name="Comment">
                            <TextArea style={{ height: 130 }} />
                        </Form.Item>
                    </section>
                </div>

                <ul className="submitData">
                    <li>
                        {/* <Button><img src={email} alt="" /> Send Email</Button>

                        <ReactToPrint
                            trigger={() => <Button><img src={print} alt="" /> Print Report</Button>}
                            content={() => inspectionReportRef.current}
                        /> */}
                    </li>
                    <li>
                        <Button type="primary" disabled htmlType="submit">Summit Report</Button>
                    </li>
                </ul>
            </Form>
            <ViewImg fileIdArr={fileIdArr} isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />
        </section>
    )
}
export default InspectionReport