import { useEffect, useState, useRef } from "react"
import "@/styles/inspectionReport.less"
import type { ColumnsType } from 'antd/es/table';
import close from '@/images/navigation/big_close.svg'
import { Input, Checkbox, Form, DatePicker, Table, Spin, message, Image, Button, InputNumber, Modal, Select, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import type { UploadProps, UploadFile } from 'antd';
import report from '@/images/navigation/report.png'
import pass from '@/images/navigation/pass.svg'
import pending from '@/images/navigation/pending.png'
import fail from '@/images/navigation/fail.png'
import email from '@/images/navigation/email.png'
import print from '@/images/navigation/print.png'
import ReactToPrint from 'react-to-print';
import { useSearchParams, useNavigate } from "react-router-dom";
import { ExclamationCircleFilled, InboxOutlined, DeleteTwoTone } from '@ant-design/icons';
import { GetReportDetail } from "@/services/purchaseOrder";
import { VerifyReport, GetInspectionDetailByInspector, OnSubmitReport, GetFile } from "@/services/inspectionReport";
import img from '@/images/navigation/img2.png'
import moment from "moment";
import ViewImg from "@/page/inspections/comonents/ViewImg";
import UploadImgPopup from "./components/uploadImgPopup";
import UploadImgPopup2 from "./components/uploadImgPopup2";
import ReportTable from "./components/ReportTable";
import ConfirmationPopup from "./components/ConfirmationPopup";
const { confirm } = Modal;
const { Dragger } = Upload;
const InspectionPCReport: React.FC = () => {
    const { TextArea } = Input
    const [form] = Form.useForm()
    const [result, setResult] = useState<any>(null)
    const inspectionReportRef = useRef(null)
    const [data, setData] = useState<DataType[]>([])
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalOpen2, setIsModalOpen2] = useState(false);
    const [fileIdArr, setFileIdArr] = useState([])
    const [fileIdArr2, setFileIdArr2] = useState<any[]>([])
    const [loading, setLoading] = useState(false)
    const [reportId, setReportId] = useState<number>(0)
    const navigate = useNavigate()
    const [fileIndex, setFileIndex] = useState(0)
    const [fileIndex2, setFileIndex2] = useState(0)
    const [submitStatus, setSubmitStatus] = useState(false)
    const [search] = useSearchParams()
    const [statusText, setStatusTexgt] = useState("")
    const [iconState, setIconState] = useState(false)
    const [fileLoading, setFileLoading] = useState(false)
    const [getFileStatus, setGetFileStatus] = useState(false)
    const [ConditionalFileList, setConditionalFileList] = useState<UploadFile[]>([])
    const [uploadImgSelect, setUploadImgSelect] = useState<uploadImgSelectType>([])
    const [confirmOpen, setConfirmOpen] = useState(false)
    const [conditionalPassComment, setConditionalPassComment] = useState("")
    const [conditionalPassId, setConditionalPassId] = useState(0)
    const [Qty, setQty] = useState({
        poqty: 0,
        inspectedQty: 0,
        aqlQty:0
    })
    const [failureCode, setFailureCode] = useState<{
        [key: string]: boolean
    }>({
        Functionality: false,
        Structure: false,
        Appearance: false,
        Certification: false,
        Packaging: false,
        Labeling: false,
    })
    const [failureCodeText] = useState([
        { key: 'Functionality', label: 'Functionality' },
        { key: 'Structure', label: 'Structure' },
        { key: 'Appearance', label: 'Appearance' },
        { key: 'Certification', label: 'Certification' },
        { key: 'Packaging', label: 'Packaging' },
        { key: 'Labeling', label: 'Labeling' },
    ])
    const showConfirmationPass = (conditionalPassComment: string) => {
        confirm({
            className: "ConfirmationPassPopup",
            title: 'Please confirm Conditional Comment',
            icon: <ExclamationCircleFilled />,
            content: <div>
                {conditionalPassComment}
            </div>,
            // onOk() {
            // },
            onCancel() {
                navigate(-1)
            },
        });
    };
    const reportParams = {
        // inspectionProjectId: search.get("inspectionId"),
        PurchaseOrder_InspectionProjectId: +(search.get("itemId") || 0),
        ItemNo: search.get("itemNo") || "",
        PONumber: search.get("poNumber") || ""
    }
    const onFinish = (values: any) => {
        console.log('Success:', values);
        // saveSubmit(0)

    };
    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    type newDataType = {
        key: number;
        details: string;
        argumentId: number;
        value: string;
        NA: boolean;
        AC: boolean;
        MI: boolean;
        MA: boolean;
        PD: boolean;
        images: number[];
        comment: string;
        uploadStatus: boolean;
        list: number;
        category: string,
        fileList: any[],
        standard: string,
        mustAddPhoto: boolean,
        completeStatus: boolean;
        categoryLength?: number
        parentArgumentMustAddPhoto?: boolean
    }[]
    const uploadImgList = (data: newDataType) => {
        let option = data.map((item, index) => {
            return {
                label: item.category || item.details,
                value: item.category || item.details,
                uploadStatus: false,
                id: 0,
                imgList: [],
                images: []
            }
        })
        const uniqueArray = (array: uploadImgSelectType): uploadImgSelectType => {
            return array.filter((item, index) => {
                const firstIndex = array.findIndex((elem) => elem.label === item.label);
                return index === firstIndex;
            });
        };
        setUploadImgSelect(uniqueArray(option).map((x, i) => ({ ...x, id: i })))

    }
    const getCategoryArr = (newData: newDataType) => {
        let arr = newData.map((item) => {
            if (item.category) {
                return item.category
            } else {
                return item.details
            }
        })

        const result = arr.reduce((acc: string[][], item) => {
            const lastGroup = acc[acc.length - 1];
            if (lastGroup && lastGroup[0] === item) {
                lastGroup.push(item);
            } else {
                acc.push([item]);
            }
            return acc;
        }, []);
        newData.forEach(item => {
            result.forEach((item2: string[]) => {
                if (item.category === item2[0]) {
                    item.categoryLength = item2.length
                }
            })
        })
        setData(newData)
    }
    const getFileList = async (fileIdArr: number[]) => {
        setFileLoading(true)
        const arr: any = fileIdArr.map(async (fileId: number) => {
            // fileList: [1,2,3].map(async (fileId: number) => {
            const res: any = await GetFile({ params: { fileId } })
            const contentDisposition = res.headers['content-disposition']
            const filenameMatches = /filename=([^;]+)/g.exec(contentDisposition);
            let name = filenameMatches ? filenameMatches[1] : "Unknown"
            delete res.headers
            return {
                uid: fileId,
                name,
                url: URL.createObjectURL(res),
                status: 'done',
                response: { data: [fileId] }
            }
        })
        Promise.all(arr).then(res => {
            setGetFileStatus(true)
            setConditionalFileList([...res])
            setFileLoading(false)


        })
    }
    const GetReportData = async () => {
        setLoading(true)
        try {
            const res = await GetInspectionDetailByInspector({ params: reportParams })
            if (res?.isSuccess) {

                const { steps, failureCodes, critical, criticalAccept, majorAccept, majorReject, minorAccept, minorReject } = res.data
                setReportId(res.data.reportId)
                form.setFieldsValue({
                    Description: res.data.description,
                    Vendor: res.data.description,
                    InspectionDate: moment(res.data.createdDate, 'M/D/YYYY h:mm A'),
                    pono: res.data.poNumber,
                    poqty: res.data.poQuantity,
                    InspectedQty: res.data.inspectedQuantity,
                    ModelNumber: res.data.itemNo,
                    Inspectionby: res.data.inspectionBy,
                    Comment: res.data.comment,
                    finalCommentsRef: res.data.finalComments,
                    critical, criticalAccept, majorAccept, majorReject, minorAccept, minorReject,
                    producedQuantity: res.data.producedQuantity,
                    status: res.data.status,
                    InspectionRate: res.data.inspectionRate,
                    ConditionaComment: res.data.conditionalPassComment,
                    insType:res.data.insType,
                    aqlQty:res.data.aqlQty
                })
                setQty({
                    poqty: res.data.poQuantity,
                    inspectedQty: res.data.inspectedQuantity,
                    aqlQty:res.data.aqlQty || 0
                })
                setStatusTexgt(res.data.status)
                setResult(res.data.result)
                setConditionalPassComment(res.data.lastConditionalPassComment)
                setConditionalPassId(res.data.reportId)
                res.data.lastConditionalPassComment && setConfirmOpen(true)
                failureCodes?.forEach((item: any) => {
                    if (item.failureCode === 0) {
                        failureCode["Functionality"] = true
                        form.setFieldValue("Functionality", item.comment)
                    } else if (item.failureCode === 1) {
                        failureCode["Structure"] = true
                        form.setFieldValue("Structure", item.comment)
                    } else if (item.failureCode === 2) {
                        failureCode["Appearance"] = true
                        form.setFieldValue("Appearance", item.comment)
                    } else if (item.failureCode === 3) {
                        failureCode["Certification"] = true
                        form.setFieldValue("Certification", item.comment)
                    } else if (item.failureCode === 4) {
                        failureCode["Packaging"] = true
                        form.setFieldValue("Packaging", item.comment)
                    } else if (item.failureCode === 5) {
                        failureCode["Labeling"] = true
                        form.setFieldValue("Labeling", item.comment)
                    }
                })
                setFailureCode(JSON.parse(JSON.stringify(failureCode)))
                let newData: newDataType = steps.map((item, index) => {
                    return {
                        key: index,
                        details: item.title,
                        argumentId: item.argumentId,
                        value: item.value,
                        NA: item.quality === 0,
                        AC: item.quality === 1,
                        MI: item.quality === 2,
                        MA: item.quality === 3,
                        PD: item.quality === 4,
                        images: item.imageIds || [],
                        comment: item.comment,
                        uploadStatus: false,
                        list: 1,
                        category: item.parentArgumentTitle,
                        fileList: [],
                        standard: item.standard,
                        // mustAddPhoto: item.mustAddPhoto,
                        mustAddPhoto: item.parentArgumentTitle ? item.parentArgumentMustAddPhoto : item.mustAddPhoto,
                        completeStatus: false
                    }
                })

                // setData(newData)
                uploadImgList(newData)
                getCategoryArr(newData)
                !getFileStatus && res.data.conditionalPassFiles && getFileList(res.data.conditionalPassFiles.map(x => x.fileID))
                setLoading(false)
            } else {
                message.error(res?.message)
            }
        } catch (error: any) {
            console.log(error);
            message.error(error.message)
        }
    }
    const saveSubmit = async (status: boolean) => {
        let producedQuantity = form.getFieldValue("producedQuantity")
        let InspectedQty = form.getFieldValue("InspectedQty")
        let poqty = form.getFieldValue("poqty")
        if (producedQuantity <= 0) {
            message.error("Produced Q 'ty cannot be less than or equal to zero.")
            return
        }
        if (InspectedQty <= 0 || InspectedQty > poqty) {
            message.error("Inspected Q 'ty cannot be less than zero or greater than P.O. Q 'ty.")
            return
        }
        if (fileLoading) {
            message.error('Please wait for the file upload to complete')
            return
        }
        setLoading(true)
        let failureCodes = []
        const fieldMap: { [key: string]: number } = {
            Functionality: 0,
            Structure: 1,
            Appearance: 2,
            Certification: 3,
            Packaging: 4,
            Labeling: 5,
        };
        for (let key in failureCode) {
            if (failureCode[key] && fieldMap.hasOwnProperty(key)) {
                failureCodes.push({
                    failureCode: fieldMap[key],
                    comment: form.getFieldValue(key),
                });
            }
        }
        let qtyobj: { [key: string]: number } = {
            NA: 0,
            AC: 1,
            MI: 2,
            MA: 3,
            PD: 4,
        }
        let details = data.map((item) => {
            let quality = null
            for (let key in item) {
                if (item[key] === true && qtyobj.hasOwnProperty(key)) {
                    quality = qtyobj[key]
                }
            }
            return ({
                argumentId: item.argumentId,
                title: item.details,
                value: item.value,
                quality,
                imageIds: item.uploadStatus ? item.fileList.map((x) => x.id) : item.images,
                comment: item.comment
            })
        })
        let requestData: ReportDataType = {
            aqlQty:form.getFieldValue('aqlQty'),
            poQuantity: form.getFieldValue("poqty"),
            itemNo: search.get("itemNo") || "",
            poNumber: search.get("poNumber") || "",
            vendor: form.getFieldValue('Vendor'),
            inspectedQuantity: form.getFieldValue("InspectedQty"),
            purchaseOrder_InspectionProjectId: +(search.get("itemId") || 0),
            comment: form.getFieldValue("Comment"),
            details,
            failureCodes,
            result,
            status: status ? 1 : 0,
            critical: form.getFieldValue("critical") || 0,
            criticalAccept: form.getFieldValue("criticalAccept") || 0,
            majorAccept: form.getFieldValue("majorAccept") || 0,
            majorReject: form.getFieldValue("majorReject") || 0,
            minorAccept: form.getFieldValue("minorAccept") || 0,
            minorReject: form.getFieldValue("minorReject") || 0,
            producedQuantity: form.getFieldValue("producedQuantity")
        }
        if (result === 3) {
            requestData.conditionalPassComment = form.getFieldValue("ConditionaComment")
            requestData.conditionalPassFiles = ConditionalFileList.map(item => item.response.data[0])

        }
        if (reportId) {
            requestData.reportId = reportId
        }
        try {
            const res: any = await OnSubmitReport({ data: requestData })
            if (res.isSuccess) {
                status ? message.success("Submit successfully") : message.success("Save successfully")
                setReportId(res.data)
                form.setFieldValue('status', status ? "Submitted" : "In Progress")
            } else {
                message.error(res.message)
            }
            setLoading(false)
        } catch (error) {

        }

    }
    const changeSubmitStatus = () => {
        let problemStatus = data.every(item => item.completeStatus)
        // 0 = Fail, 1 = Pending, 2 = Pass
        let resultStatus = (result === 0) ? ((Object.values(failureCode).some(value => value === true)) && failureCodeText.some(value => form.getFieldValue(value.key))) : (result !== 1 && result !== null)

        setSubmitStatus((problemStatus && resultStatus))
    }
    const showConfirm = () => {
        confirm({
            title: 'Any modifications cannot be made once submitted. Do you want to submit?',
            icon: <ExclamationCircleFilled />,
            content: '',
            className: "confimSubmit",
            okText: "Yes",
            cancelText: "No",
            onOk() {
                saveSubmit(true)
            },
            onCancel() {

            },
        });
    };
    useEffect(() => {
        GetReportData();
    }, [])
    useEffect(() => {
        changeSubmitStatus()
    }, [data, failureCode, result])
    return (
        <>
            <ConfirmationPopup
                confirmOpen={confirmOpen}
                setConfirmOpen={setConfirmOpen}
                conditionalPassComment={conditionalPassComment}
                id={conditionalPassId}
            />
            <section className="inspectionReport" >
                <div className="head">
                    <h2>Inspection Report</h2>
                    <img style={{ cursor: "pointer" }} onClick={() => navigate(-1)} src={close} alt="" />
                </div>
                <Spin spinning={loading}>
                    <Form
                        form={form}
                        name="basic"
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        layout='vertical'
                    >
                        <ul className="generalInfo" onCanPlay={() => showConfirmationPass('Conditional Comment')}>
                            <li>
                                <div id="target" className="active">General Info</div>
                                <div style={{ visibility: "hidden" }}>History</div>
                            </li>
                            <li>
                                {
                                    (Qty.poqty !== Qty.inspectedQty) && <span style={{
                                        // position: "absolute",
                                        // left: "0",
                                        // top: "58px",
                                        color: "red",
                                        // zIndex: "999",
                                        marginRight: "8px",
                                        whiteSpace: "nowrap",
                                    }}>Inspected QTY is inconsistent with PO QTY</span>
                                }
                                {form.getFieldValue('status') !== "Submitted" && <Button style={{ marginRight: "8px", background: "#91C561" }} type="primary" onClick={() => saveSubmit(false)}>Save Report</Button>}
                                {/* {submitStatus && form.getFieldValue('status') !== "Submitted" && <Button type="primary" onClick={showConfirm}>Save & Submit Report</Button>} */}

                                {/* {submitStatus && <Button type="primary" onClick={showConfirm}>Save & Submit Report</Button>} */}

                                <Button type="primary" onClick={() => saveSubmit(true)}>Save & Submit Report</Button>
                                {/* {submitStatus && <Button type="primary" onClick={()=>saveSubmit(true)}>Save & Submit Report</Button>} */}
                            </li>
                        </ul>

                        <div ref={inspectionReportRef}>
                            <div className="data_entry grid">
                                <Form.Item
                                    label="Model Number"
                                    name="ModelNumber"
                                // rules={[{ required: true, message: 'Please input your username!' }]}
                                >
                                    <Input disabled />
                                </Form.Item>
                                <Form.Item
                                    label="Vendor"
                                    name="Vendor"
                                    style={{gridColumn:"span 2"}}
                                >
                                    <Input disabled />
                                </Form.Item>
                                <Form.Item
                                    label="P. O. No."
                                    name="pono"
                                >
                                    <Input disabled />
                                </Form.Item>
                                <Form.Item
                                    label="INS TYPE"
                                    name="insType"
                                >
                                    <Input disabled />
                                </Form.Item>
                                <Form.Item
                                    label="P.O. Q’ty"
                                    name="poqty"
                                >
                                    <Input disabled />
                                </Form.Item>

                                <Form.Item
                                    label="Description"
                                    name="Description"
                                    style={{gridColumn:"span 3"}}
                                >
                                    <Input disabled />
                                </Form.Item>
                                <Form.Item
                                    label="Produced Q’ty"
                                    name="producedQuantity"
                                    rules={[{ required: true }]}
                                >
                                    <InputNumber min={0} style={{ width: "100%" }} />
                                </Form.Item>
                                <Form.Item
                                    label="Inspected Q’ty"
                                    name="InspectedQty"
                                    // style={{ width: "24%" }}
                                    rules={[{ required: true }]}
                                >
                                    <InputNumber min={0} max={Qty.poqty} onChange={(value: any) => {
                                        setQty({
                                            ...Qty,
                                            inspectedQty: value
                                        })

                                    }} style={{ width: "100%" }} />
                                </Form.Item>
                                <Form.Item
                                    label="AQL Q’ty"
                                    name="aqlQty"
                                    // style={{ width: "24%" }}
                                >
                                    <InputNumber min={0} max={Qty.poqty} onChange={(value: any) => {
                                        setQty({
                                            ...Qty,
                                            aqlQty: value
                                        })

                                    }} style={{ width: "100%" }} />
                                </Form.Item>

                                <Form.Item
                                    label="Inspection Date"
                                    name="InspectionDate"
                                    style={{gridColumn:"span 2"}}
                                >
                                    <DatePicker disabled style={{ width: "100%" }} />
                                </Form.Item>
                                <Form.Item
                                    label="Inspection Rate %"
                                    name="InspectionRate"
                                    style={{gridColumn:"span 2"}}
                                >
                                    <Input disabled />
                                </Form.Item>
                                <Form.Item
                                    label="Status"
                                    name="status"
                                    style={{gridColumn:"span 2"}}
                                >
                                    <Input disabled />
                                </Form.Item>
                               
                            </div>

                            {/* <div className="report">
                            <Table className="reportTable" bordered={false} columns={columns} dataSource={data} pagination={false} scroll={{ y: 400 }} />
                        </div> */}
                            <ReportTable
                                data={data}
                                setData={setData}
                                setIsModalOpen={setIsModalOpen}
                                setFileIdArr={setFileIdArr}
                                setFileIndex={setFileIndex}
                            />
                            <div className="pieviewBox_" style={{ padding: "0 20px" }}>
                                <table className="table2" style={{ width: "100%" }}>
                                    <tbody>
                                        <tr>
                                            <td style={{ background: "#7D8FB3", color: "white", fontSize: "14px", border: "1px solid #7D8FB3" }} colSpan={4}>Critical Issue</td>
                                        </tr>
                                        <tr>
                                            <td>Critical</td>
                                            <td>
                                                <Form.Item name="critical">
                                                    <InputNumber min={0} className="numberInput" />
                                                </Form.Item>
                                            </td>
                                            <td>Accept</td>
                                            <td>
                                                <Form.Item name="criticalAccept">
                                                    <InputNumber min={0} className="numberInput" />
                                                </Form.Item>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ background: "#7D8FB3", color: "white", fontSize: "14px", border: "1px solid #7D8FB3" }} colSpan={4}>Major Issue</td>
                                        </tr>
                                        <tr>
                                            <td>Accept</td>
                                            <td>
                                                <Form.Item name="majorAccept">
                                                    <InputNumber min={0} className="numberInput" />
                                                </Form.Item>
                                            </td>
                                            <td>Reject</td>
                                            <td>
                                                <Form.Item name="majorReject">
                                                    <InputNumber min={0} className="numberInput" />
                                                </Form.Item>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ background: "#7D8FB3", color: "white", fontSize: "14px", border: "1px solid #7D8FB3" }} colSpan={4}>Minor Issue</td>
                                        </tr>
                                        <tr>
                                            <td>Accept</td>
                                            <td>
                                                <Form.Item name="minorAccept">
                                                    <InputNumber min={0} className="numberInput" />
                                                </Form.Item>
                                            </td>
                                            <td>Reject</td>
                                            <td>
                                                <Form.Item name="minorReject">
                                                    <InputNumber min={0} className="numberInput" />
                                                </Form.Item>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <section className="inspection_result">
                                <div className="result">
                                    <h3>Inspection Result</h3>
                                    <ul className="" style={{ cursor: "pointer" }}>
                                        <li className="Pass" onClick={() => setResult(2)} style={{ opacity: result == 2 ? "" : "0.25", marginRight: "8px" }}>
                                            <img src={pass} alt="" />
                                            pass
                                        </li>
                                        <li className="Pending" onClick={() => setResult(1)} style={{ opacity: result == 1 ? "" : "0.25" }}>
                                            <img src={pending} alt="" />
                                            Pending
                                        </li>
                                        <li className="Fail" onClick={() => setResult(0)} style={{ opacity: result == 0 ? "" : "0.25" }}>
                                            <img src={fail} alt="" />
                                            Fail
                                        </li>
                                        {form.getFieldValue('status') === "Submitted" && result !== 2 && <li className="Pass" onClick={() => setResult(3)} style={{ opacity: result == 3 ? "" : "0.25", marginLeft: "8px" }}>
                                            <img src={pass} alt="" />
                                            Conditional Pass
                                        </li>}
                                    </ul>
                                </div>
                                <h5>Failure Code {result === 0 && <span style={{ color: "red" }}> *</span>}</h5>
                                <ul className="failure_code">
                                    {
                                        failureCodeText.map((code) => (
                                            <li key={code.key}>
                                                <Checkbox
                                                    checked={failureCode[code.key]}
                                                    onChange={(e) => {
                                                        setFailureCode({ ...failureCode, [code.key]: e.target.checked })
                                                        !e.target.checked && form.setFieldValue([code.key], "")
                                                    }}
                                                >
                                                    {code.label}
                                                </Checkbox>
                                                <Form.Item name={code.key}>
                                                    <Input onChange={() => {
                                                        changeSubmitStatus()
                                                    }} disabled={!failureCode[code.key]} autoFocus={failureCode[code.key]} />
                                                </Form.Item>
                                            </li>
                                        ))
                                    }
                                </ul>
                                <h5>Comment </h5>
                                <Form.Item name="Comment">
                                    <TextArea style={{ height: 130 }} />
                                </Form.Item>
                                {result === 3 && <>
                                    <h5>Conditional Comment <span style={{ color: "red" }}>*</span></h5>
                                    <Form.Item name="ConditionaComment">
                                        <TextArea style={{ height: 130 }} />
                                    </Form.Item>
                                    <h5>Upload Conditional File <span style={{ color: "red" }}>*</span></h5>
                                    <Spin spinning={fileLoading}>
                                        <Dragger
                                            name='formFiles'
                                            action="api/Common/UploadFile"
                                            headers={{
                                                Authorization: localStorage.getItem('Authorization') || '',
                                            }}
                                            fileList={ConditionalFileList}
                                            multiple
                                            onChange={(info) => {
                                                const { status } = info.file;
                                                setConditionalFileList([...info.fileList])
                                                if (status === 'done') {
                                                    message.success(`${info.file.name} file uploaded successfully.`);
                                                    setConditionalFileList([...info.fileList])
                                                    setFileLoading(false)
                                                } else if (status === 'error') {
                                                    message.error(`${info.file.name} file upload failed.`);
                                                    setFileLoading(false)
                                                } else {
                                                    setFileLoading(true)
                                                }

                                            }}
                                            onDrop={(e) => {
                                                console.log('Dropped files', e.dataTransfer.files);
                                            }}
                                            itemRender={(originNode, file: any) => {
                                                return (
                                                    <>
                                                        <div className="uploadBox_">
                                                            <a
                                                                onClick={(e) => e.stopPropagation()}
                                                                href={file.url || URL.createObjectURL(file.originFileObj)}
                                                                download={file.name}
                                                                className="download-link"
                                                                style={{ width: '50%' }}
                                                            >
                                                                {file.name}
                                                            </a>
                                                            <span style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                                <DeleteTwoTone onClick={async () => {
                                                                    const newFileList = [...ConditionalFileList];
                                                                    newFileList.splice(newFileList.findIndex(item => item.uid === file.uid), 1);
                                                                    setConditionalFileList([...newFileList])
                                                                }
                                                                } />
                                                            </span>
                                                        </div>
                                                    </>
                                                )
                                            }}
                                        >
                                            <p className="ant-upload-drag-icon">
                                                <InboxOutlined />
                                            </p>
                                            <p className="ant-upload-text">Click or drag file to this area to upload</p>
                                            <p className="ant-upload-hint">
                                                Support for a single or bulk upload.
                                            </p>
                                        </Dragger>
                                    </Spin>

                                </>}
                            </section>
                        </div>

                        <ul className="submitData">
                            <li>
                            </li>
                            <li>
                                {
                                    (Qty.poqty !== Qty.inspectedQty) && <span style={{
                                        // position: "absolute",
                                        // left: "0",
                                        // top: "58px",
                                        color: "red",
                                        // zIndex: "999",
                                        marginRight: "8px",
                                        whiteSpace: "nowrap",
                                    }}>Inspected QTY is inconsistent with PO QTY</span>
                                }
                                {form.getFieldValue('status') !== "Submitted" && <Button style={{ marginRight: "8px", background: "#91C561" }} type="primary" onClick={() => saveSubmit(false)}>Save Report</Button>}
                                {/* {submitStatus && <Button type="primary" onClick={() => saveSubmit(true)}>Save & Submit Report</Button>} */}
                                {/* {submitStatus && form.getFieldValue('status') !== "Submitted" &&  <Button type="primary" onClick={showConfirm}>Save & Submit Report</Button>} */}
                                <Button type="primary" onClick={() => saveSubmit(true)}>Save & Submit Report</Button>
                            </li>
                        </ul>
                    </Form>
                </Spin>
                <UploadImgPopup fileIdArr={fileIdArr} isModalOpen={isModalOpen} itemData={data} setItemData={setData} fileIndex={fileIndex} setIsModalOpen={setIsModalOpen} />
                {/* <UploadImgPopup2 fileIdArr={fileIdArr2} isModalOpen={isModalOpen2} uploadImgSelect={uploadImgSelect} setUploadImgSelect={setUploadImgSelect} fileIndex={fileIndex2} setIsModalOpen={setIsModalOpen2} /> */}
                {/* <ViewImg fileIdArr={fileIdArr} isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} /> */}
            </section>
        </>

    )
}
export default InspectionPCReport