import React, { useEffect, useState, useMemo, useCallback, useRef } from 'react'
import '@/styles/inspectionPhone/inspectionReportPhone.less'
import type { RcFile, UploadFile as UpladoFileType, UploadProps } from 'antd/es/upload/interface';
import { Button, Form, Select, Upload, Modal, Skeleton, Spin, Popconfirm } from 'antd'
import { DownOutlined, LeftCircleTwoTone, RightCircleTwoTone, ExclamationCircleFilled } from '@ant-design/icons';
import type { MenuProps } from 'antd';
import { StarOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { Dropdown, Space, message } from 'antd';
import more from '@/images/navigation/more.png'
import save from '@/images/navigation/save.png'
import exit from '@/images/navigation/exit.png'
import progress from '@/images/navigation/progress.png'
import submit from '@/images/navigation/submit.png'
import close from '@/images/navigation/close_white.png'
import InspectionProgess from '@/components/InspectionProgess';
import { Outlet } from 'react-router-dom'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import warn from '@/images/navigation/warn.png'
import camera from '@/images/navigation/camera.png'
import { OnSubmitReport, GetFile, UploadFile, GetInspectionDetailPer } from '@/services/inspectionReport';
import { connect } from 'react-redux'
import { Dispatch } from 'redux';
import getInspectionDetail from '@/utils/refreshPage';
import Compressor from 'compressorjs';
import imageCompression from 'browser-image-compression';
import { Progress } from "antd"
const { confirm } = Modal;
const { Option } = Select
const UploadImg = ({
    completePercent,
    completeArr,
    setCompleteArr,
    setCompletePercent,
    reportStatus,
    clearData,
    issueData,
    setIssueData,
    setReportStatus,
    uploadImgSelect,
    setUploadImgSelect,
    conditionalPassList,
    setConditionalPassList,
    imageStep,
    setImageStep
}: {
    completePercent: number
    completeArr: CompleteArrType
    setCompleteArr: any
    setCompletePercent: any
    reportStatus: ReportStatusType
    clearData: any
    issueData: IssueDataType
    setIssueData: any
    setReportStatus: any
    uploadImgSelect: PhoneUploadImgType
    setUploadImgSelect: any
    conditionalPassList: any
    setConditionalPassList: Function
    imageStep: boolean
    setImageStep: Function
}) => {
    const [open, setOpen] = useState(false)
    const location = useLocation()
    const navigate = useNavigate()
    const [active, setActive] = useState<number | null>(null)
    // const [fileList, setFileList] = useState<UploadFile[]>([]);
    const imageRef = useRef<any>(null);
    const [steps, setSteps] = useState<StepsArrType>([])
    const [search] = useSearchParams()
    const [changeStatus, setChangeStatus] = useState(false)
    const [completed, setCompleted] = useState(0)
    const [uploadStatus, setUploadStatus] = useState(false)
    const [confirmJumpStatus, setConfirmJumpStatus] = useState(false)
    const [submitLoading, setSubmitLoading] = useState(false)
    const [beSaving, setBeSaving] = useState(false)
    const [imageCurrentIdx, setImageCurrentIdx] = useState<number>(0)
    const [imgRotateLoading, setImgRotateLoading] = useState(false)
    const [fileIndex2, setFileIndex2] = useState(0)
    const [fileIdArr2, setFileIdArr2] = useState<any[]>([])
    const [attributeList, setAttributeList] = useState<string[]>([])
    const [confirmJumpFn, setConfirmJumpFn] = useState(() => () => { })
    const editorRef = useRef<any>(null)
    const [params, setParams] = useState<{
        itemNo?: string
        inspectionProjectId?: string
        PONumber?: string
    }>({})
    const [rotation, setRotation] = useState(0);
    const [loading, setLoading] = useState(false)
    const [loadingImg, setLoadingImg] = useState(false)
    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [previewTitle, setPreviewTitle] = useState('');
    const [percent, setPercent] = useState(0);
    let [zong, setzong] = useState(0);
    let [zong2, setzong2] = useState(0);
    const [progressInfo, setProgressInfo] = useState<inspectionReportAPI.GetInspectionDetailPerResponse>({
        count: 0,
        total: 0,
        imageStep: false,
        resultStep: false,
        percent: 0,
        info: []
    })
    // const [uploadImgSelect, setUploadImgSelect] = useState<PhoneUploadImgType>([])
    const id = search.get('id') || 0
    const showConfirm = () => {
        saveSubmit(1, true)
    };
    const items: MenuProps['items'] = localStorage.getItem('status') === "Submitted" ? [
        {
            label: (
                <div className='dropdown_' onClick={() => navigate('/inspectionList')}>
                    <img src={exit} alt="" />
                    Exit
                </div>
            ),
            key: '1',
        },
        {
            label: (
                <div className='dropdown_' onClick={() => {
                    setOpen(true)
                    setChangeStatus(!changeStatus)
                }}>
                    <img src={progress} alt="" />
                    Inspection Progress
                </div>
            ),
            key: "2"
        },
        {
            label: (
                <div className='dropdown_' onClick={() => saveSubmit(1, true)}>
                    <img src={submit} alt="" />
                    Submit Report
                </div>
            ),
            key: "3"
        }
    ] : [
        {
            label: (
                <div className='dropdown_' onClick={() => saveSubmit(0, false)}>
                    <img src={save} alt="" />
                    Save As Draft
                </div>
            ),
            key: '0',
        },
        {
            label: (
                <div className='dropdown_' onClick={() => saveSubmit(0, true)}>
                    <img src={exit} alt="" />
                    Save & Exit
                </div>
            ),
            key: '1',
        },
        {
            label: (
                <div className='dropdown_' onClick={() => {
                    setOpen(true)
                    setChangeStatus(!changeStatus)
                }}>
                    <img src={progress} alt="" />
                    Inspection Progress
                </div>
            ),
            key: "2"
        },
        {
            label: (
                <div className='dropdown_' onClick={showConfirm}>
                    <img src={submit} alt="" />
                    Submit Report
                </div>
            ),
            key: "4"
        }
    ]
    const deepCloneArray: any = (arr: any) => {
        const clonedArray = [];
        for (let i = 0; i < arr.length; i++) {
            if (Array.isArray(arr[i])) {
                clonedArray[i] = deepCloneArray(arr[i]);
            } else {
                clonedArray[i] = arr[i];
            }
        }
        return clonedArray;
    }
    const getBase64 = (file: RcFile): Promise<string> =>
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result as string);
            reader.onerror = (error) => reject(error);
        });

    const handlePreview = async (file: UpladoFileType) => {
        let pageId = Number(search.get('id') || 0)
        let idx = uploadImgSelect[fileIndex2].imgList.findIndex((item: any) => item.uid === file.uid)
        setImageCurrentIdx(uploadImgSelect[fileIndex2].imgList.findIndex((item: any) => item.uid === file.uid))
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj as RcFile);
        }
        setPreviewOpen(true);
        setPreviewTitle(file.name || file.url!.substring(file.url!.lastIndexOf('/') + 1));
        if (!uploadImgSelect[fileIndex2].imgList[idx]?.viewImgStatus) {
            setImgRotateLoading(true)
            try {
                const res: any = await GetFile({ params: { fileId: uploadImgSelect[fileIndex2].imgList[idx].response.data[0] } })
                let url = URL.createObjectURL(res)
                uploadImgSelect[fileIndex2].imgList[idx].url = url
                uploadImgSelect[fileIndex2].imgList[idx].viewImgStatus = true
                setPreviewImage(url)
                setImgRotateLoading(false)
            } catch (error) {
                setImgRotateLoading(false)
            }
        } else {
            setPreviewImage(file.url || (file.preview as string))
        }

    };
    const prevNextImg = async (num: number) => {
        if (num > 0) {
            if (imageCurrentIdx >= uploadImgSelect[fileIndex2]?.imgList.length - 1) return
        } else {
            if (!imageCurrentIdx) return
        }
        if (rotation > 0) {
            rotateImg().then(async () => {
                let url = uploadImgSelect[fileIndex2]?.imgList[imageCurrentIdx + num]?.url
                let preview = uploadImgSelect[fileIndex2]?.imgList[imageCurrentIdx + num]?.preview
                let name = uploadImgSelect[fileIndex2]?.imgList[imageCurrentIdx + num]?.name
                let baseUrl = url || preview
                if (!url && !preview) {
                    baseUrl = await getBase64(uploadImgSelect[fileIndex2]?.imgList[imageCurrentIdx + num]?.originFileObj as RcFile);
                }
                setPreviewImage(baseUrl)
                setPreviewTitle(name)
                setImageCurrentIdx(imageCurrentIdx + num)
            })
        } else {
            let url = uploadImgSelect[fileIndex2].imgList[imageCurrentIdx + num]?.url
            let preview = uploadImgSelect[fileIndex2].imgList[imageCurrentIdx + num]?.preview
            let name = uploadImgSelect[fileIndex2].imgList[imageCurrentIdx + num]?.name
            let baseUrl = url || preview
            if (!url && !preview) {
                baseUrl = await getBase64(uploadImgSelect[fileIndex2].imgList[imageCurrentIdx + num]?.originFileObj as RcFile);
            }
            setPreviewImage(baseUrl)
            setPreviewTitle(name)
            setImageCurrentIdx(imageCurrentIdx + num)
        }

    }
    const rotateImg = () => {
        setImgRotateLoading(true)
        return new Promise<void>((resolve, reject) => {
            let canvas = document.createElement("canvas");
            let ctx: any = canvas.getContext("2d");
            switch (rotation) {
                case 90: // 旋转90°
                    canvas.width = imageRef.current.height * 2;
                    canvas.height = imageRef.current.width * 2;
                    ctx.rotate((90 * Math.PI) / 180);
                    ctx.drawImage(imageRef.current, 0, -imageRef.current.height * 2, imageRef.current.width * 2, imageRef.current.height * 2);
                    break;
                case 180: // 旋转180°
                    canvas.width = imageRef.current.width * 2;
                    canvas.height = imageRef.current.height * 2;
                    ctx.rotate((180 * Math.PI) / 180);
                    ctx.drawImage(imageRef.current, -imageRef.current.width * 2, -imageRef.current.height * 2, imageRef.current.width * 2, imageRef.current.height * 2);
                    break;
                case 270: // 旋转270°
                    canvas.width = imageRef.current.height * 2;
                    canvas.height = imageRef.current.width * 2;
                    ctx.rotate((-90 * Math.PI) / 180);
                    ctx.drawImage(imageRef.current, -imageRef.current.width * 2, 0, imageRef.current.width * 2, imageRef.current.height * 2);
                    break;
                default:
                    canvas.width = imageRef.current.width * 2;
                    canvas.height = imageRef.current.height * 2;
                    ctx.drawImage(imageRef.current, 0, 0, imageRef.current.width * 2, imageRef.current.height * 2);
                    break;
            }
            let imgBaseUrl = canvas.toDataURL("image/png", 1)
            const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
            canvas.toBlob(async (blob) => {
                // 创建文件
                let imgUrl = URL.createObjectURL(blob!);
                const file = new File([blob!], previewTitle, { type: "image/png" })
                let formData = new FormData()
                formData.append("formFiles", file)
                // formData.append("FileId", completeArr[Number(search.get('id') || 0)].fileList[imageCurrentIdx!].response.data[0])
                try {
                    const res: any = await UploadFile({ data: formData, params: { FileId: uploadImgSelect[fileIndex2].imgList[imageCurrentIdx!].response.data[0] } })
                    if (res.isSuccess) {
                        message.success('Save successfully.')
                        setPreviewImage(imgUrl)
                        setRotation(0)
                        uploadImgSelect[fileIndex2].imgList[imageCurrentIdx!].url = imgBaseUrl
                        uploadImgSelect[fileIndex2].imgList[imageCurrentIdx!].preview = imgBaseUrl
                        uploadImgSelect[fileIndex2].imgList[imageCurrentIdx!].thumbUrl = imgBaseUrl
                        uploadImgSelect[fileIndex2].imgList[imageCurrentIdx!].response.data = [res.data[0]]
                        setCompleteArr(deepCloneArray(completeArr))
                        setImgRotateLoading(false)
                        resolve()
                    } else {
                        message.error('Image upload failed.')
                        setImgRotateLoading(false)
                    }
                } catch (error) {
                    message.error("Image upload failed.")
                    setImgRotateLoading(false)
                }
            })
        })


    }
    const setImgData = () => {
        completeArr.forEach(item => {
            uploadImgSelect.forEach((item2 => {
                if (item.argumentId === item2.argumentId) {
                    item.fileList = item2.imgList
                }
            }))
        })
    }
    const saveSubmit = async (status: number, open: boolean = false, isLoading: boolean = false) => {
        !isLoading && setSubmitLoading(true)
        // let steps = JSON.parse(sessionStorage.getItem("steps") || "[]")
        let steps = JSON.parse(localStorage.getItem("steps") || "[]")
        // let inspectedQuantity = Number(sessionStorage.getItem('inspectedQuantity') || 0)
        let inspectedQuantity = Number(localStorage.getItem('inspectedQuantity') || 0)
        setImgData()
        let details = completeArr.map((item, index) => {
            return {
                argumentId: +item.argumentId,
                title: steps[index].title,
                value: item.value || null,
                quality: item.quality === "NA" ? 0 :
                    (item.quality === "AC" ? 1 :
                        (item.quality === "MI" ? 2 :
                            (item.quality === "MA" ? 3 :
                                (item.quality === "PD" ? 4 : null)))),
                imageIds: item?.fileList?.map(item => item?.response?.data[0]).length == 0 ? null : item?.fileList?.map(item => item?.response?.data[0]),
                comment: item.comment
            }
        })
        let failureCodes: any = []
        let statusArr = reportStatus.failureCode.status
        let textArr = reportStatus.failureCode.text
        for (let key in statusArr) {
            if (statusArr[key]) {
                failureCodes.push({
                    failureCode: key === "Functionality" ? 0 :
                        (key === "Structure" ? 1 :
                            (key === "Appearance" ? 2 :
                                (key === "Certification" ? 3 :
                                    (key === "Packaging" ? 4 : 5)))),
                    comment: textArr[key]
                })
            }
        }
        let reportId = Number(localStorage.getItem('reportId')) || 0
        let data: ReportDataType = {
            // projectId: Number(sessionStorage.getItem("projectId")),
            purchaseOrder_InspectionProjectId: Number(localStorage.getItem("purchaseOrder_InspectionProjectId") || 0),
            poQuantity: Number(localStorage.getItem("poQuantity") || 0),
            itemNo: localStorage.getItem('itemNo') || "",
            vendor: localStorage.getItem('vendor') || "",
            poNumber: localStorage.getItem('PONumber') || "",
            aqlQty: Number(localStorage.getItem("aqlQty") || 0),
            inspectedQuantity,
            details,
            result: reportStatus.result === "Fail" ? 0 : (reportStatus.result === "Pending" ? 1 : (reportStatus.result === "Pass" ? 2 : (reportStatus.result === "Conditional Pass" ? 3 : null))),
            failureCodes: failureCodes.length == 0 ? null : failureCodes,
            comment: reportStatus.comment,
            status,
            critical: issueData.Critical,
            criticalAccept: issueData.CriticalAccept,
            majorAccept: issueData.MajorAccept,
            majorReject: issueData.MajorReject,
            minorAccept: issueData.MinorAccept,
            minorReject: issueData.MinorReject,
            producedQuantity: +(localStorage.getItem("producedQuantity") || 0)
        }
        if (reportId) {
            data.reportId = reportId
        }
        if (reportStatus.result === 'Conditional Pass') {
            data.conditionalPassComment = reportStatus.conditionalPassComment
            data.conditionalPassFiles = conditionalPassList.map((item: any) => item.response.data[0])

        }
        try {
            setBeSaving(true)
            const res: any = await OnSubmitReport({ data })
            if (res.isSuccess) {
                !isLoading && message.success("Successfully.")
                setSubmitLoading(false)
                // initializeData()
                sessionStorage.setItem("reportId", res.data)
                localStorage.setItem("reportId", res.data)
                GetProgressBar(res.data)
                if (open) {
                    clearData()
                    navigate('/inspectionList')
                }
                setBeSaving(false)
            } else {
                setSubmitLoading(false)
                message.error(res.message)
                setBeSaving(false)
            }
        } catch (error: any) {
            console.log(error);
            if (error.response.status === 400) {
                !isLoading && message.error("Please wait until the image is uploaded.")
            } else {
                !isLoading && message.error(error.response.data.title);
            }
            setSubmitLoading(false)
            setBeSaving(false)
        }
    }
    const loadImg = (list: PhoneUploadImgType, fileIndex2: number) => {
        if (!list[fileIndex2]?.loadingImage) {
            setLoadingImg(true)
            list[fileIndex2].imgList = list[fileIndex2]?.imgList.map(async ({ response }) => {
                const res: any = await GetFile({ params: { fileId: response?.data[0], type: 1 } })
                list[fileIndex2].loadingImage = true
                const contentDisposition = res.headers['content-disposition']
                const filenameMatches = /filename=([^;]+)/g.exec(contentDisposition);
                let name = filenameMatches ? filenameMatches[1] : "Unknown"
                delete res.headers
                return {
                    name,
                    url: URL.createObjectURL(res),
                    status: 'done',
                    viewImgStatus: false,
                    response: {
                        data: [response.data[0]]
                    }
                }
            })
            Promise.all(list.map(async (item) => {
                item.imgList = await Promise.all(item.imgList);
                return item;
            })).then((updatedCompleteArr) => {
                setLoadingImg(false)
                // setCompleteArr(deepCloneArray(updatedCompleteArr));
                setUploadImgSelect(deepCloneArray(updatedCompleteArr))
                // console.log(updatedCompleteArr);

                setChangeStatus(!changeStatus)
            });
        }

    }
    const uploadImgList = (data: StepType) => {//获取step列表数据
        // console.log(data);

        let option: PhoneUploadImgType = data.map((item) => {
            return {
                label: item.parentArgumentTitle || item.title,
                value: item.parentArgumentTitle || item.title,
                isCategory: item.parentArgumentTitle ? true : false,
                uploadStatus: false,
                loadingImage: false,
                quality: item.quality,
                mustAddPhoto: item.parentArgumentTitle ? item.parentArgumentMustAddPhoto : item.mustAddPhoto,
                argumentId: item.argumentId,
                id: 0,
                imgList: item?.imageIds ? item?.imageIds?.map((fileId: number) => ({
                    response: {
                        data: [fileId]
                    }
                })) : [],
                images: item.imageIds,
                attributeList: []
            }
        })
        option.forEach((item) => {
            if (!item.isCategory) {//没有父级
                item.attributeList.push(item.value)
            } else {//有父级
                data.map((item2) => {//将对应子级添加到父级
                    if (item.label === item2.parentArgumentTitle) {
                        item.attributeList.push(item2.title)
                    }
                })
            }

        })
        const uniqueArray = (array: PhoneUploadImgType): PhoneUploadImgType => {
            return array.filter((item, index) => {
                const firstIndex = array.findIndex((elem) => elem.label === item.label);
                return index === firstIndex;
            });
        };
        let list = uniqueArray(option).map((x, i) => ({ ...x, id: i, argumentId: x.argumentId }))
        loadImg(list, fileIndex2)
        // setUploadImgSelect(list)
        setAttributeList(JSON.parse(JSON.stringify(list[0].attributeList)))
        // console.log(list[0].attributeList);
    }
    useEffect(() => {
        setSteps(JSON.parse(localStorage.getItem('steps') || "[]"))
        if (completeArr?.length <= 0) {
            setLoading(true)
            clearData()
            getInspectionDetail().then(() => {
                setLoading(false)
                const steps_ = JSON.parse(localStorage.getItem('steps') || "[]")
                setSteps(steps_)
                completeArr = steps_?.map((item: any, index: number) => {
                    return ({
                        argumentId: item.argumentId,
                        value: item.value || "",
                        // mustAddPhoto: (item.quality === 2 || item.quality === 3) ? true : item.mustAddPhoto,
                        mustAddPhoto: item.parentArgumentMustAddPhoto,
                        quality: item.quality === 0 ? "NA" :
                            item.quality === 1 ? "AC" :
                                item.quality === 2 ? "MI" :
                                    item.quality === 3 ? "MA" :
                                        item.quality === 4 ? "PD" : "",
                        fileList: item?.imageIds ? item?.imageIds?.map((fileId: number) => ({
                            response: {
                                data: [fileId]
                            }
                        })) : [],
                        parentArgumentTitle: item.parentArgumentTitle,
                        comment: item.comment || "",
                        loadingImage: false,
                        uploadStatus: false,
                    })
                })
                setCompleteArr(deepCloneArray(completeArr))
                let objStatus = reportStatus.failureCode.status
                let objText = reportStatus.failureCode.text
                let failureCodesArr = JSON.parse(localStorage.getItem("failureCodes") || "[]")
                let result = Number(localStorage.getItem("result") || -1)
                let comment = localStorage.getItem('comment') || ""
                let conditionalPassComment = localStorage.getItem('conditionalPassComment') || ""
                reportStatus.comment = comment
                reportStatus.conditionalPassComment = conditionalPassComment
                let conditionalPassFiles = JSON.parse(localStorage.getItem('conditionalPassFiles') || "[]")
                setConditionalPassList([...conditionalPassFiles])
                reportStatus.result = result === 0 ? "Fail" :
                    (result === 1 ? "Pending" :
                        (result === 2 ? "Pass" :
                            (result === 3 ? "Conditional Pass" : "")))
                failureCodesArr?.forEach((item: any) => {
                    if (item.failureCode === 0) {
                        objStatus["Functionality"] = true
                        objText["Functionality"] = item.comment
                    } else if (item.failureCode === 1) {
                        objStatus["Structure"] = true
                        objText["Structure"] = item.comment
                    } else if (item.failureCode === 2) {
                        objStatus["Appearance"] = true
                        objText["Appearance"] = item.comment
                    } else if (item.failureCode === 3) {
                        objStatus["Certification"] = true
                        objText["Certification"] = item.comment
                    } else if (item.failureCode === 4) {
                        objStatus["Packaging"] = true
                        objText["Packaging"] = item.comment
                    } else if (item.failureCode === 5) {
                        objStatus["Labeling"] = true
                        objText["Labeling"] = item.comment
                    }

                })
                setReportStatus(reportStatus)
                let issueObj = JSON.parse(localStorage.getItem("issueObj") || "{}")
                setIssueData({
                    Critical: +issueObj?.critical || 0,
                    CriticalAccept: +issueObj?.criticalAccept || 0,
                    MajorAccept: +issueObj?.majorAccept || 0,
                    MajorReject: +issueObj?.majorReject || 0,
                    MinorAccept: +issueObj?.minorAccept || 0,
                    MinorReject: +issueObj?.minorReject || 0,
                })
                GetProgressBar(+(localStorage.getItem('reportId') || 0))
                setCompleteArr(completeArr)
                if (!uploadImgSelect.length) {
                    uploadImgList(steps_)
                }
                setAttributeList(uploadImgSelect[fileIndex2]?.attributeList)
                if (localStorage.getItem("status") && localStorage.getItem("status") !== "Pending") {
                    setOpen(true)
                    setChangeStatus(!changeStatus)
                }
            })
        } else {
            GetProgressBar(+(localStorage.getItem('reportId') || 0))
        }
        if (completeArr?.length && !uploadImgSelect.length) {
            uploadImgList(JSON.parse(localStorage.getItem('steps') || "[]"))
        } else {
            uploadImgSelect[fileIndex2]?.attributeList && setAttributeList(uploadImgSelect[fileIndex2].attributeList)
        }


    }, [location.search])
    const GetProgressBar = async (reportId: number) => {
        try {
            const res = await GetInspectionDetailPer({ params: { inspectionReportid: reportId } })
            if (res?.isSuccess) {
                setCompletePercent(res?.data.percent! * 100)
                // console.log("uploadimage",res.data.imageStep);
                setImageStep(res.data.imageStep)
                setProgressInfo(JSON.parse(JSON.stringify(res?.data)))
            } else {
                message.error(res?.message)
            }
        } catch (error) {
            console.log(error);
            // message.error()
        }
    }
    // useEffect(() => {
    //     completePercent = 0
    //     const steps = JSON.parse(localStorage.getItem('steps') || "[]")
    //     completeArr?.forEach((item, index) => {
    //         if (item.quality !== "" && item.quality !== "PD" && (item.mustAddPhoto ? item.fileList.length > 0 : true)) {
    //             completePercent += 1
    //         }
    //     })
    //     const result = Object.values(reportStatus.failureCode.text).some(value => value !== null && value !== undefined && value !== "");
    //     if (reportStatus.result &&
    //         // checkObjectValues(issueData) === Number(sessionStorage.getItem("inspectedQuantity") || 0) &&
    //         (reportStatus.result === "Fail" ? result : true)) {
    //         completePercent += 1
    //     }
    //     setCompletePercent(completePercent / (completeArr.length + 1) * 100)
    // }, [changeStatus])
    const prevPage = () => {
        const status = localStorage.getItem('status') !== "Submitted" ? 0 : 1
        if (loading) {
            return
        }
        if (uploadImgSelect.some((obj) => obj.uploadStatus === true)) {
            message.error("Please wait until the image is uploaded.")
            return
        }
        !beSaving && saveSubmit(status, false, true)
        navigate(`/reportPhone?id=${steps.length - 1}`)
    }
    const nextPage = () => {
        const status = localStorage.getItem('status') !== "Submitted" ? 0 : 1
        if (loading) {
            return
        }
        !beSaving && saveSubmit(status, false, true)
        setCompleteArr(deepCloneArray(completeArr))
        setChangeStatus(!changeStatus)
        if (uploadImgSelect.some((obj) => obj.uploadStatus === true)) {
            message.error("Please wait until the image is uploaded.")
            return
        }
        navigate('/submitReport')
    }
    const renderOption = (option: any) => {
        const label = option.label;
        const hasStar = option.mustAddPhoto;
        let qualityState = false
        completeArr.forEach(item => {
            if (item.argumentId === option.argumentId) {
                if (option.isCategory) {
                    let arr = completeArr.filter(x => x.parentArgumentTitle === option.label)
                    qualityState = arr.some(x => x.quality === "MI" || x.quality === "MA")
                } else {
                    qualityState = (item.quality === "MI" || item.quality === "MA")
                }
            }
        })
        return (
            <span>
                {(hasStar || qualityState) && <span style={{ color: 'red', marginRight: '5px' }}>*</span>}
                {label}
            </span>
        );
    };
    return (
        <>
            <Spin spinning={submitLoading}>
                <section className='inspectionReportPhone'>
                    <div className='report_hearder'>
                        <div className='phone_header'>
                            <span></span>
                            <span>Inspection Report</span>
                            <Dropdown menu={{ items }}>
                                <a onClick={(e) => e.preventDefault()}>
                                    <Space>
                                        <img style={{ width: "5vw" }} src={more} alt="" />
                                    </Space>
                                </a>
                            </Dropdown>
                        </div>
                        <ul className="progress" onClick={() => {
                            setOpen(true)
                            setChangeStatus(!changeStatus)
                        }}>
                            <li>
                                <span>Current</span>
                                <p>{steps.length + 1}/{steps.length + 2}</p>
                            </li>
                            <li>
                                <span></span>
                                <div className='progress_bar'>
                                    <div style={{ width: `${completePercent}%` }}></div>
                                </div>
                            </li>
                            <li>
                                <span>Completed</span>
                                <p>{completePercent.toFixed(0)}%</p>
                            </li>
                        </ul>
                    </div>
                    <div className='content_'>
                        <Skeleton loading={loading} active>
                            <div className='report_phone_content'>
                                <h1 className={"firstTitle"} style={{ fontWeight: "700" }}>Upload Image</h1>

                                <Select
                                    showSearch
                                    placeholder="Select a categories"
                                    optionFilterProp="children"
                                    className='selectCategory'
                                    disabled={uploadImgSelect.some((obj) => obj.uploadStatus === true) || loadingImg}
                                    // defaultValue={uploadImgSelect[fileIndex2]?.label}
                                    defaultValue={(JSON.parse(localStorage.getItem('steps') || "[]"))[0]?.parentArgumentTitle || (JSON.parse(localStorage.getItem('steps') || "[]"))[0]?.title}
                                    onChange={(value: string, option: any) => {
                                        setFileIndex2(option.id)
                                        setFileIdArr2(uploadImgSelect[option.id].images)
                                        setAttributeList(option.attributeList)
                                        loadImg(uploadImgSelect, option.id)
                                        console.log(completeArr);


                                    }}
                                    onSearch={(value: string) => {
                                        console.log('search:', value);
                                    }}
                                    filterOption={(input: string, option?: { label: string; value: string }) => (option?.value ?? '').toLowerCase().includes(input.toLowerCase())}

                                // options={uploadImgSelect.map(item => ({
                                //     label: item.label,
                                //     value: item.value,
                                //     id: item.id,
                                //     style: { background: item.imgList.length ? 'yellow' : "" },
                                //     attributeList: item.attributeList
                                // }))}
                                >
                                    {uploadImgSelect.map((item) => (
                                        <Option
                                            key={item.id}
                                            value={item.value}
                                            id={item.id}
                                            style={{ background: item.imgList.length ? 'yellow' : '' }}
                                            attributeList={item.attributeList}
                                        >
                                            {renderOption(item)}
                                        </Option>
                                    ))}
                                </Select>
                                <div className='attributeTitle'>
                                    {attributeList?.map((item, index) => {
                                        return <h3 className='' key={index}>{item}</h3>
                                    })}
                                </div>
                                <h3>Take photos</h3>

                                <div className=' relative'>
                                    {/* {zong!==0 && loadingImg && <div className='imgProgress'><Progress type="circle" percent={percent} /></div>} */}
                                    <Spin spinning={loadingImg}>
                                        <div className='task_photos'>
                                            <Upload
                                                action="api/Common/UploadFile"
                                                name='formFiles'
                                                listType="picture-card"
                                                accept='image/*'
                                                // capture
                                                multiple
                                                openFileDialogOnClick={true}
                                                fileList={uploadImgSelect[fileIndex2]?.imgList}
                                                headers={{
                                                    Authorization: localStorage.getItem('Authorization') || '',
                                                }}
                                                showUploadList={{
                                                    showRemoveIcon: false,

                                                }}
                                                beforeUpload={async (file: RcFile, fileList): Promise<any> => {
                                                    // console.log(fileList);

                                                    // if (!(zong === fileList.length)) {
                                                    //     zong = fileList.length
                                                    //     setzong(zong)
                                                    // }
                                                    const compressAndReturnFile = async (imageFile: File) => {
                                                        return new Promise(async (resolve, reject) => {
                                                            if (!loadingImg) {
                                                                setLoadingImg(true)
                                                            }
                                                            new Compressor(imageFile, {
                                                                quality: 0.7,
                                                                maxWidth: 1200,
                                                                maxHeight: 1200,
                                                                convertSize:150000,
                                                                convertTypes:['image/png','image/webp'],
                                                                success(result) {
                                                                    resolve(result);
                                                                    // const fileSizeInBytes = result.size;
                                                                    // const fileSizeInKB = fileSizeInBytes / 1024;
                                                                    // console.log(fileSizeInKB);

                                                                    // zong2 += 1
                                                                    // setzong2(zong2)
                                                                    // setPercent(+((zong2 / zong) * 100).toFixed(2) / 2)

                                                                },
                                                                error(err) {
                                                                    reject(err);
                                                                },
                                                            });
                                                        });
                                                    };


                                                    const compressedFile = await compressAndReturnFile(file);

                                                    return compressedFile;
                                                }}
                                                onChange={({ file, fileList: newFileList }) => {
                                                    // setFileList(newFileList);
                                                    // setPercent(newFileList.length)
                                                    // let totalObjects = newFileList.length
                                                    // let completedObjects = newFileList.filter(obj => obj.status === 'done').length;
                                                    // const progressPercentage = ((completedObjects / totalObjects) * 100) / 2;
                                                    // setPercent(50 + Number(progressPercentage.toFixed(2)))
                                                    uploadImgSelect[fileIndex2].loadingImage = true
                                                    uploadImgSelect[fileIndex2].imgList = newFileList
                                                    if (newFileList.every(file => file.status === 'done')) {
                                                        setUploadStatus(false)
                                                        uploadImgSelect[fileIndex2].uploadStatus = false
                                                        // setPercent(0)
                                                        // setzong(0)
                                                        // setzong2(0)
                                                        setLoadingImg(false);
                                                    } else if (file.status === "error") {
                                                        uploadImgSelect[fileIndex2].imgList = newFileList.filter(item => item.uid !== file.uid)
                                                        setUploadStatus(false)
                                                        setLoadingImg(false);
                                                        // setPercent(0)
                                                        // setzong(0)
                                                        // setzong2(0)
                                                        uploadImgSelect[fileIndex2].uploadStatus = false
                                                        message.error(`${file.name} Upload failure.`)
                                                    } else {
                                                        setUploadStatus(true)
                                                        uploadImgSelect[fileIndex2].uploadStatus = true
                                                    }
                                                    // setCompleteArr(deepCloneArray(completeArr))
                                                    setUploadImgSelect(deepCloneArray(uploadImgSelect))
                                                    // console.log(file);

                                                }}
                                                itemRender={(originNode, file, index2) => {
                                                    return (
                                                        <>
                                                            <div className="uploadBox">
                                                                {originNode}
                                                            </div>
                                                            <CloseCircleOutlined onClick={() => {
                                                                const newFileList = [...uploadImgSelect[fileIndex2].imgList];
                                                                newFileList.splice(newFileList.findIndex(item => item.uid === file.uid), 1);
                                                                uploadImgSelect[fileIndex2].imgList = newFileList;
                                                                if (newFileList.every(file => file.status === 'done')) {
                                                                    uploadImgSelect[fileIndex2].uploadStatus = false
                                                                }
                                                                setUploadImgSelect(deepCloneArray(uploadImgSelect))
                                                            }} className='delete_icon' />
                                                        </>
                                                    );
                                                }}
                                                onPreview={handlePreview}
                                                onRemove={() => false}
                                            >

                                                <img src={camera} alt='' />
                                            </Upload>
                                        </div>
                                    </Spin>
                                </div>
                            </div>
                        </Skeleton>
                        <div className='prev_next'>
                            <Popconfirm
                                style={{ maxWidth: "90vw" }}
                                title="Prev page"
                                disabled={localStorage.getItem('status') !== "Submitted"}
                                description="Submitting the page will occur. Are you sure you want to proceed??"
                                onConfirm={() => {
                                    prevPage()
                                }}
                                okText="Yes"
                                cancelText="No"
                            >
                                <Button onClick={() => {
                                    if (localStorage.getItem('status') === "Submitted") {
                                        return
                                    }
                                    prevPage()
                                }}>Prev</Button>
                            </Popconfirm>
                            <Popconfirm
                                style={{ maxWidth: "90vw" }}
                                title="Next page"
                                disabled={localStorage.getItem('status') !== "Submitted"}
                                description="Submitting the page will occur. Are you sure you want to proceed??"
                                onConfirm={() => {
                                    nextPage()
                                }}
                                okText="Yes"
                                cancelText="No"
                            >
                                <Button onClick={() => {
                                    if (localStorage.getItem('status') === "Submitted") {
                                        return
                                    }
                                    nextPage()
                                }} type='primary'>Next</Button>
                            </Popconfirm>
                        </div>
                    </div>
                </section>
            </Spin>
            {open ? <InspectionProgess progressInfo={progressInfo} loading={loading} setOpen={setOpen} imageStep={imageStep} uploadStatus={completeArr.some((obj) => obj.uploadStatus === true)} currentStep={id} issueData={issueData} completeArr={completeArr} reportStatus={reportStatus} /> : <></>}
            <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={() => {
                setPreviewOpen(false)
                setRotation(0)
            }}>
                <Spin spinning={imgRotateLoading}>
                    <div className='Imagebody'>
                        <div className='prev' onClick={async () => {
                            if (!imageCurrentIdx) return
                            if (!uploadImgSelect[fileIndex2].imgList[imageCurrentIdx - 1]?.viewImgStatus) {
                                setImgRotateLoading(true)
                                try {
                                    const res: any = await GetFile({ params: { fileId: uploadImgSelect[fileIndex2].imgList[imageCurrentIdx - 1].response.data[0] } })
                                    let url = URL.createObjectURL(res)
                                    uploadImgSelect[fileIndex2].imgList[imageCurrentIdx - 1].url = url
                                    uploadImgSelect[fileIndex2].imgList[imageCurrentIdx - 1].viewImgStatus = true
                                    setPreviewImage(url)
                                    setImgRotateLoading(false)
                                } catch (error) {
                                    setImgRotateLoading(false)
                                }
                                setImageCurrentIdx(imageCurrentIdx - 1)
                            }
                            prevNextImg(-1)
                        }}><LeftCircleTwoTone style={{ fontSize: "30px" }} /></div>
                        <div className='next' onClick={async () => {
                            if (imageCurrentIdx >= uploadImgSelect[fileIndex2].imgList.length - 1) return
                            if (!uploadImgSelect[fileIndex2].imgList[imageCurrentIdx + 1]?.viewImgStatus) {
                                setImgRotateLoading(true)
                                try {
                                    const res: any = await GetFile({ params: { fileId: uploadImgSelect[fileIndex2].imgList[imageCurrentIdx + 1].response.data[0] } })
                                    let url = URL.createObjectURL(res)
                                    uploadImgSelect[fileIndex2].imgList[imageCurrentIdx + 1].url = url
                                    uploadImgSelect[fileIndex2].imgList[imageCurrentIdx + 1].viewImgStatus = true
                                    setPreviewImage(url)
                                    setImgRotateLoading(false)
                                } catch (error) {
                                    setImgRotateLoading(false)
                                }
                            }

                            setImageCurrentIdx(imageCurrentIdx + 1)
                            prevNextImg(1)
                        }}><RightCircleTwoTone style={{ fontSize: "30px" }} /></div>
                        <img ref={imageRef} alt="" style={{ display: "block", width: "100%", maxWidth: '100%', margin: "0 auto", transform: `rotate(${rotation}deg)` }} src={previewImage} />
                        <div className='rotate_save'>
                            <Button onClick={() => setRotation(rotation === 270 ? 0 : rotation + 90)}>Rotate</Button>
                            <div>{imageCurrentIdx + 1} / {uploadImgSelect[fileIndex2]?.imgList?.length}</div>
                            <Button type="primary" onClick={rotateImg} >Save</Button>
                        </div>
                    </div>
                </Spin>
            </Modal >
            <Modal open={confirmJumpStatus} okText="Yes" cancelText="No" title="" onCancel={() => setConfirmJumpStatus(false)} onOk={confirmJumpFn}>
                <p style={{ padding: "10px", paddingTop: "20px", fontWeight: "700", fontSize: "20px" }}>You haven't uploaded the picture yet, are you sure you want to jump to another page?</p>
            </Modal>
        </>
    )
}
const mapStateToProps = (state: any) => {
    return {
        completeArr: state.completeArr,
        completePercent: state.completePercent,
        reportStatus: state.reportStatus,
        issueData: state.issueData,
        uploadImgSelect: state.uploadImgSelect,
        conditionalPassList: state.conditionalPassList,
        imageStep: state.imageStep
    }
}
const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        setCompleteArr(arr: any) {
            let action = { type: "completeArr", arr }
            dispatch(action)
        },
        setUploadImgSelect(imgArr: PhoneUploadImgType) {
            let action = { type: "UploadImgArr", imgArr }
            dispatch(action)
        },
        setCompletePercent(num: any) {
            let action = { type: "num", num }
            dispatch(action)
        },
        clearData() {
            let action = { type: "clear" }
            dispatch(action)
        },
        setReportStatus(result: ReportStatusType) {
            let action = { type: "reportStatus", result }
            dispatch(action)
        },
        setIssueData(issueData: IssueDataType) {
            let action = { type: "issueData", issueData }
            dispatch(action)
        },
        setConditionalPassList(conditionalPassList: any[]) {
            let action = { type: "conditionalPass", conditionalPassList }
            dispatch(action)
        },
        setImageStep(imageStep: boolean) {
            let action = { type: "imageStep", imageStep }
            dispatch(action)
        }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(UploadImg)