import React, { useEffect, useContext, useState } from "react";
import { Input, Select, Form, Checkbox, Button, Row, Col, message, Spin } from 'antd';
import { MyContext } from "@/context/MyContext";
import { GetTemplateArgumentLi, AddCheckList, GetEditCheckList, ExportCheckList } from "@/services/checkList";
import { useNavigate, useSearchParams } from "react-router-dom";
import "@/styles/checkList.less"
import "@/styles/viewCheckList.less"
import EurofaseLogo from '@/images/eurofase_logo.png'
export default function ViewCheckList() {
    const navigate = useNavigate()
    const { setTitle, setItems } = useContext(MyContext)
    const [loading, setLoading] = useState(false)
    const [argumentsArr, setArgumentsArr] = useState<any>([])
    const [search] = useSearchParams()
    const getData = async () => {
        setLoading(true)
        const res: any = await GetEditCheckList({ params: { id: Number(search.get('id')) } })
        if (res.isSuccess) {
            setArgumentsArr(res?.data?.arguments)
            setLoading(false)
        }
    }
    useEffect(() => {
        setTitle("")
        setItems("")
        getData()
    }, [])
    return (
        <section style={{ position: "relative" }}>
            <div className="page-content pieviewBox" style={{ overflow: "auto", maxHeight: "750px" }}>
                <Spin spinning={loading}>
                    <div className="table_header">
                        <img src={EurofaseLogo} alt="" />
                        {/* <p>INSPECTION REPORT CHECKLIST – New product launch</p> */}
                        <p>{search.get('name')}</p>
                    </div>
                    {/* <table className="table1">
                        <tr>
                            <td>Model Number:</td>
                            <td>Date Inspected:</td>
                        </tr>
                        <tr>
                            <td>Description:</td>
                            <td>P. O. No.:</td>
                        </tr>
                        <tr>
                            <td>Inspected Q'ty:</td>
                            <td>Inspected by:</td>
                        </tr>
                    </table> */}
                    <table className="table1">
                        <tr>
                            <td>Item No.:</td>
                            <td>P.O.NO.:</td>
                        </tr>
                        <tr>
                            <td>Description:</td>
                            <td>P. O. Qty:</td>
                        </tr>
                    </table>
                    <h2>Inspection History</h2>
                    <table className="table_history">
                        <thead>
                            <tr>
                                <td></td>
                                <td>Date Inspected</td>
                                <td>Inspected Q'ty</td>
                                <td>Inspected by</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>1st</td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                        </tbody>
                    </table>
                    <table className="table2">
                        <tr>
                            <td colSpan={4}>Critical Issue</td>
                        </tr>
                        <tr>
                            <td>Critical</td>
                            <td></td>
                            <td>Accept</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td colSpan={4}>Major Issue</td>
                        </tr>
                        <tr>
                            <td>Accept</td>
                            <td></td>
                            <td>Reject</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td colSpan={4}>Minor Issue</td>
                        </tr>
                        <tr>
                            <td>Accept</td>
                            <td></td>
                            <td>Reject</td>
                            <td></td>
                        </tr>
                    </table>
                    <div className="textBox">
                        <h3>Inspection Result:</h3>
                        <h5>Result Summary</h5>
                        <div></div>
                        <p>Following features have been verified.</p>
                        <p>Details as below</p>
                    </div>
                    <table className="table3">
                        <thead>
                            <tr>
                                <td>Factory</td>
                                <td></td>
                                <td>Result</td>
                            </tr>
                        </thead>
                        <tbody>
                            {argumentsArr?.map((item: any, index: number) => {
                                return (
                                    <tr key={index}>
                                        <td style={{ fontWeight: item.isParent ? "700" : "500" }}>{item.name}</td>
                                        <td></td>
                                        <td></td>
                                    </tr>

                                )
                            })}

                        </tbody>
                    </table>
                    <p className="text">Nomenclature: NA-Not Applicable; AC-Accept; MI-Minor Issue; MA-Major Issue; PD-Pending</p>
                    <p className="text">Please take pictures of the package and test conducted.</p>
                    <ul className="text">
                        <li>Verified By:</li>
                        <li>Date:</li>
                        <li>Final comments:</li>
                    </ul>
                </Spin>
                <div className="cancel_button">
                    <Button type="default" onClick={() => navigate(-1)}>Cancel</Button>
                    <Button type="primary" onClick={async () => {
                        try {
                            setLoading(true)
                            const res: any = await ExportCheckList({ params: { ID: search.get('id') } })
                            if (res) {
                                const link = document.createElement('a');
                                link.href = URL.createObjectURL(res);
                                const contentDisposition = res.headers['content-disposition']
                                const filenameMatches = /filename=([^;]+)/g.exec(contentDisposition);
                                let name = "Checklist_"+search.get('name')
                                link.download = name
                                link.click();
                                setLoading(false)
                            }
                        } catch (error) {
                            console.log(error);
                            setLoading(false)
                        }
                    }}>Export</Button>
                </div>
            </div >
        </section >
    )
}