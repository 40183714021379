import React, { useRef, useState, useEffect } from 'react'
import { InputRef, message } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { Input, Form, Modal, Tag, theme } from 'antd';
import close from '@/images/navigation/close.png'
import { SendReportEmail } from '@/services/checkList';
const SendEmail: React.FC<{
    sendEmailStatus: boolean
    setSendEmailStatus: React.Dispatch<React.SetStateAction<boolean>>
    reportID: number
}> = ({
    sendEmailStatus,
    setSendEmailStatus,
    reportID
}) => {
        const { TextArea } = Input
        const [form] = Form.useForm()
        const { token } = theme.useToken();
        const [loading, setLoading] = useState(false)
        const [inputVisible, setInputVisible] = useState({
            toVisible: false,
            ccVisible: false,
            bccVisible: false
        });
        const [toTags, setToTags] = useState<string[]>([]);
        const [ccTags, setCcTags] = useState<string[]>([]);
        const [bccTags, setBccTags] = useState<string[]>([]);
        const [errorStatus, setErrorStatus] = useState(false)
        const toRef = useRef<any>(null)
        const ccRef = useRef<any>(null)
        const bccRef = useRef<any>(null)
        const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        const [inputValue, setInputValue] = useState({
            toValue: "",
            ccValue: "",
            bccValue: ""
        });
        const onFinish = async (values: any) => {
            if (!toTags.length) {
                setErrorStatus(true)
                return
            }
            setLoading(true)
            const res: any = await SendReportEmail({
                data: {
                    reportID,
                    ...values,
                    toEmail: toTags.join(','),
                    cc: ccTags.join(','),
                    bcc: bccTags.join(',')
                }
            })
            if (res.isSuccess) {
                message.success('Successfully')
                initialize()
            } else {
                message.error(res.message)

            }
            setLoading(false)
        };
        function isValidEmail(email: string) {
            return emailPattern.test(email);
        }
        const createTagElements = (tags: string[], handleClose:(removedTag: string) => void) => {
            return tags.map((tag) => {
                const tagElem = (
                    <Tag
                        closable
                        className="qcTag"
                        style={{ marginBottom: "5px" }}
                        onClose={(e) => {
                            e.preventDefault();
                            handleClose(tag);
                        }}
                        title={tag}
                    >
                        {tag.split('@')[0]}
                    </Tag>
                );
                return (
                    <span key={tag} style={{ display: "inline-block" }}>
                        {tagElem}
                    </span>
                );
            });
        };
        // const forMap = (tag: string) => {
        //     const tagElem = (
        //         <Tag
        //             closable
        //             className='qcTag'
        //             style={{ marginBottom: "5px" }}
        //             onClose={(e) => {
        //                 e.preventDefault();
        //                 handleClose(tag);
        //             }}
        //         >
        //             {tag}
        //         </Tag>
        //     );
        //     return (
        //         <span key={tag} style={{ display: 'inline-block' }}>
        //             {tagElem}
        //         </span>
        //     );
        // };
        const handleClose1 = (removedTag: string) => {
            const newTags = toTags.filter((tag) => tag !== removedTag);
            setToTags(newTags);
        };
        const handleClose2 = (removedTag: string) => {
            const newTags = ccTags.filter((tag) => tag !== removedTag);
            setCcTags(newTags);
        };
        const handleClose3 = (removedTag: string) => {
            const newTags = bccTags.filter((tag) => tag !== removedTag);
            setBccTags(newTags);
        };
        const tagChild = createTagElements(toTags, handleClose1);
        const ccChild = createTagElements(ccTags, handleClose2);
        const bccChild = createTagElements(bccTags, handleClose3);

        const tagPlusStyle = {
            background: token.colorBgContainer,
            borderStyle: 'dashed',
            // height: "28px",
            // display: "flex",
            // alignItems: "center",
            // width: "78px",
            cursor: "pointer"
        };
        const isEmailExists = (tags: string[], value: string) => {
            return tags.some((tag) => tag === value);
        };
        const setEmail = (status: string) => {
            const handleEmailInput = (value: string, setter: (newValue: string) => void) => {
                const tags = {
                    to: toTags,
                    cc: ccTags,
                    bcc: bccTags,
                }[status];
                if (isEmailExists(tags!, value)) {
                    message.error('Email already exists');
                    return;
                }
                if (isValidEmail(value)) {
                    setter(value);
                    setInputValue({
                        ...inputValue,
                        [`${status}Value`]: ""
                    });
                } else {
                    message.error('E-mail format is incorrect');
                }
            };

            switch (status) {
                case "to":
                    handleEmailInput(inputValue.toValue, (newValue: string) => {
                        setToTags([...toTags, newValue]);
                    });
                    break;
                case "cc":
                    handleEmailInput(inputValue.ccValue, (newValue: string) => {
                        setCcTags([...ccTags, newValue]);
                    });
                    break;
                case "bcc":
                    handleEmailInput(inputValue.bccValue, (newValue: string) => {
                        setBccTags([...bccTags, newValue]);
                    });
                    break;
            }
        };
        const blurEmail = (status: string) => {
            switch (status) {
                case "to":
                    if (isValidEmail(inputValue.toValue) && !toTags.some((tag) => tag === inputValue.toValue)) {
                        setToTags([...toTags, inputValue.toValue])
                    }
                    break;
                case "cc":
                    if (isValidEmail(inputValue.ccValue) && !ccTags.some((tag) => tag === inputValue.ccValue)) {
                        setCcTags([...ccTags, inputValue.ccValue])
                    }
                    break;
                case "bcc":
                    if (isValidEmail(inputValue.bccValue) && !bccTags.some((tag) => tag === inputValue.bccValue)) {
                        setBccTags([...bccTags, inputValue.bccValue])
                    }
                    break;
            }
            setInputValue({
                toValue: "",
                ccValue: "",
                bccValue: ""
            })
            setInputVisible({
                toVisible: false,
                ccVisible: false,
                bccVisible: false
            })
        }
        const showInput = (status: string) => {
            switch (status) {
                case "to":
                    setInputVisible({
                        ...inputVisible,
                        toVisible: true
                    })
                    break;
                case "cc":
                    setInputVisible({
                        ...inputVisible,
                        ccVisible: true
                    })
                    break;
                case "bcc":
                    setInputVisible({
                        ...inputVisible,
                        bccVisible: true
                    })
                    break;
            }
        }
        const initialize = () => {
            form.resetFields()
            setSendEmailStatus(false)
            setToTags([])
            setCcTags([])
            setBccTags([])
        }
        useEffect(() => {
            inputVisible.toVisible && toRef.current.focus()
            inputVisible.ccVisible && ccRef.current.focus()
            inputVisible.bccVisible && bccRef.current.focus()

        }, [inputVisible])

        return (
            <>

                <Modal
                    title="Send Email"
                    maskClosable={false}
                    open={sendEmailStatus}
                    onOk={() => form.submit()}
                    confirmLoading={loading}
                    onCancel={() => {
                        initialize()
                    }}
                    okText="Send"
                    width={620}
                >
                    <div className='modal_content'>
                        <Form
                            form={form}
                            name="basic"
                            onFinish={onFinish}
                            layout='vertical'
                        >
                            <div style={{ marginBottom: "15px" }}>
                                {/* <Form.Item
                                    label="To"
                                    name="toEmail"
                                    style={{ width: "100%" }}
                                    rules={[{  required: true, message: 'Please enter the Recipient Email!' }]}
                                >
                                    <Input style={{ display: 'inline-block' }} />
                                </Form.Item> */}
                                <h3 style={{ marginBottom: "0" }}><span style={{ color: "#ff4d4f", marginRight: "4px" }}>*</span>To</h3>
                                <div className='Assign_to_Inspector2' style={{ borderColor: errorStatus && toTags.length <= 0 ? "#ff7875" : "", transition: "all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1)" }}>
                                    <div>
                                        {tagChild}
                                        {inputVisible.toVisible ? (
                                            <Input
                                                style={{ width: 200 }}
                                                value={inputValue.toValue}
                                                ref={toRef}
                                                onChange={(e) => setInputValue({
                                                    ...inputValue, toValue: e.target.value
                                                })}
                                                onBlur={() => blurEmail("to")}
                                                onPressEnter={() => setEmail("to")}
                                            />
                                        ) : (
                                            <Tag onClick={() => showInput('to')} style={tagPlusStyle}>
                                                <PlusOutlined />
                                            </Tag>
                                        )}
                                    </div>
                                </div>
                                <h3 style={{ display: errorStatus && toTags.length <= 0 ? "" : "none", height: errorStatus && toTags.length <= 0 ? "" : "0" }} className='InspectorError'>This field is required</h3>
                            </div>
                            <div style={{ marginBottom: "15px" }}>
                                {/* <Form.Item
                                    label="Cc"
                                    name="cc"
                                    style={{ width: "100%" }}
                                // rules={[{ type: "email"}]}
                                >
                                    <Input style={{ display: 'inline-block' }} />
                                </Form.Item> */}
                                <h3 style={{ marginBottom: "0" }}>Cc</h3>
                                <div className='Assign_to_Inspector2' >
                                    <div>
                                        {ccChild}
                                        {inputVisible.ccVisible ? (
                                            <Input
                                                style={{ width: 200 }}
                                                value={inputValue.ccValue}
                                                ref={ccRef}
                                                onChange={(e) => setInputValue({
                                                    ...inputValue, ccValue: e.target.value
                                                })}
                                                onBlur={() => blurEmail('cc')}
                                                onPressEnter={() => setEmail("cc")}
                                            />
                                        ) : (
                                            <Tag onClick={() => showInput('cc')} style={tagPlusStyle}>
                                                <PlusOutlined />
                                            </Tag>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div style={{ marginBottom: "15px" }}>
                                {/* <Form.Item
                                    label="Bcc"
                                    name="bcc"
                                    style={{ width: "100%" }}
                                // rules={[{ type: "email"}]}
                                >
                                    <Input style={{ display: 'inline-block' }} />
                                </Form.Item> */}
                                <h3 style={{ marginBottom: "0" }}>Bcc</h3>
                                <div className='Assign_to_Inspector2' >
                                    <div>
                                        {bccChild}
                                        {inputVisible.bccVisible ? (
                                            <Input
                                                style={{ width: 200 }}
                                                value={inputValue.bccValue}
                                                ref={bccRef}
                                                onChange={(e) => setInputValue({
                                                    ...inputValue, bccValue: e.target.value
                                                })}
                                                onBlur={() => blurEmail('bcc')}
                                                onPressEnter={() => setEmail("bcc")}
                                            />
                                        ) : (
                                            <Tag onClick={() => showInput('bcc')} style={tagPlusStyle}>
                                                <PlusOutlined />
                                            </Tag>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className='flex justify-between'>
                                <Form.Item
                                    label="Subject"
                                    name="subject"
                                    style={{ width: "100%" }}
                                    rules={[{ required: true, message: 'Please enter subject!' }]}
                                >
                                    <Input style={{ display: 'inline-block' }} />
                                </Form.Item>
                            </div>
                            <div className='flex justify-between'>
                                <Form.Item
                                    label="Comment"
                                    name="comment"
                                    style={{ width: "100%" }}
                                >
                                    <TextArea style={{ minHeight: 150 }} />
                                </Form.Item>
                            </div>
                        </Form >
                    </div>
                </Modal>
            </>
        )
    }

export default SendEmail