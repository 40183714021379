import { GetInspectionDetailByInspector } from '@/services/inspectionReport'

const getInspectionDetail = async () => {
    let ItemNo = localStorage.getItem("itemNo") || ""
    let PONumber = localStorage.getItem("PONumber") || ""
    let PurchaseOrder_InspectionProjectId = +(localStorage.getItem("purchaseOrder_InspectionProjectId") || 0)
    let inspectedQTY = localStorage.getItem("inspectedQuantity") || ""
    let aqlQty = localStorage.getItem("aqlQty") || ""
    let producedQTY = localStorage.getItem('producedQuantity') || ""
    const res: any = await GetInspectionDetailByInspector({ params: { ItemNo, PONumber, PurchaseOrder_InspectionProjectId } })
    if (res.isSuccess) {
        const {
            steps, failureCodes, inspectedQuantity,conditionalPassFiles ,vendor, result, comment,conditionalPassComment, status, poQuantity,
            critical, criticalAccept, majorAccept, majorReject, minorAccept, minorReject, reportId = 0
        } = res.data
        sessionStorage.setItem("steps", JSON.stringify(steps))
        localStorage.setItem("steps", JSON.stringify(steps))
        sessionStorage.setItem("failureCodes", JSON.stringify(failureCodes || []))
        localStorage.setItem("failureCodes", JSON.stringify(failureCodes || []))
        sessionStorage.setItem("itemNo", ItemNo)
        localStorage.setItem("itemNo", ItemNo)
        sessionStorage.setItem("PONumber", PONumber)
        localStorage.setItem("PONumber", PONumber)
        sessionStorage.setItem("inspectedQuantity", inspectedQTY)
        localStorage.setItem("inspectedQuantity", inspectedQTY)
        localStorage.setItem("producedQuantity",producedQTY)
        localStorage.setItem("aqlQty",aqlQty)
        sessionStorage.setItem("vendor", vendor || "")
        localStorage.setItem("vendor", vendor || "")
        sessionStorage.setItem("result", result?.toString() || "")
        localStorage.setItem("result", result?.toString() || "")
        sessionStorage.setItem("comment", comment || "")
        localStorage.setItem("comment", comment || "")
        sessionStorage.setItem("conditionalPassComment", conditionalPassComment || "")
        localStorage.setItem("conditionalPassComment", conditionalPassComment || "")
        sessionStorage.setItem("status", status || "")
        localStorage.setItem("status", status || "")
        sessionStorage.setItem("poQuantity", poQuantity?.toString() || "")
        localStorage.setItem("poQuantity", poQuantity?.toString() || "")
        sessionStorage.setItem("purchaseOrder_InspectionProjectId", ""+PurchaseOrder_InspectionProjectId)
        localStorage.setItem("purchaseOrder_InspectionProjectId", ""+PurchaseOrder_InspectionProjectId)
        sessionStorage.setItem("issueObj", JSON.stringify({
            critical,
            criticalAccept,
            majorAccept,
            majorReject,
            minorAccept,
            minorReject,
        }))
        localStorage.setItem("issueObj", JSON.stringify({
            critical,
            criticalAccept,
            majorAccept,
            majorReject,
            minorAccept,
            minorReject,
        }))
        reportId && sessionStorage.setItem("reportId", reportId.toString())
        reportId && localStorage.setItem("reportId", reportId.toString())
        let conditionalPassFilesList = conditionalPassFiles ? conditionalPassFiles.map((item:{fileID:number})=>{
            return {
                response: {
                    data: [item.fileID]
                } 
            }
        }) : []
        localStorage.setItem('conditionalPassFiles',JSON.stringify(conditionalPassFilesList))
    }
}
export default getInspectionDetail