import React, { useEffect, useRef, useState } from 'react';
import { Upload, Modal, Spin, message, Button } from 'antd';
import type { RcFile, UploadProps } from 'antd/es/upload';
import type { UploadFile as UploadFileType } from 'antd/es/upload/interface';
import { CloseCircleFilled, ExclamationCircleFilled } from '@ant-design/icons';
import { GetFile, UploadFile } from '@/services/inspectionReport';
import '@/styles/uploadImgPopup.less'
import Compressor from 'compressorjs';
const { confirm } = Modal;
const getBase64 = (file: RcFile): Promise<string> =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result as string);
        reader.onerror = (error) => reject(error);
    });
const deepCloneArray: any = (arr: any) => {
    const clonedArray = [];
    for (let i = 0; i < arr.length; i++) {
        if (Array.isArray(arr[i])) {
            clonedArray[i] = deepCloneArray(arr[i]);
        } else {
            clonedArray[i] = arr[i];
        }
    }
    return clonedArray;
}
const UploadImgPopup: React.FC<{
    isModalOpen: boolean
    setIsModalOpen: Function
    fileIdArr: number[]
    fileIndex: number
    itemData: any
    setItemData: Function
}> = ({
    isModalOpen,
    setIsModalOpen,
    fileIdArr,
    fileIndex,
    itemData,
    setItemData }) => {
        const imageRef = useRef<HTMLImageElement | any>(null)
        const uploadFileRef = useRef<any>(null)
        const [previewOpen, setPreviewOpen] = useState(false);
        const [previewImage, setPreviewImage] = useState('');
        const [previewTitle, setPreviewTitle] = useState('');
        const [revomeStatus, setRemoveStatus] = useState(false)
        const [dragging, setDragging] = useState(false);
        const [imageIndex, setImageIndex] = useState(0)
        const [rotation, setRotation] = useState(0);
        const [loading, setLoading] = useState(false)
        let imageList = itemData[fileIndex]?.fileList
        const handleCancel = () => {
            setPreviewOpen(false)
            setRotation(0)
        };
        const handleDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
            e.preventDefault();
            setDragging(true);
        };

        const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
            e.preventDefault();
            setDragging(false);
        };

        const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
            e.preventDefault();
        };

        const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
            e.preventDefault();
            setDragging(false);

            const files: FileList = e.dataTransfer.files;
            const imageFiles: File[] = [];
            // 处理上传逻辑，可以调用上传函数或执行其他操作
            for (let i = 0; i < files.length; i++) {
                const file = files[i];
                if (file.type.startsWith("image/")) {
                    imageFiles.push(file);
                }
            }
            const imageFileList = new DataTransfer();
            for (let i = 0; i < imageFiles.length; i++) {
                imageFileList.items.add(imageFiles[i]);
            }
            handleUpload(imageFileList.files);
        };
        const compressAndReturnFile:(imageFile: File) => Promise<File|Blob> = async (imageFile: File) => {
            return new Promise(async (resolve, reject) => {
                new Compressor(imageFile, {
                    quality: 0.5,
                    maxWidth: 1200,
                    maxHeight: 1200,
                    convertSize:150000,//150kb
                    convertTypes:['image/png','image/webp'],
                    success(result) {
                        resolve(result);
                    },
                    error(err) {
                        reject(err);
                    },
                });
            });
        };
        const handleUpload = async (files: FileList) => {
            // 执行上传操作
            console.log(files, Array.from(files));
            setLoading(true)
            const arr = Array.from(files).map(async (file) => {
                const newBlob:File | Blob = await compressAndReturnFile(file)
                
                // const fileSizeInBytes = file.size;
                // const fileSizeInBytes2 = newBlob.size;
                // const fileSizeInKB = fileSizeInBytes / 1024;
                // const fileSizeInKB2 = fileSizeInBytes2 / 1024;
                const newFile = new File([newBlob], file.name,{type:file.type});
                // console.log(newFile,fileSizeInKB.toFixed(0)+"KB","压缩后:"+fileSizeInKB2.toFixed(0)+"kb",file.name);
                let data = new FormData()
                data.append("formFiles", newFile)
                const res: any = await UploadFile({ data })
                if (res.isSuccess) {
                    imageList.push({
                        url: URL.createObjectURL(newFile),
                        id: res.data[0],
                        name: file.name
                    })
                    // setFileList(deepCloneArray(fileList))
                    setItemData(JSON.parse(JSON.stringify(itemData)))
                }
            })
            Promise.all(arr).then(() => {
                setLoading(false)
            })
        };
        const uploadFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
            handleUpload(e.target.files!)
        }
        const removeImage = (index: number) => {
            imageList?.splice(index, 1)
            // setFileList(JSON.parse(JSON.stringify(fileList)))
            setItemData(JSON.parse(JSON.stringify(itemData)))
        }
        const showConfirm = () => {
            confirm({
                title: 'Are you sure you want to delete all the pictures?',
                icon: <ExclamationCircleFilled />,
                className: "ConfirmDelete",
                okText: "Yes",
                cancelText: "No",
                onOk() {
                    // setFileList([])
                    itemData[fileIndex].fileList = []
                    itemData[fileIndex].images = []
                    setItemData(JSON.parse(JSON.stringify(itemData)))
                },
                onCancel() {

                },
            });
        };
        const rotateImg = () => {
            if (rotation === 0) return
            setLoading(true)
            return new Promise<void>((resolve, reject) => {
                let canvas = document.createElement("canvas");
                let ctx: any = canvas.getContext("2d");
                switch (rotation) {
                    case 90:
                    case -270: // 旋转90°
                        canvas.width = imageRef.current.height;
                        canvas.height = imageRef.current.width;
                        ctx.rotate((90 * Math.PI) / 180);
                        ctx.drawImage(imageRef.current, 0, -imageRef.current.height, imageRef.current.width, imageRef.current.height);
                        break;
                    case 180:
                    case -180:// 旋转180°
                        canvas.width = imageRef.current.width;
                        canvas.height = imageRef.current.height;
                        ctx.rotate((180 * Math.PI) / 180);
                        ctx.drawImage(imageRef.current, -imageRef.current.width, -imageRef.current.height, imageRef.current.width, imageRef.current.height);
                        break;
                    case 270:
                    case -90: // 旋转270°
                        canvas.width = imageRef.current.height;
                        canvas.height = imageRef.current.width;
                        ctx.rotate((-90 * Math.PI) / 180);
                        ctx.drawImage(imageRef.current, -imageRef.current.width, 0, imageRef.current.width, imageRef.current.height);
                        break;
                    default:
                        canvas.width = imageRef.current.width;
                        canvas.height = imageRef.current.height;
                        ctx.drawImage(imageRef.current, 0, 0, imageRef.current.width, imageRef.current.height);
                        break;
                }
                let imgBaseUrl = canvas.toDataURL("image/png", 1)
                const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
                console.log(imageData);
                canvas.toBlob(async (blob) => {
                    let imgUrl = URL.createObjectURL(blob!);
                    const file = new File([blob!], previewTitle, { type: "image/png" })
                    let formData = new FormData()
                    formData.append("formFiles", file)
                    // formData.append("FileId", completeArr[Number(search.get('id') || 0)].fileList[imageCurrentIdx!].response.data[0])
                    try {
                        const res: any = await UploadFile({ data: formData, params: { FileId: imageList[imageIndex].id } })
                        if (res.isSuccess) {
                            message.success('Save successfully.')
                            setPreviewImage(imgUrl)
                            setRotation(0)
                            imageList[imageIndex].url = imgBaseUrl
                            imageList[imageIndex].id = res.data[0]
                            setItemData(JSON.parse(JSON.stringify(itemData)))
                            setLoading(false)
                            resolve()
                        } else {
                            message.error('Image upload failed.')
                            setLoading(false)
                        }
                    } catch (error) {
                        message.error("Image upload failed.")
                        setLoading(false)
                    }
                })
            })


        }
        const nextPrev = (num: number) => {
            if (num > 0 && imageIndex === imageList.length - 1) return
            if (num < 0 && imageIndex === 0) return
            if (rotation === 0) {
                setPreviewImage(imageList[imageIndex + num].url)
                setPreviewTitle(imageList[imageIndex + num].name)
                setImageIndex(imageIndex + num)
            } else {
                rotateImg()!.then(() => {
                    setPreviewImage(imageList[imageIndex + num].url)
                    setPreviewTitle(imageList[imageIndex + num].name)
                    setImageIndex(imageIndex + num)
                })
            }
        }
        useEffect(() => {
            if (!itemData[fileIndex]?.uploadStatus) {
                // setFileList([])
                // itemData[fileIndex].fileList = []
                // setItemData(JSON.parse(JSON.stringify(itemData)))
                setLoading(true)
                const arr: any = fileIdArr.map(async (fileId: number) => {
                    // fileList: [1,2,3].map(async (fileId: number) => {
                    const res: any = await GetFile({ params: { fileId } })
                    const contentDisposition = res.headers['content-disposition']
                    const filenameMatches = /filename=([^;]+)/g.exec(contentDisposition);
                    let name = filenameMatches ? filenameMatches[1] : "Unknown"
                    delete res.headers
                    return {
                        name,
                        url: URL.createObjectURL(res),
                        id: fileId
                    }
                })
                Promise.all(arr).then(res => {
                    setLoading(false)
                    // setFileList(res)
                    if (itemData[fileIndex]) {
                        itemData[fileIndex].fileList = res
                        itemData[fileIndex].uploadStatus = true
                    }
                    setItemData(JSON.parse(JSON.stringify(itemData)))
                })


            }

        }, [isModalOpen])
        return (
            <Modal
                maskClosable={false}
                title="Upload images"
                className='uploadImgPopup'
                open={isModalOpen}
                onOk={() => {
                    setIsModalOpen(false)
                    setRemoveStatus(false)
                    uploadFileRef.current.value = ""
                }}
                onCancel={() => {
                    setIsModalOpen(false)
                    setRemoveStatus(false)
                    uploadFileRef.current.value = ""
                }}>
                <div className='uploadContent'>
                    <Spin spinning={loading}>
                        <div className='imageContent'>
                            <div className='imageTitle'>
                                <h5>{imageList?.length} Images</h5>
                                <div className='buttonList'>
                                    <Button type='default' onClick={() => uploadFileRef.current.click()}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                            <path d="M9.16699 10.8337H4.16699V9.16699H9.16699V4.16699H10.8337V9.16699H15.8337V10.8337H10.8337V15.8337H9.16699V10.8337Z" fill="#4D5E80" />
                                        </svg> Add New</Button>
                                    <Button type='default' onClick={() => setRemoveStatus(!revomeStatus)}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                            <path d="M13.3337 7.5V15.8333H6.66699V7.5H13.3337ZM12.0837 2.5H7.91699L7.08366 3.33333H4.16699V5H15.8337V3.33333H12.917L12.0837 2.5ZM15.0003 5.83333H5.00033V15.8333C5.00033 16.75 5.75033 17.5 6.66699 17.5H13.3337C14.2503 17.5 15.0003 16.75 15.0003 15.8333V5.83333Z" fill="#4D5E80" />
                                        </svg>Delete</Button>
                                    <Button type='default' onClick={showConfirm}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                            <path d="M13.3337 7.5V15.8333H6.66699V7.5H13.3337ZM12.0837 2.5H7.91699L7.08366 3.33333H4.16699V5H15.8337V3.33333H12.917L12.0837 2.5ZM15.0003 5.83333H5.00033V15.8333C5.00033 16.75 5.75033 17.5 6.66699 17.5H13.3337C14.2503 17.5 15.0003 16.75 15.0003 15.8333V5.83333Z" fill="#4D5E80" />
                                        </svg>Delete All</Button>
                                </div>
                            </div>
                            <input
                                multiple
                                ref={uploadFileRef}
                                type="file"
                                accept="image/*"
                                style={{ display: "none" }}
                                onChange={uploadFileChange}
                            />
                            <div
                                className={`fileContent dropzone ${dragging ? 'dragging' : ''}`}
                                onDragEnter={handleDragEnter}
                                onDragLeave={handleDragLeave}
                                onDragOver={handleDragOver}
                                onDrop={handleDrop}
                            >
                                <div className='imgBoxList'>
                                    {
                                        imageList?.length ? (
                                            imageList?.map((item: any, index: number) => {
                                                return (
                                                    <div onClick={() => {
                                                        setPreviewOpen(true)
                                                        setPreviewTitle(item.name)
                                                        setPreviewImage(item.url)
                                                        setImageIndex(index)
                                                    }} title={item.name} key={item.id} className='imgBox'>
                                                        <CloseCircleFilled onClick={(e) => {
                                                            e.stopPropagation()
                                                            removeImage(index)
                                                        }} style={{ display: revomeStatus ? "" : "none" }} className='removeImg' />
                                                        <img style={{userSelect:"none"}} src={item.url} alt='' />
                                                    </div>
                                                )
                                            })
                                        ) : (
                                            <div className='dragText'>
                                                <p>Drag and drop Files Here to Upload</p>
                                                <Button onClick={() => uploadFileRef.current.click()} type='primary'>Or Select Files to Upload</Button>
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    </Spin>
                </div>
                <Modal
                    className='viewImagePopup'
                    open={previewOpen}
                    title="Images"
                    footer={null}
                    onCancel={handleCancel}>
                    <Spin spinning={loading}>
                        <div className='viewImagePopupContent'>
                            <div className='imageTitle'>
                                <div></div>
                                <h5>{previewTitle}</h5>
                                <div>
                                    <button type='button' onClick={() => setRotation(rotation === -270 ? 0 : rotation - 90)}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                            <path d="M15.75 9.79688H3.375C2.96016 9.79688 2.625 10.132 2.625 10.5469V20.25C2.625 20.6648 2.96016 21 3.375 21H15.75C16.1648 21 16.5 20.6648 16.5 20.25V10.5469C16.5 10.132 16.1648 9.79688 15.75 9.79688ZM14.7188 19.2188H4.40625V11.5781H14.7188V19.2188Z" fill="#7D8FB3" />
                                            <path d="M19.2019 7.69966C17.355 5.3395 14.6081 4.09966 11.8284 4.0856L11.8237 2.5856C11.8237 2.43325 11.6456 2.34888 11.5284 2.44263L8.52842 4.80982C8.43467 4.88247 8.43701 5.0231 8.52842 5.0981L11.5308 7.46763C11.6503 7.56138 11.8284 7.477 11.8261 7.32466V5.827C12.1284 5.82935 12.4331 5.8481 12.7355 5.8856C13.7222 6.00747 14.6597 6.31216 15.5245 6.79263C16.4175 7.2895 17.1933 7.95747 17.8308 8.76841C18.4659 9.58169 18.9253 10.4958 19.1925 11.4825C19.4503 12.4364 19.5206 13.4208 19.4011 14.4075C19.3847 14.534 19.3683 14.6606 19.3448 14.7848H21.1003C21.4472 12.3567 20.8355 9.79263 19.2019 7.69966Z" fill="#7D8FB3" />
                                        </svg>
                                    </button>
                                    <button type='button' onClick={() => setRotation(rotation === 270 ? 0 : rotation + 90)}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                            <path d="M8.25 9.79688H20.625C21.0398 9.79688 21.375 10.132 21.375 10.5469V20.25C21.375 20.6648 21.0398 21 20.625 21H8.25C7.83516 21 7.5 20.6648 7.5 20.25V10.5469C7.5 10.132 7.83516 9.79688 8.25 9.79688ZM9.28125 19.2188H19.5938V11.5781H9.28125V19.2188Z" fill="#7D8FB3" />
                                            <path d="M4.79814 7.69966C6.64502 5.3395 9.39189 4.09966 12.1716 4.0856L12.1763 2.5856C12.1763 2.43325 12.3544 2.34888 12.4716 2.44263L15.4716 4.80982C15.5653 4.88247 15.563 5.0231 15.4716 5.0981L12.4692 7.46763C12.3497 7.56138 12.1716 7.477 12.1739 7.32466V5.827C11.8716 5.82935 11.5669 5.8481 11.2645 5.8856C10.2778 6.00747 9.34033 6.31216 8.47548 6.79263C7.58252 7.2895 6.80674 7.95747 6.16924 8.76841C5.53408 9.58169 5.0747 10.4958 4.80752 11.4825C4.5497 12.4364 4.47939 13.4208 4.59892 14.4075C4.61533 14.534 4.63173 14.6606 4.65517 14.7848H2.8997C2.55283 12.3567 3.16455 9.79263 4.79814 7.69966Z" fill="#7D8FB3" />
                                        </svg>
                                    </button>
                                    <Button type='primary' onClick={rotateImg}>Save</Button>
                                </div>
                            </div>
                            <div className='img_content'>
                                <img ref={imageRef} style={{ transform: `rotate(${rotation}deg)` }} src={previewImage} alt="" />
                            </div>
                        </div>
                        <ul className='img_footer'>
                            <li onClick={() => nextPrev(-1)}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="29" viewBox="0 0 32 29" fill="none">
                                    <path d="M10.7167 14.0221L15.5232 9.82986C16.1087 9.31927 16.7785 9.20533 17.5328 9.48804C18.287 9.77074 18.6648 10.2743 18.666 10.9988V19.3026C18.666 20.0282 18.2883 20.5324 17.5328 20.8151C16.7773 21.0978 16.1074 20.9833 15.5232 20.4716L10.7167 16.2794C10.5318 16.1182 10.3931 15.9435 10.3007 15.7554C10.2083 15.5673 10.1621 15.3657 10.1621 15.1507C10.1621 14.9358 10.2083 14.7342 10.3007 14.5461C10.3931 14.358 10.5318 14.1833 10.7167 14.0221Z" fill="#4D5E80" />
                                </svg>
                            </li>
                            <li>
                                <div className='imageList'>
                                    {
                                        imageList?.map((item: any, index: number) => {
                                            return (
                                                <div key={item.id} className={imageIndex === index ? "active" : ""} onClick={() => {
                                                    setPreviewImage(item.url)
                                                    setPreviewTitle(item.name)
                                                    setImageIndex(index)
                                                    setRotation(0)
                                                }}>
                                                    <img src={item.url} alt='' />
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </li>
                            <li onClick={() => nextPrev(1)}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="29" viewBox="0 0 32 29" fill="none">
                                    <path d="M21.2833 14.0221L16.4768 9.82986C15.8913 9.31927 15.2215 9.20533 14.4672 9.48804C13.713 9.77074 13.3352 10.2743 13.334 10.9988L13.334 19.3026C13.334 20.0282 13.7117 20.5324 14.4672 20.8151C15.2227 21.0978 15.8926 20.9833 16.4768 20.4716L21.2833 16.2794C21.4682 16.1182 21.6069 15.9435 21.6993 15.7554C21.7917 15.5673 21.8379 15.3657 21.8379 15.1507C21.8379 14.9358 21.7917 14.7342 21.6993 14.5461C21.6069 14.358 21.4682 14.1833 21.2833 14.0221Z" fill="#4D5E80" />
                                </svg>
                            </li>
                        </ul>
                    </Spin>
                </Modal>
            </Modal>
        );
    };

export default UploadImgPopup;