import React,{useRef, useState} from 'react'
import '../../styles/login.less'
import logo from '@/images/login/logo.png'
import { Button, Checkbox, Form, Input, message } from 'antd';
import circleBlack from '@/images/login/circle-black.png'
import circleWhite from '@/images/login/circle-white.png'
import { useNavigate,useSearchParams } from 'react-router-dom';
import { IsLogin } from '@/services/login';
const Login: React.FC = () => {
    const [search] = useSearchParams()
    const [loading,setLoading] = useState(false)
    const navigate = useNavigate()
    const onFinish = async (values: any) => {
        setLoading(true)
        const res:any = await IsLogin({params:values})
        if(res.isSuccess){
            message.success('Login Successfully.')
            localStorage.setItem("Authorization",res.data.token)
            let type = search.get('type') || ""
            // if(document.body.clientWidth<766){
            //     type === "expired" ? navigate(-1) : navigate('/inspectionPhone')
            // }else{
            //     type === "expired" ? navigate(-1) : navigate('/inspections')
            // }
            localStorage.setItem("role",res.data.role)
            if(res.data.role === 1){
                 navigate('/inspectionPhone')
            }else{
                 navigate('/inspections')
            }
            setLoading(false)
        }else{
            message.error(res.message)
            setLoading(false)
        }
        
    };
    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };
    return (
        <section className='login'>
            <div className='login_left'>
                <div className="content">
                    <h2>Quality inspection systems with benefits you can measure</h2>
                    <p className='text'>Faster, more accurate, and spatially enabling. A inspection system based paperless process, simple for field staff and integrates with existing management systems.</p>
                    <p>QC-inspection V1.0.0  © 2023 8bsolutions. All rights reserved.</p>
                </div>
                <img className='circleW' src={circleWhite} alt="" />
            </div>
            <div className='login_right' >
                <div className='content'>
                    {/* <img className='logo' src={logo} alt="" /> */}
                    <svg  className='logo' width="250" height="60" viewBox="0 0 250 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M22.4989 36.9524C26.4749 40.9285 32.9213 40.9285 36.8974 36.9524C36.9138 36.936 36.9301 36.9196 36.9464 36.9031C33.8846 39.9185 28.958 39.9042 25.9139 36.8601C22.8554 33.8016 22.8554 28.8429 25.9139 25.7844C28.9724 22.7259 33.9312 22.7259 36.9896 25.7844C39.8268 28.6216 40.0321 33.094 37.6055 36.1678C40.8574 32.169 40.6213 26.2779 36.8974 22.554C32.9213 18.5779 26.4749 18.5779 22.4989 22.554C18.5229 26.53 18.5229 32.9764 22.4989 36.9524Z" fill="#5C7CEA" />
                        <path fillRule="evenodd" clipRule="evenodd" d="M26.1538 16.3702C18.7727 18.3479 14.3924 25.9347 16.3702 33.3158C18.3479 40.6969 25.9348 45.0771 33.3158 43.0994C34.7789 42.7074 36.115 42.0994 37.3051 41.3223C39.7195 39.7457 42.9548 40.425 44.5314 42.8394C46.1079 45.2539 45.4286 48.4892 43.0142 50.0657C40.9107 51.4392 38.5616 52.5045 36.0185 53.1859C23.0668 56.6563 9.75409 48.9702 6.2837 36.0185C2.81331 23.0668 10.4994 9.75407 23.4511 6.28368C36.4028 2.81329 49.7155 10.4994 53.1859 23.4511C53.9475 26.2933 54.1724 29.1643 53.9172 31.9491C53.654 34.8206 51.1128 36.9351 48.2413 36.6719C45.3697 36.4087 43.2553 33.8675 43.5185 30.996C43.6629 29.4205 43.5372 27.7878 43.0994 26.1537C41.1217 18.7727 33.5348 14.3924 26.1538 16.3702Z" fill="#5C7CEA" />
                        <path fillRule="evenodd" clipRule="evenodd" d="M36.5201 41.9876C38.5591 39.9486 41.865 39.9486 43.9039 41.9876L50.3562 48.4398C52.3952 50.4788 52.3952 53.7846 50.3562 55.8236C48.3172 57.8626 45.0113 57.8626 42.9724 55.8236L36.5201 49.3714C34.4811 47.3324 34.4811 44.0266 36.5201 41.9876Z" fill="#5C7CEA" />
                        <path d="M88.4882 38.5085H76.4732C74.9234 38.5085 73.4857 38.2845 72.16 37.8364C70.853 37.3696 69.7234 36.6974 68.7712 35.8198C67.8189 34.9423 67.0721 33.878 66.5306 32.627C65.9891 31.376 65.7184 29.9477 65.7184 28.3419C65.7184 26.8482 65.9891 25.4758 66.5306 24.2248C67.0907 22.9739 67.8469 21.9003 68.7992 21.004C69.7514 20.1078 70.8717 19.4169 72.16 18.9315C73.4484 18.4274 74.8301 18.1753 76.3051 18.1753C77.7802 18.1753 79.1618 18.4274 80.4502 18.9315C81.7385 19.4356 82.8588 20.1358 83.811 21.032C84.7633 21.9283 85.5101 23.0019 86.0516 24.2529C86.6118 25.4852 86.8918 26.8482 86.8918 28.3419C86.8918 29.0514 86.8078 29.7423 86.6398 30.4144C86.4904 31.0866 86.257 31.7214 85.9396 32.3189C85.6222 32.9164 85.2114 33.4579 84.7073 33.9434C84.2031 34.4101 83.615 34.7929 82.9428 35.0916V35.1477H88.4882V38.5085ZM70.2556 28.3419C70.2556 29.2008 70.4143 30.0037 70.7317 30.7505C71.0491 31.4974 71.4785 32.1509 72.02 32.711C72.5802 33.2525 73.2243 33.682 73.9525 33.9994C74.6807 34.3168 75.4649 34.4755 76.3051 34.4755C77.1453 34.4755 77.9295 34.3168 78.6577 33.9994C79.3859 33.682 80.0207 33.2525 80.5622 32.711C81.1224 32.1509 81.5611 31.4974 81.8785 30.7505C82.196 30.0037 82.3547 29.2008 82.3547 28.3419C82.3547 27.483 82.196 26.6801 81.8785 25.9333C81.5611 25.1864 81.1224 24.5423 80.5622 24.0008C80.0207 23.4406 79.3859 23.0019 78.6577 22.6845C77.9295 22.367 77.1453 22.2083 76.3051 22.2083C75.4649 22.2083 74.6807 22.367 73.9525 22.6845C73.2243 23.0019 72.5802 23.4406 72.02 24.0008C71.4785 24.5423 71.0491 25.1864 70.7317 25.9333C70.4143 26.6801 70.2556 27.483 70.2556 28.3419Z" fill="#4D5E80" />
                        <path d="M103.6 23.8327C103.17 23.3099 102.638 22.9085 102.003 22.6284C101.387 22.3484 100.668 22.2083 99.8469 22.2083C99.0254 22.2083 98.2598 22.367 97.5503 22.6845C96.8595 23.0019 96.2527 23.45 95.7299 24.0288C95.2257 24.5889 94.8243 25.2611 94.5255 26.0453C94.2455 26.8295 94.1054 27.6791 94.1054 28.594C94.1054 29.5275 94.2455 30.3864 94.5255 31.1706C94.8243 31.9362 95.2257 32.6083 95.7299 33.1872C96.234 33.7473 96.8221 34.1861 97.4943 34.5035C98.1852 34.8209 98.9227 34.9796 99.7069 34.9796C100.603 34.9796 101.397 34.7929 102.087 34.4195C102.778 34.046 103.348 33.5232 103.796 32.8511L107.437 35.5678C106.597 36.7441 105.532 37.6123 104.244 38.1724C102.956 38.7326 101.63 39.0127 100.267 39.0127C98.7173 39.0127 97.2889 38.7699 95.9819 38.2845C94.6749 37.799 93.5453 37.1082 92.5931 36.2119C91.6408 35.297 90.8939 34.1954 90.3525 32.9071C89.8297 31.6188 89.5683 30.1811 89.5683 28.594C89.5683 27.0069 89.8297 25.5692 90.3525 24.2809C90.8939 22.9925 91.6408 21.9003 92.5931 21.004C93.5453 20.0891 94.6749 19.3889 95.9819 18.9035C97.2889 18.418 98.7173 18.1753 100.267 18.1753C100.827 18.1753 101.406 18.2313 102.003 18.3433C102.62 18.4367 103.217 18.5954 103.796 18.8195C104.393 19.0435 104.963 19.3423 105.504 19.7157C106.046 20.0891 106.531 20.5466 106.961 21.088L103.6 23.8327Z" fill="#4D5E80" />
                        <path d="M115.379 33.2152H108.489V29.8543H115.379V33.2152Z" fill="#4D5E80" />
                        <path d="M117.617 24.897H121.818V38.5085H117.617V24.897ZM117.281 20.6119C117.281 19.9397 117.514 19.3703 117.981 18.9035C118.466 18.418 119.045 18.1753 119.717 18.1753C120.389 18.1753 120.959 18.418 121.426 18.9035C121.911 19.3703 122.154 19.9397 122.154 20.6119C122.154 21.2841 121.911 21.8629 121.426 22.3484C120.959 22.8152 120.389 23.0485 119.717 23.0485C119.045 23.0485 118.466 22.8152 117.981 22.3484C117.514 21.8629 117.281 21.2841 117.281 20.6119Z" fill="#4D5E80" />
                        <path d="M124.836 24.897H128.869V26.7455H128.925C129.056 26.4841 129.242 26.2227 129.485 25.9613C129.728 25.6999 130.017 25.4665 130.353 25.2611C130.689 25.0557 131.072 24.8877 131.502 24.757C131.931 24.6263 132.398 24.5609 132.902 24.5609C133.966 24.5609 134.825 24.729 135.479 25.0651C136.132 25.3825 136.636 25.8306 136.991 26.4094C137.364 26.9882 137.617 27.6697 137.747 28.4539C137.878 29.2381 137.943 30.0877 137.943 31.0026V38.5085H133.742V31.8428C133.742 31.4507 133.724 31.0493 133.686 30.6385C133.668 30.2091 133.584 29.817 133.434 29.4622C133.303 29.1074 133.089 28.818 132.79 28.594C132.51 28.3699 132.099 28.2579 131.558 28.2579C131.016 28.2579 130.577 28.3606 130.241 28.566C129.905 28.7527 129.644 29.0141 129.457 29.3502C129.289 29.6676 129.177 30.0317 129.121 30.4425C129.065 30.8532 129.037 31.2827 129.037 31.7308V38.5085H124.836V24.897Z" fill="#4D5E80" />
                        <path d="M148.62 28.9021C148.004 28.1365 147.192 27.7538 146.184 27.7538C145.829 27.7538 145.483 27.8378 145.147 28.0058C144.811 28.1739 144.643 28.4633 144.643 28.874C144.643 29.2101 144.811 29.4622 145.147 29.6302C145.502 29.7796 145.941 29.9196 146.464 30.0503C147.005 30.1624 147.575 30.2931 148.172 30.4425C148.788 30.5732 149.358 30.7879 149.88 31.0866C150.422 31.3854 150.861 31.7961 151.197 32.3189C151.552 32.8231 151.729 33.5046 151.729 34.3635C151.729 35.241 151.533 35.9692 151.141 36.548C150.767 37.1082 150.282 37.5656 149.684 37.9204C149.087 38.2565 148.415 38.4899 147.668 38.6206C146.921 38.7699 146.184 38.8446 145.455 38.8446C144.503 38.8446 143.542 38.7139 142.571 38.4525C141.6 38.1724 140.778 37.687 140.106 36.9961L142.655 34.1674C143.047 34.6529 143.476 35.0263 143.943 35.2877C144.428 35.5304 144.989 35.6518 145.623 35.6518C146.109 35.6518 146.548 35.5864 146.94 35.4557C147.332 35.3064 147.528 35.045 147.528 34.6715C147.528 34.3168 147.351 34.0554 146.996 33.8873C146.66 33.7006 146.221 33.5513 145.679 33.4392C145.157 33.3085 144.587 33.1778 143.971 33.0471C143.373 32.8977 142.804 32.683 142.263 32.403C141.74 32.1229 141.301 31.7308 140.946 31.2267C140.61 30.7039 140.442 30.013 140.442 29.1541C140.442 28.3512 140.601 27.6604 140.918 27.0816C141.254 26.5028 141.684 26.0266 142.207 25.6532C142.748 25.2798 143.364 25.0091 144.055 24.841C144.746 24.6543 145.446 24.5609 146.156 24.5609C147.052 24.5609 147.957 24.6916 148.872 24.953C149.787 25.2144 150.562 25.6812 151.197 26.3534L148.62 28.9021Z" fill="#4D5E80" />
                        <path d="M154.275 24.897H158.14V26.6895H158.196C158.364 26.4468 158.578 26.204 158.84 25.9613C159.12 25.6999 159.437 25.4665 159.792 25.2611C160.165 25.0557 160.567 24.8877 160.996 24.757C161.426 24.6263 161.883 24.5609 162.369 24.5609C163.377 24.5609 164.292 24.7383 165.113 25.0931C165.935 25.4292 166.635 25.9146 167.214 26.5494C167.811 27.1656 168.269 27.9031 168.586 28.762C168.904 29.6209 169.062 30.5638 169.062 31.5907C169.062 32.543 168.913 33.4579 168.614 34.3355C168.334 35.1943 167.923 35.9599 167.382 36.632C166.859 37.3042 166.215 37.8457 165.449 38.2565C164.684 38.6486 163.816 38.8446 162.845 38.8446C161.967 38.8446 161.146 38.7139 160.38 38.4525C159.633 38.1724 159.017 37.7057 158.532 37.0522H158.476V44.8942H154.275V24.897ZM158.14 31.7028C158.14 32.7297 158.429 33.5606 159.008 34.1954C159.605 34.8302 160.436 35.1477 161.5 35.1477C162.565 35.1477 163.386 34.8302 163.965 34.1954C164.563 33.5606 164.861 32.7297 164.861 31.7028C164.861 30.6758 164.563 29.845 163.965 29.2101C163.386 28.5753 162.565 28.2579 161.5 28.2579C160.436 28.2579 159.605 28.5753 159.008 29.2101C158.429 29.845 158.14 30.6758 158.14 31.7028Z" fill="#4D5E80" />
                        <path d="M184.407 36.1559C183.735 37.0148 182.885 37.6776 181.858 38.1444C180.831 38.6112 179.767 38.8446 178.666 38.8446C177.62 38.8446 176.63 38.6766 175.697 38.3405C174.782 38.0044 173.979 37.5283 173.288 36.9121C172.616 36.2773 172.084 35.5211 171.692 34.6435C171.3 33.766 171.104 32.7857 171.104 31.7028C171.104 30.6198 171.3 29.6396 171.692 28.762C172.084 27.8845 172.616 27.1376 173.288 26.5214C173.979 25.8866 174.782 25.4012 175.697 25.0651C176.63 24.729 177.62 24.5609 178.666 24.5609C179.636 24.5609 180.514 24.729 181.298 25.0651C182.101 25.4012 182.773 25.8866 183.315 26.5214C183.875 27.1376 184.304 27.8845 184.603 28.762C184.902 29.6396 185.051 30.6198 185.051 31.7028V33.0191H175.305C175.473 33.822 175.837 34.4662 176.397 34.9516C176.957 35.4184 177.648 35.6518 178.47 35.6518C179.16 35.6518 179.739 35.5024 180.206 35.2037C180.691 34.8863 181.112 34.4848 181.466 33.9994L184.407 36.1559ZM180.85 30.1624C180.869 29.4529 180.635 28.846 180.15 28.3419C179.664 27.8378 179.039 27.5857 178.273 27.5857C177.807 27.5857 177.396 27.6604 177.041 27.8098C176.686 27.9591 176.378 28.1552 176.117 28.3979C175.874 28.622 175.678 28.8927 175.529 29.2101C175.398 29.5089 175.323 29.8263 175.305 30.1624H180.85Z" fill="#4D5E80" />
                        <path d="M196.82 29.2942C196.615 28.9954 196.326 28.7527 195.952 28.566C195.579 28.3606 195.177 28.2579 194.748 28.2579C193.684 28.2579 192.853 28.5753 192.255 29.2101C191.676 29.845 191.387 30.6758 191.387 31.7028C191.387 32.7297 191.676 33.5606 192.255 34.1954C192.853 34.8302 193.684 35.1477 194.748 35.1477C195.215 35.1477 195.616 35.045 195.952 34.8396C196.288 34.6342 196.578 34.3915 196.82 34.1114L199.593 37.0241C198.94 37.6963 198.165 38.1724 197.268 38.4525C196.391 38.7139 195.551 38.8446 194.748 38.8446C193.702 38.8446 192.713 38.6766 191.779 38.3405C190.864 38.0044 190.061 37.5283 189.37 36.9121C188.698 36.2773 188.166 35.5211 187.774 34.6435C187.382 33.766 187.186 32.7857 187.186 31.7028C187.186 30.6198 187.382 29.6396 187.774 28.762C188.166 27.8845 188.698 27.1376 189.37 26.5214C190.061 25.8866 190.864 25.4012 191.779 25.0651C192.713 24.729 193.702 24.5609 194.748 24.5609C195.551 24.5609 196.391 24.701 197.268 24.981C198.165 25.2424 198.94 25.7092 199.593 26.3814L196.82 29.2942Z" fill="#4D5E80" />
                        <path d="M210.388 28.2579H206.691V32.7951C206.691 33.1685 206.709 33.5139 206.747 33.8313C206.784 34.1301 206.868 34.3915 206.999 34.6155C207.129 34.8396 207.326 35.017 207.587 35.1477C207.867 35.2597 208.231 35.3157 208.679 35.3157C208.903 35.3157 209.193 35.297 209.547 35.2597C209.921 35.2037 210.201 35.0916 210.388 34.9236V38.4245C209.921 38.5925 209.435 38.7046 208.931 38.7606C208.427 38.8166 207.932 38.8446 207.447 38.8446C206.737 38.8446 206.084 38.7699 205.486 38.6206C204.889 38.4712 204.366 38.2378 203.918 37.9204C203.47 37.5843 203.115 37.1548 202.854 36.632C202.611 36.1092 202.49 35.4744 202.49 34.7276V28.2579H199.801V24.897H202.49V20.864H206.691V24.897H210.388V28.2579Z" fill="#4D5E80" />
                        <path d="M212.579 24.897H216.78V38.5085H212.579V24.897ZM212.242 20.6119C212.242 19.9397 212.476 19.3703 212.943 18.9035C213.428 18.418 214.007 18.1753 214.679 18.1753C215.351 18.1753 215.921 18.418 216.388 18.9035C216.873 19.3703 217.116 19.9397 217.116 20.6119C217.116 21.2841 216.873 21.8629 216.388 22.3484C215.921 22.8152 215.351 23.0485 214.679 23.0485C214.007 23.0485 213.428 22.8152 212.943 22.3484C212.476 21.8629 212.242 21.2841 212.242 20.6119Z" fill="#4D5E80" />
                        <path d="M219.294 31.7028C219.294 30.6198 219.49 29.6396 219.882 28.762C220.274 27.8845 220.806 27.1376 221.478 26.5214C222.169 25.8866 222.972 25.4012 223.887 25.0651C224.82 24.729 225.81 24.5609 226.856 24.5609C227.901 24.5609 228.882 24.729 229.796 25.0651C230.73 25.4012 231.533 25.8866 232.205 26.5214C232.896 27.1376 233.437 27.8845 233.829 28.762C234.222 29.6396 234.418 30.6198 234.418 31.7028C234.418 32.7857 234.222 33.766 233.829 34.6435C233.437 35.5211 232.896 36.2773 232.205 36.9121C231.533 37.5283 230.73 38.0044 229.796 38.3405C228.882 38.6766 227.901 38.8446 226.856 38.8446C225.81 38.8446 224.82 38.6766 223.887 38.3405C222.972 38.0044 222.169 37.5283 221.478 36.9121C220.806 36.2773 220.274 35.5211 219.882 34.6435C219.49 33.766 219.294 32.7857 219.294 31.7028ZM223.495 31.7028C223.495 32.7297 223.784 33.5606 224.363 34.1954C224.961 34.8302 225.791 35.1477 226.856 35.1477C227.92 35.1477 228.742 34.8302 229.32 34.1954C229.918 33.5606 230.217 32.7297 230.217 31.7028C230.217 30.6758 229.918 29.845 229.32 29.2101C228.742 28.5753 227.92 28.2579 226.856 28.2579C225.791 28.2579 224.961 28.5753 224.363 29.2101C223.784 29.845 223.495 30.6758 223.495 31.7028Z" fill="#4D5E80" />
                        <path d="M236.892 24.897H240.925V26.7455H240.981C241.112 26.4841 241.299 26.2227 241.541 25.9613C241.784 25.6999 242.073 25.4665 242.41 25.2611C242.746 25.0557 243.128 24.8877 243.558 24.757C243.987 24.6263 244.454 24.5609 244.958 24.5609C246.022 24.5609 246.881 24.729 247.535 25.0651C248.188 25.3825 248.692 25.8306 249.047 26.4094C249.421 26.9882 249.673 27.6697 249.803 28.4539C249.934 29.2381 249.999 30.0877 249.999 31.0026V38.5085H245.798V31.8428C245.798 31.4507 245.78 31.0493 245.742 30.6385C245.724 30.2091 245.64 29.817 245.49 29.4622C245.36 29.1074 245.145 28.818 244.846 28.594C244.566 28.3699 244.155 28.2579 243.614 28.2579C243.072 28.2579 242.634 28.3606 242.298 28.566C241.961 28.7527 241.7 29.0141 241.513 29.3502C241.345 29.6676 241.233 30.0317 241.177 30.4425C241.121 30.8532 241.093 31.2827 241.093 31.7308V38.5085H236.892V24.897Z" fill="#4D5E80" />
                    </svg>

                    <img className='circleB' src={circleBlack} alt="" />
                    <Form
                        name="basic"
                        layout='vertical'
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        autoComplete="off"
                    >
                        <Form.Item
                            label="Email Address"
                            name="Email"
                            rules={[
                                { required: true, message: 'Please input your Email Address!' },
                                { type: "email", message: "Email is not a valid email!" }
                            ]}
                        >
                            <Input autoComplete='Email' className='qcInput' />
                        </Form.Item>

                        <Form.Item
                            label="Password"
                            name="Password"
                            rules={[{ required: true, message: 'Please input your password!' }]}
                        >
                            <Input.Password className='qcInput' />
                        </Form.Item>


                        <Form.Item >
                            <Button loading={loading} className='loginButton' type="primary" htmlType="submit">
                                Login
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
                <h3 className=''>QC-inspection V1.0.0  © 2023 8bsolutions. All rights reserved.</h3>
            </div>
        </section>
    )
}
export default Login