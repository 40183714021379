import React, { useEffect, useState } from 'react'
import "@/styles/inspectionPhone/inspectionPhone.less"
import Avatar from '@/components/Avatar'
import avatar from '@/images/navigation/avatar.png'
import setting from '@/images/navigation/settings.png'
import quest from '@/images/navigation/quest.png'
import '@/styles/inspectionPhone/inspectionList.less'
import { GetDashboardInfoByInspector } from '@/services/inspectionReport'
import type { MenuProps } from 'antd';
import { Dropdown, Space, message } from 'antd';
import { Skeleton } from 'antd'
import { useNavigate, Link } from 'react-router-dom'
const InspectionPhone: React.FC = () => {
    const navigate = useNavigate()
    const [name, setName] = useState('')
    const [qty,setQty] = useState(0)
    const [loading, setLoading] = useState(false)
    const [progress, setProgress] = useState<{
        progress?: any,
        pending?: number,
        inspectionsQty?:number
        inProgress?: number,
        submittedToday?: number
    }>({})
    const [newInspections, setNewInspections] = useState<{
        inspectionProjectId: number
        itemNo: string
        poNumber: string
        quantity: number
        progress: string
        status: string
        purchaseOrder_InspectionProjectId:number
    }[]>([])
    const items: MenuProps['items'] = [
        {
            key: '1',
            label: (
                <Link to="/login" onClick={() => message.success('Log out successfully.')}>Logout</Link>
            ),
        }];
    const GetDashboardList = async () => {
        setLoading(true)
        const res: any = await GetDashboardInfoByInspector()
        if (res.isSuccess) {
            setQty(res.data.inspectionsQty)
            setName(res.data.name)
            setProgress(res.data.progress)
            setNewInspections(res.data.newInspections)
            setLoading(false)
        }
    }
    const now = new Date();
    const hour = now.getHours();
    useEffect(() => {
        GetDashboardList()
    }, [])
    return (
        <section className="inspectionPhone">
            <div className='title'>
                <img className='avatar' style={{visibility:"hidden"}} src={avatar} alt="" />
                {/* <Dropdown menu={{ items }} className='cursor-pointer ml-[19px]'> */}
                    <a onClick={(e) => {
                        e.preventDefault()
                        localStorage.removeItem("Authorization")
                        navigate("/login")
                        message.success('Log out successfully.')
                    }}>
                        <div>
                            {/* <img src={setting} alt="" /> */}
                            <span>Log Out</span>
                        </div>
                    </a>
                {/* </Dropdown> */}

            </div>
            <h1>Good {hour > 0 && hour < 12 ? "morning" : hour >= 12 && hour < 18 ? "afternoon" : "evening"},<br />{name}</h1>
            {loading ? <>
                <Skeleton active />
                {/* <Skeleton.Input style={{marginTop:"7.639vw",marginBottom: "0.5em"}} size='small' active /> */}
                <Skeleton style={{marginTop:"7.639vw",marginBottom: "0.5em"}} paragraph={{rows:2}} active />
            </> : (
                // <><div className='quest' onClick={() => navigate('/inspectionList?status=All')}>
                <><div className='quest'>
                    <div>
                        <h3 onClick={() => navigate('/inspectionList?status=All')}>{progress.progress}</h3>
                        <Link target='_blank' to='/conditionalPassReportPublic'>Reports</Link>
                        <img src={quest} alt="" />
                    </div>
                    <ul>
                        <li onClick={(e) => {
                            e.stopPropagation()
                            navigate('/inspectionList?status=Pending')
                        }}>
                            <p>Pending</p>
                            <p>{progress.pending}</p>
                        </li>
                        <li onClick={(e) => {
                            e.stopPropagation()
                            navigate('/inspectionList?status=In Progress')
                        }}>
                            <p>In Progress</p>
                            <p>{progress.inProgress}</p>
                        </li>
                        <li onClick={(e) =>{
                            e.stopPropagation()
                            navigate('/inspectionList?status=Submitted')
                        }}>
                            <p>Submitted Today</p>
                            <p>{progress.submittedToday}</p>
                        </li>
                    </ul>
                </div>
                    <h2>New Inspections {`(${qty})`}</h2>
                    <ul className='newInspectionsList'>
                        {newInspections?.map((item, index) => (
                            <li key={index} onClick={() => {
                                window.matchMedia('(max-width: 768px)').matches ?
                                navigate(`/inspectionDetail?itemNo=${item.itemNo}&PONumber=${item.poNumber}&itemId=${item.purchaseOrder_InspectionProjectId}`):
                                navigate(`/inspectionPCReport?itemNo=${item.itemNo}&poNumber=${item.poNumber}&itemId=${item.purchaseOrder_InspectionProjectId}`)
                            }}>
                            {/* <li key={index} onClick={() => navigate(`/inspectionPCReport?itemNo=${item.itemNo}&PONumber=${item.poNumber}&itemId=${item.purchaseOrder_InspectionProjectId}`)}> */}
                                <div className='no'>
                                    <h3>{item.itemNo}</h3>
                                    <div>
                                        <span>{item.progress}</span>
                                        <div className={` ${item.status.indexOf("Pending") !== -1 ? "pending" :
                                    item.status.indexOf("Progress") !== -1 ? "InProgress" :
                                    item.status.indexOf("Submitted") !== -1 ? "submitted" : ""}`}>{item.status}</div>
                                    </div>
                                </div>
                                <ul className='type'>
                                    {/* <li>FOTO</li> */}
                                    <li>{item.poNumber}</li>
                                    <li>{item.quantity}</li>
                                </ul>
                            </li>
                        ))}
                    </ul>
                    <div className='more' onClick={()=>navigate('/inspectionList')}>More</div>
                    </>
            )}

        </section>
    )
}
export default InspectionPhone