
import React, { useState, useEffect } from 'react'
import type { RcFile, UploadFile, UploadProps } from 'antd/es/upload/interface';
import { useNavigate } from 'react-router-dom';
import { Input, Checkbox, Spin, InputNumber, Popconfirm, Button, message, Modal, Upload } from 'antd';
import pass1 from '@/images/navigation/pass.svg'
import { ExclamationCircleFilled, UploadOutlined, DeleteTwoTone } from '@ant-design/icons';
import pass2 from '@/images/navigation/pass1.svg'
import pending1 from '@/images/navigation/pending.svg'
import pending2 from '@/images/navigation/pending1.svg'
import fail1 from '@/images/navigation/fail1.svg'
import fail2 from '@/images/navigation/fail.svg'
import warn from '@/images/navigation/warn.png'
import more from '@/images/navigation/more.png'
import save from '@/images/navigation/save.png'
import exit from '@/images/navigation/exit.png'
import progress from '@/images/navigation/progress.png'
import submit from '@/images/navigation/submit.png'
import type { MenuProps } from 'antd'
import { Dropdown, Space } from 'antd';
import InspectionProgess from '@/components/InspectionProgess';
import { connect } from 'react-redux'
import { OnSubmitReport, GetInspectionDetailPer, DeleteFile, GetFile } from '@/services/inspectionReport';
import getInspectionDetail from '@/utils/refreshPage';
const { TextArea } = Input
const { confirm } = Modal;
const SubmitReport: React.FC<{
    completePercent: number
    completeArr: CompleteArrType
    reportStatus: ReportStatusType
    setCompleteArr: any
    setReportStatus: any
    setCompletePercent: any
    issueData: IssueDataType
    setIssueData: any
    uploadImgSelect: PhoneUploadImgType
    setUploadImgSelect: any
    conditionalPassList: any
    setConditionalPassList: Function
    imageStep:boolean
    setImageStep:Function
}> = ({
    completePercent,
    completeArr,
    reportStatus,
    setReportStatus,
    setCompletePercent,
    issueData,
    setIssueData,
    setCompleteArr,
    uploadImgSelect,
    setUploadImgSelect,
    conditionalPassList,
    setConditionalPassList,
    imageStep,
    setImageStep }) => {
        const navigate = useNavigate()
        const [active, setActive] = useState<number>()
        const [changeSatus, setChangeStatus] = useState(false)
        const [submitLoading, setSubmitLoading] = useState(false)
        const [fileLoading, setFileLoading] = useState(false)
        const [getFileStatus, setGetFileStatus] = useState(false)
        const [progressInfo, setProgressInfo] = useState<inspectionReportAPI.GetInspectionDetailPerResponse>({
            count: 0,
            total: 0,
            imageStep: false,
            resultStep: false,
            percent: 0,
            info: []
        })
        const resultArr = [
            {
                img1: pass1,
                img2: pass2,
                text: 'Pass'
            },
            {
                img1: pending1,
                img2: pending2,
                text: 'Pending'
            },
            {
                img1: fail1,
                img2: fail2,
                text: 'Fail'
            },
        ]
        const [failureCode, setFailureCode] = useState({
            functionality: false,
            structure: false,
            appearance: false,
            certification: false,
            packaging: false,
            labeling: false

        })
        const [open, setOpen] = useState(false)
        const showConfirm = () => {
            // confirm({
            //   title: 'Any modifications cannot be made once submitted. Do you want to submit?',
            //   icon: <ExclamationCircleFilled />,
            //   content: '',
            //   className:"confimSubmit",
            //   okText: "Yes",
            //   cancelText: "No",
            //   onOk() {
            //     saveSubmit(1, true)
            //   },
            //   onCancel() {

            //   },
            // });
            saveSubmit(1, true)
        };
        const items: MenuProps['items'] = localStorage.getItem('status') === "Submitted" ? [


            {
                label: (
                    <div className='dropdown_' onClick={() => navigate('/inspectionList')}>
                        <img src={exit} alt="" />
                        Exit
                    </div>
                ),
                key: '1',
            },
            {
                label: (
                    <div className='dropdown_' onClick={() => setOpen(true)}>
                        <img src={progress} alt="" />
                        Inspection Progress
                    </div>
                ),
                key: "2"
            },
            {
                label: (
                    <div className='dropdown_' onClick={() => saveSubmit(1, true)}>
                        <img src={submit} alt="" />
                        Submit Report
                    </div>
                ),
                key: "3"
            }
        ] : [
            {
                label: (
                    <div className='dropdown_' onClick={() => saveSubmit(0, false)}>
                        <img src={save} alt="" />
                        Save As Draft
                    </div>
                ),
                key: '0',
            },
            {
                label: (
                    <div className='dropdown_' onClick={() => saveSubmit(0, true)}>
                        <img src={exit} alt="" />
                        Save & Exit
                    </div>
                ),
                key: '1',
            },
            {
                label: (
                    <div className='dropdown_' onClick={() => setOpen(true)}>
                        <img src={progress} alt="" />
                        Inspection Progress
                    </div>
                ),
                key: "2"
            },
            {
                label: (
                    <div className='dropdown_'
                        onClick={showConfirm}
                    >
                        <img src={submit} alt="" />
                        Submit Report
                    </div>
                ),
                key: "4"
            }
        ];
        const setImgData = () => {
            completeArr.forEach(item => {
                uploadImgSelect.forEach((item2 => {
                    if (item.argumentId === item2.argumentId) {
                        item.fileList = item2.imgList
                    }
                }))
            })
        }
        const getFileList = async (fileIdArr: number[]) => {
            setFileLoading(true)
            const arr: any = fileIdArr.map(async (fileId: number) => {
                // fileList: [1,2,3].map(async (fileId: number) => {
                const res: any = await GetFile({ params: { fileId } })
                const contentDisposition = res.headers['content-disposition']
                const filenameMatches = /filename=([^;]+)/g.exec(contentDisposition);
                let name = filenameMatches ? filenameMatches[1] : "Unknown"
                delete res.headers
                return {
                    uid: fileId,
                    name,
                    url: URL.createObjectURL(res),
                    status: 'done',
                    response: { data: [fileId] }
                }
            })
            Promise.all(arr).then(res => {
                setGetFileStatus(true)
                setConditionalPassList([...res])
                setFileLoading(false)


            })
        }
        const saveSubmit = async (status: number, open: boolean = false, isLoading: boolean = false) => {
            setSubmitLoading(true)
            let steps = JSON.parse(localStorage.getItem("steps") || "[]")
            let inspectedQuantity = Number(localStorage.getItem('inspectedQuantity') || 0)
            setImgData()
            let details = completeArr.map((item, index) => {
                return {
                    argumentId: +item.argumentId,
                    title: steps[index].title,
                    value: item.value || null,
                    quality: item.quality === "NA" ? 0 :
                        (item.quality === "AC" ? 1 :
                            (item.quality === "MI" ? 2 :
                                (item.quality === "MA" ? 3 :
                                    (item.quality === "PD" ? 4 : null)))),
                    imageIds: item?.fileList?.map(item => item?.response?.data[0]).length == 0 ? null : item?.fileList?.map(item => item?.response?.data[0]),
                    comment: item.comment
                }
            })
            let failureCodes: any = []
            let statusArr = reportStatus.failureCode.status
            let textArr = reportStatus.failureCode.text
            for (let key in statusArr) {
                if (statusArr[key]) {
                    failureCodes.push({
                        failureCode: key === "Functionality" ? 0 :
                            (key === "Structure" ? 1 :
                                (key === "Appearance" ? 2 :
                                    (key === "Certification" ? 3 :
                                        (key === "Packaging" ? 4 : 5)))),
                        comment: textArr[key]
                    })
                }
            }
            let reportId = Number(localStorage.getItem('reportId') || 0)
            let data: ReportDataType = {
                // projectId: Number(sessionStorage.getItem("projectId")),
                purchaseOrder_InspectionProjectId: Number(localStorage.getItem("purchaseOrder_InspectionProjectId") || 0),
                poQuantity: Number(localStorage.getItem("poQuantity") || 0),
                aqlQty:Number(localStorage.getItem("aqlQty") || 0),
                itemNo: localStorage.getItem('itemNo') || "",
                vendor: localStorage.getItem('vendor') || "",
                poNumber: localStorage.getItem('PONumber') || "",
                inspectedQuantity,
                details,
                result: reportStatus.result === "Fail" ? 0 : (reportStatus.result === "Pending" ? 1 : (reportStatus.result === "Pass" ? 2 : (reportStatus.result === "Conditional Pass" ? 3 : null))),
                failureCodes: failureCodes.length == 0 ? null : failureCodes,
                comment: reportStatus.comment,
                status,
                critical: issueData.Critical,
                criticalAccept: issueData.CriticalAccept,
                majorAccept: issueData.MajorAccept,
                majorReject: issueData.MajorReject,
                minorAccept: issueData.MinorAccept,
                minorReject: issueData.MinorReject,
                producedQuantity: +(localStorage.getItem("producedQuantity") || 0)
            }
            if (reportId) {
                data.reportId = reportId
            }
            if (reportStatus.result === 'Conditional Pass') {
                data.conditionalPassComment = reportStatus.conditionalPassComment
                data.conditionalPassFiles = conditionalPassList.map((item: any) => item.response.data[0])

            }
            try {
                const res: any = await OnSubmitReport({ data })
                if (res.isSuccess) {
                    !isLoading && message.success("Successfully.")
                    setSubmitLoading(false)
                    sessionStorage.setItem("reportId", res.data)
                    localStorage.setItem("reportId", res.data)
                    GetProgressBar(res.data)
                    if (open) {
                        initializeData()
                        navigate('/inspectionList')
                    }

                } else {
                    !isLoading && message.error(res.message)
                    setSubmitLoading(false)
                }
            } catch (error: any) {
                if (error.response.status === 400) {
                    !isLoading && message.error("Please wait until the image is uploaded before submitting")
                } else {
                    !isLoading && message.error(error.response.data.title);
                }
                setSubmitLoading(false)
            }

        }
        const initializeData = () => {
            setCompleteArr([])
            setCompletePercent(0)
            setReportStatus({
                result: "",
                failureCode: {
                    status: {
                        Functionality: false,
                        Structure: false,
                        Appearance: false,
                        Certification: false,
                        Packaging: false,
                        Labeling: false
                    },
                    text: {
                        Functionality: "",
                        Structure: "",
                        Appearance: "",
                        Certification: "",
                        Packaging: "",
                        Labeling: ""
                    }
                },
                comment: ""
            })
            sessionStorage.clear()
            let Authorization = localStorage.getItem("Authorization") || ""
            localStorage.clear()
            localStorage.setItem("Authorization", Authorization)
        }
        // function checkObjectValues(obj: any) {
        //     return Object.values(obj).every((val: any) => val > 0);
        // }
        function checkObjectValues(obj: any) {
            return Object.values(obj).reduce((acc: any, val) => acc + val, 0);
        }
        const deepCloneArray: any = (arr: any) => {
            const clonedArray = [];
            for (let i = 0; i < arr.length; i++) {
                if (Array.isArray(arr[i])) {
                    clonedArray[i] = deepCloneArray(arr[i]);
                } else {
                    clonedArray[i] = arr[i];
                }
            }
            return clonedArray;
        }

        const steps = JSON.parse(localStorage.getItem('steps') || "[]")
        const getDefaultData = async () => {
            const steps = JSON.parse(localStorage.getItem('steps') || "[]")
            completeArr = steps?.map((item: any, index: number) => {
                return ({
                    argumentId: item.argumentId,
                    value: item.value || "",
                    mustAddPhoto: (item.quality === 2 || item.quality === 3) ? true : item.mustAddPhoto,
                    quality: item.quality === 0 ? "NA" :
                        item.quality === 1 ? "AC" :
                            item.quality === 2 ? "MI" :
                                item.quality === 3 ? "MA" : "",
                    // fileList: item.imageIds ? item.imageIds.map(async (fileId: number) => {
                    //     setSubmitLoading(true)
                    //     const res: any = await GetFile({ params: { fileId } })
                    //     const contentDisposition = res.headers['content-disposition']
                    //     const filenameMatches = /filename=([^;]+)/g.exec(contentDisposition);
                    //     let name = filenameMatches ? filenameMatches[1] : "Unknown"
                    //     console.log(res, name);
                    //     delete res.headers
                    //     return {
                    //         name,
                    //         url: URL.createObjectURL(res),
                    //         status: 'done',
                    //         response: {
                    //             data: [fileId]
                    //         }
                    //     }
                    // }) : [],
                    parentArgumentTitle: item.parentArgumentTitle,
                    fileList: item?.imageIds.map((fileId: number) => ({
                        response: {
                            data: [fileId]
                        }
                    })),
                    // fileList:item?.imageIds || [],
                    comment: item.comment || "",
                    loadingImage: false,
                    uploadStatus: false,
                })
            })
            setCompleteArr(deepCloneArray(completeArr))
            // Promise.all(completeArr.map(async (item) => {
            //     item.fileList = await Promise.all(item.fileList);
            //     return item;
            // })).then((updatedCompleteArr) => {
            //     setSubmitLoading(false)
            //     setCompleteArr(deepCloneArray(updatedCompleteArr));
            // });
            let objStatus = reportStatus.failureCode.status
            let objText = reportStatus.failureCode.text
            let failureCodesArr = JSON.parse(localStorage.getItem("failureCodes") || "[]")
            let result = Number(localStorage.getItem("result") || -1)
            let comment = localStorage.getItem('comment') || ""
            let conditionalPassComment = localStorage.getItem('conditionalPassComment') || ""
            reportStatus.comment = comment
            reportStatus.conditionalPassComment = conditionalPassComment
            let conditionalPassFiles = JSON.parse(localStorage.getItem('conditionalPassFiles') || "[]")
            getFileList(conditionalPassFiles.map((x: any) => x.response.data[0]))
            reportStatus.result = result === 0 ? "Fail" :
                (result === 1 ? "Pending" :
                    (result === 2 ? "Pass" :
                        (result === 3 ? "Conditional Pass" : "")))
            failureCodesArr?.forEach((item: any) => {
                if (item.failureCode === 0) {
                    objStatus["Functionality"] = true
                    objText["Functionality"] = item.comment
                } else if (item.failureCode === 1) {
                    objStatus["Structure"] = true
                    objText["Structure"] = item.comment
                } else if (item.failureCode === 2) {
                    objStatus["Appearance"] = true
                    objText["Appearance"] = item.comment
                } else if (item.failureCode === 3) {
                    objStatus["Certification"] = true
                    objText["Certification"] = item.comment
                } else if (item.failureCode === 4) {
                    objStatus["Packaging"] = true
                    objText["Packaging"] = item.comment
                } else if (item.failureCode === 5) {
                    objStatus["Labeling"] = true
                    objText["Labeling"] = item.comment
                }

            })
            setReportStatus(reportStatus)
            let issueObj = JSON.parse(localStorage.getItem("issueObj") || "{}")
            setIssueData({
                Critical: +issueObj?.critical || 0,
                CriticalAccept: +issueObj?.criticalAccept || 0,
                MajorAccept: +issueObj?.majorAccept || 0,
                MajorReject: +issueObj?.majorReject || 0,
                MinorAccept: +issueObj?.minorAccept || 0,
                MinorReject: +issueObj?.minorReject || 0,
            })
            GetProgressBar(+(localStorage.getItem('reportId') || 0))
            setChangeStatus(!changeSatus)
        }
        useEffect(() => {
            if (completeArr?.length <= 0) {
                setSubmitLoading(true)
                getInspectionDetail().then(() => {
                    setSubmitLoading(false)
                    getDefaultData()
                })
            } else {
                GetProgressBar(+(localStorage.getItem('reportId') || 0))
                getFileList(conditionalPassList.map((x: any) => x.response.data[0]))
            }

        }, [])
        const GetProgressBar = async (reportId: number) => {
            try {
                const res = await GetInspectionDetailPer({ params: { inspectionReportid: reportId } })
                if (res?.isSuccess) {
                    setCompletePercent(res?.data.percent! * 100)
                    setImageStep(res.data.imageStep)
                    setProgressInfo(JSON.parse(JSON.stringify(res?.data)))
                } else {
                    message.error(res?.message)
                }
            } catch (error) {
                console.log(error);
                // message.error()
            }
        }
        // useEffect(() => {
        //     completePercent = 0
        //     // let statusComplete = steps.some((obj: any, index: number) => obj.parentArgumentTitle === "LED Dimmable" && completeArr[index].quality !== "" && completeArr[index].fileList.length > 0)
        //     // console.log(statusComplete);

        //     // if (statusComplete) {
        //     //     steps.forEach((item: any, index: number) => {
        //     //         if (item.parentArgumentTitle === "LED Dimmable" && completeArr[index]?.quality !== "" && completeArr[index]?.fileList.length > 0) {
        //     //             completeArr.forEach((item2, index2) => {
        //     //                 if (steps[index2]?.parentArgumentTitle === "LED Dimmable" && item2.quality === "") {
        //     //                     item2.quality = "true"
        //     //                     item2.mustAddPhoto = false
        //     //                 } else if (steps[index2]?.parentArgumentTitle === "LED Dimmable") {
        //     //                     item2.mustAddPhoto = false
        //     //                 }
        //     //             })
        //     //             setCompleteArr(deepCloneArray(completeArr))
        //     //             return;
        //     //         }
        //     //     })
        //     // } else {
        //     //     steps.forEach((item: any, index: number) => {
        //     //         if (item.parentArgumentTitle === "LED Dimmable") {
        //     //             completeArr.forEach((item2, index2) => {
        //     //                 if (steps[index2]?.parentArgumentTitle === "LED Dimmable") {
        //     //                     item2.mustAddPhoto = true
        //     //                 }
        //     //             })
        //     //             setCompleteArr(deepCloneArray(completeArr))
        //     //             return;
        //     //         }
        //     //     })
        //     // }
        //     completeArr?.forEach((item, index) => {
        //         if (item.quality !== "" && item.quality !== "PD" && (item.mustAddPhoto ? item.fileList.length > 0 : true)) {
        //             completePercent += 1
        //         }
        //     })
        //     const result = Object.values(reportStatus.failureCode.text).some(value => value !== null && value !== undefined && value !== "");
        //     // let num = 0
        //     // for (let key in issueData) {
        //     //     num += issueData[key]
        //     // }
        //     // if (reportStatus.result  && num===Number(sessionStorage.getItem("inspectedQuantity")||0)) {
        //     //     completePercent += 1
        //     // }
        //     if (reportStatus.result && reportStatus.result !== 'Pending' &&
        //         // checkObjectValues(issueData) === Number(sessionStorage.getItem("inspectedQuantity") || 0) &&
        //         (reportStatus.result === "Fail" ? result : true)) {
        //         completePercent += 1
        //     }
        //     setCompletePercent(completePercent / (completeArr?.length + 1) * 100)

        // }, [reportStatus.result, changeSatus])
        const prevPage = () => {
            const status = localStorage.getItem('status') !== "Submitted" ? 0 : 1
            saveSubmit(status, false, true)
            setChangeStatus(!changeSatus)
            navigate(`/uploadImg`)
        }
        return (
            <>
                <Spin spinning={submitLoading}>
                    <section className='inspectionReportPhone'>
                        <div className='report_hearder'>
                            <div className='phone_header'>
                                <span></span>
                                <span>Inspection Report</span>
                                <Dropdown menu={{ items }}>
                                    <a onClick={(e) => e.preventDefault()}>
                                        <Space>
                                            <img style={{ width: "5vw" }} src={more} alt="" />
                                        </Space>
                                    </a>
                                </Dropdown>
                            </div>
                            <ul className="progress" onClick={() => {
                                setOpen(true)
                            }}>
                                <li>
                                    <span>Current</span>
                                    <p>{steps.length + 2}/{steps.length + 2}</p>
                                </li>
                                <li>
                                    <span></span>
                                    <div className='progress_bar'>
                                        <div style={{ width: completePercent + "%" }}></div>
                                    </div>
                                </li>
                                <li>
                                    <span>Completed</span>
                                    <p>{completePercent.toFixed(0)}%</p>
                                </li>
                            </ul>
                        </div>
                        <div className='content_'>
                            <div className='report_phone_content'>
                                <h1>Inspection Result</h1>
                                <ul className='Inspection_Result'>
                                    {resultArr.map((item, index) => (
                                        <li
                                            key={index}
                                            className={reportStatus.result == item.text ? `active${index}` : ""}
                                            onClick={() => {
                                                // setActive(index)
                                                reportStatus.result = item.text
                                                setReportStatus(reportStatus)

                                            }}
                                        >
                                            <img src={reportStatus.result === item.text ? item.img1 : item.img2} alt="" />
                                            <span>{item.text}</span>
                                        </li>
                                    ))}
                                </ul>
                                {localStorage.getItem('status') === "Submitted" && reportStatus.result !=="Pass" && <div className={`ConditionalPass ${reportStatus.result === 'Conditional Pass' && "ConditionalPassActive"}`} onClick={() => setReportStatus({ ...reportStatus, result: 'Conditional Pass' })}>
                                    <img src={reportStatus.result === 'Conditional Pass' ? pass1 : pass2} alt="" />
                                    <span>Conditional Pass</span>
                                </div>}
                                
                                <h3>{reportStatus.result === "Fail" && "*"} Failure Code</h3>
                                <ul className="failure_code phone_failure_code">
                                    {["Functionality", "Structure", "Appearance", "Certification", "Packaging", "Labeling"].map((item, index) => (
                                        <li key={index}>
                                            <Checkbox
                                                checked={reportStatus?.failureCode?.status[item]}
                                                onChange={(e) => {
                                                    if (!e.target.checked) {
                                                        reportStatus.failureCode.text[item] = ""
                                                    }
                                                    reportStatus.failureCode.status[item] = e.target.checked
                                                    setReportStatus(reportStatus)
                                                    setChangeStatus(!changeSatus)
                                                }} >{item}</Checkbox>
                                            <TextArea
                                                style={{ display: reportStatus?.failureCode?.status[item] ? "" : "none" }}
                                                value={reportStatus?.failureCode?.text[item]}
                                                onChange={(e) => {
                                                    reportStatus.failureCode.text[item] = e.target.value
                                                    setReportStatus(reportStatus)
                                                    setChangeStatus(!changeSatus)

                                                }}
                                            />
                                        </li>
                                    ))}
                                </ul>


                            </div>
                            <div className='issueBox'>
                                {/* <h3 style={{ color: "#4D5E80", marginBottom: "1em" }}>Inspected QTY  ({Number(sessionStorage.getItem('inspectedQuantity') || 0)})</h3>
                                {!(checkObjectValues(issueData) === Number(sessionStorage.getItem("inspectedQuantity") || 0)) && <p style={{ marginBottom: "0.5em",color:"#EF4B24" }}>Total quantity does not match the inspected quantity.</p>} */}
                                <ul className='issue'>
                                    <li>Critical Issue</li>
                                    <li>
                                        <label htmlFor="">
                                            <span>Critical:</span>
                                            <InputNumber onFocus={(e) => e.target.select()} value={issueData.Critical} onChange={(value) => {
                                                issueData.Critical = value || 0
                                                setIssueData(JSON.parse(JSON.stringify(issueData)))
                                                setChangeStatus(!changeSatus)
                                            }} />
                                        </label>
                                        <label htmlFor="">
                                            <span>Accept:</span>
                                            <InputNumber onFocus={(e) => e.target.select()} value={issueData.CriticalAccept} onChange={(value) => {
                                                issueData.CriticalAccept = value || 0
                                                setIssueData(JSON.parse(JSON.stringify(issueData)))
                                                setChangeStatus(!changeSatus)
                                            }} />
                                        </label>
                                    </li>
                                    <li>Major Issue</li>
                                    <li>
                                        <label htmlFor="">
                                            <span>Accept:</span>
                                            <InputNumber onFocus={(e) => e.target.select()} value={issueData.MajorAccept} onChange={(value) => {
                                                issueData.MajorAccept = value || 0
                                                setIssueData(JSON.parse(JSON.stringify(issueData)))
                                                setChangeStatus(!changeSatus)
                                            }} />
                                        </label>
                                        <label htmlFor="">
                                            <span>Reject:</span>
                                            <InputNumber onFocus={(e) => e.target.select()} value={issueData.MajorReject} onChange={(value) => {
                                                issueData.MajorReject = value || 0
                                                setIssueData(JSON.parse(JSON.stringify(issueData)))
                                                setChangeStatus(!changeSatus)
                                            }} />
                                        </label>
                                    </li>
                                    <li>Minor Issue</li>
                                    <li>
                                        <label htmlFor="">
                                            <span>Accept:</span>
                                            <InputNumber onFocus={(e) => e.target.select()} value={issueData.MinorAccept} onChange={(value) => {
                                                issueData.MinorAccept = value || 0
                                                setIssueData(JSON.parse(JSON.stringify(issueData)))
                                                setChangeStatus(!changeSatus)
                                            }} />
                                        </label>
                                        <label htmlFor="">
                                            <span>Reject:</span>
                                            <InputNumber onFocus={(e) => e.target.select()} value={issueData.MinorReject} onChange={(value) => {
                                                issueData.MinorReject = value || 0
                                                setIssueData(JSON.parse(JSON.stringify(issueData)))
                                                setChangeStatus(!changeSatus)
                                            }} />
                                        </label>
                                    </li>
                                </ul>
                            </div>
                            <div className='comment'>
                                <h5>Comment </h5>
                                <TextArea value={reportStatus.comment} style={{ height: 130 }} onChange={(e) => setReportStatus({ ...reportStatus, comment: e.target.value })} />
                            </div>
                            {reportStatus.result === "Conditional Pass" && <div className='comment '>
                                <h5>Conditional Comment <span style={{ color: "red" }}>*</span></h5>
                                <TextArea value={reportStatus.conditionalPassComment} style={{ height: 130 }} onChange={(e) => setReportStatus({ ...reportStatus, conditionalPassComment: e.target.value })} />
                                <h5 style={{ margin: '10px 0' }}>Upload Conditional File <span style={{ color: "red" }}>*</span></h5>
                                <div>
                                    <Spin spinning={fileLoading}>
                                        <Upload
                                            name='formFiles'
                                            action='api/Common/UploadFile'
                                            headers={{
                                                Authorization: localStorage.getItem('Authorization') || '',
                                            }}
                                            multiple
                                            fileList={conditionalPassList}
                                            onChange={(info) => {
                                                setConditionalPassList([...info.fileList])
                                                if (info.file.status !== 'uploading') {
                                                    console.log(info.file, info.fileList);
                                                }
                                                if (info.file.status === 'done') {
                                                    setFileLoading(false)
                                                    message.success(`${info.file.name} file uploaded successfully`);
                                                } else if (info.file.status === 'error') {
                                                    message.error(`${info.file.name} file upload failed.`);
                                                    setFileLoading(false)
                                                } else {
                                                    setFileLoading(true)
                                                }
                                            }}
                                            itemRender={(originNode, file: any) => {
                                                if (!file.url && file?.originFileObj) {
                                                    file.url = URL.createObjectURL(file?.originFileObj)
                                                }
                                                return (
                                                    <>
                                                        <div className="uploadBox_">
                                                            <a
                                                                onClick={(e) => e.stopPropagation()}
                                                                href={file.url}
                                                                download={file.name}
                                                                className="download-link"
                                                            >
                                                                {file.name}
                                                            </a>
                                                            <span>
                                                                <DeleteTwoTone onClick={async () => {
                                                                    const newFileList = [...conditionalPassList];
                                                                    newFileList.splice(newFileList.findIndex(item => item.uid === file.uid), 1);
                                                                    conditionalPassList = newFileList;
                                                                    setConditionalPassList(deepCloneArray(newFileList))


                                                                }} />
                                                            </span>
                                                        </div>
                                                    </>
                                                )
                                            }}
                                        >
                                            <Button icon={<UploadOutlined />}>Click to Upload</Button>
                                        </Upload>
                                    </Spin>
                                </div>
                            </div>}
                            {+(localStorage.getItem("inspectedQuantity") || 0) !== +(localStorage.getItem("poQuantity") || 0) &&
                                <p className='warn' >
                                    <img src={warn} alt="" />
                                    <span>Inspected QTY is inconsistent with PO QTY</span>
                                </p>}

                            {/* <p className='warn' style={{ display: completePercent !== 100 ? "" : "none" }}>
                                <img src={warn} alt="" />
                                <span>There are unfinished items, please ensure all are completed before submitting.</span>
                            </p> */}
                            <div className='save_submit' style={{ marginTop: "10px" }}>
                                <Button style={{ marginTop: completePercent !== 100 ? "" : "2.778vw" }} onClick={showConfirm} type='primary'>Save & Submit</Button>
                                {/* <Button style={{ marginTop: completePercent !== 100 ? "" : "2.778vw" }} onClick={() => console.log(conditionalPassList)
                                } type='primary'>Save & Submit</Button> */}
                                <Popconfirm
                                    style={{ maxWidth: "90vw" }}
                                    title="Prev page"
                                    disabled={localStorage.getItem('status') !== "Submitted"}
                                    description="Submitting the page will occur. Are you sure you want to proceed??"
                                    onConfirm={() => {
                                        if (fileLoading) {
                                            message.error('Please wait for the file upload to complete')
                                            return
                                        }
                                        prevPage()
                                    }}
                                    okText="Yes"
                                    cancelText="No"
                                >
                                    <Button style={{
                                        width: localStorage.getItem('status') === "Submitted" ? '100%' : ""
                                    }} onClick={() => {
                                        if (localStorage.getItem('status') === "Submitted") {
                                            return
                                        }
                                        if (fileLoading) {
                                            message.error('Please wait for the file upload to complete')
                                            return
                                        }
                                        prevPage()
                                    }}>Prev</Button>
                                </Popconfirm>
                                {localStorage.getItem('status') !== "Submitted" && <Button onClick={() => saveSubmit(0, false)}>Save As Draft</Button>}
                            </div>
                        </div>

                    </section>
                </Spin>
                {open ? <InspectionProgess imageStep={imageStep} progressInfo={progressInfo} setOpen={setOpen} reportStatus={reportStatus} issueData={issueData} completeArr={completeArr} /> : <></>}
            </>
        )
    }
const mapStateToProps = (state: any) => {
    return {
        completeArr: state.completeArr,
        completePercent: state.completePercent,
        reportStatus: state.reportStatus,
        issueData: state.issueData,
        uploadImgSelect: state.uploadImgSelect,
        conditionalPassList: state.conditionalPassList,
        imageStep:state.imageStep
    }
}
const mapDispatchToProps = (dispatch: any) => {
    return {
        setCompleteArr(arr: any) {
            let action = { type: "completeArr", arr }
            dispatch(action)
        },
        setUploadImgSelect(imgArr: PhoneUploadImgType) {
            let action = { type: "UploadImgArr", imgArr }
            dispatch(action)
        },
        setCompletePercent(num: number) {
            let action = { type: "num", num }
            dispatch(action)
        },
        setReportStatus(result: ReportStatusType) {
            let action = { type: "reportStatus", result }
            dispatch(action)
        },
        setIssueData(issueData: IssueDataType) {
            let action = { type: "issueData", issueData }
            dispatch(action)
        },
        setConditionalPassList(conditionalPassList: any[]) {
            let action = { type: "conditionalPass", conditionalPassList }
            dispatch(action)
        },
        setImageStep(imageStep: boolean) {
            let action = { type: "imageStep", imageStep }
            dispatch(action)
        }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(SubmitReport);