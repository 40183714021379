import { useEffect, useState } from "react"
import "@/styles/addPoToInspection.less"
import { Button, message, Spin } from "antd"
import type { ColumnsType } from 'antd/es/table';
import close from '@/images/navigation/big_close.svg'
import { Input, Select, Form, DatePicker, Table, Modal, Upload } from 'antd';
import { GetPODetail, AddPOToInspection } from "@/services/inspection";
import { GetFile, DeleteFile } from "@/services/inspectionReport";
import { InboxOutlined, CloudUploadOutlined, DeleteTwoTone,SendOutlined, ExclamationCircleFilled } from '@ant-design/icons';
import type { UploadProps } from 'antd';
import { GetInspectionRate } from "@/services/inspectionRate";
import { GetPurchaseOrder } from "@/services/purchaseOrder"
import report from '@/images/navigation/report.png'
import { GetCheckList } from '@/services/checkList';
import moment from "moment";
import { ExportOutlined } from '@ant-design/icons'
import { GetInspectorList } from "@/services/inspection";
import { EditInspectionPurchaseOrder } from "@/services/purchaseOrder";
import { Export } from "@/services/inspectionReport";
import { useSearchParams, useNavigate, Link } from "react-router-dom";
import SendEmail from "../conditionalPassReport/components/SendEmail";
const { Dragger } = Upload;
interface ItemDataType {
    itemNo: string,
    description: string,
    quantity: string,
    comment: string,
    // fileList: any
    usedInProject: boolean
    canCreateInspection:boolean
    // uploadFileIds: number[]
    // getFileStatus: boolean

}
interface ReportDataType {
    reportId: string
    itemNo: string,
    inspectionDate: string,
    inspectedQty: string,
    passQty: string,
    rejectQty: string,
    InspectionResult: string,
    comment: string,
    isFinal: boolean
    inspectResult: string
    failureCode: string,
    ViewReport: string,
    uploadFileIds: number[]
    producedQty: number,
    getFileStatus: boolean
    fileList: any
    poQty: number
    verified:boolean
}
const EditPo: React.FC = () => {
    const [form] = Form.useForm()
    const [poNumArr, setPoNumArr] = useState<{ value: string; label: string }[]>([])
    const [checkList, setCheckList] = useState([])
    const [itemData, setItemData] = useState<ItemDataType[]>([])
    const [inspectionRateList, setInspectionRateList] = useState([])
    const [submitLoading, setSubmitLoading] = useState(false)
    const [fileLoading, setFileLoading] = useState(false)
    const [reportOpen, setReportOpen] = useState(false)
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const [assigntoInspectorOptions, setAssigntoInspectorOptions] = useState([])
    const [search] = useSearchParams()
    const inspectionId = search.get('inspectionId')
    const poNumber = search.get('poNumber')
    const itemID = search.get('itemId')
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [itemNo, setItemNo] = useState('')
    const navigate = useNavigate()
    const [reportID,setReportId] = useState(0)
    const [sendEmailStatus,setSendEmailStatus] = useState(false)
    const [reportParams, setReportParams] = useState({
        inspectionProjectId: inspectionId,
        ItemNo: "",
        PONumber: ""
    })
    const [reportsData, setReportsData] = useState<ReportDataType[]>([])
    const [loading, setLoading] = useState(false)
    const [fileIndex, setFileIndex] = useState(0)
    const [fileList, setFileList] = useState([])
    const [autoGenerate, setAutoGenerate] = useState(false)
    const onFinish = async (values: any) => {
        setSubmitLoading(true)
        const { poNumber, inspectionRateId, assignToInspector } = values
        if (selectedRowKeys.length === 0) {
            message.error('Please select at least one item')
            setSubmitLoading(false)
            return
        }
        try {
            const res: any = await EditInspectionPurchaseOrder({
                data: {
                    PurchaseOrder_InspectionProjectId: itemID,
                    ProjectId: inspectionId,
                    poNumber,
                    inspectionRateId,
                    inspectionId: +assignToInspector,
                    items: selectedRowKeys
                }
            })
            if (res.isSuccess) {
                setSubmitLoading(false)
                message.success("Successfully.")
                // navigate('/inspections')
                navigate(-1)
                // getData()
                // closePage()
            } else {
                message.error(res.message)
                setSubmitLoading(false)

            }
        } catch (error: any) {
            console.log(error);
            setSubmitLoading(false)
            message.error(error.message)
        }
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };
    function getFileNameFromResponse(response:any) {
        // 从 Content-Disposition 响应头获取文件名
        let fileName = ''
        const contentDispositionHeader = response.headers.get('Content-Disposition');
        if (contentDispositionHeader) {
          const matches = contentDispositionHeader.match(/filename=([^;]+)/);
          if (matches && matches.length > 1) {
            fileName = matches[1].trim();
          }
        }
      
        if(fileName){
            return fileName.replace(/"/g, '')
        }else{
            return 'downloaded.file';
        }
        
      }
    const itemColumns: ColumnsType<ItemDataType> = [
        {
            title: 'Item no.',
            dataIndex: 'itemNo',
            key: 'itemNo',
            width: 200,
            sorter: (a, b) => +a.itemNo.replace('-', '') - +b.itemNo.replace('-', ''),
        },
        {
            title: 'Desc.',
            dataIndex: 'description',
            key: 'description',
            sorter: (a, b) => {
                const aFirstLetter = a.description[0].toLowerCase();
                const bFirstLetter = b.description[0].toLowerCase();
                if (aFirstLetter < bFirstLetter) {
                    return -1;
                }
                if (aFirstLetter > bFirstLetter) {
                    return 1;
                }
                return 0;
            },
        },
        {
            title: 'QTY',
            dataIndex: "quantity",
            key: "quantity",
            align: "right",
            width: 100,
            sorter: (a, b) => +a.quantity - +b.quantity,
            render: (text) => (
                <span>{(text).toLocaleString()}</span>
            )
        },
        {
            title: 'Comment',
            dataIndex: 'comment',
            key: 'comment',
            sorter: (a, b) => {
                const aFirstLetter = a.comment[0]?.toLowerCase();
                const bFirstLetter = b.comment[0]?.toLowerCase();
                if (aFirstLetter < bFirstLetter) {
                    return -1;
                }
                if (aFirstLetter > bFirstLetter) {
                    return 1;
                }
                return 0;
            },
        },
        // {
        //     title: 'Upload',
        //     dataIndex: 'upload',
        //     key: 'upload',
        //     render: (text, record, index) => {
        //         return (
        //             selectedRowKeys.some((item: any) => item.itemNo === record.itemNo) && record.usedInProject && <div onClick={() => {
        //                 setFileIndex(index)
        //                 setIsModalOpen(true)
        //                 setFileList(itemData[fileIndex]?.fileList)
        //                 setItemNo(record.itemNo)
        //                 !record.getFileStatus && getFileList(record.uploadFileIds, index)
        //             }}><CloudUploadOutlined title="Upload File" style={{ fontSize: "20px", cursor: "pointer", marginLeft: "10px" }} /></div>
        //         )
        //     }
        // },
    ]
    const reportsColumns: ColumnsType<ReportDataType> = [
        {
            title: 'Item no.',
            dataIndex: 'itemNo',
            key: 'itemNo',
            sorter: (a, b) => +a.itemNo.replace('-', '') - +b.itemNo.replace('-', ''),
        },
        {
            title: 'Insection Date.',
            dataIndex: 'inspectionDate',
            key: 'inspectionDate',
            width: 130,
            sorter: (a, b) => +new Date(a.inspectionDate) - +new Date(b.inspectionDate)
        },
        {
            title: 'PO QTY',
            dataIndex: 'poQty',
            key: 'poQty',
            align: "right",
            sorter: (a, b) => +a.poQty - +b.poQty,
            render: (text) => <>{text.toLocaleString()}</>
        },
        {
            title: 'Produced QTY',
            dataIndex: 'producedQty',
            key: 'producedQty',
            align: "right",
            sorter: (a, b) => +a.producedQty - +b.producedQty,
            render: (text) => <>{text.toLocaleString()}</>
        },
        {
            title: 'Inspected QTY',
            dataIndex: 'inspectedQty',
            key: 'inspectedQty',
            align: "right",
            sorter: (a, b) => +a.inspectedQty - +b.inspectedQty,
            render: (text) => <>{text.toLocaleString()}</>
        },
        {
            title: 'Pass QTY',
            dataIndex: 'passQty',
            key: 'passQty',
            width: 130,
            align: "right",
            sorter: (a, b) => +a.passQty - +b.passQty,
        },
        {
            title: 'Reject QTY',
            dataIndex: 'rejectQty',
            key: 'rejectQty',
            width: 130,
            align: "right",
            sorter: (a, b) => +a.rejectQty - +b.rejectQty,
        },
        {
            title: 'Inspection Result',
            dataIndex: 'inspectResult',
            key: 'inspectResult',
            width:170,
            sorter: (a, b) => +a.inspectResult - +b.inspectResult,
            render: (text) => (
                <div className="flex" style={{ display: text === null ? "none" : "" }}>
                    <div className={text === 2 || text === 3 ? "qcStatus_pass" : text === 0 ? "qcStatus_fail" : "qcStatus_default"}>
                        <div className="round"></div>
                        <span>{text === 2 ? "Pass" : text === 0 ? "Fail" : text === 1 ? "Pending" : text === 3 ? "Conditional Pass":""}</span>
                    </div>
                </div>
            )
        },
        {
            title: 'Failure Code',
            dataIndex: 'failureCode',
            key: 'failureCode',
            sorter: (a, b) => {
                const aFirstLetter = a.failureCode[0]?.toLowerCase();
                const bFirstLetter = b.failureCode[0]?.toLowerCase();
                if (aFirstLetter < bFirstLetter) {
                    return -1;
                }
                if (aFirstLetter > bFirstLetter) {
                    return 1;
                }
                return 0;
            },
        },
        {
            title: 'Comment',
            dataIndex: 'comment',
            key: 'comment',
            width: 365,
            sorter: (a, b) => {
                const aFirstLetter = a.comment[0]?.toLowerCase();
                const bFirstLetter = b.comment[0]?.toLowerCase();
                if (aFirstLetter < bFirstLetter) {
                    return -1;
                }
                if (aFirstLetter > bFirstLetter) {
                    return 1;
                }
                return 0;
            },
        },
        {
            title: 'Verified',
            dataIndex: 'verified',
            key: 'verified',
            width: 100,
            render: (verified) => (
                verified ?
                    <svg className="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2318" width="24" height="24"><path d="M511.999994 0C229.205543 0 0.020822 229.226376 0.020822 512.020827c0 282.752797 229.184721 511.979173 511.979173 511.979173s511.979173-229.226376 511.979173-511.979173C1023.979167 229.226376 794.794446 0 511.999994 0zM815.371918 318.95082l-346.651263 461.201969c-10.830249 14.370907-27.32555 23.409999-45.27877 24.742952-1.582882 0.124964-3.12411 0.166619-4.665338 0.166619-16.328682 0-32.074198-6.373185-43.779197-17.911565l-192.903389-189.44604c-24.617988-24.20144-24.992881-63.731847-0.791441-88.349835 24.20144-24.659643 63.731847-24.951226 88.349835-0.833096l142.042875 139.501932 303.788472-404.2182c20.744091-27.575479 59.899605-33.115568 87.516739-12.413131C830.534266 252.219827 836.116009 291.375341 815.371918 318.95082z" fill="#9EDD8F" p-id="2319"></path></svg> :
                    <svg className="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3345" width="24" height="24"><path d="M512 0a512 512 0 0 0-512 512 512 512 0 0 0 512 512 512 512 0 0 0 512-512 512 512 0 0 0-512-512z" fill="#FD6B6D" p-id="3346"></path><path d="M513.755429 565.540571L359.277714 720.018286a39.058286 39.058286 0 0 1-55.296-0.073143 39.277714 39.277714 0 0 1 0.073143-55.442286l154.331429-154.331428-155.062857-155.136a36.571429 36.571429 0 0 1 51.712-51.785143l365.714285 365.714285a36.571429 36.571429 0 1 1-51.785143 51.785143L513.755429 565.540571z m157.549714-262.582857a35.254857 35.254857 0 1 1 49.737143 49.737143l-106.057143 108.982857a35.254857 35.254857 0 1 1-49.883429-49.810285l106.203429-108.982858z" fill="#FFFFFF" p-id="3347"></path></svg>
            )
        },
        {
            title: 'Action',
            dataIndex: 'Action',
            key: 'Action',
            width: 200,
            render: (_, record, index) => (
                <div
                    className="flex items-center"
                    onClick={() => {
                        setReportParams({
                            inspectionProjectId: inspectionId,
                            ItemNo: record.itemNo,
                            PONumber: form.getFieldValue("poNumber")
                        })
                        setReportOpen(true)
                    }}>
                    <Link title="Report" to={`/inspectionReport?itemId=${itemID}&itemNo=${record.itemNo}&poNumber=${form.getFieldValue("poNumber")}`}><div className="flex">
                        <img style={{ marginRight: "4px" }} className="mr-[4px]" src={report} />
                        {/* <span>Report</span> */}
                    </div></Link>
                    <a className="flex items-center" title="Export" onClick={async () => {
                        setLoading(true)
                        const res: any = await Export({ params: { PONumber: form.getFieldValue("poNumber"), ItemNo: record.itemNo, PurchaseOrder_InspectionProjectId: itemID } })
                        const link = document.createElement('a');
                        link.href = URL.createObjectURL(res);
                        // const contentDisposition = res.headers['content-disposition']
                        // const filenameMatches = /filename=([^;]+)/g.exec(contentDisposition);
                        // let name = filenameMatches ? filenameMatches[1] : "Unknown"
                        // let inspectorName = ""
                        // assigntoInspectorOptions.forEach((item: any) => {
                        //     if (item.value === form.getFieldValue("assignToInspector")) {
                        //         inspectorName = item.label
                        //     }
                        // })
                        // let version = form.getFieldValue('version')
                        // link.download = `${form.getFieldValue("poNumber")}_${inspectorName}_${record.itemNo}_${version}`
                        link.download = getFileNameFromResponse(res)
                        link.click();
                        setLoading(false)
                    }} style={{ marginLeft: "20px" }}>
                        <ExportOutlined width="20px" height="20px" style={{ fontSize: "30px", width: "20px", height: "20px", color: "#3B82F6" }} />
                        {/* <a>Export</a> */}
                    </a>
                    <CloudUploadOutlined onClick={() => {
                        setFileIndex(index)
                        setIsModalOpen(true)
                        setFileList(reportsData[fileIndex]?.fileList)
                        setItemNo(record.itemNo)
                        !record.getFileStatus && getFileList(record.uploadFileIds, index)
                    }} title="Upload File" style={{ fontSize: "23px", cursor: "pointer", marginLeft: "20px", color: "#3B82F6" }} />
                    <SendOutlined onClick={()=>{
                        setSendEmailStatus(true)
                        setReportId(+record.reportId)
                        }} title='Send Email' style={{fontSize:22,marginLeft:"20px",color: "#3B82F6",cursor:"pointer"}} />
                </div>
            )
        },
    ]
    const deepCloneArray: any = (arr: any) => {
        const clonedArray = [];
        for (let i = 0; i < arr.length; i++) {
            if (Array.isArray(arr[i])) {
                clonedArray[i] = deepCloneArray(arr[i]);
            } else {
                clonedArray[i] = arr[i];
            }
        }
        return clonedArray;
    }
    const changePoNum = async () => {
        setLoading(true)
        try {
            const res: any = await GetPODetail({
                params: {
                    PurchaseOrder_InspectionProjectId: itemID, PONumber: poNumber
                }
            })
            if (res.isSuccess) {
                setLoading(false)
                const { createdDate, checklistId, inspectionRateId, vendor, version, status, assignToInspector, assignToInspectorId, area, items, inspectionReports } = res.data
                const dataString = moment(createdDate, 'M/D/YYYY h:mm A')
                form.setFieldsValue({
                    poNumber, status: status.toString(), checklistId, inspectionRateId,
                    createdDate: dataString,
                    assignToInspector: assignToInspectorId, vendor, area, version
                })
                setItemData(items?.map((item: any) => ({
                    key: item.itemNo,
                    no: "",
                    fileList: [],
                    getFileStatus: false,
                    ...item
                })))
                let arr: any = []
                items.forEach((item: any) => {
                    if (item.usedInProject) {
                        arr.push({
                            itemNo: item.itemNo,
                            quantity: item.quantity
                        })
                    }
                })
                setAutoGenerate(res.data.autoGenerate)
                setSelectedRowKeys(JSON.parse(JSON.stringify(arr)))
                setReportsData(inspectionReports?.map((item: any) => ({
                    key: item.reportId,
                    fileList: [],
                    getFileStatus: false,
                    ...item
                })))

            } else {
                message.error(res.message)
            }
        } catch (error: any) {
            message.error(error.response.statusText);

        }
    }
    const getPoNumber = async () => {
        const res: any = await GetPurchaseOrder()
        if (res.isSuccess) {
            setPoNumArr(res?.data?.data?.map((item: any) => {
                return {
                    value: item.poNumber,
                    label: item.poNumber
                }
            }))
        }
    }
    const getCheckList = async () => {
        const res: any = await GetCheckList()
        if (res.isSuccess) {
            setCheckList(res?.data?.data?.map((item: any) => {
                return {
                    value: item.id,
                    label: item.name
                }
            }))
        }
    }
    const getRateList = async () => {
        const res: any = await GetInspectionRate()
        if (res.isSuccess) {
            setInspectionRateList(res?.data?.data?.map((item: any) => ({
                value: item.id,
                label: item.optionValue
            })))
        }
    }
    const getAssigntoInspector = async () => {
        const res: any = await GetInspectorList({ params: { InspectionProjectId: inspectionId } })
        if (res.isSuccess) {
            setAssigntoInspectorOptions(res.data.map((item: any) => ({ label: item.userName, value: item.id })))

        }
    }
    useEffect(() => {
        changePoNum()
        getPoNumber()
        getCheckList()
        getRateList()
        getAssigntoInspector()
    }, [])
    const onSelectChange = (newSelectedRowKeys: React.Key[], selectedRows: any) => {
        console.log('selectedRowKeys changed: ', selectedRows?.map((item: any) => ({
            itemNo: item.itemNo,
            quantity: item.quantity
        })));

        setSelectedRowKeys(selectedRows?.map((item: ItemDataType) => ({
            itemNo: item.itemNo,
            quantity: item.quantity
        })));
    };
    const rowSelection = {
        selectedRowKeys: selectedRowKeys.map((item: any) => item.itemNo),
        onChange: onSelectChange,
        // defaultSelectedRowKeys:selectedRowKeys.map((item: any) => item.itemNo),
        getCheckboxProps: (record: ItemDataType) => {
            let flag = false
            console.log(record);
            
            reportsData?.forEach(item => {
                if (item.itemNo === record.itemNo && item.inspectionDate) {
                    flag = true
                }

            })
            return ({
                disabled:record.canCreateInspection? (autoGenerate ? autoGenerate : form.getFieldValue("status") === "0" ? false :
                    (form.getFieldValue("status") === "3" ? record.usedInProject : flag)):true,
                name: record.itemNo
            })
        }
    };
    const props: UploadProps = {
        name: 'formFiles',
        // data:{itemNo},
        multiple: true,
        action: `api/Common/UploadFile?itemNo=${itemNo}&PurchaseOrder_InspectionProjectId=${itemID}`,
        headers: {
            Authorization: localStorage.getItem('Authorization') || ''
        },
        fileList: reportsData?.length ? reportsData[fileIndex]?.fileList : [],
        itemRender: (originNode, file: any) => {
            // const url = URL.createObjectURL(file.originFileObj);
            console.log(originNode);

            return (
                <>
                    <div className="uploadBox_">
                        <a
                            style={{width:"50%"}}
                            onClick={(e) => e.stopPropagation()}
                            href={file.url || URL.createObjectURL(file.originFileObj)}
                            download={file.name}
                            className="download-link"
                        >
                            {file.name}
                            {/* {originNode} */}
                        </a>
                        <span>
                            <DeleteTwoTone onClick={async () => {
                                setFileLoading(true)
                                const res: any = await DeleteFile({
                                    params: {
                                        FileId: file.response.data[0],
                                        PurchaseOrder_InspectionProjectId: itemID,
                                        ItemNo: reportsData[fileIndex].itemNo
                                    }
                                })
                                if (res.isSuccess) {
                                    setFileLoading(false)
                                    const newFileList = [...reportsData[fileIndex]?.fileList];
                                    newFileList.splice(newFileList.findIndex(item => item.uid === file.uid), 1);
                                    reportsData[fileIndex].fileList = newFileList;
                                    setReportsData(deepCloneArray(reportsData));
                                } else {
                                    setFileLoading(false)
                                    message.error(res.message)
                                }

                            }} />
                        </span>
                    </div>
                </>
            )
        },
        onChange(info: any) {
            const { status } = info.file;
            reportsData[fileIndex].fileList = info.fileList
            // setItemData(itemData)
            setReportsData(reportsData)
            setFileList(reportsData[fileIndex]?.fileList)
            if (status !== 'uploading') {
                console.log(info.file, info.fileList);
            }
            if (status === 'done') {
                message.success(`${info.file.name} file uploaded successfully.`);

            } else if (status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        },
        onDrop(e) {
            console.log('Dropped files', e.dataTransfer.files);
        },
        onRemove: async (file) => {
            return false
            // return new Promise(async (resolve, reject) => {
            //     setFileLoading(true)
            //     const res: any = await DeleteFile({
            //         params: {
            //             FileId: file.response.data[0],
            //             PurchaseOrder_InspectionProjectId: itemID,
            //             ItemNo: reportsData[fileIndex].itemNo
            //         }
            //     })
            //     if (res.isSuccess) {
            //         setFileLoading(false)
            //         resolve()
            //     } else {
            //         setFileLoading(false)
            //         reject()
            //     }
            // })
        }
    };
    const getFileList = async (fileIdArr: number[], index: number) => {
        setFileLoading(true)
        const arr: any = fileIdArr.map(async (fileId: number) => {
            // fileList: [1,2,3].map(async (fileId: number) => {
            const res: any = await GetFile({ params: { fileId } })
            const contentDisposition = res.headers['content-disposition']
            const filenameMatches = /filename=([^;]+)/g.exec(contentDisposition);
            let name = filenameMatches ? filenameMatches[1] : "Unknown"
            delete res.headers
            return {
                uid: fileId,
                name,
                url: URL.createObjectURL(res),
                status: 'done',
                response: { data: [fileId] }
            }
        })
        Promise.all(arr).then(res => {
            // setFileList(res)
            // itemData[index].fileList = res
            // itemData[index].getFileStatus = true
            reportsData[index].fileList = res
            reportsData[index].getFileStatus = true
            // setItemData(deepCloneArray(itemData))
            setReportsData(deepCloneArray(reportsData))
            setFileLoading(false)


        })
    }

    return (
        <>
            <section className="AddPo">
                <div className="head">
                    <h2>Edit PO to inspection</h2>
                    <img style={{ cursor: "pointer" }} onClick={() => navigate(-1)} src={close} alt="" />
                </div>
                <Spin spinning={loading} >
                    <Form
                        initialValues={{
                            po: "All"
                        }}
                        form={form}
                        name="basic"
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        layout='vertical'
                    >
                        <ul className="generalInfo">
                            <li>
                                <div className="active">General Info</div>
                                <div style={{ visibility: "hidden" }}>History</div>
                            </li>
                            <li>
                                {/* <Button style={{ background: "none" }} onClick={() => form.resetFields()}>Cancel</Button> */}
                                {/* <Button style={{ margin: "0 8px" }}>Send Email</Button> */}
                                <Button type="primary" loading={submitLoading} htmlType="submit">Save</Button>
                            </li>
                        </ul>
                        <div className="data_entry">
                            <div>
                                <Form.Item
                                    label="PO #"
                                    name="poNumber"
                                    style={{ width: "32%" }}
                                    rules={[{ required: true }]}
                                >
                                    <Select
                                        className=""
                                        showSearch
                                        disabled
                                        onChange={changePoNum}
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                        }
                                        options={poNumArr}
                                    />
                                </Form.Item>
                                <Form.Item
                                    label="Created Date"
                                    name="createdDate"
                                    style={{ width: "32%" }}
                                >
                                    <DatePicker
                                        format="YYYY-MM-DD"
                                        disabled
                                        style={{ width: "100%" }} />
                                </Form.Item>
                                <Form.Item
                                    label="Inspection Rate %"
                                    name="inspectionRateId"
                                    style={{ width: "32%" }}
                                    rules={[{ required: true }]}
                                >
                                    <Select
                                        className=""
                                        disabled
                                        options={inspectionRateList}
                                    />
                                </Form.Item>
                                <Form.Item
                                    label="Inspection Checklist Form"
                                    name="checklistId"
                                    style={{ width: "100%" }}
                                    rules={[{ required: true }]}
                                >
                                    <Select
                                        disabled
                                        className=""
                                        options={checkList}
                                    />
                                </Form.Item>
                            </div>
                            <div>
                                <Form.Item
                                    label="Vendor"
                                    name="vendor"
                                    style={{ width: "80%" }}
                                >
                                    <Input disabled />
                                </Form.Item>
                                <Form.Item
                                    label="Status"
                                    name="status"
                                    style={{ width: "18%" }}
                                    rules={[{ required: true }]}
                                >
                                    <Select
                                        disabled
                                        className=""
                                        options={[
                                            { label: "To Do", value: "0" },
                                            { label: "Active", value: "1" },
                                            { label: "Fail", value: "2" },
                                            { label: "Completed ", value: "3" },
                                        ]}
                                    />
                                </Form.Item>
                                <Form.Item
                                    label="Assign to Inspector"
                                    name="assignToInspector"
                                    style={{ width: "49%" }}
                                    rules={[{ required: true }]}
                                >
                                    <Select
                                        className=""
                                        options={assigntoInspectorOptions}
                                    />
                                    {/* <Input disabled /> */}
                                </Form.Item>
                                <Form.Item
                                    label="Version"
                                    name="version"
                                    style={{ width: "49%" }}
                                // rules={[{ required: true }]}
                                >
                                    <Input disabled />
                                    {/* <Input disabled /> */}
                                </Form.Item>
                                {/* <Form.Item
                                    label="Area"
                                    name="area"
                                    style={{ width: "48%" }}
                                >
                                    <Input disabled />
                                </Form.Item> */}
                            </div>
                        </div>
                        <div className="tableContent">
                            <h3><span style={{ color: "red" }}>*</span> Items {itemData.length > 0 && selectedRowKeys.length === 0 && (<span style={{ color: "red" }}>Please select at least one item</span>)}</h3>
                            <Table
                                className="poTable"
                                columns={itemColumns}
                                dataSource={itemData}
                                scroll={{ y: 200 }}
                                pagination={false}
                                rowSelection={rowSelection}
                            />
                            <h3 style={{ marginTop: "25px" }}>Inspection Reports</h3>
                            <Table
                                className="poTable"
                                columns={reportsColumns}
                                dataSource={reportsData}
                                scroll={{ y: 200, x: window.matchMedia('(max-width: 1600px)').matches ? 1700 : undefined }}
                                pagination={false}
                            />
                        </div>
                    </Form>
                </Spin>
            </section>
            <SendEmail reportID={reportID} sendEmailStatus={sendEmailStatus} setSendEmailStatus={setSendEmailStatus}/>
            <Modal
                okButtonProps={{ style: { display: 'none' } }}
                cancelButtonProps={{ style: { display: 'none' } }} title="Upload File" open={isModalOpen} onOk={() => setIsModalOpen(false)} onCancel={() => setIsModalOpen(false)}>
                <Spin spinning={fileLoading}>
                    <div style={{ padding: '10px' }}>
                        <Dragger {...props}>
                            <p className="ant-upload-drag-icon">
                                <InboxOutlined />
                            </p>
                            <p className="ant-upload-text">Click or drag file to this area to upload</p>
                            <p className="ant-upload-hint">
                                Support for a single or bulk upload.
                            </p>
                        </Dragger>
                    </div>
                </Spin>
            </Modal>
        </>
    )
}
export default EditPo