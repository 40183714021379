import React, { useState, useEffect } from 'react';
import { Input, Select, Form, Modal, Tag, theme, Tooltip, Space, Spin, message } from 'antd';
import { CreateUser } from '@/services/login';
import { GetTemplateArgumentLi, AddAttribute } from "@/services/checkList";
const NewAttribute: React.FC<{
    isModalOpen: boolean
    setIsModalOpen: Function
    setUpdateList: Function
    updateList: boolean
    parentId?: number
}> = ({ isModalOpen, setIsModalOpen, updateList, setUpdateList, parentId }) => {
    const [form] = Form.useForm()
    const [loading, setLoading] = useState(false)
    const [parentOption, setParentOption] = useState([])
    const [loadData, setLoadData] = useState(false)
    const [disabled,setDisable] = useState(false)
    const close = () => {
        form.resetFields()
        setIsModalOpen(false)
        setDisable(false)
    }
    const onFinish = async (values: any) => {
        console.log(values);
        const { name, parentId, mustAddPhoto } = values
        setLoading(true)
        try {
            const res: any = await AddAttribute({ data: parentId ? { ...values } : { name, mustAddPhoto } })
            if (res.isSuccess) {
                message.success("Successfully.")
                setLoading(false)
                close()
                setUpdateList(!updateList)
            } else {
                setLoading(false)
                message.error(res.message)
            }
        } catch (error: any) {
            console.log(error);
            setLoading(false)
            message.error(error.message)
        }
    };
    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };
    const getCheckListTemplate = async () => {
        setLoadData(true)
        const res: any = await GetTemplateArgumentLi()
        if (res.isSuccess) {
            setLoadData(false)
            let option: any = [{
                key: 0,
                value: 0,
                label: "NONE",
                id: 0,
                style: { color: "#0A1629" }
            }]
            res.data.forEach((item: any) => {
                option.push({
                    key: item.id,
                    value: item.id,
                    label: item.argument,
                    id: item.id,
                    style: { color: "#0A1629" }
                })
            })
            setParentOption(option)
        }
    }
    // useEffect(() => {
    //     getCheckListTemplate()
    // }, [])
    useEffect(() => {
        form.setFieldValue('parentId', parentId)
        if (isModalOpen) {
            getCheckListTemplate()
        }
    }, [parentId, isModalOpen])
    return (
        <div><Modal
            title="Add New Attribute"
            okText="Create"
            className='Add_Attributes'
            confirmLoading={loading}
            open={isModalOpen}
            onOk={() => form.submit()}
            onCancel={close}>
            <div className='modal_content'>
                <Spin spinning={loadData}>
                    <Form
                        form={form}
                        name="basic"
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        layout='vertical'
                        initialValues={{ mustAddPhoto: true }}
                    >
                        <Form.Item
                            label="Attribute Name"
                            name="name"
                            style={{ width: "100%" }}
                            rules={[{ required: true }]}
                        >
                            <Input style={{ display: 'inline-block' }} />
                        </Form.Item>
                        <Form.Item
                            label="Parent"
                            name="parentId"
                            style={{ width: "100%" }}
                            rules={[{ required: true }]}
                        >
                            <Select
                                showSearch
                                className=""
                                onSearch={(value: string) => {
                                    console.log('search:', value);
                                }}
                                onChange={(value)=>{
                                    if(value){
                                        setDisable(true)
                                        form.setFieldsValue({
                                            mustAddPhoto:false
                                        })
                                    }else{
                                        setDisable(false)
                                        
                                    }
                                }}
                                filterOption={(input: string, option?: { label: string; value: string }) =>
                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                options={parentOption}
                            />
                        </Form.Item>
                        <Form.Item
                            label="Must Add Photo?"
                            name="mustAddPhoto"
                            style={{ width: "100%" }}
                            rules={[{ required: true }]}
                        >
                            <Select
                                className=""
                                disabled={disabled}
                                options={[
                                    { value: true, label: 'Yes' },
                                    { value: false, label: 'No' },
                                ]}
                            />
                        </Form.Item>
                        {/* <div className='type_status'>
                    <Form.Item
                        label="Type"
                        name="Type"
                        style={{ width: "49%" }}
                        rules={[{ required: true }]}
                    >
                        <Select
                            className=""
                            options={[
                                { value: '0', label: 'Normal' },
                                { value: '1', label: 'Input Value' },
                                { value: '1', label: 'Images' },
                            ]}
                        />
                    </Form.Item>
                    <Form.Item
                        label="Status"
                        name="Status"
                        style={{ width: "49%" }}
                        rules={[{ required: true }]}
                    >
                        <Select
                            className=""
                            options={[
                                { value: '0', label: 'Active' },
                                { value: '1', label: 'Inactive' },
                            ]}
                        />
                    </Form.Item>
                </div> */}
                    </Form >
                </Spin>
            </div>
        </Modal>
        </div>

    );
};

export default NewAttribute;