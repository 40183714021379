import React, { useState } from 'react';
import { Input, Select, Form, Modal, Tag, theme, Tooltip, Space, AutoComplete, message } from 'antd';
import { CreateUser } from '@/services/login';
const AddUser: React.FC<{
    isModalOpen: boolean
    setIsModalOpen: Function
    setUpdateList: Function
    updateList: boolean
}> = ({ isModalOpen, setIsModalOpen, updateList, setUpdateList }) => {
    const [form] = Form.useForm()
    const [loading, setLoading] = useState(false)
    const close = () => {
        form.resetFields()
        setIsModalOpen(false)
    }
    const onFinish = async (values: any) => {
        const { Password, ConfirmPassword } = values
        if (Password !== ConfirmPassword) {
            message.error('The two password entries are inconsistent.')
            return
        }
        setLoading(true)
        try {
            const res: any = await CreateUser({
                params: {
                    ...values,
                    UserRole: +values.UserRole
                }
            })
            if (res.isSuccess) {
                message.success("Successfully.")
                setLoading(false)
                close()
                setUpdateList(!updateList)
            }else{
                setLoading(false)
                message.error(res.message)
            }
        } catch (error: any) {
            console.log(error);
            setLoading(false)
            message.error(error.message)
        }
    };
    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };
    return (
        <Modal
            title="New User"
            okText="Create"
            confirmLoading={loading}
            open={isModalOpen}
            onOk={() => form.submit()}
            onCancel={close}>
            <div className='modal_content'>
                <Form
                    form={form}
                    name="basic"
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    layout='vertical'
                    initialValues={{ UserRole: "0" ,UserStatus:"1"}}
                >
                    <Form.Item
                        label="User Name"
                        name="UserName"
                        style={{ width: "100%" }}
                        rules={[{ required: true }]}
                    >
                        <Input style={{ display: 'inline-block' }} />
                    </Form.Item>
                    <Form.Item
                        label="Email"
                        name="Email"
                        style={{ width: "100%" }}
                        rules={[{ required: true }, { type: "email", message: "Email is not a valid email!" }]}
                    >
                        <Input style={{ display: 'inline-block' }} />
                    </Form.Item>
                    <Form.Item
                        label="User Status"
                        name="UserStatus"
                        style={{ width: "100%" }}
                        rules={[{ required: true }]}
                    >
                        <Select
                            className=""
                            options={[
                                { value: '1', label: 'Active' },
                                { value: '0', label: 'Inactive' },
                            ]}
                        />
                    </Form.Item>
                    <Form.Item
                        label="User Role"
                        name="UserRole"
                        style={{ width: "100%" }}
                        rules={[{ required: true }]}
                    >
                        <Select
                            className=""
                            options={[
                                { value: '0', label: 'Super Admin' },
                                { value: '1', label: 'Inspector' },
                            ]}
                        />
                    </Form.Item>
                    <Form.Item
                        label="Password"
                        name="Password"
                        style={{ width: "100%" }}
                        rules={[{ required: true }]}
                    >
                        <Input.Password />
                    </Form.Item>
                    <Form.Item
                        label="Confirm Password"
                        name="ConfirmPassword"
                        style={{ width: "100%" }}
                        rules={[{ required: true }]}
                    >
                        <Input.Password />
                    </Form.Item>
                </Form >
            </div>
        </Modal>
    );
};

export default AddUser;