import React, { useEffect, useState, useMemo, useCallback, useRef } from 'react'
import '@/styles/inspectionPhone/inspectionReportPhone.less'
import type { RcFile, UploadFile as UpladoFileType, UploadProps } from 'antd/es/upload/interface';
import { Button, Input, Upload, Modal, Skeleton, Spin, Popconfirm } from 'antd'
import { DownOutlined, LeftCircleTwoTone, RightCircleTwoTone, ExclamationCircleFilled } from '@ant-design/icons';
import type { MenuProps } from 'antd';
import { StarOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { Dropdown, Space, message } from 'antd';
import more from '@/images/navigation/more.png'
import save from '@/images/navigation/save.png'
import exit from '@/images/navigation/exit.png'
import progress from '@/images/navigation/progress.png'
import submit from '@/images/navigation/submit.png'
import close from '@/images/navigation/close_white.png'
import InspectionProgess from '@/components/InspectionProgess';
import { Outlet } from 'react-router-dom'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import warn from '@/images/navigation/warn.png'
import camera from '@/images/navigation/camera.png'
import { OnSubmitReport, GetFile, UploadFile, GetInspectionDetailPer } from '@/services/inspectionReport';
import { connect } from 'react-redux'
import { Dispatch } from 'redux';
import getInspectionDetail from '@/utils/refreshPage';
import Compressor from 'compressorjs';
const { TextArea } = Input
const { confirm } = Modal;
const ReportPhone = ({
    completePercent,
    completeArr,
    setCompleteArr,
    setCompletePercent,
    reportStatus,
    clearData,
    issueData,
    setIssueData,
    setReportStatus,
    uploadImgSelect,
    setUploadImgSelect,
    conditionalPassList,
    setConditionalPassList,
    imageStep,
    setImageStep
}: {
    completePercent: number
    completeArr: CompleteArrType
    setCompleteArr: any
    setCompletePercent: any
    reportStatus: ReportStatusType
    clearData: any
    issueData: IssueDataType
    setIssueData: any
    setReportStatus: any
    uploadImgSelect: PhoneUploadImgType
    setUploadImgSelect: any
    conditionalPassList: any
    setConditionalPassList: Function
    imageStep:boolean
    setImageStep:Function
}) => {
    const [open, setOpen] = useState(false)
    const location = useLocation()
    const navigate = useNavigate()
    const [active, setActive] = useState<number | null>(null)
    // const [fileList, setFileList] = useState<UploadFile[]>([]);
    const imageRef = useRef<any>(null);
    const [steps, setSteps] = useState<StepsArrType>([])
    const [search] = useSearchParams()
    const [changeStatus, setChangeStatus] = useState(false)
    const [completed, setCompleted] = useState(0)
    const [uploadStatus, setUploadStatus] = useState(false)
    const [confirmJumpStatus, setConfirmJumpStatus] = useState(false)
    const [submitLoading, setSubmitLoading] = useState(false)
    const [beSaving, setBeSaving] = useState(false)
    const [imageCurrentIdx, setImageCurrentIdx] = useState<number>(0)
    const [imgRotateLoading, setImgRotateLoading] = useState(false)
    const [confirmJumpFn, setConfirmJumpFn] = useState(() => () => { })
    const [progressInfo, setProgressInfo] = useState<inspectionReportAPI.GetInspectionDetailPerResponse>({
        count: 0,
        total: 0,
        imageStep: false,
        resultStep: false,
        percent: 0,
        info: []
    })
    const [rotation, setRotation] = useState(0);
    const [loading, setLoading] = useState(false)
    const showConfirm = () => {
        saveSubmit(1, true)
    };
    const items: MenuProps['items'] = localStorage.getItem('status') === "Submitted" ? [


        {
            label: (
                <div className='dropdown_' onClick={() => navigate('/inspectionList')}>
                    <img src={exit} alt="" />
                    Exit
                </div>
            ),
            key: '1',
        },
        {
            label: (
                <div className='dropdown_' onClick={() => {
                    setOpen(true)
                    setChangeStatus(!changeStatus)
                }}>
                    <img src={progress} alt="" />
                    Inspection Progress
                </div>
            ),
            key: "2"
        },
        {
            label: (
                <div className='dropdown_' onClick={() => saveSubmit(1, true)}>
                    <img src={submit} alt="" />
                    Submit Report
                </div>
            ),
            key: "3"
        }
    ] : [
        {
            label: (
                <div className='dropdown_' onClick={() => saveSubmit(0, false)}>
                    <img src={save} alt="" />
                    Save As Draft
                </div>
            ),
            key: '0',
        },
        {
            label: (
                <div className='dropdown_' onClick={() => saveSubmit(0, true)}>
                    <img src={exit} alt="" />
                    Save & Exit
                </div>
            ),
            key: '1',
        },
        {
            label: (
                <div className='dropdown_' onClick={() => {
                    setOpen(true)
                    setChangeStatus(!changeStatus)
                }}>
                    <img src={progress} alt="" />
                    Inspection Progress
                </div>
            ),
            key: "2"
        },
        // completePercent === 100 ? {
        //     label: (
        //         <div className='dropdown_' onClick={showConfirm}>
        //             <img src={submit} alt="" />
        //             Submit Report
        //         </div>
        //     ),
        //     key: "4"
        // } : null
        {
            label: (
                <div className='dropdown_' onClick={showConfirm}>
                    <img src={submit} alt="" />
                    Submit Report
                </div>
            ),
            key: "4"
        }
    ]
    const id = search.get('id') || 0
    const deepCloneArray: any = (arr: any) => {
        const clonedArray = [];
        for (let i = 0; i < arr.length; i++) {
            if (Array.isArray(arr[i])) {
                clonedArray[i] = deepCloneArray(arr[i]);
            } else {
                clonedArray[i] = arr[i];
            }
        }
        return clonedArray;
    }
    const getBase64 = (file: RcFile): Promise<string> =>
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result as string);
            reader.onerror = (error) => reject(error);
        });
    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [previewTitle, setPreviewTitle] = useState('');
    const handlePreview = async (file: UpladoFileType) => {
        let pageId = Number(search.get('id') || 0)
        let idx = completeArr[Number(search.get('id') || 0)].fileList.findIndex(item => item.uid === file.uid)
        setImageCurrentIdx(completeArr[Number(search.get('id') || 0)].fileList.findIndex(item => item.uid === file.uid))
        // console.log(completeArr[Number(search.get('id') || 0)].fileList[completeArr[Number(search.get('id') || 0)].fileList.findIndex(item => item.uid === file.uid)], completeArr[Number(search.get('id') || 0)].fileList.findIndex(item => item.uid === file.uid));
        // console.log(file);

        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj as RcFile);
        }

        // setPreviewImage(file.url || (file.preview as string));
        setPreviewOpen(true);
        setPreviewTitle(file.name || file.url!.substring(file.url!.lastIndexOf('/') + 1));
        if (!completeArr[pageId].fileList[idx]?.viewImgStatus) {
            setImgRotateLoading(true)
            try {
                const res: any = await GetFile({ params: { fileId: completeArr[pageId].fileList[idx].response.data[0] } })
                let url = URL.createObjectURL(res)
                completeArr[pageId].fileList[idx].url = url
                completeArr[pageId].fileList[idx].viewImgStatus = true
                setPreviewImage(url)
                setImgRotateLoading(false)
            } catch (error) {
                setImgRotateLoading(false)
            }
        } else {
            setPreviewImage(file.url || (file.preview as string))
        }

    };
    const prevNextImg = async (num: number) => {
        if (num > 0) {
            if (imageCurrentIdx >= completeArr[Number(id)].fileList.length - 1) return
        } else {
            if (!imageCurrentIdx) return
        }
        if (rotation > 0) {
            rotateImg().then(async () => {
                let url = completeArr[Number(id)].fileList[imageCurrentIdx + num]?.url
                let preview = completeArr[Number(id)].fileList[imageCurrentIdx + num]?.preview
                let name = completeArr[Number(id)].fileList[imageCurrentIdx + num]?.name
                let baseUrl = url || preview
                if (!url && !preview) {
                    baseUrl = await getBase64(completeArr[Number(id)].fileList[imageCurrentIdx + num]?.originFileObj as RcFile);
                }
                setPreviewImage(baseUrl)
                setPreviewTitle(name)
                setImageCurrentIdx(imageCurrentIdx + num)
            })
        } else {
            let url = completeArr[Number(id)].fileList[imageCurrentIdx + num]?.url
            let preview = completeArr[Number(id)].fileList[imageCurrentIdx + num]?.preview
            let name = completeArr[Number(id)].fileList[imageCurrentIdx + num]?.name
            let baseUrl = url || preview
            if (!url && !preview) {
                baseUrl = await getBase64(completeArr[Number(id)].fileList[imageCurrentIdx + num]?.originFileObj as RcFile);
            }
            setPreviewImage(baseUrl)
            setPreviewTitle(name)
            setImageCurrentIdx(imageCurrentIdx + num)
        }

    }
    const rotateImg = () => {
        setImgRotateLoading(true)
        return new Promise<void>((resolve, reject) => {
            let canvas = document.createElement("canvas");
            let ctx: any = canvas.getContext("2d");
            switch (rotation) {
                case 90: // 旋转90°
                    canvas.width = imageRef.current.height * 2;
                    canvas.height = imageRef.current.width * 2;
                    ctx.rotate((90 * Math.PI) / 180);
                    ctx.drawImage(imageRef.current, 0, -imageRef.current.height * 2, imageRef.current.width * 2, imageRef.current.height * 2);
                    break;
                case 180: // 旋转180°
                    canvas.width = imageRef.current.width * 2;
                    canvas.height = imageRef.current.height * 2;
                    ctx.rotate((180 * Math.PI) / 180);
                    ctx.drawImage(imageRef.current, -imageRef.current.width * 2, -imageRef.current.height * 2, imageRef.current.width * 2, imageRef.current.height * 2);
                    break;
                case 270: // 旋转270°
                    canvas.width = imageRef.current.height * 2;
                    canvas.height = imageRef.current.width * 2;
                    ctx.rotate((-90 * Math.PI) / 180);
                    ctx.drawImage(imageRef.current, -imageRef.current.width * 2, 0, imageRef.current.width * 2, imageRef.current.height * 2);
                    break;
                default:
                    canvas.width = imageRef.current.width * 2;
                    canvas.height = imageRef.current.height * 2;
                    ctx.drawImage(imageRef.current, 0, 0, imageRef.current.width * 2, imageRef.current.height * 2);
                    break;
            }
            let imgBaseUrl = canvas.toDataURL("image/png", 1)
            const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
            console.log(imageData);
            canvas.toBlob(async (blob) => {
                // 创建文件
                let imgUrl = URL.createObjectURL(blob!);
                const file = new File([blob!], previewTitle, { type: "image/png" })
                let formData = new FormData()
                formData.append("formFiles", file)
                // formData.append("FileId", completeArr[Number(search.get('id') || 0)].fileList[imageCurrentIdx!].response.data[0])
                try {
                    const res: any = await UploadFile({ data: formData, params: { FileId: completeArr[Number(search.get('id') || 0)].fileList[imageCurrentIdx!].response.data[0] } })
                    if (res.isSuccess) {
                        message.success('Save successfully.')
                        setPreviewImage(imgUrl)
                        setRotation(0)
                        completeArr[Number(search.get('id') || 0)].fileList[imageCurrentIdx!].url = imgBaseUrl
                        completeArr[Number(search.get('id') || 0)].fileList[imageCurrentIdx!].preview = imgBaseUrl
                        completeArr[Number(search.get('id') || 0)].fileList[imageCurrentIdx!].thumbUrl = imgBaseUrl
                        completeArr[Number(search.get('id') || 0)].fileList[imageCurrentIdx!].response.data = [res.data[0]]
                        setCompleteArr(deepCloneArray(completeArr))
                        setImgRotateLoading(false)
                        resolve()
                    } else {
                        message.error('Image upload failed.')
                        setImgRotateLoading(false)
                    }
                } catch (error) {
                    message.error("Image upload failed.")
                    setImgRotateLoading(false)
                }
            })
        })


    }
    const setImgData = () => {
        completeArr.forEach(item => {
            uploadImgSelect.forEach((item2 => {
                if (item.argumentId === item2.argumentId) {
                    item.fileList = item2.imgList
                }
            }))
        })
    }
    const saveSubmit = async (status: number, open: boolean = false, isLoading: boolean = false) => {
        !isLoading && setSubmitLoading(true)
        // let steps = JSON.parse(sessionStorage.getItem("steps") || "[]")
        let steps = JSON.parse(localStorage.getItem("steps") || "[]")
        // let inspectedQuantity = Number(sessionStorage.getItem('inspectedQuantity') || 0)
        let inspectedQuantity = Number(localStorage.getItem('inspectedQuantity') || 0)
        setImgData()
        let details = completeArr.map((item, index) => {
            return {
                argumentId: +item.argumentId,
                title: steps[index].title,
                value: item.value || null,
                quality: item.quality === "NA" ? 0 :
                    (item.quality === "AC" ? 1 :
                        (item.quality === "MI" ? 2 :
                            (item.quality === "MA" ? 3 :
                                (item.quality === "PD" ? 4 : null)))),
                imageIds: item?.fileList?.map(item => item?.response?.data[0]).length == 0 ? null : item?.fileList?.map(item => item?.response?.data[0]),
                comment: item.comment
            }
        })
        let failureCodes: any = []
        let statusArr = reportStatus.failureCode.status
        let textArr = reportStatus.failureCode.text
        for (let key in statusArr) {
            if (statusArr[key]) {
                failureCodes.push({
                    failureCode: key === "Functionality" ? 0 :
                        (key === "Structure" ? 1 :
                            (key === "Appearance" ? 2 :
                                (key === "Certification" ? 3 :
                                    (key === "Packaging" ? 4 : 5)))),
                    comment: textArr[key]
                })
            }
        }
        let reportId = Number(localStorage.getItem('reportId')) || 0
        let data: ReportDataType = {
            // projectId: Number(sessionStorage.getItem("projectId")),
            purchaseOrder_InspectionProjectId: Number(localStorage.getItem("purchaseOrder_InspectionProjectId") || 0),
            poQuantity: Number(localStorage.getItem("poQuantity") || 0),
            aqlQty:Number(localStorage.getItem("aqlQty") || 0),
            itemNo: localStorage.getItem('itemNo') || "",
            vendor: localStorage.getItem('vendor') || "",
            poNumber: localStorage.getItem('PONumber') || "",
            inspectedQuantity,
            details,
            result: reportStatus.result === "Fail" ? 0 : (reportStatus.result === "Pending" ? 1 : (reportStatus.result === "Pass" ? 2 : (reportStatus.result === "Conditional Pass" ? 3 : null))),
            failureCodes: failureCodes.length == 0 ? null : failureCodes,
            comment: reportStatus.comment,
            status,
            critical: issueData.Critical,
            criticalAccept: issueData.CriticalAccept,
            majorAccept: issueData.MajorAccept,
            majorReject: issueData.MajorReject,
            minorAccept: issueData.MinorAccept,
            minorReject: issueData.MinorReject,
            producedQuantity: +(localStorage.getItem("producedQuantity") || 0)
        }
        if (reportStatus.result === 'Conditional Pass') {
            data.conditionalPassComment = reportStatus.conditionalPassComment
            data.conditionalPassFiles = conditionalPassList.map((item: any) => item.response.data[0])

        }
        if (reportId) {
            data.reportId = reportId
        }
        try {
            setBeSaving(true)
            const res: any = await OnSubmitReport({ data })
            if (res.isSuccess) {
                !isLoading && message.success("Successfully.")
                setSubmitLoading(false)
                // initializeData()
                sessionStorage.setItem("reportId", res.data)
                localStorage.setItem("reportId", res.data)
                GetProgressBar(res.data)
                if (open) {
                    clearData()
                    navigate('/inspectionList')
                }
                setBeSaving(false)
            } else {
                setSubmitLoading(false)
                message.error(res.message)
                setBeSaving(false)
            }
        } catch (error: any) {
            console.log(error);
            if (error.response.status === 400) {
                !isLoading && message.error("Please wait until the image is uploaded.")
            } else {
                !isLoading && message.error(error.response.data.title);
            }
            setSubmitLoading(false)
            setBeSaving(false)
        }
    }
    const prevPage = () => {
        const status = localStorage.getItem('status') !== "Submitted" ? 0 : 1
        if (loading) {
            return
        }
        !beSaving && saveSubmit(status, false, true)
        setChangeStatus(!changeStatus)
        if (Number(id) <= 0) {
            let itemNo = localStorage.getItem('itemNo')
            let itemId = localStorage.getItem('purchaseOrder_InspectionProjectId')
            let PONumber = localStorage.getItem('PONumber')
            navigate(`/inspectionDetail?itemNo=${itemNo}&PONumber=${PONumber}&itemId=${itemId}`)
        } else {
            navigate(`/reportPhone?id=${Number(id || 0) - 1}`)
        }
    }
    const nextPage = () => {
        const status = localStorage.getItem('status') !== "Submitted" ? 0 : 1
        if (loading) {
            return
        }
        !beSaving && saveSubmit(status, false, true)
        setCompleteArr(deepCloneArray(completeArr))
        setChangeStatus(!changeStatus)
        if (Number(id) >= steps.length - 1) {
            navigate('/uploadImg')
        } else {
            navigate(`/reportPhone?id=${Number(search.get('id') || 0) + 1}`)
        }
    }
    useEffect(() => {
        const steps = JSON.parse(localStorage.getItem('steps') || "[]")
        setSteps(steps)
        let current = Number(search.get('id') || 0)
        if (completeArr?.length <= 0) {
            setLoading(true)
            clearData()
            getInspectionDetail().then(() => {
                setLoading(false)
                completeArr = JSON.parse(localStorage.getItem('steps') || "[]")?.map((item: any, index: number) => {
                    return ({
                        argumentId: item.argumentId,
                        value: item.value || "",
                        mustAddPhoto: (item.quality === 2 || item.quality === 3) ? true : item.mustAddPhoto,
                        quality: item.quality === 0 ? "NA" :
                            item.quality === 1 ? "AC" :
                                item.quality === 2 ? "MI" :
                                    item.quality === 3 ? "MA" :
                                        item.quality === 4 ? "PD" : "",
                        // fileList: item.imageIds ? item.imageIds.map(async (fileId: number) => {
                        //     setLoading(true)
                        //     const res: any = await GetFile({ params: { fileId } })
                        //     const contentDisposition = res.headers['content-disposition']
                        //     const filenameMatches = /filename=([^;]+)/g.exec(contentDisposition);
                        //     let name = filenameMatches ? filenameMatches[1] : "Unknown"
                        //     // console.log(res, name);
                        //     delete res.headers
                        //     return {
                        //         name,
                        //         url: URL.createObjectURL(res),
                        //         status: 'done',
                        //         response: {
                        //             data: [fileId]
                        //         }
                        //     }
                        // }) : [],
                        parentArgumentTitle: item.parentArgumentTitle,
                        fileList: item?.imageIds ? item?.imageIds?.map((fileId: number) => ({
                            response: {
                                data: [fileId]
                            }
                        })) : [],
                        comment: item.comment || "",
                        loadingImage: false,
                        uploadStatus: false,
                    })
                })
                setCompleteArr(deepCloneArray(completeArr))
                // Promise.all(completeArr.map(async (item) => {
                //     item.fileList = await Promise.all(item.fileList);
                //     return item;
                // })).then((updatedCompleteArr) => {
                //     setLoading(false)
                //     setCompleteArr(deepCloneArray(updatedCompleteArr));
                //     setChangeStatus(!changeStatus)
                // });
                let objStatus = reportStatus.failureCode.status
                let objText = reportStatus.failureCode.text
                let failureCodesArr = JSON.parse(localStorage.getItem("failureCodes") || "[]")
                let result = Number(localStorage.getItem("result") || -1)
                let comment = localStorage.getItem('comment') || ""
                let conditionalPassComment = localStorage.getItem('conditionalPassComment') || ""
                reportStatus.comment = comment
                reportStatus.conditionalPassComment = conditionalPassComment
                let conditionalPassFiles = JSON.parse(localStorage.getItem('conditionalPassFiles') || "[]")
                setConditionalPassList([...conditionalPassFiles])
                reportStatus.result = result === 0 ? "Fail" :
                    (result === 1 ? "Pending" :
                        (result === 2 ? "Pass" :
                            (result === 3 ? "Conditional Pass" : "")))
                failureCodesArr?.forEach((item: any) => {
                    if (item.failureCode === 0) {
                        objStatus["Functionality"] = true
                        objText["Functionality"] = item.comment
                    } else if (item.failureCode === 1) {
                        objStatus["Structure"] = true
                        objText["Structure"] = item.comment
                    } else if (item.failureCode === 2) {
                        objStatus["Appearance"] = true
                        objText["Appearance"] = item.comment
                    } else if (item.failureCode === 3) {
                        objStatus["Certification"] = true
                        objText["Certification"] = item.comment
                    } else if (item.failureCode === 4) {
                        objStatus["Packaging"] = true
                        objText["Packaging"] = item.comment
                    } else if (item.failureCode === 5) {
                        objStatus["Labeling"] = true
                        objText["Labeling"] = item.comment
                    }

                })
                setReportStatus(reportStatus)
                let issueObj = JSON.parse(localStorage.getItem("issueObj") || "{}")
                setIssueData({
                    Critical: +issueObj?.critical || 0,
                    CriticalAccept: +issueObj?.criticalAccept || 0,
                    MajorAccept: +issueObj?.majorAccept || 0,
                    MajorReject: +issueObj?.majorReject || 0,
                    MinorAccept: +issueObj?.minorAccept || 0,
                    MinorReject: +issueObj?.minorReject || 0,
                })
                GetProgressBar(+(localStorage.getItem('reportId') || 0))
            })
        } else {
            GetProgressBar(+(localStorage.getItem('reportId') || 0))
        }
    }, [location.search])
    // function checkObjectValues(obj:any) {
    //     return Object.values(obj).every((val:any) => val > 0);
    //   }
    const GetProgressBar = async (reportId: number) => {
        try {
            const res = await GetInspectionDetailPer({ params: { inspectionReportid: reportId } })
            if (res?.isSuccess) {
                setCompletePercent(res?.data.percent! * 100)
                setImageStep(res.data.imageStep)
                // console.log("inspectionreportphone",res.data.imageStep);
                setProgressInfo(JSON.parse(JSON.stringify(res?.data)))
            } else {
                message.error(res?.message)
            }
        } catch (error) {
            console.log(error);
            // message.error()
        }
    }

    return (
        <>
            <Spin spinning={submitLoading}>
                <section className='inspectionReportPhone'>
                    <div className='report_hearder'>
                        <div className='phone_header'>
                            <span></span>
                            <span>Inspection Report</span>
                            <Dropdown menu={{ items }}>
                                <a onClick={(e) => e.preventDefault()}>
                                    <Space>
                                        <img style={{ width: "5vw" }} src={more} alt="" />
                                    </Space>
                                </a>
                            </Dropdown>
                        </div>
                        <ul className="progress" onClick={() => {
                            setOpen(true)
                            setChangeStatus(!changeStatus)
                        }}>
                            <li>
                                <span>Current</span>
                                <p>{+id + 1}/{steps.length + 2}</p>
                            </li>
                            <li>
                                <span></span>
                                <div className='progress_bar'>
                                    <div style={{ width: `${completePercent}%` }}></div>
                                </div>
                            </li>
                            <li>
                                <span>Completed</span>
                                <p>{completePercent.toFixed(0)}%</p>
                            </li>
                        </ul>
                    </div>
                    <div className='content_'>
                        <Skeleton loading={loading} active>
                            {steps?.map((item, index) => (
                                <div key={index} style={{ display: +id === index ? "" : "none" }} className='report_phone_content'>
                                    <h1 className={"firstTitle"} style={{ fontWeight: "700" }}>{item.parentArgumentTitle}</h1>
                                    <h1 className={"firstTitle"}>{index + 1}. {item.title}</h1>

                                    {item.standard?.indexOf("https") !== -1 ? <img src={item.standard} style={{ marginBottom: "20px" }} alt='' /> : <h2 className='secondTitle'>{item.standard}</h2>}
                                    <ul className='report_status'>
                                        {['NA', 'AC', 'MI', 'MA', 'PD'].map((item2, index2) => (
                                            <li
                                                key={index2}
                                                // className={active === index ? `active${index}` : ""}
                                                className={completeArr[index]?.quality === item2 ? `active${index2}` : ""}
                                                onClick={() => {
                                                    if (completeArr[index].quality === item2 && completeArr[index].quality !== "") {
                                                        completeArr[index].quality = ""
                                                    } else {
                                                        completeArr[index].quality = item2
                                                        if (item2 === "MI" || item2 === "MA") {
                                                            completeArr[index].mustAddPhoto = true
                                                        } else if (!item.mustAddPhoto) {
                                                            completeArr[index].mustAddPhoto = false
                                                        }
                                                    }

                                                    setCompleteArr(deepCloneArray(completeArr))
                                                    setChangeStatus(!changeStatus)
                                                    console.log(completeArr);

                                                }}
                                            >{item2}</li>
                                        ))}
                                    </ul>

                                    <h3>Comment</h3>
                                    <TextArea style={{ minHeight: "130px" }} value={completeArr[index]?.comment} onChange={(e) => {
                                        completeArr[index].comment = e.target.value
                                        setCompleteArr(deepCloneArray(completeArr))
                                    }} />
                                </div>
                            ))}
                        </Skeleton>
                        <div className='prev_next'>
                            {/* <Button onClick={() => navigate(`/inspectionDetail`)}>Prev</Button> */}
                            <Popconfirm
                                style={{ maxWidth: "90vw" }}
                                title="Prev page"
                                disabled={localStorage.getItem('status') !== "Submitted"}
                                description="Submitting the page will occur. Are you sure you want to proceed??"
                                onConfirm={() => {
                                    prevPage()
                                }}
                                okText="Yes"
                                cancelText="No"
                            >
                                <Button onClick={() => {
                                    if (localStorage.getItem('status') === "Submitted") {
                                        return
                                    }
                                    prevPage()
                                }}>Prev</Button></Popconfirm>
                            {/* <Button onClick={() => navigate(`/report/report0${+location.pathname.slice(-1) + 1}`)} type='primary'>Next</Button> */}
                            <Popconfirm
                                style={{ maxWidth: "90vw" }}
                                title="Next page"
                                disabled={localStorage.getItem('status') !== "Submitted"}
                                description="Submitting the page will occur. Are you sure you want to proceed??"
                                onConfirm={() => {
                                    nextPage()
                                }}
                                okText="Yes"
                                cancelText="No"
                            >
                                <Button onClick={() => {
                                    if (localStorage.getItem('status') === "Submitted") {
                                        return
                                    }
                                    nextPage()

                                }} type='primary'>Next</Button>
                            </Popconfirm>
                        </div>
                    </div>
                </section>
            </Spin>
            {open ? <InspectionProgess imageStep={imageStep} progressInfo={progressInfo} loading={loading} setOpen={setOpen} uploadStatus={completeArr.some((obj) => obj.uploadStatus === true)} currentStep={id} issueData={issueData} completeArr={completeArr} reportStatus={reportStatus} /> : <></>}
            <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={() => {
                setPreviewOpen(false)
                setRotation(0)
            }}>
                <Spin spinning={imgRotateLoading}>
                    <div className='Imagebody'>
                        <div className='prev' onClick={async () => {
                            if (!imageCurrentIdx) return
                            let pageId = Number(search.get('id') || 0)
                            if (!completeArr[pageId].fileList[imageCurrentIdx - 1]?.viewImgStatus) {
                                setImgRotateLoading(true)
                                try {
                                    const res: any = await GetFile({ params: { fileId: completeArr[pageId].fileList[imageCurrentIdx - 1].response.data[0] } })
                                    let url = URL.createObjectURL(res)
                                    completeArr[pageId].fileList[imageCurrentIdx - 1].url = url
                                    completeArr[pageId].fileList[imageCurrentIdx - 1].viewImgStatus = true
                                    setPreviewImage(url)
                                    setImgRotateLoading(false)
                                } catch (error) {
                                    setImgRotateLoading(false)
                                }
                                setImageCurrentIdx(imageCurrentIdx - 1)
                            }
                            prevNextImg(-1)
                        }}><LeftCircleTwoTone style={{ fontSize: "30px" }} /></div>
                        <div className='next' onClick={async () => {
                            if (imageCurrentIdx >= completeArr[Number(id)].fileList.length - 1) return
                            let pageId = Number(search.get('id') || 0)
                            if (!completeArr[pageId].fileList[imageCurrentIdx + 1]?.viewImgStatus) {
                                setImgRotateLoading(true)
                                try {
                                    const res: any = await GetFile({ params: { fileId: completeArr[pageId].fileList[imageCurrentIdx + 1].response.data[0] } })
                                    let url = URL.createObjectURL(res)
                                    completeArr[pageId].fileList[imageCurrentIdx + 1].url = url
                                    completeArr[pageId].fileList[imageCurrentIdx + 1].viewImgStatus = true
                                    setPreviewImage(url)
                                    setImgRotateLoading(false)
                                } catch (error) {
                                    setImgRotateLoading(false)
                                }
                            }

                            setImageCurrentIdx(imageCurrentIdx + 1)
                            prevNextImg(1)
                        }}><RightCircleTwoTone style={{ fontSize: "30px" }} /></div>
                        <img ref={imageRef} alt="" style={{ display: "block", width: "100%", maxWidth: '100%', margin: "0 auto", transform: `rotate(${rotation}deg)` }} src={previewImage} />
                        <div className='rotate_save'>
                            <Button onClick={() => setRotation(rotation === 270 ? 0 : rotation + 90)}>Rotate</Button>
                            <div>{imageCurrentIdx + 1} / {completeArr[Number(id)]?.fileList.length}</div>
                            <Button type="primary" onClick={rotateImg} >Save</Button>
                        </div>
                    </div>
                </Spin>
            </Modal >
            <Modal open={confirmJumpStatus} okText="Yes" cancelText="No" title="" onCancel={() => setConfirmJumpStatus(false)} onOk={confirmJumpFn}>
                <p style={{ padding: "10px", paddingTop: "20px", fontWeight: "700", fontSize: "20px" }}>You haven't uploaded the picture yet, are you sure you want to jump to another page?</p>
            </Modal>
        </>
    )
}
const mapStateToProps = (state: any) => {
    return {
        completeArr: state.completeArr,
        completePercent: state.completePercent,
        reportStatus: state.reportStatus,
        issueData: state.issueData,
        uploadImgSelect: state.uploadImgSelect,
        conditionalPassList: state.conditionalPassList,
        imageStep:state.imageStep
    }
}
const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        setCompleteArr(arr: any) {
            let action = { type: "completeArr", arr }
            dispatch(action)
        },
        setUploadImgSelect(imgArr: PhoneUploadImgType) {
            let action = { type: "UploadImgArr", imgArr }
            dispatch(action)
        },
        setCompletePercent(num: any) {
            let action = { type: "num", num }
            dispatch(action)
        },
        clearData() {
            let action = { type: "clear" }
            dispatch(action)
        },
        setReportStatus(result: ReportStatusType) {
            let action = { type: "reportStatus", result }
            dispatch(action)
        },
        setIssueData(issueData: IssueDataType) {
            let action = { type: "issueData", issueData }
            dispatch(action)
        },
        setConditionalPassList(conditionalPassList: any[]) {
            let action = { type: "conditionalPass", conditionalPassList }
            dispatch(action)
        },
        setImageStep(imageStep: boolean) {
            let action = { type: "imageStep", imageStep }
            dispatch(action)
        }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(ReportPhone)