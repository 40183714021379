import React, { useState, useEffect } from 'react'
import { useNavigate, Link } from 'react-router-dom';
import Navigation from '@/components/Navigation';
import Header from '@/components/Header';
import { Outlet, useLocation } from 'react-router-dom'
import { prettyLog } from '@/utils';
import '@/styles/home.less'
import { MyContext } from '@/context/MyContext';
const Home: React.FC = () => {
    const location = useLocation()
    const [items, setItems] = useState("");
    const [title, setTitle] = useState("")
    const navigate = useNavigate()
    useEffect(() => {
        let token = localStorage.getItem("Authorization")
        let role = +(localStorage.getItem('role') || 0)
        if (!token) {
            navigate('/login')
        } else if (token && role && location.pathname === "/") {
            navigate('/inspectionPhone')
        } else if (token && !role && location.pathname === "/") {
            navigate('/inspections')
        }
        
    }, [])
    useEffect(()=>{
        const log = prettyLog();
        log.success('艾青', '人间没有永恒的夜晚，世界没有永恒的冬天。');
        log.info('鲁迅','希望是附丽于存在的，有存在，便有希望，有希望，便是光明。')
        log.warning('龚自珍','九州生气恃风雷，万马齐喑究可哀。我劝天公重抖擞，不拘一格降人才。')
        log.error('岳飞','莫等闲，白了少年头，空悲切！')
    },[])
    // useEffect(() => {
    //     let token = localStorage.getItem("Authorization")
    //     let media = window.matchMedia('(max-width: 1600px)').matches
    //     let role = +(localStorage.getItem('role') || 0)
    //     if (!token) {
    //         navigate('/login')
    //     } else if (token && (role === 1 || media) && location.pathname === "/") {
    //         navigate('/inspectionPhone')
    //     } else if (token && (role === 0 && !media) && location.pathname === "/") {
    //         navigate('/inspections')

    //     }
    // }, [])
    return (
        <MyContext.Provider value={{ items, setItems, title, setTitle }}>
            <section className='flex' >
                <Navigation />
                <div style={{ width: "calc(100vw - 200px)", height: "100vh", padding: "0 23px 15px 15px" }} className={` bg-[rgb(249,249,251)]`}>
                    <Header />
                    <Outlet />
                </div>
            </section>
        </MyContext.Provider>
    )
}
export default Home