const defaultState: StoreStateType = {
    completeArr: [],
    completePercent: 0,
    reportStatus: {
        result: "",
        failureCode: {
            status: {
                Functionality: false,
                Structure: false,
                Appearance: false,
                Certification: false,
                Packaging: false,
                Labeling: false
            },
            text: {
                Functionality: "",
                Structure: "",
                Appearance: "",
                Certification: "",
                Packaging: "",
                Labeling: ""
            }
        },
        comment: "",
        conditionalPassComment:""
    },
    issueData: {
        Critical: 0,
        CriticalAccept: 0,
        MajorAccept: 0,
        MajorReject: 0,
        MinorAccept: 0,
        MinorReject: 0
    },
    uploadImgSelect:[],
    conditionalPassList:[],
    imageStep:false

}
function deepClone(obj: any) {
    let clone: any = {};
    for (let key in obj) {
        if (typeof obj[key] === 'object' && obj[key] !== null) {
            clone[key] = Array.isArray(obj[key]) ? [] : {};
            clone[key] = Object.assign(clone[key], deepClone(obj[key]));
        } else {
            clone[key] = obj[key];
        }
    }
    return clone;
}
function clearData(newState: StoreStateType) {
    newState.completeArr = []
    newState.completePercent = 0
    newState.uploadImgSelect = []
    newState.reportStatus = {
        result: "",
        failureCode: {
            status: {
                Functionality: false,
                Structure: false,
                Appearance: false,
                Certification: false,
                Packaging: false,
                Labeling: false
            },
            text: {
                Functionality: "",
                Structure: "",
                Appearance: "",
                Certification: "",
                Packaging: "",
                Labeling: ""
            }
        },
        comment: "",
        conditionalPassComment:""
    }
    sessionStorage.clear()
    let Authorization = localStorage.getItem("Authorization") || ""
    let ItemNo = localStorage.getItem("itemNo") || ""
    let PONumber = localStorage.getItem("PONumber") || ""
    let purchaseOrder_InspectionProjectId = localStorage.getItem("purchaseOrder_InspectionProjectId") || ""
    let inspectedQTY = localStorage.getItem("inspectedQuantity") || ""
    let producedQTY = localStorage.getItem('producedQuantity') || ""
    let aqlQty = localStorage.getItem('aqlQty') || ""
    localStorage.clear()
    localStorage.setItem("PONumber", PONumber)
    localStorage.setItem("itemNo", ItemNo)
    localStorage.setItem("inspectedQuantity", inspectedQTY)
    localStorage.setItem("producedQuantity", producedQTY)
    localStorage.setItem("aqlQty", aqlQty)
    localStorage.setItem("purchaseOrder_InspectionProjectId", purchaseOrder_InspectionProjectId)
    localStorage.setItem("Authorization", Authorization)
}
export default (state = defaultState, action: any) => {
    let newState = deepClone(state)
    switch (action.type) {
        case "completeArr":
            newState.completeArr = action.arr;
            break;
        case "UploadImgArr":
            newState.uploadImgSelect = action.imgArr;
            break;
        case "num":
            newState.completePercent = action.num;
            break;
        case "reportStatus":
            newState.reportStatus = action.result;
            break;
        case "issueData":
            newState.issueData = action.issueData;
            break;
        case "conditionalPass":
            newState.conditionalPassList = action.conditionalPassList;
            break;
        case "imageStep":
            newState.imageStep = action.imageStep;
            break;
        case "clear":
            clearData(newState)
            break;
    }
    return newState
}