import { useEffect, useState } from "react"
import "@/styles/addPoToInspection.less"
import { Button, message } from "antd"
import type { ColumnsType } from 'antd/es/table';
import close from '@/images/navigation/big_close.svg'
import { Input, Select, Form, DatePicker, Table } from 'antd';
import { GetPODetail, AddPOToInspection } from "@/services/inspection";
import { GetInspectionRate } from "@/services/inspectionRate";
import { GetPurchaseOrder } from "@/services/purchaseOrder"
import report from '@/images/navigation/report.png'
import { GetCheckList } from '@/services/checkList';
import InspectionReport from "./InspectionReport";
import moment from "moment";
import { ExportOutlined } from '@ant-design/icons'
import { GetInspectorList } from "@/services/inspection";
import { EditInspectionPurchaseOrder } from "@/services/purchaseOrder";
interface ItemDataType {
    itemNo: string,
    description: string,
    quantity: string,
    comment: string,
}
interface ReportDataType {
    reportId: string
    itemNo: string,
    inspectionDate: string,
    inspectedQty: string,
    passQty: string,
    rejectQty: string,
    InspectionResult: string,
    comment: string,
    inspectResult:string
    failureCode: string,
    ViewReport: string,
}
const EditPo: React.FC<{
    getData: Function
    closePage: Function
    reportOpen: Function
    inspectionId: string
    poNumber?: string
}> = ({ closePage, inspectionId, getData, poNumber }) => {
    const [form] = Form.useForm()
    const [poNumArr, setPoNumArr] = useState<{ value: string; label: string }[]>([])
    const [checkList, setCheckList] = useState([])
    const [itemData, setItemData] = useState<ItemDataType[]>([])
    const [inspectionRateList, setInspectionRateList] = useState([])
    const [submitLoading, setSubmitLoading] = useState(false)
    const [reportOpen, setReportOpen] = useState(false)
    const [assigntoInspectorOptions, setAssigntoInspectorOptions] = useState([])
    const [reportParams, setReportParams] = useState({
        inspectionProjectId: inspectionId,
        ItemNo: "",
        PONumber: ""
    })
    const [reportsData, setReportsData] = useState<ReportDataType[]>([])
    const [loading, setLoading] = useState(false)
    const onFinish = async (values: any) => {
        setSubmitLoading(true)
        const { poNumber, inspectionRateId } = values
        try {
            const res: any = await EditInspectionPurchaseOrder({
                params: {
                    ProjectId: inspectionId,
                    poNumber,
                    inspectionRateId,
                }
            })
            if (res.isSuccess) {
                setSubmitLoading(false)
                message.success(res.message)
                getData()
                closePage()
            } else {
                message.error(res.message)
                setSubmitLoading(false)

            }
        } catch (error: any) {
            console.log(error);
            setSubmitLoading(false)
            message.error(error.message)
        }
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };
    const itemColumns: ColumnsType<ItemDataType> = [
        {
            title: 'Item no.',
            dataIndex: 'itemNo',
            key: 'itemNo',
            width:200,
            sorter: (a, b) => +a.itemNo.replace('-', '') - +b.itemNo.replace('-', ''),
        },
        {
            title: 'Desc.',
            dataIndex: 'description',
            key: 'description',
            sorter:(a, b) =>{
                const aFirstLetter = a.description[0].toLowerCase();
                const bFirstLetter = b.description[0].toLowerCase();
                if (aFirstLetter < bFirstLetter) {
                  return -1;
                }
                if (aFirstLetter > bFirstLetter) {
                  return 1;
                }
                return 0;
              },
        },
        {
            title: 'QTY',
            dataIndex: "quantity",
            key: "quantity",
            align: "right",
            width: 100,
            sorter: (a, b) => +a.quantity - +b.quantity,
            render: (text) => (
                <span>{(text).toLocaleString()}</span>
            )
        },
        {
            title: 'Comment',
            dataIndex: 'comment',
            key: 'comment',
            sorter:(a, b) =>{
                const aFirstLetter = a.comment[0]?.toLowerCase();
                const bFirstLetter = b.comment[0]?.toLowerCase();
                if (aFirstLetter < bFirstLetter) {
                  return -1;
                }
                if (aFirstLetter > bFirstLetter) {
                  return 1;
                }
                return 0;
              },
        },
    ]
    const reportsColumns: ColumnsType<ReportDataType> = [
        {
            title: 'No.',
            dataIndex: 'reportId',
            key: 'reportId',
            sorter: (a, b) => +a.reportId - +b.reportId,
        },
        {
            title: 'Item no.',
            dataIndex: 'itemNo',
            key: 'itemNo',
            sorter: (a, b) => +a.itemNo.replace('-', '') - +b.itemNo.replace('-', ''),
        },
        {
            title: 'Insection Date.',
            dataIndex: 'inspectionDate',
            key: 'inspectionDate',
            width:130,
            sorter: (a, b) => +new Date(a.inspectionDate) - +new Date(b.inspectionDate)
        },
        {
            title: 'Inspected QTY',
            dataIndex: 'inspectedQty',
            key: 'inspectedQty',
            align: "right",
            sorter: (a, b) => +a.inspectedQty - +b.inspectedQty,
        },
        {
            title: 'Pass QTY',
            dataIndex: 'passQty',
            key: 'passQty',
            width:130,
            align: "right",
            sorter: (a, b) => +a.passQty - +b.passQty,
        },
        {
            title: 'Reject QTY',
            dataIndex: 'rejectQty',
            key: 'rejectQty',
            width:130,
            align: "right",
            sorter: (a, b) => +a.rejectQty - +b.rejectQty,
        },
        {
            title: 'Inspection Result',
            dataIndex: 'inspectResult',
            key: 'inspectResult',
            sorter: (a, b) => +a.inspectResult - +b.inspectResult,
            render: (text) => (
                <div className="flex" style={{ display: text === null ? "none" : "" }}>
                    <div className={text === 2 ? "qcStatus_pass" : text === 0 ? "qcStatus_fail" : "qcStatus_default"}>
                        <div className="round"></div>
                        <span>{text === 2 ? "Pass" : text === 0 ? "Fail" : text === 1 ? "Pending" : ""}</span>
                    </div>
                </div>
            )
        },
        {
            title: 'Failure Code',
            dataIndex: 'failureCode',
            key: 'failureCode',
            sorter:(a, b) =>{
                const aFirstLetter = a.failureCode[0]?.toLowerCase();
                const bFirstLetter = b.failureCode[0]?.toLowerCase();
                if (aFirstLetter < bFirstLetter) {
                  return -1;
                }
                if (aFirstLetter > bFirstLetter) {
                  return 1;
                }
                return 0;
              },
        },
        {
            title: 'Comment',
            dataIndex: 'comment',
            key: 'comment',
            width: 365,
            sorter:(a, b) =>{
                const aFirstLetter = a.comment[0]?.toLowerCase();
                const bFirstLetter = b.comment[0]?.toLowerCase();
                if (aFirstLetter < bFirstLetter) {
                  return -1;
                }
                if (aFirstLetter > bFirstLetter) {
                  return 1;
                }
                return 0;
              },
        },
        {
            title: 'View Report',
            dataIndex: 'ViewReport',
            key: 'ViewReport',
            width:200,
            render: (_, record) => (
                <div 
                className="flex"
                onClick={() => {
                    setReportParams({
                        inspectionProjectId: inspectionId,
                        ItemNo: record.itemNo,
                        PONumber: form.getFieldValue("poNumber")
                    })
                    setReportOpen(true)
                }}>
                    <div className="flex">
                        <img style={{ marginRight: "4px" }} className="mr-[4px]" src={report} />
                        <a>Report</a>
                    </div>
                    {/* <div className="flex" style={{marginLeft:"20px"}}>
                        <ExportOutlined width="20px" height="20px" style={{width:"20px",height:"20px",color:"#3B82F6"}}/>
                        <a>Export</a>
                    </div> */}
                </div>
            )
        },
    ]

    const changePoNum = async () => {
        setLoading(true)
        try {
            const res: any = await GetPODetail({ params: { ProjectId: inspectionId, PONumber: poNumber } })
            if (res.isSuccess) {
                setLoading(false)
                const { createdDate, checklistId, inspectionRateId, vendor, status, assignToInspector, area, items, inspectionReports } = res.data
                const dataString = moment(createdDate, 'M/D/YYYY h:mm A')
                form.setFieldsValue({
                    poNumber, status: status.toString(), checklistId, inspectionRateId,
                    createdDate: dataString,
                    assignToInspector, vendor, area
                })
                setItemData(items?.map((item: any) => ({
                    key: item.itemNo,
                    no: "",
                    ...item
                })))
                setReportsData(inspectionReports?.map((item: any) => ({ key: item.reportId, ...item })))
            } else {
                message.error(res.message)
            }
        } catch (error: any) {
            message.error(error.response.statusText);

        }
    }
    const getPoNumber = async () => {
        const res: any = await GetPurchaseOrder()
        if (res.isSuccess) {
            setPoNumArr(res?.data?.data?.map((item: any) => {
                return {
                    value: item.poNumber,
                    label: item.poNumber
                }
            }))
        }
    }
    const getCheckList = async () => {
        const res: any = await GetCheckList()
        if (res.isSuccess) {
            setCheckList(res?.data?.data?.map((item: any) => {
                return {
                    value: item.id,
                    label: item.name
                }
            }))
        }
    }
    const getRateList = async () => {
        const res: any = await GetInspectionRate()
        if (res.isSuccess) {
            setInspectionRateList(res?.data?.data?.map((item: any) => ({
                value: item.id,
                label: item.optionValue
            })))
        }
    }
    const getAssigntoInspector = async () => {
        const res: any = await GetInspectorList({ params: { InspectionProjectId: inspectionId } })
        if (res.isSuccess) {
            setAssigntoInspectorOptions(res.data.map((item: any) => ({ label: item.email, value: item.id })))
        }
    }
    useEffect(() => {
        changePoNum()
        getPoNumber()
        getCheckList()
        getRateList()
        getAssigntoInspector()
    }, [])
    return (
        <>
            <section className="AddPo">
                <div className="head">
                    <h2>Edit PO to inspection</h2>
                    <img style={{ cursor: "pointer" }} onClick={() => closePage(false)} src={close} alt="" />
                </div>
                <Form
                    initialValues={{
                        po: "All"
                    }}
                    form={form}
                    name="basic"
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    layout='vertical'
                >
                    <ul className="generalInfo">
                        <li>
                            <div className="active">General Info</div>
                            <div style={{ visibility: "hidden" }}>History</div>
                        </li>
                        <li>
                            {/* <Button style={{ background: "none" }} onClick={() => form.resetFields()}>Cancel</Button> */}
                            {/* <Button style={{ margin: "0 8px" }}>Send Email</Button> */}
                            {/* <Button type="primary" loading={submitLoading} htmlType="submit">Save</Button> */}
                        </li>
                    </ul>
                    <div className="data_entry">
                        <div>
                            <Form.Item
                                label="PO #"
                                name="poNumber"
                                style={{ width: "32%" }}
                                rules={[{ required: true }]}
                            >
                                <Select
                                    className=""
                                    showSearch
                                    disabled
                                    onChange={changePoNum}
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    options={poNumArr}
                                />
                            </Form.Item>
                            <Form.Item
                                label="Created Date"
                                name="createdDate"
                                style={{ width: "32%" }}
                            >
                                <DatePicker
                                    format="YYYY-MM-DD"
                                    disabled
                                    style={{ width: "100%" }} />
                            </Form.Item>
                            <Form.Item
                                label="Inspection Rate %"
                                name="inspectionRateId"
                                style={{ width: "32%" }}
                                rules={[{ required: true }]}
                            >
                                <Select
                                    className=""
                                    disabled
                                    options={inspectionRateList}
                                />
                            </Form.Item>
                            <Form.Item
                                label="Inspection Checklist Form"
                                name="checklistId"
                                style={{ width: "100%" }}
                                rules={[{ required: true }]}
                            >
                                <Select
                                    disabled
                                    className=""
                                    options={checkList}
                                />
                            </Form.Item>
                        </div>
                        <div>
                            <Form.Item
                                label="Vendor"
                                name="vendor"
                                style={{ width: "80%" }}
                            >
                                <Input disabled />
                            </Form.Item>
                            <Form.Item
                                label="Status"
                                name="status"
                                style={{ width: "18%" }}
                                rules={[{ required: true }]}
                            >
                                <Select
                                    disabled
                                    className=""
                                    options={[
                                        { label: "To Do", value: "0" },
                                        { label: "Active", value: "1" },
                                        { label: "Fail", value: "2" },
                                        { label: "Completed ", value: "3" },
                                    ]}
                                />
                            </Form.Item>
                            <Form.Item
                                label="Assign to Inspector"
                                name="assignToInspector"
                                style={{ width: "48%" }}
                                rules={[{ required: true }]}
                            >
                                <Select
                                    className=""
                                    disabled
                                    options={assigntoInspectorOptions}
                                />
                                {/* <Input disabled /> */}
                            </Form.Item>
                            <Form.Item
                                label="Area"
                                name="area"
                                style={{ width: "48%" }}
                            >
                                <Input disabled />
                            </Form.Item>
                        </div>
                    </div>
                    <div className="tableContent">
                        <h3>Items</h3>
                        <Table
                            className="poTable"
                            columns={itemColumns}
                            dataSource={itemData}
                            scroll={{ y: 200 }}
                            loading={loading}
                            pagination={false}
                        />
                        <h3 style={{ marginTop: "25px" }}>Inspection Reports</h3>
                        <Table
                            className="poTable"
                            columns={reportsColumns}
                            dataSource={reportsData}
                            scroll={{ y: 200 }}
                            loading={loading}
                            pagination={false}
                        />
                    </div>
                </Form>
            </section>
            {reportOpen && <InspectionReport closePage={setReportOpen} reportParams={reportParams} />}
        </>
    )
}
export default EditPo