import React from 'react';
import ReactDOM from 'react-dom/client';
import Route from './route'
import 'antd/dist/reset.css';
import './index.css';
import { ConfigProvider } from 'antd';
import { Provider } from 'react-redux';
import store from '@/store'
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <ConfigProvider
    theme={{
      token: {
        colorPrimary: '#5C7CEA',
      },
    }}
  >
    <Provider store={store}>
      <Route />
    </Provider>
  </ConfigProvider>
);
