import { Modal, Spin, Upload, message } from "antd"
import { InboxOutlined, DeleteTwoTone } from '@ant-design/icons'
import type { UploadProps } from 'antd';
import { GetFile, DeleteFile } from "@/services/inspectionReport";
const { Dragger } = Upload;
interface ReportDataType {
    key:string | number
    id: number
    purchaseOrder_InspectionProjectsID:number
    itemNo: string,
    vendor: string
    poNumber:string
    producedQuantity:number
    inspectionDate: string,
    inspectedQuantity: number,
    passQuantity: number,
    rejectQuantity: number,
    inspector:string
    comment: string,
    isFinal: boolean
    resultStr: string
    failureCodes: {
        failureCode: number,
        comment: string
      }[],
    uploadFileIds: number[]
    getFileStatus: boolean
    fileList: any
    poQuantity: number
    verified:boolean
    conditionalPassComment:string
}
const UploadReportFile: React.FC<{
    reportsData: ReportDataType[],
    setReportsData: Function,
    fileIndex: number,
    fileLoading: boolean,
    setFileLoading: Function,
    isModalOpen: boolean,
    setIsModalOpen: Function,
    itemNo: string,
    purchaseOrder_InspectionProjectsID: number | string
}> = ({
    reportsData,
    setReportsData,
    fileIndex,
    fileLoading,
    setFileLoading,
    isModalOpen,
    setIsModalOpen,
    itemNo,
    purchaseOrder_InspectionProjectsID
}) => {
        const deepCloneArray: any = (arr: any) => {
            const clonedArray = [];
            for (let i = 0; i < arr.length; i++) {
                if (Array.isArray(arr[i])) {
                    clonedArray[i] = deepCloneArray(arr[i]);
                } else {
                    clonedArray[i] = arr[i];
                }
            }
            return clonedArray;
        }
        const props: UploadProps = {
            name: 'formFiles',
            // data:{itemNo},
            multiple: true,
            action: `api/Common/UploadFile?itemNo=${itemNo}&PurchaseOrder_InspectionProjectId=${purchaseOrder_InspectionProjectsID}`,
            headers: {
                Authorization: localStorage.getItem('Authorization') || ''
            },
            fileList: reportsData?.length ? reportsData[fileIndex]?.fileList : [],
            itemRender: (originNode, file: any) => {
                return (
                    <>
                        <div className="uploadBox_">
                            <a 
                                style={{width:'50%'}}
                                onClick={(e) => e.stopPropagation()}
                                href={file.url || URL.createObjectURL(file.originFileObj)}
                                download={file.name}
                                className="download-link"
                            >
                                {file.name}
                                {/* {originNode} */}
                            </a>
                            <span>
                                <DeleteTwoTone onClick={async () => {
                                    setFileLoading(true)
                                    const res: any = await DeleteFile({
                                        params: {
                                            FileId: file.response.data[0],
                                            PurchaseOrder_InspectionProjectId: purchaseOrder_InspectionProjectsID,
                                            ItemNo: reportsData[fileIndex].itemNo
                                        }
                                    })
                                    if (res.isSuccess) {
                                        setFileLoading(false)
                                        const newFileList = [...reportsData[fileIndex]?.fileList];
                                        newFileList.splice(newFileList.findIndex(item => item.uid === file.uid), 1);
                                        reportsData[fileIndex].fileList = newFileList;
                                        setReportsData(deepCloneArray(reportsData));
                                    } else {
                                        setFileLoading(false)
                                        message.error(res.message)
                                    }

                                }} />
                            </span>
                        </div>
                    </>
                )
            },
            onChange(info: any) {
                const { status } = info.file;
                reportsData[fileIndex].fileList = info.fileList
                // setItemData(itemData)
                setReportsData([...reportsData])
                if (status !== 'uploading') {
                    console.log(info.file, info.fileList);
                }
                if (status === 'done') {
                    message.success(`${info.file.name} file uploaded successfully.`);

                } else if (status === 'error') {
                    message.error(`${info.file.name} file upload failed.`);
                }
            },
            onDrop(e) {
                console.log('Dropped files', e.dataTransfer.files);
            },
            onRemove: async (file) => {
                return false
                // return new Promise(async (resolve, reject) => {
                //     setFileLoading(true)
                //     const res: any = await DeleteFile({
                //         params: {
                //             FileId: file.response.data[0],
                //             PurchaseOrder_InspectionProjectId: itemID,
                //             ItemNo: reportsData[fileIndex].itemNo
                //         }
                //     })
                //     if (res.isSuccess) {
                //         setFileLoading(false)
                //         resolve()
                //     } else {
                //         setFileLoading(false)
                //         reject()
                //     }
                // })
            }
        };
        return (
            <Modal
                okButtonProps={{ style: { display: 'none' } }}
                cancelButtonProps={{ style: { display: 'none' } }} title="Upload File" open={isModalOpen} onOk={() => setIsModalOpen(false)} onCancel={() => setIsModalOpen(false)}>
                <Spin spinning={fileLoading}>
                    <div style={{ padding: '10px' }}>
                        <Dragger {...props}>
                            <p className="ant-upload-drag-icon">
                                <InboxOutlined />
                            </p>
                            <p className="ant-upload-text">Click or drag file to this area to upload</p>
                            <p className="ant-upload-hint">
                                Support for a single or bulk upload.
                            </p>
                        </Dragger>
                    </div>
                </Spin>
            </Modal>
        )
    }
export default UploadReportFile