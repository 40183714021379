import React, { useEffect, useContext, useState,Fragment } from "react";
import { Input, Select, Form, Checkbox, Button, Row, Col, message, Spin } from 'antd';
import { MyContext } from "@/context/MyContext";
import { GetTemplateArgumentLi, AddCheckList } from "@/services/checkList";
import { useNavigate } from "react-router-dom";
import "@/styles/checkList.less"
import { PlusCircleOutlined, MinusCircleOutlined } from "@ant-design/icons"
export default function NewCheckList() {
    const navigate = useNavigate()
    const [form] = Form.useForm()
    const { setTitle, setItems } = useContext(MyContext)
    const [allStatus,setAllStatus] = useState(true)
    const [loading, setLoading] = useState(false)
    const [submitLoading, setSubmitLoading] = useState(false)
    const [checkBoxArr, setCheckBoxArr] = useState<{
        id: string,
        argument: string,
        subarguments: { id: number, subargument: string, checked: boolean }[],
        checked: boolean
        open: boolean
    }[]>([])

    const onFinish = async (values: any) => {
        setSubmitLoading(true)
        const { name, description, status } = values
        let argumentIds: any = []
        argumentIds = checkBoxArr.map(item => {
            if (item.checked) {
                return item.id
            }
        }).filter((item) => item !== undefined)
        checkBoxArr.forEach(item => {
            item.subarguments.forEach(item2 => {
                if (item2.checked) {
                    argumentIds.push(item2.id)
                }
            })
        })
        const res: any = await AddCheckList({
            data: {
                name, description, status: +status, argumentIds
            }
        })
        if (res.isSuccess) {
            message.success("Successfully.")
            navigate('/checkList')
            setSubmitLoading(false)
        } else {
            message.error(res.errorMsg)
            setSubmitLoading(false)
        }
    }
    const onFinishFailed = () => {

    }
    const getTemplateList = async () => {
        setLoading(true)
        const res: any = await GetTemplateArgumentLi()
        if (res.isSuccess) {
            setCheckBoxArr(res?.data.map((item: any) => ({
                argument: item.argument,
                id: item.id,
                subarguments: item.subarguments?.map((item: any) => {
                    return {
                        ...item,
                        checked: true
                    }
                }),
                open: true,
                checked: true
            })))
            setLoading(false)
            // form.setFieldsValue({ "argumentIds": res?.data.map((item: any) => item.id) })
        }
    }
    const allChecked = ()=>{
       return checkBoxArr.every(item=>{
            if(item.subarguments.length === 0){
                return item.checked
            }
            return item.checked && item.subarguments.every(item2=>item2.checked)
        })
    }
    useEffect(() => {
        setTitle("New Check List")
        setItems("")
        getTemplateList()

    }, [])
    return (
        <section>
            <div className="page-content" >
                <Form
                    form={form}
                    name="basic"
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    initialValues={{status:"1"}}
                    layout='vertical'
                >
                    <div className='flex justify-between flex-wrap'>
                        <Form.Item
                            label="Name"
                            name="name"
                            style={{ width: "20%" }}
                            rules={[{ required: true, message: 'Please input your project title!' }]}
                        >
                            <Input style={{ display: 'inline-block' }} />
                        </Form.Item>
                        <Form.Item
                            label="Status"
                            name="status"
                            style={{ width: "20%" }}
                            rules={[{ required: true, message: 'Please input your project title!' }]}
                        >
                            <Select
                                options={[
                                    { value: "1", label: "Active" },
                                    { value: "0", label: "Inactive" },
                                ]}
                            >
                            </Select>
                        </Form.Item>
                        <Form.Item
                            label="Description"
                            name="description"
                            style={{ width: "59%" }}
                            rules={[{ required: true, message: 'Please input your project title!' }]}
                        >
                            <Input style={{ display: 'inline-block' }} />
                        </Form.Item>
                    </div>
                    {/* <div className='flex justify-between' style={{flexWrap:"wrap"}}>
                        {checkBoxArr?.map(item => {
                            return (
                                // <Form.Item
                                //     label={item.argument}
                                //     name={item.id}
                                //     style={{ width: "10%" }}
                                //     layout=""
                                //     >
                                //     <Checkbox />

                                // </Form.Item>
                                
                            )
                        })}
                    </div> */}
                    <Checkbox checked={allStatus} onChange={(e)=>{
                        if(e.target.checked){
                            checkBoxArr.forEach(item=>{
                                item.checked = true
                                if(item?.subarguments?.length>0){
                                    item?.subarguments?.forEach(item2=>{
                                        item2.checked = true
                                    })
                                }
                                
                            })
                        }else{
                            checkBoxArr.forEach(item=>{
                                item.checked = false
                                if(item?.subarguments?.length>0){
                                    item?.subarguments?.forEach(item2=>{
                                        item2.checked = false
                                    })
                                }
                            })
                        }
                        setCheckBoxArr(JSON.parse(JSON.stringify(checkBoxArr)))
                        setAllStatus(e.target.checked)
                    }}>Select All/Select None</Checkbox>
                    <div className="templateList">
                        {loading ? (
                            <Spin style={{ marginTop: "100px" }} tip="Loading" size="large">
                                <div className="content" />
                            </Spin>
                        ) : (
                            // <Form.Item name="argumentIds" >
                            <Row>
                                {checkBoxArr?.map((item,index) => {
                                    return (

                                        <Fragment key={index}>
                                            {item.subarguments.length > 0 ? (<>
                                                    <div style={{width:"100%"}}>
                                                        <Checkbox style={{ lineHeight: '32px' }} onChange={(e) => {
                                                            item.checked = e.target.checked
                                                            item.subarguments = item.subarguments.map(item => ({ ...item, checked: e.target.checked }))
                                                            if(!e.target.checked){
                                                                setAllStatus(false)
                                                            }
                                                            if(item.checked && allChecked() ){
                                                                setAllStatus(true)
                                                            }
                                                            setCheckBoxArr(JSON.parse(JSON.stringify(checkBoxArr)))
                                                        }} checked={item.checked}>
                                                            <span style={{ fontWeight: 700 }}>{item.argument}</span>
                                                        </Checkbox>
                                                        <span className="checkbox_icon" onClick={() => {
                                                            item.open = !item.open
                                                            setCheckBoxArr(JSON.parse(JSON.stringify(checkBoxArr)))
                                                            
                                                        }}>{
                                                                item.open ? <MinusCircleOutlined /> : <PlusCircleOutlined />
                                                            }
                                                        </span>
                                                        <div className="child_checkbox" style={{ display: item.open ? "" : "none" }}>
                                                            {item.subarguments.map(item2 => {
                                                                return (
                                                                    <div key={item2.id}>
                                                                        <Checkbox onChange={(e) => {
                                                                            item2.checked = e.target.checked
                                                                            item.checked = item.subarguments.every(item3 => item3.checked)
                                                                            setAllStatus(checkBoxArr.every(item4 => item4.checked))
                                                                            if (!e.target.checked) {
                                                                                item.checked = false
                                                                            }
                                                                            setCheckBoxArr(JSON.parse(JSON.stringify(checkBoxArr)))
                                                                        }} checked={item2.checked} style={{ lineHeight: '32px' }}>
                                                                            {item2.subargument}
                                                                        </Checkbox>
                                                                    </div>
                                                                )
                                                            })}
                                                        </div>
                                                    </div>
                                            </>) : (
                                                <Col key={item.id} span={6}>
                                                    <Checkbox checked={item.checked} onChange={(e) => {
                                                        item.checked = e.target.checked
                                                        setAllStatus(checkBoxArr.every(item4 => item4.checked))
                                                        setCheckBoxArr(JSON.parse(JSON.stringify(checkBoxArr)))
                                                        if(!e.target.checked){
                                                            setAllStatus(false)
                                                        }
                                                    }} style={{ lineHeight: '32px' }}>
                                                        {item.argument}
                                                    </Checkbox>
                                                </Col>
                                            )}

                                        </Fragment>

                                    )
                                })}
                            </Row>
                            // </Form.Item>
                        )}
                    </div>

                    <Button type="primary" htmlType="submit" loading={submitLoading} >Save</Button>
                    <Button type="default" style={{marginLeft:"10px"}} onClick={()=>navigate(-1)}>Cancel</Button>
                </Form>
            </div >

        </section >
    )
}