import { useState, useEffect, useContext } from 'react'
import { Input, Modal, Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { GetPurchaseOrder, GetItemsByPONumber } from '@/services/purchaseOrder';
import edit from '@/images/navigation/edit.png'
import { MyContext } from '@/context/MyContext';
import ItemDetail from './components/ItemDetail';
const PurchaseOrders: React.FC = () => {
    const { Search } = Input;
    const [loading, setLoading] = useState(false)
    const [params, setParams] = useState({})
    const [page, setPage] = useState({ PageIndex: 0, pageSize: 10 })
    const [total, setTotal] = useState(0)
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { setItems, setTitle } = useContext(MyContext)
    const [PONumber, setPoNumArr] = useState("")
    const handleChange = (Vendor: string) => {
        setParams({
            ...params,
            Vendor
        })
        getInspectionTableData({
            ...params,
            Vendor
        })
    };
    const handleStatus = (Status: string) => {
        setParams({
            ...params,
            Status
        })
        getInspectionTableData({
            ...params,
            Status
        })
    }
    interface DataType {
        key: string
        poNumber: string,
        vendorNumber: string,
        vendorName: string,
        inspectionNumber: string,
        items: string,
        qcStatus: string,
        createdDateTime: string,
    }
    const columns: ColumnsType<DataType> = [
        {
            title: 'PO #',
            dataIndex: 'poNumber',
            key: 'poNumber',
            sorter: (a, b) => +a.poNumber - +b.poNumber,
        },
        {
            title: 'Vendor #.',
            dataIndex: 'vendorNumber',
            key: 'vendorNumber',
            sorter: (a, b) => +a.vendorNumber - +b.vendorNumber,
        },
        {
            title: 'Vendor Name',
            dataIndex: 'vendorName',
            key: 'vendorName',
            width: "40%",
            sorter: (a, b) => {
                const aFirstLetter = a.vendorName[0].toLowerCase();
                const bFirstLetter = b.vendorName[0].toLowerCase();
                if (aFirstLetter < bFirstLetter) {
                    return -1;
                }
                if (aFirstLetter > bFirstLetter) {
                    return 1;
                }
                return 0;
            },
        },
        // {
        //     title: 'Inspection #',
        //     key: 'inspectionNumber',
        //     dataIndex: 'inspectionNumber',
        //     render:(text)=>{
        //         console.log(text);

        //         return(<>{text}</>)
        //     }
        // },
        {
            title: 'Items',
            key: 'items',
            dataIndex: 'items',
            align: "right",
            width: 100,
            sorter: (a, b) => +a.items - +b.items,
            render: (text, record) => (<span style={{ cursor: "pointer", color: "#5C7CEA" }} onClick={async () => {
                setPoNumArr(record.poNumber)
                setIsModalOpen(true)
            }}>{text.toLocaleString()}</span>)
        },
        // {
        //     title: 'Status',
        //     key: 'status',
        //     dataIndex: 'status',
        //     width: 100,
        //     render: (text, record) => (
        //         text && <div className="flex">
        //         <div className={
        //             // text === "ToDo" || text === "Completed"  ? "qcStatus_default" :
        //             text === "Active" ? "qcStatus_pass" :
        //                 text === "Fail" ? "qcStatus_fail" : "qcStatus_default"
        //         }>
        //             <div className="round"></div>
        //             <span>{text}</span>
        //         </div>
        //     </div>
        //     ),
        // },
        {
            title: 'Created Date Time',
            key: 'createdDateTime',
            dataIndex: 'createdDateTime',
            sorter: (a, b) => +new Date(a.createdDateTime) - +new Date(b.createdDateTime),
        },
        // {
        //     title: 'Action',
        //     key: 'action',
        //     render: (_, record) => (
        //         <div className='action'>
        //             <img title='Edit' src={edit} alt="" />
        //         </div>
        //     ),
        // },
    ];
    const [data, setData] = useState<DataType[]>([])
    useEffect(() => {
        setItems("")
        getInspectionTableData({ ...page })
    }, [])
    useEffect(() => {
        setTitle("Purchase Orders")
    }, [])
    const onSearch = (Keyword: string) => {
        if (Keyword === "") {
            getInspectionTableData({
                ...params,
                Keyword,
                PageIndex: 0,
                PageSize: 10
            })
            setPage({ PageIndex: 0, pageSize: 10 })
        } else {
            getInspectionTableData({
                ...params,
                Keyword,
                PageIndex: 0,
                PageSize: 10
            })
            setPage({ PageIndex: 0, pageSize: 10 })
            setParams({
                ...params,
                Keyword,
            })
        }

    };
    const getInspectionTableData = async (params?: InspectionAPI.InspectionListRequest) => {
        setLoading(true)
        GetPurchaseOrder({ params }).then((res: any) => {
            if (res.isSuccess) {
                setData(res?.data?.data?.map((item: any) => ({
                    key: item.poNumber,
                    ...item
                })))
                setItems(`(${res.totalCount.toLocaleString()})`)
                setTotal(res.totalCount)
                setLoading(false)
            } else {
                setData([])
                setItems(0)
                setTotal(0)
                setLoading(false)
            }
        }).catch(error => {
            console.log(error);
            setData([])
            setItems(0)
            setTotal(0)
            setLoading(false)
        })

    }
    return (
        <section>
            <div className="flex justify-between">
                <div>
                    <Search
                        placeholder="Search by PO #, Vendor #,Vendor Name"
                        style={{ width: 400 }}
                        className="search"
                        onSearch={onSearch}
                        onChange={(e) => setParams({
                            ...params,
                            Keyword: e.target.value
                        })}
                    />
                </div>
                <div>
                    {/* <span className="vendor">Vendor</span>
                    <Select
                        style={{ width: 120 }}
                        onChange={handleChange}
                        className="select"
                        defaultValue="all"
                        options={[
                            { value: 'all', label: 'All' },
                            { value: 'lucy', label: 'Lucy' },
                            { value: 'Yiminghe', label: 'yiminghe' },
                        ]}
                    /> */}
                    {/* <span className="status">Status</span>
                    <Select
                        style={{ width: 120 }}
                        onChange={handleStatus}
                        className="select"
                        defaultValue="all"
                        options={[
                            { value: 'all', label: 'All' },
                            { value: 'To Do', label: 'To Do' },
                            { value: 'Active', label: 'Active' },
                            { value: 'Fail', label: 'Fail' },
                            { value: 'Completed', label: 'Completed' },
                        ]}
                    /> */}
                </div>
            </div>
            <div className="page-content bg-white" style={{ height: 200 }}>
                <Table
                    className="qc-table"
                    columns={columns}
                    dataSource={data}
                    loading={loading}
                    scroll={{ y: 550, x: document.body.clientWidth < 1750 ? 1600 : undefined }}
                    pagination={{
                        current: page.PageIndex + 1,
                        pageSize: page.pageSize,
                        total,
                        onChange: (page, pageSize) => {
                            setPage({ PageIndex: page - 1, pageSize: pageSize })
                            getInspectionTableData({
                                ...params, PageIndex: page - 1, PageSize: pageSize
                            })
                        }
                    }}
                />
            </div>
            {isModalOpen && <ItemDetail PONumber={PONumber} isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />}
        </section>
    )
}
export default PurchaseOrders;