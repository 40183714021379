import React, { useEffect, useContext, useState, Fragment } from "react";
import { Input, Select, Form, Checkbox, Button, Row, Col, message, Spin } from 'antd';
import { MyContext } from "@/context/MyContext";
import { GetTemplateArgumentLi, AddCheckList } from "@/services/checkList";
import { useNavigate } from "react-router-dom";
import "@/styles/newCheckList.less"
import { PlusCircleOutlined, MinusCircleOutlined,DeleteOutlined } from "@ant-design/icons"
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
export default function NewCheckList2() {
    const navigate = useNavigate()
    const [form] = Form.useForm()
    const { setTitle, setItems } = useContext(MyContext)
    const [allStatus, setAllStatus] = useState(true)
    const [loading, setLoading] = useState(false)
    const [submitLoading, setSubmitLoading] = useState(false)
    const [checkBoxArr, setCheckBoxArr] = useState<CheckBoxType>([])
    const [Fold, setFold] = useState(true)
    type CheckBoxType = {
        id: number,
        argument: string,
        subarguments: { id: number, subargument: string, checked: boolean, isShow: boolean }[],
        checked: boolean
        open: boolean
        isShow: boolean
    }[]
    const [itemList, setItemList] = useState<{
        argument: string
        id: number
        status: boolean
        checked: boolean
        subarguments: {
            subargument: string
            id: number
            checked: boolean
        }[]
    }[]>([])
    const onFinish = async (values: any) => {
        setSubmitLoading(true)
        const { name, description, status } = values
        let argumentIds: any = []
        // argumentIds = checkBoxArr.map(item => {
        //     if (item.checked) {
        //         return item.id
        //     }
        // }).filter((item) => item !== undefined)
        // checkBoxArr.forEach(item => {
        //     item.subarguments.forEach(item2 => {
        //         if (item2.checked) {
        //             argumentIds.push(item2.id)
        //         }
        //     })
        // })
        let num = 0
        itemList.forEach((x, idx) => {
            if (x.subarguments.length) {
                argumentIds.push(...x.subarguments.map((x2, idx2) => ({
                    argumentId: x2.id,
                    name: x2.subargument
                })))
                num += x.subarguments.length
            } else {
                argumentIds.push({
                    argumentId: x.id,
                    name: x.argument
                })
            }

        })
        let argumentArr = argumentIds.map((item: any, index: number) => ({
            argumentId: item.argumentId,
            sort: index,
        }))
        const res: any = await AddCheckList({
            data: {
                name, description, status: +status, arguments: argumentArr
            }
        })
        if (res.isSuccess) {
            message.success("Successfully.")
            navigate(-1)
            setSubmitLoading(false)
        } else {
            message.error(res.message)
            setSubmitLoading(false)
        }
    }
    const onFinishFailed = () => {

    }
    const getTemplateList = async () => {
        setLoading(true)
        const res: any = await GetTemplateArgumentLi()
        if (res.isSuccess) {
            setCheckBoxArr(res?.data.map((item: any) => ({
                argument: item.argument,
                id: item.id,
                subarguments: item.subarguments?.map((item: any) => {
                    return {
                        ...item,
                        checked: true,
                        isShow: true
                    }
                }),
                open: true,
                checked: true,
                isShow: true
            })))
            setLoading(false)
            getItemList(res?.data.map((item: any) => ({
                argument: item.argument,
                id: item.id,
                subarguments: item.subarguments?.map((item: any) => {
                    return {
                        ...item,
                        checked: true
                    }
                }),
                open: true,
                checked: true
            })))
            // form.setFieldsValue({ "argumentIds": res?.data.map((item: any) => item.id) })
        }
    }
    const allChecked = () => {
        return checkBoxArr.every(item => {
            if (item.subarguments.length === 0) {
                return item.checked
            }
            return item.checked && item.subarguments.every(item2 => item2.checked)
        })
    }
    const getItemList = (arr: CheckBoxType) => {
        let itemArr: any = []
        arr.forEach(item => {
            itemArr.push({
                argument: item.argument,
                status: item.checked,
                checked: item.checked,
                id: item.id,
                subarguments: item.subarguments.map(item2 => ({
                    subargument: item2.subargument,
                    id: item2.id,
                    checked: item2.checked
                }))
            })
        })
        setItemList(itemArr)
    }
    const addOption = (item: { id: any; argument: any; subarguments: any; checked?: boolean; open?: boolean; }, ischecked: boolean) => {
        if (ischecked) {
            itemList.forEach((x, i) => {
                if (x.id === +item.id) {
                    itemList.splice(i, 1)
                }
            })
            itemList.push({
                argument: item.argument,
                id: item.id,
                subarguments: item.subarguments?.map((item: any) => {
                    return {
                        ...item,
                        checked: true
                    }
                }),
                status: true,
                checked: true,
            })
        } else {
            itemList.forEach((x, i) => {
                if (x.id === +item.id) {
                    itemList.splice(i, 1)
                }
            })
        }
    }
    const searchAttrbute = (e: React.ChangeEvent<HTMLInputElement>) => {
        let keyWord = e.target.value.toLowerCase()
        checkBoxArr.forEach(x=>{
            x.open = true
        })
        if(e.target.value === ""){
            checkBoxArr.forEach(x=>{
                x.isShow = true
                x.subarguments.forEach(x2=>{
                    x2.isShow = true
                })
            })
        }else{
            checkBoxArr.forEach(x1 => {
                if (x1.argument.toLowerCase().indexOf(keyWord) !== -1) {
                    x1.isShow = true
                    x1.subarguments.forEach(x2=>{
                        x2.isShow = true
                    })
                } else {
                    if(x1.subarguments.some(x2=>x2.subargument.toLowerCase().indexOf(keyWord) !==-1)){
                        x1.isShow = true
                    }else{
                        x1.isShow = false
                    }
                    x1.subarguments.forEach(x2 => {
                        if (x2.subargument.toLowerCase().indexOf(keyWord) !== -1) {
                            x2.isShow = true
                        } else {
                            x2.isShow = false
                        }
                    })
                }
            })
        }
        setCheckBoxArr(JSON.parse(JSON.stringify(checkBoxArr)))
    }
    useEffect(() => {
        setTitle("New Check List")
        setItems("")
        getTemplateList()
    }, [])

    return (
        <section>
            <div className=" newCheckList" >
                <Form
                    form={form}
                    name="basic"
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    initialValues={{ status: "1" }}
                    layout='vertical'
                >
                    <ul className="generalInfo">
                        <li>
                            <div className="active">General Info</div>
                            <div style={{ visibility: "hidden" }}>History</div>
                        </li>
                        <li>
                            {/* <Button style={{ background: "none" }} onClick={() => form.resetFields()}>Cancel</Button> */}
                            {/* <Button style={{ margin: "0 8px" }}>Send Email</Button> */}
                            <Button type="primary" loading={submitLoading} htmlType="submit">Save</Button>
                        </li>
                    </ul>
                    <div className='change_data'>
                        <Form.Item
                            label="Name"
                            name="name"
                            style={{ width: "20%" }}
                            rules={[{ required: true, message: 'Please input your project title!' }]}
                        >
                            <Input style={{ display: 'inline-block' }} />
                        </Form.Item>
                        <Form.Item
                            label="Status"
                            name="status"
                            style={{ width: "20%" }}
                            rules={[{ required: true, message: 'Please input your project title!' }]}
                        >
                            <Select
                                options={[
                                    { value: "1", label: "Active" },
                                    { value: "0", label: "Inactive" },
                                ]}
                            >
                            </Select>
                        </Form.Item>
                        <Form.Item
                            label="Description"
                            name="description"
                            style={{ width: "59%" }}
                            rules={[{ required: true, message: 'Please input your project title!' }]}
                        >
                            <Input style={{ display: 'inline-block' }} />
                        </Form.Item>
                    </div>
                    {/* <div className='flex justify-between' style={{flexWrap:"wrap"}}>
                        {checkBoxArr?.map(item => {
                            return (
                                // <Form.Item
                                //     label={item.argument}
                                //     name={item.id}
                                //     style={{ width: "10%" }}
                                //     layout=""
                                //     >
                                //     <Checkbox />

                                // </Form.Item>
                                
                            )
                        })}
                    </div> */}
                    <ul className="newCheckList_content">
                        <li>
                            <h3>Optional</h3>
                            <div className="checkboxdiv">
                                <div className="select_all"  style={{padding:"10px 14px"}}>
                                    <div><Checkbox checked={allStatus} onChange={(e) => {
                                        if (e.target.checked) {
                                            checkBoxArr.forEach(item => {
                                                item.checked = true
                                                if (item?.subarguments?.length > 0) {
                                                    item?.subarguments?.forEach(item2 => {
                                                        item2.checked = true
                                                    })
                                                }

                                            })
                                            getItemList(checkBoxArr)
                                        } else {
                                            checkBoxArr.forEach(item => {
                                                item.checked = false
                                                if (item?.subarguments?.length > 0) {
                                                    item?.subarguments?.forEach(item2 => {
                                                        item2.checked = false
                                                    })
                                                }
                                            })

                                            setItemList([])
                                        }
                                        setCheckBoxArr(JSON.parse(JSON.stringify(checkBoxArr)))
                                        setAllStatus(e.target.checked)
                                    }}>Select All/Select None</Checkbox> </div>
                                    <Input style={{ width: "200px" }} onChange={searchAttrbute} placeholder="Search Attrbute" />
                                </div>
                                <div className="templateList">
                                    {loading ? (
                                        <Spin style={{ marginTop: "100px" }} tip="Loading" size="large">
                                            <div className="content" />
                                        </Spin>
                                    ) : (
                                        // <Form.Item name="argumentIds" >
                                        <Row>
                                            {checkBoxArr?.map((item, index) => {
                                                return (
                                                    <Fragment key={index}>
                                                        {item.subarguments.length > 0 ? (<>
                                                            <div style={{ width: "100%", display: item.isShow ? "" : "none" }}>
                                                                <Checkbox className="checkbox_css" style={{ lineHeight: '32px' }} onChange={(e) => {
                                                                    item.checked = e.target.checked
                                                                    item.subarguments = item.subarguments.map(item => ({ ...item, checked: e.target.checked }))
                                                                    if (!e.target.checked) {
                                                                        setAllStatus(false)
                                                                    }
                                                                    if (item.checked && allChecked()) {
                                                                        setAllStatus(true)
                                                                    }
                                                                    setCheckBoxArr(JSON.parse(JSON.stringify(checkBoxArr)))

                                                                    addOption(item, e.target.checked)
                                                                }} checked={item.checked}>
                                                                    <span >{item.argument}</span>
                                                                </Checkbox>
                                                                <span className="checkbox_icon" onClick={() => {
                                                                    item.open = !item.open
                                                                    setCheckBoxArr(JSON.parse(JSON.stringify(checkBoxArr)))

                                                                }}>{
                                                                        item.open ? <MinusCircleOutlined /> : <PlusCircleOutlined />
                                                                    }
                                                                </span>
                                                                <div className="child_checkbox" style={{ display: item.open ? "" : "none" }}>
                                                                    {item.subarguments.map(item2 => {
                                                                        return (
                                                                            <div key={item2.id} style={{ display: item2.isShow ? "" : "none" }}>
                                                                                <Checkbox className="checkbox_css_child" onChange={(e) => {
                                                                                    item2.checked = e.target.checked
                                                                                    item.checked = item.subarguments.every(item3 => item3.checked)
                                                                                    setAllStatus(checkBoxArr.every(item4 => item4.checked))
                                                                                    if (!e.target.checked) {
                                                                                        item.checked = false
                                                                                    }
                                                                                    setCheckBoxArr(JSON.parse(JSON.stringify(checkBoxArr)))

                                                                                    if (e.target.checked) {
                                                                                        itemList.forEach((x, i) => {
                                                                                            if (x.id === item.id) {
                                                                                                itemList[i].subarguments.push({
                                                                                                    subargument: item2.subargument,
                                                                                                    id: item2.id,
                                                                                                    checked: item2.checked
                                                                                                })
                                                                                            }
                                                                                        })
                                                                                        if (!itemList.some(x => x.id === item.id)) {
                                                                                            itemList.push({
                                                                                                argument: item.argument,
                                                                                                id: item.id,
                                                                                                status: Fold,
                                                                                                checked: true,
                                                                                                subarguments: [{
                                                                                                    subargument: item2.subargument,
                                                                                                    id: item2.id,
                                                                                                    checked: item2.checked
                                                                                                }]
                                                                                            })
                                                                                        }
                                                                                    } else {
                                                                                        itemList.forEach((x, i) => {
                                                                                            if (x.id === item.id) {
                                                                                                itemList[i].subarguments.forEach((x2, i2) => {
                                                                                                    if (x2.id === item2.id) {
                                                                                                        itemList[i].subarguments.splice(i2, 1)
                                                                                                    }
                                                                                                })
                                                                                                !itemList[i].subarguments.length && itemList.splice(i, 1)

                                                                                            }
                                                                                        })

                                                                                    }

                                                                                }} checked={item2.checked} style={{ lineHeight: '32px' }}>
                                                                                    {item2.subargument}
                                                                                </Checkbox>
                                                                            </div>
                                                                        )
                                                                    })}
                                                                </div>
                                                            </div>
                                                        </>) : (
                                                            <Col key={item.id} span={24} style={{ display: item.isShow ? "" : "none" }}>
                                                                <Checkbox checked={item.checked} onChange={(e) => {
                                                                    item.checked = e.target.checked
                                                                    setAllStatus(checkBoxArr.every(item4 => item4.checked))
                                                                    setCheckBoxArr(JSON.parse(JSON.stringify(checkBoxArr)))
                                                                    if (!e.target.checked) {
                                                                        setAllStatus(false)
                                                                    }

                                                                    if (e.target.checked) {
                                                                        itemList.push({
                                                                            argument: item.argument,
                                                                            id: item.id,
                                                                            subarguments: item.subarguments?.map((item: any) => {
                                                                                return {
                                                                                    ...item,
                                                                                    checked: true
                                                                                }
                                                                            }),
                                                                            status: Fold,
                                                                            checked: true,
                                                                        })
                                                                    } else {
                                                                        itemList.forEach((x, i) => {
                                                                            if (x.id === +item.id) {
                                                                                itemList.splice(i, 1)
                                                                            }
                                                                        })
                                                                    }
                                                                }} style={{ lineHeight: '32px' }}>
                                                                    {item.argument}
                                                                </Checkbox>
                                                            </Col>
                                                        )}

                                                    </Fragment>

                                                )
                                            })}
                                        </Row>
                                        // </Form.Item>
                                    )}
                                </div>
                            </div>
                        </li>
                        <li>
                            <h3>Fold/Unfold</h3>
                            <div className="selectedBox">
                                <div className="select_all">
                                    <div>
                                        <Checkbox checked={Fold} onChange={(e) => {
                                            itemList.forEach(item => item.status = e.target.checked)
                                            setItemList(JSON.parse(JSON.stringify(itemList)))
                                            setFold(e.target.checked)
                                        }}>Fold/Unfold</Checkbox>
                                    </div>
                                </div>
                                <DragDropContext onDragEnd={(result) => {
                                    if (!result.destination) return; // 如果没有有效的目标位置，则不执行任何操作

                                    const updatedList = Array.from(itemList);
                                    const [removed] = updatedList.splice(result.source.index, 1);
                                    updatedList.splice(result.destination.index, 0, removed);

                                    setItemList(updatedList);
                                }}>
                                    <Droppable droppableId="sortable-list">
                                        {(provided) => (
                                            <div className="selected" {...provided.droppableProps} ref={provided.innerRef}>
                                                {
                                                    itemList.map((item, index) => {
                                                        return (
                                                            <Draggable key={item.id} draggableId={item.argument} index={index}>
                                                                {(provided) => (
                                                                    <div
                                                                        ref={provided.innerRef}
                                                                        {...provided.draggableProps}
                                                                        {...provided.dragHandleProps}
                                                                        key={item.id}>
                                                                        <Fragment >
                                                                            <div className="selectItem" >
                                                                                <span>{item.argument} {
                                                                                    item.subarguments.length > 0 && <span onClick={() => {
                                                                                        item.status = !item.status
                                                                                        if (itemList.every(x => x.status)) {
                                                                                            setFold(true)
                                                                                        } else {
                                                                                            setFold(false)
                                                                                        }
                                                                                        setItemList(JSON.parse(JSON.stringify(itemList)))
                                                                                    }}>
                                                                                        {item.status ?
                                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                                                <path d="M11.4234 8.03798L7.81842 11.6429C7.37936 12.082 7.28138 12.5844 7.52448 13.1501C7.76758 13.7158 8.20064 13.9991 8.82365 14H15.9642C16.5881 14 17.0217 13.7167 17.2648 13.1501C17.5079 12.5834 17.4094 12.0811 16.9694 11.6429L13.3645 8.03798C13.2258 7.89933 13.0756 7.79534 12.9139 7.72602C12.7521 7.65669 12.5788 7.62203 12.3939 7.62203C12.2091 7.62203 12.0357 7.65669 11.874 7.72602C11.7122 7.79534 11.562 7.89933 11.4234 8.03798Z" fill="#7D8FB3" />
                                                                                            </svg> :
                                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                                                <path d="M11.4234 15.962L7.81842 12.3571C7.37936 11.918 7.28138 11.4156 7.52448 10.8499C7.76758 10.2842 8.20064 10.0009 8.82365 10H15.9642C16.5881 10 17.0217 10.2833 17.2648 10.8499C17.5079 11.4166 17.4094 11.9189 16.9694 12.3571L13.3645 15.962C13.2258 16.1007 13.0756 16.2047 12.9139 16.274C12.7521 16.3433 12.5788 16.378 12.3939 16.378C12.2091 16.378 12.0357 16.3433 11.874 16.274C11.7122 16.2047 11.562 16.1007 11.4234 15.962Z" fill="#7D8FB3" />
                                                                                            </svg>}

                                                                                    </span>
                                                                                }</span>
                                                                                
                                                                                <DeleteOutlined style={{cursor:"pointer",color:"red",fontSize:"20px"}} onClick={(e)=>{
                                                                                    e.stopPropagation()
                                                                                    checkBoxArr.forEach((x,i)=>{
                                                                                        if(x.id === item.id){
                                                                                            x.checked = false
                                                                                            x.subarguments.forEach(x2=>{x2.checked = false})
                                                                                            itemList.splice(index,1)
                                                                                        }
                                                                                    })
                                                                                    setItemList(JSON.parse(JSON.stringify(itemList)))
                                                                                    setCheckBoxArr(JSON.parse(JSON.stringify(checkBoxArr)))
                                                                                }} />
                                                                            </div>
                                                                            {item.subarguments.length > 0 && <ul style={{ display: item.status ? "" : "none", height: item.status ? "" : "0" }} className="selectItemChild">
                                                                                {
                                                                                    item.subarguments.map((item2,index2) => (
                                                                                        item2.checked && <li key={item2.id}>
                                                                                            {item2.subargument}
                                                                                            <DeleteOutlined style={{cursor:"pointer",color:"red",fontSize:"20px"}} onClick={(e)=>{
                                                                                                    e.stopPropagation()
                                                                                                    checkBoxArr.forEach((x,i)=>{
                                                                                                        if(x.id === item.id){
                                                                                                            x.checked = false
                                                                                                            // x.subarguments.forEach(x2=>{x2.checked = false})
                                                                                                            x.subarguments.forEach(x2=>{
                                                                                                                if(x2.id === item2.id){
                                                                                                                    x2.checked = false
                                                                                                                }
                                                                                                            })
                                                                                                            itemList[index].subarguments.splice(index2,1)
                                                                                                        }
                                                                                                    })
                                                                                                    checkBoxArr.forEach(x3=>{
                                                                                                        if(x3.id === item.id){
                                                                                                            if(x3.subarguments.length && !item.subarguments.length){
                                                                                                                itemList.splice(index,1)
                                                                                                            }
                                                                                                        }
                                                                                                    })
                                                                                                    setItemList(JSON.parse(JSON.stringify(itemList)))
                                                                                                    setCheckBoxArr(JSON.parse(JSON.stringify(checkBoxArr)))
                                                                                            }}/>
                                                                                        </li>
                                                                                    ))
                                                                                }
                                                                            </ul>
                                                                            }
                                                                        </Fragment>
                                                                    </div>

                                                                )}
                                                            </Draggable>
                                                        )
                                                    })
                                                }
                                                {provided.placeholder}
                                            </div>
                                        )}
                                    </Droppable>
                                </DragDropContext>
                            </div>
                        </li>
                    </ul>

                    <Button type="primary" htmlType="submit" loading={submitLoading} >Save</Button>
                    <Button type="default" style={{ marginLeft: "10px" }} onClick={() => navigate(-1)}>Cancel</Button>
                </Form>
            </div >

        </section >
    )
}