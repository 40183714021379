import React, { useRef, useState, useEffect } from 'react'
import { InputRef, message } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { Input, Select, Form, Modal, Tag, theme, Spin, AutoComplete } from 'antd';
import close from '@/images/navigation/close.png'
import { EditInspectionProject, GetEditDetail, GetInspectorList } from '@/services/inspection';
const NewInspection: React.FC<{
    isModalOpen: boolean
    handleOk: () => void
    handleCancel: () => void
    id: number
    updateList: boolean
    setUpdateList: Function
}> = ({
    isModalOpen,
    handleOk,
    handleCancel,
    id, updateList,
    setUpdateList
}) => {
        const [form] = Form.useForm()
        const [options, setOptions] = useState<any>([])
        const { token } = theme.useToken();
        const [oldOptions, setOldOptions] = useState([])
        const [tags, setTags] = useState<string[]>([]);
        const [inputVisible, setInputVisible] = useState(false);
        const [inputValue, setInputValue] = useState('');
        const [inspectorId, setInspectorId] = useState(0)
        const [errorStatus, setErrorStatus] = useState(false)
        const [loading, setLoading] = useState(false)
        const [dataLoading, setDataLoading] = useState(false)
        const inputRef = useRef<any>(null);
        const onFinish = async (values: any) => {
            setLoading(true)
            const { type, status } = values
            setErrorStatus(true)
            if (!tags.length) {
                return
            } else {
                setErrorStatus(false)
            }
            let inspectorIds = oldOptions.map((item: any) => {
                for (let i = 0; i < tags.length; i++) {
                    if (tags[i] === item.value) {
                        return item.id
                    }
                }
            }).filter((item) => item !== undefined)
            EditInspectionProject({
                data: {
                    id,
                    ...values,
                    type: +type,
                    status: +status,
                    // inspectorId
                    inspectorIds
                }
            }).then((res: any) => {
                if (res.isSuccess) {
                    setDataLoading(false)
                    message.success('Successfully.')
                    handleCancel()
                    initialize()
                    setUpdateList(!updateList)
                } else {
                    setDataLoading(false)
                    message.error(res.message)
                }
            }).catch(error => {
                console.log(error);
                setDataLoading(false)

            })

        };
        const onFinishFailed = (errorInfo: any) => {
            console.log('Failed:', errorInfo);
            setErrorStatus(true)
        };
        useEffect(() => {
            if (inputVisible) {
                inputRef.current?.focus();
            }
        }, [inputVisible]);
        const handleClose = (removedTag: string) => {
            const newTags = tags.filter((tag) => tag !== removedTag);
            console.log(newTags);
            setTags(newTags);
        };
        const showInput = () => {
            setInputVisible(true);
        };
        const handleInputChange = (value: any, option: any) => {
            setInputValue(value);
            setInspectorId(() => option.id)

        };
        const handleInputConfirm = () => {
            let flag = false
            options.forEach((item: any) => {
                if (item.value === inputValue) {
                    flag = true
                }
            })
            if (!flag) {
                setInputVisible(false);
                setInputValue('');
                return
            }
            if (inputValue && tags.indexOf(inputValue) === -1) {
                setTags([...tags, inputValue]);
            }

            setInputVisible(false);
            setInputValue('');
        };
        const forMap = (tag: string) => {
            const tagElem = (
                <Tag
                    closable
                    className='qcTag'
                    onClose={(e) => {
                        e.preventDefault();
                        handleClose(tag);
                    }}
                >
                    {tag}
                </Tag>
            );
            return (
                <span key={tag} style={{ display: 'inline-block' }}>
                    {tagElem}
                </span>
            );
        };

        const tagChild = tags.map(forMap);
        const tagPlusStyle = {
            background: token.colorBgContainer,
            borderStyle: 'dashed',
            // height: "28px",
            // display: "flex",
            // alignItems: "center",
            // width: "78px",
            cursor: "pointer"
        };
        const initialize = () => {
            form.resetFields()
            setTags([])
            setInspectorId(0)
            setErrorStatus(false)
        }
        const getInspectorData = () => {
            GetInspectorList({}).then((res: any) => {
                if (res.isSuccess) {
                    let obj: any = []
                    res.data.forEach((item: any) => {
                        obj.push({
                            id: item.id,
                            // value: item.email
                            value:item.userName
                        })
                    })
                    setOptions(obj)
                    setOldOptions(obj)
                }
            }).catch(error => {
                setDataLoading(false)
                console.log(error);

            })
        }
        useEffect(() => {
            getInspectorData()
            getEditData()
        }, [])
        const getEditData = () => {
            setDataLoading(true)
            GetEditDetail({ params: { id } }).then((res: any) => {
                if (res.isSuccess) {
                    setDataLoading(false)
                    const { title, type, status, assignToInspectors } = res.data
                    form.setFieldsValue({
                        title,
                        type: String(type),
                        status: String(status)
                    })
                    // setTags([assignToInspector?.email])
                    setTags(assignToInspectors?.map((item: any) => (
                        item.userName
                    )))
                    // setInspectorId(assignToInspector?.id)
                } else {
                    setDataLoading(false)
                }
            })
        }
        useEffect(() => {
            if (isModalOpen) {
                getEditData()
            }
        }, [isModalOpen])
        return (
            <>

                <Modal
                    title="Edit Inspection Project"
                    open={isModalOpen}
                    maskClosable={false}
                    onOk={() => form.submit()}
                    confirmLoading={loading}
                    onCancel={() => {
                        handleCancel()
                        initialize()
                    }}
                    destroyOnClose={true}
                    okText="Save"
                    width={620}

                >
                    <div className='modal_content'>
                        <Spin spinning={dataLoading}>
                            <Form
                                form={form}
                                name="basic"
                                onFinish={onFinish}
                                onFinishFailed={onFinishFailed}
                                layout='vertical'
                                initialValues={{type:"0"}}
                            >
                                <div className='flex justify-between'>
                                    <Form.Item
                                        label="Project Title"
                                        name="title"
                                        style={{ width: "100%" }}
                                        rules={[{ required: true, message: 'Please input your project title!' }]}
                                    >
                                        <Input style={{ display: 'inline-block' }} />
                                    </Form.Item>
                                    {/* <Form.Item
                                    label="  "
                                    name="aa"
                                    style={{ width: "80%" }}
                                // rules={[{ required: true, message: 'Please input your username!' }]}
                                >
                                    <Input style={{ display: 'inline-block' }} />
                                </Form.Item> */}
                                </div>
                                <div className='flex justify-between'>
                                    <Form.Item
                                        label="Project Type"
                                        name="type"
                                        style={{ width: "100%" }}
                                        rules={[{ required: true }]}
                                    >
                                        <Select
                                            className=""
                                            options={[
                                                { value: '0', label: 'Normal' },
                                            ]}
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        label="Status"
                                        name="status"
                                        style={{ width: "48%" ,display:"none"}}
                                        rules={[{ required: true }]}
                                    >
                                        <Select
                                            className=""
                                            options={[
                                                { value: '0', label: 'ToDo' },
                                                { value: '1', label: 'Active' },
                                                { value: '2', label: 'Fail' },
                                                { value: '3', label: 'Completed' },
                                            ]}
                                        />
                                    </Form.Item>
                                </div>
                                <h3><span style={{ color: "#ff4d4f", marginRight: "4px" }}>*</span>Assign to Inspector</h3>
                                <div className='Assign_to_Inspector' style={{ borderColor: errorStatus && tags.length <= 0 ? "#ff7875" : "", transition: "all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1)" }}>
                                    <div>
                                        {tagChild}
                                        {inputVisible ? (
                                            <AutoComplete
                                                ref={inputRef}
                                                style={{ width: 200 }}
                                                options={options}
                                                value={inputValue}
                                                onChange={handleInputChange}
                                                placeholder=""
                                                defaultActiveFirstOption
                                                onBlur={handleInputConfirm}
                                                filterOption={(inputValue, option: any) =>
                                                    option!.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                                }
                                                onKeyDown={(e) => {
                                                    if (e.key === "Enter") {
                                                        let flag = false
                                                        options.forEach((item: any) => {
                                                            if (item.value === inputValue) {
                                                                flag = true
                                                            }
                                                        })
                                                        if (flag) {
                                                            handleInputConfirm()
                                                        }
                                                    }
                                                }}
                                            />
                                        ) : (
                                            <Tag onClick={showInput} style={tagPlusStyle}>
                                                <PlusOutlined />
                                            </Tag>
                                        )}
                                    </div>
                                </div>
                                <h3 style={{ display: errorStatus && tags.length <= 0 ? "" : "none", height: errorStatus && tags.length <= 0 ? "" : "0" }} className='InspectorError'>Please select Inspector</h3>
                            </Form >
                        </Spin>

                    </div>
                </Modal>
            </>
        )
    }

export default NewInspection