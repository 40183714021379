import { useState, useEffect, useContext } from 'react'
import { Button } from "antd";
import { Input, Space, Select, Table, Modal } from 'antd';
import file from '@/images/navigation/file.png'
import '@/styles/inspection.less'
import type { ColumnsType } from 'antd/es/table';
import report from '@/images/navigation/report.png'
import add from '@/images/navigation/add.png'
import "@/styles/addPoToInspection.less"
import { CaretDownOutlined, CaretRightOutlined } from '@ant-design/icons';
import { GetInspectionList } from '@/services/inspection';
import { GetCheckList } from '@/services/checkList';
import { GetUserList } from '@/services/login';
import edit from '@/images/navigation/edit.png'
import { MyContext } from '@/context/MyContext';
import { Link, useNavigate } from 'react-router-dom';
import EditUser from './components/EditUser';
import AddUser from './components/AddUser';
const User: React.FC = () => {
    const { Search } = Input
    const Navigate = useNavigate()
    const { setItems, setTitle } = useContext(MyContext)
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [editOpen, setEditOpen] = useState(false);
    const [editId, setEditId] = useState(0)
    const [addToPoOpen, setAddToPoOpen] = useState(false)
    const [total, setTotal] = useState(0)
    const [page, setPage] = useState({ PageIndex: 0, pageSize: 10 })
    const [loading, setLoading] = useState(false)
    const [params, setParams] = useState<any>({})
    const [updateList, setUpdateList] = useState(false)
    const [inspectionId, setInspectionId] = useState("")
    const handleChange = (Status: string) => {
        params.Status = Status
        setParams(params)
        getInspectionTableData({
            ...params,
            PageIndex: 0,
            PageSize: 10
        })
        setPage({ PageIndex: 0, pageSize: 10 })
    };
    const handleStatus = (Role: string) => {
        params.Role = Role
        setParams(params)
        getInspectionTableData({
            ...params,
            PageIndex: 0,
            PageSize: 10
        })
        setPage({ PageIndex: 0, pageSize: 10 })
    }
    const openPoModal = (num: any) => {
        setAddToPoOpen(true)
        // setPONumber(num)
        console.log(num);
        setInspectionId(num.InspectionID)

    }
    interface DataType {
        key: string
        userName: string
        id: number
        email: string,
        userRole: string,
        dateCreated: string,
        userStatus: string
    }
    const columns: ColumnsType<DataType> = [
        {
            title: 'User Name',
            dataIndex: 'userName',
            key: 'userName',
            sorter: (a, b) => {
                const aFirstLetter = a.userName[0].toLowerCase();
                const bFirstLetter = b.userName[0].toLowerCase();
                if (aFirstLetter < bFirstLetter) {
                    return -1;
                }
                if (aFirstLetter > bFirstLetter) {
                    return 1;
                }
                return 0;
            },
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            sorter: (a, b) => {
                const aFirstLetter = a.email[0].toLowerCase();
                const bFirstLetter = b.email[0].toLowerCase();
                if (aFirstLetter < bFirstLetter) {
                    return -1;
                }
                if (aFirstLetter > bFirstLetter) {
                    return 1;
                }
                return 0;
            },
        },
        {
            title: 'User Role',
            dataIndex: 'userRole',
            key: 'userRole',
            sorter: (a, b) => {
                const aFirstLetter = a.userRole[0].toLowerCase();
                const bFirstLetter = b.userRole[0].toLowerCase();
                if (aFirstLetter < bFirstLetter) {
                    return -1;
                }
                if (aFirstLetter > bFirstLetter) {
                    return 1;
                }
                return 0;
            },
        },
        {
            title: 'User Status',
            dataIndex: 'userStatus',
            key: 'userStatus',
            sorter: (a, b) => {
                const aFirstLetter = a.userStatus[0].toLowerCase();
                const bFirstLetter = b.userStatus[0].toLowerCase();
                if (aFirstLetter < bFirstLetter) {
                    return -1;
                }
                if (aFirstLetter > bFirstLetter) {
                    return 1;
                }
                return 0;
            },
            render: (text, record) => (
                <div className="flex">
                    <div className={
                        // text === "ToDo" || text === "Completed"  ? "qcStatus_default" :
                        text == "Active" ? "qcStatus_pass" : "qcStatus_default"
                    }>
                        <div className="round"></div>
                        <span>{text}</span>
                    </div>
                </div>
            ),
        },
        {
            title: 'Created Date',
            key: 'dateCreated',
            dataIndex: 'dateCreated',
            sorter: (a, b) => +new Date(a.dateCreated) - +new Date(b.dateCreated),
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
                <div className='action'>
                    <img title='Edit' onClick={() => {
                        setEditId(record.id)
                        setEditOpen(true)
                    }} src={edit} alt="" />
                    {/* <svg style={{cursor:"pointer"}} onClick={()=>{
                        setEditId(record.id)
                        setEditOpen(true)
                    }} className="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2417" width="32" height="32"><path d="M619.018576 484.15844a57.96838 57.96838 0 0 1 55.519012 60.417748v142.063354a57.96838 57.96838 0 0 1-55.519012 60.417748H415.721018A57.96838 57.96838 0 0 1 356.936183 686.639542V544.576188a57.96838 57.96838 0 0 1 55.519011-60.417748zM520.227393 537.228084a51.436731 51.436731 0 0 0-52.253187 50.620275 50.620275 50.620275 0 0 0 30.208874 41.639259v65.316484h44.905083V628.671162a50.620275 50.620275 0 0 0 30.208874-41.639259 51.436731 51.436731 0 0 0-53.069644-49.803819z m0-157.576019a48.987363 48.987363 0 0 0-52.253187 52.253188v52.253187h-56.335468v-47.354451a105.322831 105.322831 0 1 1 210.645662 0v47.354451h-52.253187v-52.253187A48.987363 48.987363 0 0 0 520.227393 379.652065z" fill="#cdcdcd" p-id="2418"></path><path d="M520.227393 1023.835892A489.873633 489.873633 0 1 1 748.835089 107.772199a40.822803 40.822803 0 1 1-39.189891 71.848133 408.228027 408.228027 0 1 0 202.481102 263.715306 40.822803 40.822803 0 0 1 81.645605-18.77849A489.873633 489.873633 0 0 1 520.227393 1023.835892z" fill="#cdcdcd" p-id="2419"></path><path d="M760.265473 0L600.240087 221.259591l258.816569 38.373434L760.265473 0z" fill="#cdcdcd" p-id="2420"></path></svg> */}
                </div>
            ),
        },
    ];
    const [data, setData] = useState<DataType[]>([])
    const showModal = () => {
        setIsModalOpen(true);
    };
    useEffect(() => {
        getInspectionTableData({ ...page })
        setTitle("Users")
    }, [updateList])
    const onSearch = (Keyword: string) => {
        getInspectionTableData({
            ...params,
            Keyword,
            PageIndex: 0,
            PageSize: 10
        })
        setPage({ PageIndex: 0, pageSize: 10 })
        setParams({
            ...params,
            Keyword
        })
    };
    const getInspectionTableData = async (params?: any) => {
        setLoading(true)
        GetUserList({ params }).then((res: any) => {
            if (res.isSuccess) {
                setLoading(false)
                setItems(`(${res?.totalCount.toLocaleString()})`)
                setData(res?.data?.data?.map((item: any, index: number) => ({ key: index, ...item })))
                setTotal(res.totalCount)
            } else {
                setLoading(false)
                setItems(0)
                setData([])
                setTotal(0)
            }
        }).catch(error => {
            console.log(error);
            setLoading(false)
            setItems(0)
            setData([])
            setTotal(0)
        })

    }
    return (
        <section>
            <div className="flex justify-between">
                <div>
                    <Button type="primary" onClick={() => setIsModalOpen(true)} className="mr-[5px] new" style={{ borderRadius: "4px" }}>+ New</Button>
                    <Search
                        placeholder="Search by Email"
                        style={{ width: 400 }}
                        className="search"
                        onSearch={onSearch}
                        onChange={(e) => setParams({
                            ...params,
                            Keyword: e.target.value
                        })}
                    />
                </div>
                <div>
                    <span className="vendor">User Status</span>
                    <Select
                        style={{ width: 120 }}
                        onChange={handleChange}
                        className="select"
                        defaultValue="All"
                        options={[
                            { value: '', label: 'All' },
                            { value: 'Active', label: 'Active' },
                            { value: 'Inactive', label: 'Inactive' },
                        ]}
                    />
                    <span className="status">User Role</span>
                    <Select
                        style={{ width: 150 }}
                        onChange={handleStatus}
                        className="select"
                        defaultValue="All"
                        options={[
                            { value: '', label: 'All' },
                            { value: 'Super Admin', label: 'Super Admin' },
                            { value: 'Inspector', label: 'Inspector' },
                        ]}
                    />
                </div>
            </div>
            <AddUser isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} updateList={updateList} setUpdateList={setUpdateList} />
            {editOpen && <EditUser editOpen={editOpen} editId={editId} setEditOpen={setEditOpen} updateList={updateList} setUpdateList={setUpdateList} />}
            <div className="page-content bg-white" style={{ height: 200 }}>
                <Table
                    className="qc-table"
                    columns={columns}
                    dataSource={data}
                    loading={loading}
                    scroll={{ y: 500 }}
                    pagination={{
                        current: page.PageIndex + 1,
                        pageSize: page.pageSize,
                        total,
                        onChange: (page, pageSize) => {
                            setPage({ PageIndex: page - 1, pageSize: pageSize })
                            getInspectionTableData({
                                ...params, PageIndex: page - 1, PageSize: pageSize
                            })
                        }
                    }}
                // expandIcon={(e)=>{
                //     return <>e</>
                // }}
                />
            </div>
        </section>
    )
}
export default User;