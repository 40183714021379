import { Input, Checkbox, Form, DatePicker, Table, Spin, message, Image, Button, InputNumber, Modal, Select } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import img from '@/images/navigation/img2.png'
const { TextArea } = Input
const ReportTable: React.FC<{
    data: DataType[];
    setData: Function;
    setIsModalOpen: Function;
    setFileIdArr: Function;
    setFileIndex: Function;
}> = ({ data, setData, setIsModalOpen, setFileIdArr, setFileIndex }) => {
    const columns: ColumnsType<DataType> = [
        // {
        //     title: 'Category',
        //     dataIndex: 'category',
        //     render: (text, record) => (
        //         <div className="detail1">{text || record.details}</div>
        //     )
        // },
        {
            title: 'Details',
            dataIndex: 'details',
            // width: 150,
            render: (text, record) => (
                <div className={record?.list === 1 ? "detail1" : "detail2"}>{text}</div>
            )

        },
        {
            title: 'Value',
            dataIndex: 'standard',
            // width: 150,
            className: "standard",
            render: (text, record) => (
                <div className="comment">
                    {text?.indexOf("http") !== -1 ? <Image width={50} src={text} /> : <>{text}</>}
                </div>
            )

        },
        {
            title: 'NA',
            dataIndex: 'NA',
            align: "center",
            width: 100,
            render: (_, record, index) => (
                record.NA !== "" && <div className="reportTd"
                    onClick={() => {
                        if (record.list === 1) {
                            if (record.NA) {
                                record.NA = false
                            } else {
                                record.NA = true
                                record.AC = false
                                record.MA = false
                                record.MI = false
                                record.PD = false
                            }
                        } else if (record.list === 2) {
                            data.forEach((x, i) => {
                                x?.children?.forEach((y, idx2) => {
                                    if (record.key === y.key) {
                                        if (record.NA) {
                                            y.NA = false
                                        } else {
                                            y.NA = true
                                            y.MI = false
                                            y.MA = false
                                            y.AC = false
                                            y.PD = false
                                        }
                                    }
                                })

                            })
                        }
                        setData(JSON.parse(JSON.stringify(data)))
                    }}
                >
                    <div className="round_" style={{ background: record.NA ? "#7D8FB3" : "" }}>
                        {record.NA && <svg width="100%" height="100%" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="12.749" cy="12" r="7.5" fill="white" stroke="#7D8FB3" strokeWidth="9" />
                        </svg>}
                    </div>
                </div>
            )
        },
        {
            title: 'AC',
            dataIndex: 'AC',
            align: "center",
            className: "ac",
            width: 100,
            render: (_, record, index) => (
                record.AC !== "" && <div className="reportTd"
                    onClick={() => {
                        if (record.list === 1) {
                            if (record.AC) {
                                record.AC = false
                            } else {
                                record.AC = true
                                record.MI = false
                                record.MA = false
                                record.NA = false
                                record.PD = false
                            }
                        } else if (record.list === 2) {
                            data.forEach((x, i) => {
                                x?.children?.forEach((y, idx2) => {
                                    if (record.key === y.key) {
                                        if (record.AC) {
                                            y.AC = false
                                        } else {
                                            y.AC = true
                                            y.MI = false
                                            y.MA = false
                                            y.NA = false
                                            y.PD = false
                                        }
                                    }
                                })

                            })
                        }
                        setData(JSON.parse(JSON.stringify(data)))
                    }}
                >
                    <div className="round_" style={{ background: record.AC ? "#98C9A6" : "" }}>
                        {record.AC && <svg width="100%" height="100%" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="12.749" cy="12" r="7.5" fill="white" stroke="#98C9A6" strokeWidth="9" />
                        </svg>}
                    </div>
                </div>
            )
        },
        {
            title: 'MI',
            dataIndex: 'MI',
            align: "center",
            className: "mi",
            width: 100,
            render: (_, record, index) => (
                record.MI !== "" && <div className="reportTd"
                    onClick={() => {
                        if (record.list === 1) {
                            if (record.MI) {
                                record.MI = false
                            } else {
                                record.MI = true
                                record.AC = false
                                record.MA = false
                                record.NA = false
                                record.PD = false
                            }
                        } else if (record.list === 2) {
                            data.forEach((x, i) => {
                                x?.children?.forEach((y, idx2) => {
                                    if (record.key === y.key) {
                                        if (record.MI) {
                                            y.MI = false
                                        } else {
                                            y.MI = true
                                            y.AC = false
                                            y.MA = false
                                            y.NA = false
                                            y.PD = false
                                        }
                                    }
                                })

                            })
                        }
                        setData(JSON.parse(JSON.stringify(data)))
                    }}
                >
                    <div className="round_" style={{ background: record.MI ? "#E3BA7B" : "" }}>
                        {record.MI && <svg width="100%" height="100%" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="12.749" cy="12" r="7.5" fill="white" stroke="#E3BA7B" strokeWidth="9" />
                        </svg>}
                    </div>
                </div>
            ),
        },
        {
            title: 'MA',
            dataIndex: 'MA',
            align: "center",
            className: "ma",
            width: 100,
            render: (_, record, index) => {
                return (
                    record.MA !== "" && <div className="reportTd"
                        onClick={() => {
                            if (record.list === 1) {
                                if (record.MA) {
                                    record.MA = false
                                } else {
                                    record.MA = true
                                    record.AC = false
                                    record.MI = false
                                    record.NA = false
                                    record.PD = false
                                }
                                setData(JSON.parse(JSON.stringify(data)))
                            } else if (record.list === 2) {
                                data.forEach((x, i) => {
                                    x?.children?.forEach((y, idx2) => {
                                        if (record.key === y.key) {
                                            if (record.MA) {
                                                y.MA = false
                                            } else {
                                                y.MA = true
                                                y.AC = false
                                                y.MI = false
                                                y.NA = false
                                                y.PD = false
                                            }
                                        }
                                    })

                                })
                                setData(JSON.parse(JSON.stringify(data)))
                            }
                        }}
                    >
                        <div className="round_" style={{ background: record.MA ? "#E37B7B" : "" }}>
                            {record.MA && <svg width="100%" height="100%" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="12.749" cy="12" r="7.5" fill="white" stroke="#E37B7B" strokeWidth="9" />
                            </svg>}
                        </div>
                    </div>
                )
            }
        },
        {
            title: 'PD',
            dataIndex: 'PD',
            align: "center",
            className: "pd",
            width: 100,
            render: (_, record, index) => {
                return (
                    record.PD !== "" && <div className="reportTd"
                        onClick={() => {
                            if (record.list === 1) {
                                if (record.PD) {
                                    record.PD = false
                                } else {
                                    record.PD = true
                                    record.MA = false
                                    record.AC = false
                                    record.MI = false
                                    record.NA = false
                                }
                                setData(JSON.parse(JSON.stringify(data)))
                            } else if (record.list === 2) {
                                data.forEach((x, i) => {
                                    x?.children?.forEach((y, idx2) => {
                                        if (record.key === y.key) {
                                            if (record.PD) {
                                                y.PD = false
                                            } else {
                                                y.PD = true
                                                y.MA = false
                                                y.AC = false
                                                y.MI = false
                                                y.NA = false
                                            }
                                        }
                                    })

                                })
                                setData(JSON.parse(JSON.stringify(data)))
                            }
                        }}
                    >
                        <div className="round_" style={{ background: record.PD ? "#3B9CF6" : "" }}>
                            {record.PD && <svg width="100%" height="100%" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="12.749" cy="12" r="7.5" fill="white" stroke="#3B9CF6" strokeWidth="9" />
                            </svg>}
                        </div>
                    </div>
                )
            }
        },
        {
            title: 'Images',
            dataIndex: 'images',
            align: "center",
            width: 100,
            onCell: (record, index) => {
                if (record?.category) {
                    if (data[index! - 1]?.category === record?.category) {
                        return { rowSpan: 0 }

                    } else {
                        return { rowSpan: record.categoryLength }
                    }
                } else {
                    return { rowSpan: 1 }
                }
            },
            render: (text, record, index) => {
                let state: boolean | "" = false
                if (record.category) {
                    let arr = data.filter(item => item.category === record.category)
                    state = arr.some(item => item.MI || item.MA)
                } else {
                    state = (record.MI || record.MA)
                }
                return (
                    <div style={{ cursor: "pointer", color: "#3361FF" }} onClick={() => {
                        setIsModalOpen(true);
                        setFileIdArr(text)
                        setFileIndex(index)
                    }}><img src={img} alt="" />
                        {record.uploadStatus ? record?.fileList?.length : text?.length}
                        {(record.mustAddPhoto || state) && <span style={{ color: "red" }}> *</span>}
                        {/* {(record.MA || record.MI || record.mustAddPhoto) && <span style={{ color: "red" }}> *</span>} */}
                    </div>
                )
            }
        },
        {
            title: 'Complete',
            dataIndex: 'complete',
            align: "center",
            className: "completeTh",
            width: 100,
            // sorter: (a, b) => a.completeStatus - b.completeStatus,
            onCell: (record, index) => {
                if (record?.category) {
                    if (data[index! - 1]?.category === record?.category) {
                        return { rowSpan: 0 }

                    } else {
                        return { rowSpan: record.categoryLength }
                    }
                } else {
                    return { rowSpan: 1 }
                }
            },
            render: (text, record, index) => {
                let completeStatus: string | boolean = false
                if (record.category) {
                    let categoryData = data.filter(x => x.category === record.category)
                    let isImage = categoryData.some(x => x.uploadStatus ? x.fileList.length : x.images.length)
                    let inspectionStatus = categoryData.some(x => x.MI || x.MA)
                    let status = categoryData.every(x => x.NA || x.AC || x.MI || x.MA)
                    // completeStatus = status && (record.parentArgumentMustAddPhoto ? isImage : (inspectionStatus ? isImage : true))
                    completeStatus = record.mustAddPhoto ? (status && isImage) : status && (inspectionStatus ? isImage : true)
                } else {
                    let qualityStatus = (record.AC || record.MA || record.MI || record.NA)
                    let imgStatus = ((record.AC || record.NA) && !record.mustAddPhoto) ? true : record.mustAddPhoto ? (record.uploadStatus ? record.fileList.length > 0 : record.images.length > 0) : ((record.MA || record.MI) && (record.uploadStatus ? record.fileList.length > 0 : record.images.length > 0))
                    completeStatus = (qualityStatus && imgStatus)
                }
                return (
                    <div>{
                        completeStatus ?
                            <svg className="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2318" width="24" height="24"><path d="M511.999994 0C229.205543 0 0.020822 229.226376 0.020822 512.020827c0 282.752797 229.184721 511.979173 511.979173 511.979173s511.979173-229.226376 511.979173-511.979173C1023.979167 229.226376 794.794446 0 511.999994 0zM815.371918 318.95082l-346.651263 461.201969c-10.830249 14.370907-27.32555 23.409999-45.27877 24.742952-1.582882 0.124964-3.12411 0.166619-4.665338 0.166619-16.328682 0-32.074198-6.373185-43.779197-17.911565l-192.903389-189.44604c-24.617988-24.20144-24.992881-63.731847-0.791441-88.349835 24.20144-24.659643 63.731847-24.951226 88.349835-0.833096l142.042875 139.501932 303.788472-404.2182c20.744091-27.575479 59.899605-33.115568 87.516739-12.413131C830.534266 252.219827 836.116009 291.375341 815.371918 318.95082z" fill="#9EDD8F" p-id="2319"></path></svg> :
                            <svg className="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3345" width="24" height="24"><path d="M512 0a512 512 0 0 0-512 512 512 512 0 0 0 512 512 512 512 0 0 0 512-512 512 512 0 0 0-512-512z" fill="#FD6B6D" p-id="3346"></path><path d="M513.755429 565.540571L359.277714 720.018286a39.058286 39.058286 0 0 1-55.296-0.073143 39.277714 39.277714 0 0 1 0.073143-55.442286l154.331429-154.331428-155.062857-155.136a36.571429 36.571429 0 0 1 51.712-51.785143l365.714285 365.714285a36.571429 36.571429 0 1 1-51.785143 51.785143L513.755429 565.540571z m157.549714-262.582857a35.254857 35.254857 0 1 1 49.737143 49.737143l-106.057143 108.982857a35.254857 35.254857 0 1 1-49.883429-49.810285l106.203429-108.982858z" fill="#FFFFFF" p-id="3347"></path></svg>}</div>

                )
            }
        },
        {
            title: 'Comment',
            dataIndex: 'comment',
            render: (text, record, index) => (
                <div className="comment">
                    <TextArea value={data[index].comment} style={{ height: "32px", color: "#3361FF" }} onChange={(e) => {
                        data[index].comment = e.target.value
                        setData(JSON.parse(JSON.stringify(data)))
                    }} />
                </div>
            )
        },
    ];
    return (
        <div className="report">
            <Table className="reportTable" bordered={false} columns={columns} dataSource={data} pagination={false} scroll={{ y: 400 }} />
        </div>
    )
}
export default ReportTable