
import React, { useState } from 'react'
import { Button } from 'antd'
import { useLocation,useNavigate } from 'react-router-dom';
const Report01: React.FC = () => {
    const location = useLocation()
    const navigate = useNavigate()
    let [active, setActive] = useState<any>({
        active0: null,
        active1: null,
        active2: null,
        active3: null,
    })
    let checkArr = [
        {
            text1:"DVCL-153PR",
            text2:"12.2/1.7W"
        },
        {
            text1:"MACL-153MLH",
            text2:"10.7/2.4W"
        },
        {
            text1:"P-PKG1W",
            text2:""
        },
        {
            text1:"STCL-153PH",
            text2:""
        },
    ]
    return (
        <>
        <div className='report_phone_content'>
            <h5>10.Functional Check</h5>
            <h1> 10.3. Lutron</h1>
            {checkArr.map((x1,i1) => (
                <div className='Functional_Check' key={i1}>
                    <ul className='desc'>
                        <li>{x1.text1}</li>
                        <li>{x1.text2}</li>
                    </ul>
                    <ul className='statue_'>
                        {['NA', 'AC', 'MI', 'MA'].map((x2, i2) => (
                            <li
                                className={active[`active${i1}`] === i2 ? `active${i2}` : ""}
                                key={i2+1}
                                onClick={() => setActive({
                                    ...active,
                                    [`active${i1}`]: i2
                                })}
                            >{x2}</li>
                        ))}
                    </ul>
                </div>
            ))}
        </div>
            <div className='prev_next'>
                <Button onClick={()=>navigate(`/report/report01`)}>Prev</Button>
                <Button onClick={()=>navigate(`/report/report03`)} type='primary'>Next</Button>
            </div>
        </>
    )
}
export default Report01;