import React, { useState, useEffect } from 'react';
import { Input, Spin, Form, Modal, Select, message } from 'antd';
import { EditUsers, GetUserDetail } from '@/services/login';
const EditUser: React.FC<{
    editOpen: boolean
    setEditOpen: Function
    setUpdateList: Function
    updateList: boolean
    editId: number
}> = ({ editOpen, setEditOpen, updateList, setUpdateList, editId }) => {
    const [form] = Form.useForm()
    const [loading, setLoading] = useState(false)
    const [dataLoading, setDataLoading] = useState(false)
    const close = () => {
        form.resetFields()
        setEditOpen(false)
    }
    const onFinish = async (values: any) => {
        const { UserName, OldPassword, NewPassword, ConfirmPassword } = values
        if (OldPassword !== "" || NewPassword !== "" || ConfirmPassword !== "") {
            if (NewPassword !== ConfirmPassword) {
                message.error('The two password entries are inconsistent.')
                return
            }
            setLoading(true)
            try {
                const res: any = await EditUsers({
                    params: {
                        ID: editId,
                        ...values,
                    }
                })
                if (res.isSuccess) {
                    message.success("Successfully.")
                    setLoading(false)
                    close()
                    setUpdateList(!updateList)
                } else {
                    message.error(res.message)
                    setLoading(false)
                }
            } catch (error: any) {
                console.log(error);
                setLoading(false)
                message.error(error.message)
            }
        } else {
            setLoading(true)
            try {
                const res: any = await EditUsers({
                    params: {
                        ID: editId,
                        UserName
                    }
                })
                if (res.isSuccess) {
                    message.success("Successfully.")
                    setLoading(false)
                    close()
                    setUpdateList(!updateList)
                } else {
                    message.error(res.message)
                    setLoading(false)
                }
            } catch (error: any) {
                console.log(error);
                setLoading(false)
                message.error(error.message)
            }
        }


    };
    useEffect(() => {
        getOldData()
    }, [])
    const getOldData = async () => {
        setDataLoading(true)
        const res: any = await GetUserDetail({ params: { ID: editId } })
        if (res.isSuccess) {
            form.setFieldsValue({ UserName: res.data.userName,UserStatus:res.data.userStatus })
            setDataLoading(false)
        }
    }
    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };
    return (
        <Modal
            title="Edit User"
            okText="Save"
            open={editOpen}
            confirmLoading={loading}
            onOk={() => form.submit()}
            onCancel={close}>
            <div className='modal_content'>
                <Spin spinning={dataLoading} >
                    <Form
                        form={form}
                        name="basic"
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        layout='vertical'
                    >
                        {/* <Form.Item
                        label="Email"
                        name="Email"
                        style={{ width: "100%" }}
                        rules={[{ required: true }, { type: "email", message: "Email is not a valid email!" }]}
                    >
                        <Input style={{ display: 'inline-block' }} />
                    </Form.Item>
                    <Form.Item
                        label="User Role"
                        name="UserRole"
                        style={{ width: "100%" }}
                        rules={[{ required: true }]}
                    >
                        <Select
                            className=""
                            options={[
                                { value: '0', label: 'Super Admin' },
                                { value: '1', label: 'Inspector' },
                            ]}
                        />
                    </Form.Item> */}
                        <Form.Item
                            label="User Name"
                            name="UserName"
                            style={{ width: "100%" }}
                            rules={[{ required: true }]}
                        >
                            <Input style={{ display: 'inline-block' }} />
                        </Form.Item>
                        <Form.Item
                            label="User Status"
                            name="UserStatus"
                            style={{ width: "100%" }}
                            rules={[{ required: true }]}
                        >
                            <Select
                                className=""
                                options={[
                                    { value: '1', label: 'Active' },
                                    { value: '0', label: 'Inactive' },
                                ]}
                            />
                        </Form.Item>
                        <Form.Item
                            label="Old Password "
                            name="OldPassword "
                            style={{ width: "100%" }}
                        >
                            <Input.Password />
                        </Form.Item>
                        <Form.Item
                            label="New Password "
                            name="NewPassword"
                            style={{ width: "100%" }}
                        >
                            <Input.Password />
                        </Form.Item>
                        <Form.Item
                            label="Confirm Password"
                            name="ConfirmPassword"
                            style={{ width: "100%" }}
                        >
                            <Input.Password />
                        </Form.Item>
                    </Form >
                </Spin>
            </div>
        </Modal>
    );
};

export default EditUser;