import request from '../request'
import { ResponseDataType,RequestOptionsType } from '../request'
export async function GetListByInspector(options?:any){
    return request('api/InspectionReport/GetInspectionListByInspector',{
        method:"GET",
        ...(options || {})
    })
}
export async function GetDashboardInfoByInspector(options?:any){
    return request('api/InspectionReport/GetDashboardInfoByInspector',{
        method:"GET",
        ...(options || {})
    })
}
export async function GetInspectionDetailByInspector<
    T extends inspectionReportAPI.GetInspectionDetailByInspectorRequest,
    R extends ResponseDataType<inspectionReportAPI.GetInspectionDetailByInspectorResponse>
>(options:RequestOptionsType<T>){
    return request<R>('api/InspectionReport/GetInspectionDetailByInspector',{
        method:"GET",
        ...(options || {})
    })
}
export async function OnSubmitReport(options:any){
    return request('api/InspectionReport/SubmitReport',{
        method:"POST",
        ...(options || {})
    })
}

export async function UploadFile(options:any){
    return request('api/Common/UploadFile',{
        method:"POST",
        ...(options || {}),
        
        
    })
}
export async function DeleteFile(options:any){
    return request('api/Common/DeleteFileFromInspection',{
        method:"POST",
        ...(options || {}),
        
        
    })
}
export async function GetFile(options:any){
    return request('api/Common/GetFile',{
        method:"GET",
        ...(options || {}),
        responseType:"blob",
        
        
    })
}
export async function GetInspectionDetailPer<
    R extends ResponseDataType<inspectionReportAPI.GetInspectionDetailPerResponse>
>(options:RequestOptionsType<{inspectionReportid:number}>){
    return request<R>('api/InspectionReport/GetInspectionDetailPer',{
        method:"GET",
        ...(options || {}),
    })
}
export async function Export(options:any){
    return request('api/InspectionReport/Export',{
        method:"GET",
        ...(options || {}),
        responseType:"blob",
        
        
    })
}
export async function VerifyReport(options:any){
    return request('api/InspectionReport/VerifyReport',{
        method:"POST",
        ...(options || {}),
    })
}
export async function GetReportList<
    T extends inspectionReportAPI.GetReportListRequest,
    R extends ResponseDataType<inspectionReportAPI.GetReportListResponse>
>(options:RequestOptionsType<T>){
    return request<R>('api/InspectionReport/GetReportList',{
        method:"GET",
        ...(options || {}),
    })
}
export async function VerifyLastConditionalPassComment<
    R extends ResponseDataType<boolean>
>(options:RequestOptionsType<{id:number}>){
    return request<R>('api/InspectionReport/VerifyLastConditionalPassComment',{
        method:"GET",
        ...(options || {}),
    })
}
