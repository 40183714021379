
import React, { useState } from 'react'
import type { RcFile, UploadFile, UploadProps } from 'antd/es/upload/interface';
import camera from '@/images/navigation/camera.png'
import { Button, Input, Upload } from 'antd'
import { useLocation,useNavigate } from 'react-router-dom';
const { TextArea } = Input
const Report01: React.FC = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const [active, setActive] = useState<number | null>(null)
    const [fileList, setFileList] = useState<UploadFile[]>([]);
    const onChange: UploadProps['onChange'] = ({ fileList: newFileList }) => {
        setFileList(newFileList);

    };
    const onPreview = async (file: UploadFile) => {
        let src = file.url as string;
        if (!src) {
            src = await new Promise((resolve) => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj as RcFile);
                reader.onload = () => resolve(reader.result as string);
            });
        }
        const image = new Image();
        image.src = src;
        const imgWindow = window.open(src);
        imgWindow?.document.write(image.outerHTML);
    };
    return (
        <>
            <div className='report_phone_content'>
                <h1>1.1. Self-inspection report</h1>
                <ul className='report_status'>
                    {['NA', 'AC', 'MI', 'MA'].map((item, index) => (
                        <li
                            key={index}
                            className={active === index ? `active${index}` : ""}
                            onClick={() => setActive(index)}
                        >{item}</li>
                    ))}
                </ul>
                <h3>Take photos</h3>
                <div className='task_photos'>
                    <Upload
                        // action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                        listType="picture-card"
                        fileList={fileList}
                        onChange={onChange}
                        onPreview={onPreview}
                    >
                        <img src={camera} alt='' />
                    </Upload>
                </div>
                <h3>Comment</h3>
                <TextArea />
            </div>
            {/* <p className='warn'>
                <img src={warn} alt="" />
                <span>There are unfinished items, please ensure all are completed before submitting.</span>
            </p> */}
            <div className='prev_next'>
                    <Button onClick={()=>navigate(`/inspectionDetail`)}>Prev</Button>
                    <Button onClick={()=>navigate(`/report/report0${+location.pathname.slice(-1) + 1}`)} type='primary'>Next</Button>
            </div>
        </>
    )
}
export default Report01;