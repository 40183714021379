import { useEffect, useRef } from 'react';
import '@/styles/inspectionPhone/inspectionList.less'
import { CaretLeftOutlined, SearchOutlined } from '@ant-design/icons'
import { Input, Empty, Spin, message } from 'antd';
import { useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { GetListByInspector } from '@/services/inspectionReport';
const { Search } = Input;
export default function InspectionList() {
    const [Status, setStatus] = useState("All")
    const [Keyword, setKeyword] = useState("")
    const [loading, setLoading] = useState(false)
    const [hasMore, setHasMore] = useState(true)
    const PageIndex = useRef(0)
    const [search] = useSearchParams()
    const [currentLoading, setCurrentLoading] = useState(false)
    const viewMore = useRef<any>(null)
    const viewMoreDom = useRef<any>(null)
    const navigate = useNavigate()
    const [statusArr, setStatusArr] = useState([
        {
            text: "All",
            num: 0
        },
        {
            text: "Pending",
            num: 0
        },
        {
            text: "In Progress",
            num: 0
        },
        {
            text: "Submitted",
            num: 0
        },
    ])
    const [inspectionList, setInspectionList] = useState<{
        inspectionProjectId: number;
        itemNo: string
        poNumber: string
        quantity: number
        progress: string
        status: string
        purchaseOrder_InspectionProjectId: number
        orderDate: string
        per: {
            inspectionResult: number
        }
    }[]>([])
    const getInspectionList = async (params?: {
        Keyword?: string | ""
        Status?: string | ""
        PageIndex: number
        PageSize: number
    }) => {
        setLoading(true)
        setHasMore(true)
        try {
            const res: any = await GetListByInspector({ params })
            if (res.isSuccess) {
                // if (!params) {
                setStatusArr([
                    {
                        text: "All",
                        num: res?.data?.allCount
                    },
                    {
                        text: "Pending",
                        num: res?.data?.pendingCount
                    },
                    {
                        text: "In Progress",
                        num: res?.data?.inProgressCount
                    },
                    {
                        text: "Submitted",
                        num: res?.data?.submittedCount
                    },
                ])
                // }
                setInspectionList(res.data.data)
                setLoading(false)
                if (res.data.data.length >= 10) {
                    setHasMore(true)
                } else {
                    setHasMore(false)
                }
            } else {
                setInspectionList([])
                setLoading(false)

            }
        } catch (error: any) {
            message.error(error.status === 500 ? "Internal Server Error" : error.message);

            setInspectionList([])
            setLoading(false)
        }
    }
    const loadmoreData = async (params: {
        Keyword?: string | ""
        Status?: string | ""
        PageIndex: number
        PageSize: number
    }) => {
        try {
            setCurrentLoading(true)
            const res: any = await GetListByInspector({ params })
            if (res.isSuccess) {
                if (res.data.data.length) {
                    setInspectionList([...inspectionList, ...res.data.data])
                    if (res?.data?.data?.length >= 10) {
                        setHasMore(true)
                    } else {
                        setHasMore(false)
                    }
                } else {
                    setHasMore(false)
                }
                setCurrentLoading(false)

            }
        } catch (error: any) {

        }
    }
    const onSearch = (value: any) => {
        if (loading) { return }
        getInspectionList({
            Keyword: value,
            Status,
            PageIndex: 0,
            PageSize: 10
        })
        PageIndex.current = 0
        setKeyword(value)
    }
    useEffect(() => {
        const Status = search.get('status') || ""
        setStatus(Status)
        getInspectionList({ Keyword: "", Status, PageIndex: 0, PageSize: 10 })
        const observer = new IntersectionObserver((entries) => {
            if (entries[0].isIntersecting && hasMore && !currentLoading && !loading) {
                viewMoreDom.current?.click()
            }
        }, {

        })
        observer.observe(viewMore.current)
    }, [])
    return (
        <section className="inspectionList">
            <div className='phone_header'>
                <CaretLeftOutlined onClick={() => navigate('/inspectionPhone')} />
                <span>Inspection list</span>
                <span></span>
            </div>
            <Search className='search' onChange={(e) => setKeyword(e.target.value)
            } placeholder="Search by Item No., PO No...." onSearch={onSearch} />
            {/* <input type="text" className='search' onKeyDown={(e:any)=>{
                if (e.key === "Enter") {
                    getInspectionList({
                        Keyword: e.target.value,
                        Status
                    })
                    setKeyword(e.target.value)
                }
            }} placeholder='Search by Item No., PO No....' /> */}
            <ul className='status'>
                {statusArr.map((item, index) => (
                    <li className={Status === item.text ? "active" : ""} onClick={() => {
                        if (loading) { return }
                        setHasMore(true)
                        getInspectionList({ Keyword, Status: item.text, PageIndex: 0, PageSize: 10 }).then(() => {
                            PageIndex.current = 0
                        })
                        setStatus(item.text)
                        navigate(`/inspectionList?status=${item.text}`)
                    }} key={index}>{item.text}<span>({item.num})</span></li>
                ))}
            </ul>
            <Spin spinning={loading}>
                <ul className='newInspectionsList'>
                    {inspectionList.map((item, index) => (
                        <Link key={index} to={
                            window.matchMedia('(max-width: 768px)').matches ?
                                `/inspectionDetail?itemNo=${item.itemNo}&PONumber=${item.poNumber}&itemId=${item.purchaseOrder_InspectionProjectId}` :
                                `/inspectionPCReport?itemNo=${item.itemNo}&poNumber=${item.poNumber}&itemId=${item.purchaseOrder_InspectionProjectId}`
                        } >
                            <li className='list_li' >
                                <div className='no'>
                                    <h3>{item.itemNo}</h3>
                                    <div>
                                        {item.status.indexOf("Submitted") === -1 && <span>{item.progress}</span>}
                                        <div className={
                                            item.status.indexOf("Pending") !== -1 ? "pending" :
                                                item.status.indexOf("Progress") !== -1 ? "InProgress" :
                                                    item.status.indexOf("Submitted") !== -1 ?
                                                        (item.per.inspectionResult === 0 ? "Fail" :
                                                            item.per.inspectionResult === 1 ? "submitted" :
                                                                item.per.inspectionResult === 2 ? "Pass" :
                                                                    item.per.inspectionResult === 3 ? "Pass" : "") : ""
                                        }>{item.status.indexOf("Submitted") === -1 ? item.status : (//0 = Fail, 1 = Pending, 2 = Pass, 3 = ConditionalPass
                                            item.per.inspectionResult === 0 ? "Fail" :
                                                (item.per.inspectionResult === 1 ? "Pending" :
                                                    (item.per.inspectionResult === 2 ? "Pass" :
                                                        (item.per.inspectionResult === 3 ? "Conditional Pass" : "")))
                                        )}</div>
                                    </div>
                                </div>
                                <ul className='type'>
                                    <li><span>{item.poNumber}</span><span>{item.quantity}</span></li>
                                    <li>{new Date(item.orderDate).toLocaleString()}</li>
                                </ul>
                            </li>
                        </Link>
                    ))}
                    {!loading && inspectionList.length === 0 && <li><Empty image={Empty.PRESENTED_IMAGE_SIMPLE} /></li>}
                    <li ref={viewMore} style={{ height: "50px", display: "flex", justifyContent: "center", marginTop: "20px" }}>
                        {inspectionList.length ? (
                            currentLoading ?
                                <Spin spinning={true} /> :
                                <div className='more' ref={viewMoreDom} onClick={() => {
                                    console.log(hasMore);
                                    if (hasMore) {
                                        loadmoreData({ Keyword, Status, PageIndex: ++PageIndex.current, PageSize: 10 })
                                    }
                                }}>{hasMore ? "View More" : "No More Data"}</div>
                        ) : <></>}

                    </li>
                </ul>
            </Spin>
        </section>
    )
}