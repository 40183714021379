import { useState, useEffect, useContext } from 'react'
import React from "react"
import { Modal, Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { GetItemsByPONumber } from '@/services/purchaseOrder';
import "@/styles/itemDetailPopup.less"
const ItemDetail: React.FC<{
    PONumber: string
    isModalOpen: boolean
    setIsModalOpen: Function
}> = ({ isModalOpen, setIsModalOpen, PONumber }) => {
    const [loading, setLoading] = useState(false)
    interface DataType {
        key: string
        itemNo: string,
        variantCode: string,
        vendorItemNo: string,
        vendorName: string,
        itemDescription: string,
        vendorNo: string,
        quantity: number,
        outstandingQuantity: number
        lineNo: number
        expectedReceiptDate: string
        orderDate: string
    }
    const columns: ColumnsType<DataType> = [
        {
            title: 'Item No',
            dataIndex: 'itemNo',
            key: 'itemNo',
            sorter: (a, b) => +a.itemNo.replace('-', '') - +b.itemNo.replace('-', ''),
        },
        // {
        //     title: 'Variant Code',
        //     dataIndex: 'variantCode',
        //     key: 'variantCode',
        //     sorter: (a, b) => +a?.variantCode - +b?.variantCode,
        // },
        {
            title: 'Description',
            key: 'itemDescription',
            dataIndex: 'itemDescription',
            sorter: (a, b) => {
                const aFirstLetter = a.itemDescription[0].toLowerCase();
                const bFirstLetter = b.itemDescription[0].toLowerCase();
                if (aFirstLetter < bFirstLetter) {
                    return -1;
                }
                if (aFirstLetter > bFirstLetter) {
                    return 1;
                }
                return 0;
            },
        },
        {
            title: 'Vendor Name',
            dataIndex: 'vendorName',
            key: 'vendorName',
            sorter: (a, b) => {
                const aFirstLetter = a.vendorName[0].toLowerCase();
                const bFirstLetter = b.vendorName[0].toLowerCase();
                if (aFirstLetter < bFirstLetter) {
                    return -1;
                }
                if (aFirstLetter > bFirstLetter) {
                    return 1;
                }
                return 0;
            },
        },
        {
            title: 'Vendor Item No',
            key: 'vendorItemNo',
            dataIndex: 'vendorItemNo',
            sorter: (a, b) => {
                const aFirstLetter = a.vendorItemNo[0].toLowerCase();
                const bFirstLetter = b.vendorItemNo[0].toLowerCase();
                if (aFirstLetter < bFirstLetter) {
                    return -1;
                }
                if (aFirstLetter > bFirstLetter) {
                    return 1;
                }
                return 0;
            },
        },
        {
            title: 'Vendor No',
            key: 'vendorNo',
            dataIndex: 'vendorNo',
            sorter: (a, b) => +a.vendorNo.replace('-', '') - +b.vendorNo.replace('-', ''),
        },
        {
            title: 'Quantity',
            key: 'quantity',
            dataIndex: 'quantity',
            sorter: (a, b) => +a.quantity - +b.quantity,
            render:(text)=>(text.toLocaleString()),
            align:"right"
        },
        {
            title: 'Outstanding Quantity',
            key: 'outstandingQuantity',
            dataIndex: 'outstandingQuantity',
            sorter: (a, b) => +a.outstandingQuantity - +b.outstandingQuantity,
            render:(text)=>(text.toLocaleString()),
            align:"right"
        },
        {
            title: 'Line No',
            key: 'lineNo',
            dataIndex: 'lineNo',
            sorter: (a, b) => +a.lineNo - +b.lineNo,
        },
        {
            title: 'Expected Receipt Date',
            key: 'expectedReceiptDate',
            dataIndex: 'expectedReceiptDate',
            sorter: (a, b) => +new Date(a.expectedReceiptDate) - +new Date(b.expectedReceiptDate),
        },
        {
            title: 'Order Date',
            key: 'orderDate',
            dataIndex: 'orderDate',
            sorter: (a, b) => +new Date(a.orderDate) - +new Date(b.orderDate),
        },
    ];
    const [data, setData] = useState<DataType[]>([
    ])
    const getItemData = async () => {
        setLoading(true)
        setData([])
        const res: any = await GetItemsByPONumber({ params: { PONumber } })
        if (res.isSuccess) {
            setData(res.data?.map((item:DataType)=>({
                ...item,
                key:item.itemNo
            })))
        } else {
            setData([])
        }
        setLoading(false)
    }
    useEffect(() => {
        if (isModalOpen) {
            getItemData()
        }
    }, [isModalOpen])
    return (
        <Modal
            className='itemDetailPopup'
            title="Items"
            maskClosable={false}
            open={isModalOpen}
            footer={null}
            onOk={() => setIsModalOpen(false)}
            onCancel={() => setIsModalOpen(false)}>
            <Table
                className="qc-table"
                columns={columns}
                dataSource={data}
                loading={loading}
                scroll={{ x: 1800 }}
            />
        </Modal>
    )
}
export default ItemDetail