import request from '../request'

export async function GetPurchaseOrder(options?:any){
    return request('api/PurchaseOrder/Get',{
        method:"GET",
        ...(options || {})
    })
}
export async function GetReportDetail(options:any){
    return request('api/PurchaseOrder/GetReportDetail',{
        method:"GET",
        ...(options || {})
    })
}
export async function EditInspectionPurchaseOrder(options:any){
    return request('api/PurchaseOrder/EditInspectionPurchaseOrder',{
        method:"POST",
        ...(options || {})
    })
}
export async function GetPoList(options?:any){
    return request('api/PurchaseOrder/GetPONumberList',{
        method:"GET",
        ...(options || {})
    })
}
export async function GetItemsByPONumber(options:any){
    return request('/api/PurchaseOrder/GetItemsByPONumber',{
        method:"GET",
        ...(options || {})
    })
}
